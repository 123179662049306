<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
    <span *ngIf="lang == 1"> درج کاربر </span>
    <span *ngIf="lang == 2"> Insert user</span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="insert()">
    <div mat-dialog-content class="matDialogContent" style="padding:0px !important">
        <div class="row">
            <div class='col-md-912 col-lg-12 col-sm-12 col-xs-12 pe'>
                <div class="row">

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="sex" class='wh-label'>
                            <span *ngIf="lang == 1"> عنوان </span>
                            <span *ngIf="lang == 2"> Title </span>
                            <span (click)="reset_input(10)">
                                <mat-icon class="close" class="input_search input_search_pe"
                                    [ngClass]="{input_search_en:lang==2}">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='sex_title' autocomplete="off" readonly
                                (click)="load_services(10)" required>
                            <input class='wh-input' formControlName='sex' autocomplete="off" required readonly
                                [hidden]="true">
                        </label>
                    </div>
                    <div class='ol-md-3 col-lg-3 col-sm-4  col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="groups" class='wh-label'>
                            <span *ngIf="lang == 1"> گروه کاربری</span>
                            <span *ngIf="lang == 2">User group</span>
                            <span (click)="reset_input(155)">
                                <mat-icon class="close" class="input_search input_search_pe"
                                    [ngClass]="{input_search_en:lang==2}">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='groups_title' autocomplete="off"
                                (click)="load_services(155)" required readonly>
                            <input class='wh-input' formControlName='groups' autocomplete="off" required [hidden]="true"
                                readonly>
                        </label>
                    </div>
                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="title" class='wh-label'>
                            <span *ngIf="lang == 1">نام و نام خانوادگی</span>
                            <span *ngIf="lang == 2">first name and last name</span>
                            <input class='wh-input' formControlName='title' autocomplete="off" required>
                        </label>
                    </div>
                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="store" class='wh-label'>
                            <span *ngIf="lang == 1">نام شرکت / فروشگاه</span>
                            <span *ngIf="lang == 2">Company / store name</span>
                            <input class='wh-input' formControlName='store' autocomplete="off">
                        </label>
                    </div>


                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="cellphone" class='wh-label'>
                            <span *ngIf="lang == 1"> شماره همراه </span>
                            <span *ngIf="lang == 2">mobile number</span>
                            <input class='wh-input' formControlName='cellphone' pattern="[0-9]{1,}" minlength=11
                                maxlength=11 autocomplete="off" (keypress)="serverService.get_number($event)">
                        </label>
                    </div>
                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="phone" class='wh-label'>
                            <span *ngIf="lang == 1"> شماره ثابت </span>
                            <span *ngIf="lang == 2">fixed number</span>
                            <input class='wh-input' formControlName='phone' autocomplete="off"
                                (keypress)="serverService.get_number($event)">
                        </label>
                    </div>
                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="email" class='wh-label'>
                            <span *ngIf="lang == 1"> پست الکترونیک </span>
                            <span *ngIf="lang == 2">E-mail</span>
                            <input class='wh-input' formControlName='email' autocomplete="off">
                        </label>
                    </div>
                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="code_meli" class='wh-label'>
                            <span *ngIf="lang == 1"> کد ملی / شناسه </span>
                            <span *ngIf="lang == 2">National code / ID</span>
                            <input class='wh-input' formControlName='code_meli' autocomplete="off"
                                (keypress)="serverService.get_number($event)">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="code_posti" class='wh-label'>
                            <span *ngIf="lang == 1"> کد پستی </span>
                            <span *ngIf="lang == 2">Postal code</span>
                            <input class='wh-input' formControlName='code_posti' autocomplete="off"
                                (keypress)="serverService.get_number($event)" length="10" minlength="10" maxlength="10">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="economic_code" class='wh-label'>
                            <span *ngIf="lang == 1"> شماره اقتصادی </span>
                            <span *ngIf="lang == 2">Economical number</span>
                            <input class='wh-input' formControlName='economic_code' autocomplete="off"
                                (keypress)="serverService.get_number($event)">
                        </label>
                    </div>
                    <div class='col-md-6 col-lg-6 col-sm-6 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="address" class='wh-label'>
                            <span *ngIf="lang == 1"> آدرس </span>
                            <span *ngIf="lang == 2">Address</span>
                            <input class='wh-input' formControlName='address' autocomplete="off">
                        </label>
                    </div>
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <label for="comment" class='wh-label'>
                            <span *ngIf="lang == 1"> توضیحات </span>
                            <span *ngIf="lang == 2">Comment</span>
                            <textarea class="wh-input" formControlName='comment' style="height:80px"></textarea>
                        </label>
                    </div>
                </div><!-- row -->
            </div><!-- col-md-8 col-lg-8 col-sm-8 col-xs-12  -->
        </div><!-- row -->
    </div> <!-- content -->


    <div mat-dialog-actions class="matDialogActions" style="padding-right:25px">
        <button class="btn_pe" [ngClass]="{btn_en:lang==2}" [disabled]="!form1.valid">
            {{ serverService.get_event_title(1,lang) }}
        </button>
    </div><!-- row -->
</form>