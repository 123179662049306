<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        درج جدید
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="group" class='wh-label'>
                    <span> گروه </span>
                    <mat-icon class="add-icon fleft" (click)="add_group()">
                        add </mat-icon>
                    <select class="wh-input" formControlName="group" (change)="get_cate()" required>
                        <option value=""></option>
                        <option *ngFor="let x of list_group" [value]="x.wharehouse_group_id">
                            {{ x.wharehouse_group_title }}
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="cate" class='wh-label'>
                    <span> زیر گروه </span>
                    <mat-icon class="add-icon fleft" (click)="add_cate()">
                        add </mat-icon>
                    <select class="wh-input" formControlName="cate" required>
                        <option value=""></option>
                        <option *ngFor="let x of list_cate" [value]="x.wharehouse_material_cate_id">
                            {{ x.wharehouse_material_cate_title }}
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="title" class='wh-label'>
                    <span> عنوان کالا </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="inventory" class='wh-label'>
                    <span> موجودی اول دوره </span>
                    <input class='wh-input' formControlName="inventory" autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="cate" class='wh-label'>
                    <span> واحد اندازه گیری </span>
                    <span>
                        <mat-icon class="add-icon fleft" (click)="add_weight()">
                            add </mat-icon>
                    </span>
                    <select class="wh-input" formControlName="weight" required>
                        <option value=""></option>
                        <option *ngFor="let x of list_weight" [value]="x.wharehouse_weight_id">
                            {{ x.wharehouse_weight_title }}
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="cost" class='wh-label'>
                    <span> قیمت خرید </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="price2" class='wh-label'>
                    <span> قیمت فروش </span>
                    <input class='wh-input' formControlName="price2" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="coding" class='wh-label'>
                    <span> کد کالا </span>
                    <input class='wh-input' formControlName="coding" autocomplete="off">
                </label>
            </div>

        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid || loading" class="btn_pe" (click)="save()">
            <span>ذخیره</span>
        </button>
    </div>
</form>