<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title class="matDialogTitle" cdkDragHandle>
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
        {{ serverService.get_title(type_task,lang) }}
        <span class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </span>
    </div>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent" style="padding:0px !important;overflow:hidden;max-height:91vh">
        <div class="row pe1">
            <textarea matInput spellcheck="false" formControlName="codes"></textarea>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Search</span>
            <span *ngIf="lang == 1">جستجو</span>
        </button>
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3"
            (click)="check_access(type_task,2)">
            <span *ngIf="lang == 2">Excel</span>
            <span *ngIf="lang == 1">خروجی اکسل</span>
        </button>
    </div>
</form>