<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row dflex">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <div class="pager_pe">
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
                <span class="title header_other" *ngIf="user_title">
                    کاردکس {{ user_title }}
                </span>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll_bg pe1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px' (click)="reset_sort()">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="text-align: center !important;" style="max-width:120px"
                [ngClass]="{active1:order == 'date ' + asc_desc }" (click)="sort('date',asc_desc)">
                <span>تاریخ</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:120px">
                {{ x.date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef style="min-width:280px"
                [ngClass]="{active1:order == 'title ' + asc_desc }" (click)="sort('title',asc_desc)">
                <span>شرح</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:280px">
                <div class="right">{{ x.title | substr:0:60 }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:280px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'type_title ' + asc_desc }"
                (click)="sort('type_title',asc_desc)">
                <span>سند</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.type_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id2">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'id2 ' + asc_desc }"
                (click)="sort('id2',asc_desc)">
                <span>شماره</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.id2 }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'number ' + asc_desc }"
                (click)="sort('number',asc_desc)">
                <span>تعداد</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'price ' + asc_desc }"
                (click)="sort('price',asc_desc)">
                <span>فی واحد</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.price | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="discount">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'discount ' + asc_desc }"
                (click)="sort('discount',asc_desc)">
                <span>تخفیف</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.discount | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'bed ' + asc_desc }"
                (click)="sort('bed',asc_desc)">
                <span>بدهکار</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.bed | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{ bed | number }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="best">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'best ' + asc_desc }"
                (click)="sort('best',asc_desc)">
                <span>بستانکار</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.best | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{ best | number }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="remain">
            <mat-header-cell *matHeaderCellDef>
                <span>مانده</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="number">
                {{ x.remain2 | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{ remain | number }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'comment ' + asc_desc }"
                (click)="sort('comment',asc_desc)">
                <span>توضیحات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <div class="right">{{ x.comment }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>


        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>