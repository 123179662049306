import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NewFastMaterialComponent } from '../../wharehouse/new-fast-material/new-fast-material.component';
// import { WharehouseExit2Component } from '../../wharehouse-exit2/wharehouse-exit2.component';
import { DecimalPipe } from '@angular/common';
import Num2persian from 'num2persian';
import { WharehouseOrderReturnBuyDetaileComponent } from '../wharehouse-order-return-buy-detaile/wharehouse-order-return-buy-detaile.component';

@Component({
  selector: 'app-wharehouse-invoice-return-sale',
  templateUrl: './wharehouse-invoice-return-sale.component.html',
  styleUrls: ['./wharehouse-invoice-return-sale.component.scss'],
  providers: [DecimalPipe]
})
export class WharehouseInvoiceReturnSaleComponent implements OnInit, OnDestroy {
  public loading = false;
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: any = this.serverService.get_server();
  public lang: any = <any>localStorage.getItem("lang");
  public dir: any = <any>localStorage.getItem("dir");
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public subscription2: Subscription | any;
  public subscription1: Subscription | any;

  list_record: any = [];
  search: string | undefined;
  page: number = 1;
  invoice_id: number | undefined;
  device: number | undefined;
  cate: number | undefined;
  reception_id: number | undefined;
  exit_id: number | undefined;
  list_material_type: any = [];
  material_type: number = 1;
  ref: string | undefined;
  type: number | undefined;
  list_invoice: any = [];
  new_invoice_id: number;
  sum_all: number = 0;
  remain: number = 0;
  sum_all_letter: string | undefined;
  remain_letter: string | undefined;
  code: number = 0;
  id: number | undefined;
  token: number | undefined;;
  creator: any | undefined;
  type_list: string = 'material';
  sum_all_real: number = 0;
  document_type: number | undefined;
  date3: number | undefined;
  list_order: any = [];
  x: number = 0;
  x2: number = 0;
  x3: number = 0;
  list_repairman: any = [];
  requests_id: number = 0;
  count_order: number = 0;
  level: number | undefined;
  currency_title: string | undefined;
  back: number = 0;
  user: number | undefined;//این برای موقعی است که می خواهیم نام ظرف حساب در فاکتور ها چه کسی هستش

  public count_total: number = 1;
  public count_total_service: number = 0;
  public count_total_material: number = 0;
  public page_number_first: number | undefined; public page_number_second: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;



  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  document_type_text: string | undefined;
  invoice1_id: number | undefined;
  displayedColumns2 = ['row', 'material', 'group', 'cate', 'inventory', 'order', 'number_sale', 'cost', 'number', 'add'];
  displayedColumns = ['row', 'type', 'material', 'group', 'title', 'number', 'weight', 'price', 'discount', 'sum', 'operation'];



  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , private matDialogRef: MatDialogRef<WharehouseInvoiceReturnSaleComponent>
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any
    , private dialog: MatDialog) {


    this.subscription2 = this.serverService.get_material().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )

    if (dialog_data) {
      this.invoice_id = dialog_data.invoice_id;
      this.requests_id = dialog_data.requests_id;
      this.date3 = dialog_data.date3;
      this.token = dialog_data.token;
      this.ref = dialog_data.ref;
      this.document_type_text = dialog_data.document_type_text;
      this.document_type = dialog_data.document_type;
      this.device = dialog_data.device;
      this.exit_id = dialog_data.exit_id;
      this.cate = dialog_data.cate;
      this.type = dialog_data.type;
      this.reception_id = dialog_data.reception_id;
      this.code = dialog_data.code;
      this.id = dialog_data.reception_id;
      this.back = dialog_data.back;
      this.user = dialog_data.user;
      this.count_order = dialog_data.count_order;
      this.level = dialog_data.level;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
  }

  which(page) {
    if (page == 2) { this.page = this.page + 1; }
    if (page == 3) { this.page = this.page - 1; }
    if (this.material_type == 1) {
      this.get_material(1);
    } else {
      this.get_services(1);
    }
  }

  check_access(type_task: number, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 4) {
              this.get_material(1);
              this.get_services_count();
            }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  get_services2() {
    this.page = 1;
    this.get_services(2);
  }

  get_services3() {
    this.page = 1;
    this.search = '';
    this.get_services(1);
  }

  get_services_count() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6474, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_total_service = res['count'];
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_services(loading) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.material_type = 2;
    if (loading == 1) {
      this.loading = true;
      //this.search = '';
    }
    this.type_list = 'services';
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6454, page: this.page, search: this.search, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource.data = this.list_record;
          this.pages(0);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  get_material2() {
    this.page = 1;
    this.get_material(2);
  }

  get_material3() {
    this.page = 1;
    this.search = '';
    this.get_material(1);
  }


  get_material(loading: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.material_type = 1;
    this.type_list = "material";
    if (loading == 1) {
      this.loading = true;
      //this.search = '';
    }
    var obj = {
      address: 6505, search: this.search, page: this.page
      , device: this.device, cate: this.cate, creator: this.creator, token: this.token, user: this.user
      , back: this.back, document_type_text: this.document_type_text,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total_material = res['count'];
          this.pages(0);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 10 - 9;
    this.page_number_second = this.page * 10;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 10) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  select(id: number, title: string) {
    this.matDialogRef.close({ title: title, id: id });
  }

  add() {
    if (!this.creator) {
      var pe_message = "نام صاحب اکانت مشخص نیست . لطفا یکبار دیگر لاگین شوید";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    const dialogRef = this.dialog.open(NewFastMaterialComponent, {
      width: '22rem',
      height: 'auto',
      data: { device: this.device, cate: this.cate, material_type: 1, creator: this.creator, back: this.back }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )
  }
  //********************************************************************** */
  request_material(material_id) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    var input1 = <any>document.getElementById("input1" + material_id);
    var number = input1.value;

    var obj = {
      address: 6258, user_id: this.user_id, creator: this.creator, material_type: 1,
      material_id: material_id, number: number, token: this.token,
      reception_id: this.reception_id, cost: 0, requests_id: this.requests_id
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.count_order++;
          this.serverService.send_order();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //********************************************************************** */
  save(i: number, material_id: number, order_id: number, cost: number, order_id_consumed: number, material_type: number): any {
    if (!order_id_consumed) {
      var pe_message = "خطا در شناسه";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    var input1 = <any>document.getElementById("input1" + order_id);
    var number_return = parseInt(input1.value.match(/\d/g).join(""));
    //*********************************************************************  */
    if (number_return < 1) {
      var pe_message = "تعداد درخواستی باید بیشتر از عدد 0 باشد.";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    this.check_inventory2(i, material_id, material_type, number_return, cost, order_id, order_id_consumed);
  }

  check_inventory2(i: number, material_id: number, material_type: number, number_return: number, cost: number, order_id: number, order_id_consumed: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    this.loading = true;
    var obj = {
      address: 6506
      , material_id: material_id
      , exit_id: this.exit_id
      , order_id: order_id
      , token: this.token
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (number_return <= res['remain']) {
            this.add_to_bascket_exit(i, material_id, number_return, cost, material_type, order_id, order_id_consumed);
            this.recieve_message(false, "", "", 1, "", "");
          } else {
            var pe_message = "تعدادی درخواستی بیشتر ار تعداد فروش می باشد .لطفا اصلاح نمائید.";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //***********************************************************************************************  */
  add_to_bascket_exit(i, material_id: number, number_return: number, price_buy: number, material_type: number, number_invoice_buy: number, order_id_consumed: number): any {
    var comment;
    this.loading = true;
    var obj = {
      address: 6507, reception_id: this.reception_id, id: material_id, number: number_return, order_id: number_invoice_buy,
      type: this.type, user_id: this.user_id, creator: this.creator
      , material_type: material_type, comment: comment, cost: price_buy
      , trash: 3, invoice_id: this.invoice_id, token: this.token, document_type_text: this.document_type_text
      , order_id_consumed: order_id_consumed
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.dataSource.data = this.list_record;
          if (this.document_type_text == 'exit') {
            this.list_record[i].inventory--;
            if (this.list_record[i].inventory == 0) {
              this.list_record.splice(i, 1);
              this.dataSource.data = this.list_record;
            }
          }
          this.count_order++;
          this.send_invoice_material(res['result'][0]);
          var pe_message = "با موفقیت اضافه شد";
          var pe_action = "بستن";
          this.recieve_message(true, '----', pe_message, 1, 'close', pe_action);
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  send_invoice_material(result) {
    this.serverService.send_invoice_material(result);
  }

  //***********************************************************************************************  */
  check_add_to_invoice(material_id: number, number_sale: number, cost: number, discount) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6364, material_id: material_id, token: this.token, invoice_id: this.invoice_id, cost: cost
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          if (res['result'][0].count == 0) {
            this.add_to_invoice(material_id, number_sale, cost, discount);
          } else {
            this.update_order(res['result'][0].wharehouse_order_id, number_sale);
            this.recieve_message(false, "", "", 1, "", "");
          }
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update_order(order_id: number, number_sale: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6365, order_id: order_id, number_sale: number_sale
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_update_invoice_material();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  add_to_invoice(id: number, number: number, cost: number, discount): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      address: 6069
      , reception_id: this.reception_id
      , material_id: id, number: number, cost: cost, discount: discount
      , invoice_id: this.invoice_id
      , token: this.token
      , type: 1
      , material_type: 1
      , ref: this.ref
      , user_id: this.user_id
      , creator: this.creator
      , back: this.back
    }

    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.dataSource.data = this.list_record;
          this.count_order++;
          this.serverService.send_invoice_material(res['result'][0]);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //************************************************************************************************* */
  get_exit(loading: number, page: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.page = page;
    this.material_type = 0;
    if (loading == 1) {
      this.loading = true;
      this.search = '';
    }

    this.material_type = 0;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1862, reception_id: this.id, page: this.page }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record = [];
          this.sum_all = 0;
          for (var i = 0; i < res['num']; i++) {
            res['result'][i].sum = res['result'][i].wharehouse_order_number * res['result'][i].cost;
            this.sum_all += res['result'][i].sum;
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total = res['count'];
          this.pages(0);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }//end recieve_data

  open_detaile_exit(i: number, type_task: number, id: number, title: string, footer: boolean, material_type: number): any {
    // const dialogRef = this.dialog.open(WharehouseExit2Component, {
    //   width: '45rem',
    //   height: 'auto',
    //   disableClose: true,
    //   data: { type_task: type_task, id: id, material_type: material_type, i: i, code: this.code, title: title, reception_id: this.id, device: this.device, ref: 'reception' }
    // });
    // dialogRef.afterClosed().subscribe(
    //   (res: any) => {
    //     if (res) {
    //       if (type_task == 1) {
    //         this.dataSource.data = this.list_record;
    //         this.count_total = this.list_record;
    //       }
    //       else if (type_task == 2) {
    //         this.list_record[i].wharehouse_material_title = res.result.wharehouse_material_title;
    //         this.list_record[i].wharehouse_material_cate_title = res.result.wharehouse_material_cate_title;
    //         this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
    //         this.list_record[i].cost = res.result.cost;
    //         this.list_record[i].sum = res.result.sum;
    //         this.list_record[i].title = res.result.title;
    //         this.list_record[i].cost = res.result.cost;
    //         this.list_record[i].sum = res.result.wharehouse_order_number * res.result.cost;
    //       }
    //       this.get_amount_exit();
    //     }
    //   }
    // )
  }

  get_amount_exit() {
    this.sum_all = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.sum_all = this.sum_all + this.list_record[i].sum;
    }
  }
  //************************************************************************************************* */

  check_for_access(i: number, type_task: number, id: number, material_type: number, creator: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              this.open_order_return_buy(i, id);
              this.recieve_message(false, "", "", 1, "", "");
            } else {
              var pe_message = "شما دسترسی برای تغییرات را ندارید";
              var pe_action = "بستن";
              this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  open_order_return_buy(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderReturnBuyDetaileComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: id, code: this.code, ref: 'invoice', token: this.token, type_task: 2 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_order[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_order[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_order[i].wharehouse_order_discount = 0;
          this.get_update_material_remain(res.result.wharehouse_order_material);
          this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          this.list_order[i].sum = res.result.sum;
          this.get_sum_all();
          if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.user) this.get_account(this.user); this.serverService.send_order();
          this.serverService.send_order();
        }
      })
  }


  get_order() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.type_list = 'order';
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6455, invoice: this.invoice_id, token: this.token }).subscribe(
      (res: any) => {
        this.list_order = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_order.push(res['result'][i]);
          }//end for
          this.count_order = this.list_order.length;
          this.dataSource = new MatTableDataSource(this.list_order);
          this.get_sum_all();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //******************************************************************************************************************* */
  get_request() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.type_list = 'request';

    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6285, requests_id: this.requests_id, token: this.token }).subscribe(
      (res: any) => {
        this.list_order = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_order.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_order);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  delete_request(id: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6028, "user_id": this.user_id
        , 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_order.splice(this.list_order.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_order;
            this.count_order--;
            this.serverService.send_order();
            var pe_message = "با موفقیت حذف شد";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve	', pe_message, 1, 'close', pe_action);
          }//end if
          else {
            var pe_message = "خطا در حذف";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve	', pe_message, 1, 'close', pe_action);
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //******************************************************************************************************************* */
  get_sum_all() {
    this.x2 = 0;
    for (var j = 0; j < this.list_order.length; j++) {
      if (this.list_order[j].wharehouse_order_trash == 3) {
        this.x2 = Number(this.x2) + Number(this.list_order[j].wharehouse_order_cost * this.list_order[j].wharehouse_order_number);
      }
    }
    this.sum_all = 0;
    for (var i = 0; i < this.list_order.length; i++) {
      this.sum_all += this.list_order[i].sum;
    }
    this.sum_all_letter = Num2persian(this.sum_all);
    if (this.document_type == 1) this.sum_all_real = Number(this.sum_all) + Number(this.x2);
    if (this.document_type == 2) this.sum_all_real = Number(this.sum_all) - Number(this.x2);
  }


  access_for_delete(id: number, material: number, trash: number, material_type: number, creator: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                this.check_to_delete(id, material, trash, material_type);
              } else {
                this.delete_order(id, trash);
              }
            } else {
              var pe_message = "شما دسترسی برای حذف را ندارید";
              var pe_action = "بستن";
              this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  check_to_delete(id: number, material: number, trash: number, material_type: number): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6003, material: material, check_order_id: id, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.document_type_text == 'exit') {
            this.delete_order(id, trash);
          }
          else if (this.document_type_text == 'input' && res['remain'] == res['result'][0].order_number) {
            this.delete_order(id, trash);
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          }
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  delete_order(id: number, trash: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6028, "user_id": this.user_id
        , 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (trash == 2) {
              for (var i = 0; i < this.list_order.length; i++) {
                if (this.list_order[i].wharehouse_order_id == id && this.list_order[i].wharehouse_order_material_type == 1) {
                  this.get_update_material_remain(this.list_order[i].wharehouse_order_material);
                  this.get_update_order_consumed(this.list_order[i].wharehouse_order_consumed_invoice);
                }
              }
            }
            this.list_order.splice(this.list_order.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_order;
            this.get_sum_all();
            if (trash == 2) {
              this.update_financial2();
            }
            this.serverService.send_order();
            this.count_order = this.list_order.length;
            var pe_message = "با موفقیت حذف شد";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve	', pe_message, 1, 'close', pe_action);
          }//end if
          else {
            var pe_message = "خطا در حذف";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve	', pe_message, 1, 'close', pe_action);
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  //******************************************************************************* */
  get_update_material_remain(material_id) {
    var obj = {
      address: 6445,
      material_id: material_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_update_order_consumed(order_id: number) {
    var obj = {
      address: 6446,
      order_id: order_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update_financial2() {
    var obj = {
      address: 6106,
      id: this.invoice_id,
      amount: this.sum_all_real,
      date3: this.date3,
      document_type: this.document_type,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2();
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update_all_financial2() {
    var obj = {
      address: 6111,
      id: this.invoice_id,
      user: this.user,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.user) this.get_account(this.user);
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_account(user: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: user, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.remain = 0;
          this.remain = res['result'][0].balance;
          this.x = 0;
          for (var j = 0; j < this.list_order.length; j++) {
            if (this.list_order[j].wharehouse_order_trash == 3) {
              this.x = this.x + Number(this.list_order[j].wharehouse_order_cost * this.list_order[j].wharehouse_order_number) - this.list_order[j].wharehouse_order_discount;
            }
          }
          this.x3 = 0;
          if (this.document_type == 1) this.remain = Number(this.remain) + Number(this.x) - Number(this.x3);
          if (this.document_type == 2) this.remain = Number(this.remain) - Number(this.x) + Number(this.x3);
          this.remain_letter = Num2persian(this.remain);

          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در $";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //************************************************************************************************  */

  close() {
    this.matDialogRef.close();
  }

  //************************************************************************************************** */
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if

    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }//end if

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if
  }//end OnDestroy
}
