import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-pay-detaile-cash',
  templateUrl: './pay-detaile-cash.component.html',
  styleUrls: ['./pay-detaile-cash.component.scss']
})
export class PayDetaileCashComponent implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public loading: boolean = false;
  public sum: number = 0;
  public search_array: any = [];
  public result: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public financial!: number;
  public close_after_save!: string;
  public token!: number;
  public creator!: number;
  public obj!: any;
  public video!: string;
  public video2!: string;

  constructor(
    public serverService: ServerService,
    public messageService: MessageService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PayDetaileCashComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.sum = dialog_data.sum;
      this.token = dialog_data.token;
      this.financial = dialog_data.financial;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.creator = dialog_data.creator;
      if (this.type_task == 1) {
        this.check_access(5);
      }
      if (this.type_task == 2) {
        this.check_access(4);
      }
      if (this.type_task == 3) {
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    if (this.type_task == 3) { }
    this.form1_group = new FormGroup({
      'amount': new FormControl(this.sum),
      'comment': new FormControl(null),
    });
  }
  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 1) { this.insert(); }
            if (type_task == 2) { this.update(); }
            if (type_task == 3) { }
            if (type_task == 4) { this.get_data(); }
            if (type_task == 5) { }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7061, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'amount': res['result'][0].finance_financial2_amount,
            'comment': res['result'][0].finance_financial2_comment,
          }
          );
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7059,
      "user_id": this.user_id,
      'amount': this.form1_group.value.amount,
      'comment': this.form1_group.value.comment,
      'financial': this.financial,
      'token': this.token,
      'trash': 3,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ result: res['result'][0] });
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7060,
      "user_id": this.user_id,
      'amount': this.form1_group.value.amount,
      'comment': this.form1_group.value.comment,
      'financial_id': this.financial,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close({ result: res['result'][0] });
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  save(type_task: number, close: string) {
    this.close_after_save = close;
    this.check_access(type_task);
  }
  load_services(id: number): any {
    var code_id;
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '21ren',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
        }
      }
    )
  }
  reset_input(id: number) {
  }
  close() {
    this.matDialogRef.close();

  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

