import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserDetaile2Component } from '../../user/user-detaile2/user-detaile2.component';
import { UserSearchComponent } from '../../user/user-search/user-search.component';
import { WharehouseOrderCostDetaileComponent } from '../../wharehouse/wharehouse-order-cost-detaile/wharehouse-order-cost-detaile.component';
import { WharehouseOrderSaleDetaileComponent } from '../../wharehouse/wharehouse-order-sale-detaile/wharehouse-order-sale-detaile.component';
import { MessageService } from '../../service/message/message.service';
import { WharehouseOrderSaleSearialComponent } from '../../wharehouse/wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';
import { WharehouseOrderIserviceDetaileComponent } from '../wharehouse-order-iservice-detaile/wharehouse-order-iservice-detaile.component';

@Component({
  selector: 'app-invoice-sale-detaile',
  templateUrl: './invoice-sale-detaile.component.html',
  styleUrls: ['./invoice-sale-detaile.component.scss']
})
export class InvoiceSaleDetaileComponent implements OnInit, OnDestroy {
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public token: any = Math.floor(Math.random() * 99999999999999);
  public loading: boolean = false;
  public sum_tax_all: number = 0;
  public sum_all: number = 0;
  public sum_all_real: number = 0;
  public remain: number = 0;
  public x: number = 0;
  public x2: number = 0;
  public x3: number = 0;
  public list_record: any = [];
  public search_array: any = [];
  public access_insert_finance: boolean = false;
  public readonly: boolean = false;
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public type_save: string;
  public server!: any;
  public server_main!: any;
  public type_task!: number;
  public user_id: number | any;
  public id!: number;
  public i!: number;
  public code!: number;
  public repairman_title!: string;
  public repairman_id!: string;
  public new_invoice_id!: number;
  public currency_title!: string;
  public sum_all_letter!: string;
  public remain_letter!: string;
  public search_all!: string;
  public page!: number;
  public access_service!: number;
  public level!: number;
  public access_invoice_sale!: Boolean;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public amount!: number;
  public result!: any;
  public creator!: number;
  public expire!: boolean;
  public temp_user: number;
  public target_user_id: number = 0;
  public target_user_title: string;
  public dataSource: any | any;
  public displayedColumns = ['row', 'type', 'material', 'number', 'unit', 'cost', 'discount', 'tax', 'sum', 'repairman', 'searial', 'operation'];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<InvoiceSaleDetaileComponent>) {
    this.server = this.serverService.get_server();
    this.server_main = this.serverService.get_server_main();
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.readonly = dialog_data.readonly;
      this.type_task = dialog_data.type_task;
      this.new_invoice_id = dialog_data.new_invoice_id;
      this.creator = dialog_data.creator;
      this.create_form1();
      this.id = dialog_data.id;
      this.page = dialog_data.page;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          invoice_number: dialog_data.search_array.invoice_number,
          comment: dialog_data.search_array.comment,
          invoice_date: dialog_data.search_array.invoice_date,
          settlement_date: dialog_data.search_array.settlement_date,
          user: dialog_data.search_array.user,
          user_title: dialog_data.search_array.user_title,
        });
      }
    }
  }//end consructor
  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      invoice_number: this.form1_group.value.invoice_number,
      comment: this.form1_group.value.comment,
      invoice_date: this.form1_group.value.invoice_date,
      settlement_date: this.form1_group.value.settlement_date,
      user: this.form1_group.value.user,
      user_title: this.form1_group.value.user_title,
      export_excel: excel,
    })
  }
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  change_access_insert_finance() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6504, user_id: this.user_id }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.access_insert_finance = res['result'][0].access;
            this.access_invoice_sale = res['result'][0].user_access_invoice_sale;
          } else {
            this.access_insert_finance = false;
            this.access_invoice_sale = false;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'invoice_number': new FormControl(null),
      'comment': new FormControl(null),
      'invoice_date': new FormControl(null),
      'settlement_date': new FormControl(null),
      'user_title': new FormControl(null),
      'user': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      //this.form1_group.controls['status_id'].setValidators([Validators.required])
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6,
      'type': 'user',
      'user_id': this.user_id,
      'id': this.code
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);

        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    var obj = {
      'address': 6,
      'user_id': this.creator,
      'id': this.code
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
          }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) {
            this.get_data();
          }
          if (type_task == 5) {
            this.form1_group.patchValue({
              'invoice_number': this.new_invoice_id
            });
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7185,
      'id': this.id,
      'creator': this.creator,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].wharehouse_invoice_title,
            'invoice_number': res['result'][0].wharehouse_invoice_number,
            'comment': res['result'][0].wharehouse_invoice_comment,
            'invoice_date': res['result'][0].wharehouse_invoice_date3,
            'settlement_date': res['result'][0].wharehouse_invoice_settlement_date,
            'user': res['result'][0].user_id,
            'user_title': res['result'][0].user_title,
          });
          this.temp_user = res['result'][0].wharehouse_invoice_user;
          if (!res['result'][0].wharehouse_invoice_number) {
            this.form1_group.patchValue({
              'invoice_number': this.new_invoice_id
            });
          }
          this.get_order();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_order() {
    var obj = {
      'address': 7186,
      'invoice': this.id,
      'token': this.token,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.get_amounts();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_amounts() {
    this.x2 = 0;
    for (var j = 0; j < this.list_record.length; j++) {
      if (this.list_record[j].wharehouse_order_trash == 3) {
        this.x2 = Number(this.x2) + Number(this.list_record[j].wharehouse_order_cost * this.list_record[j].wharehouse_order_number - this.list_record[j].wharehouse_order_discount);
      }
    }
    this.sum_all = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.sum_all += this.list_record[i].sum;
    }
  }
  update(): any {
    // if (this.i == -1 && this.list_record.length == 0) {
    //   return false;
    // }
    this.loading = true;
    var obj = {
      'address': 7183,
      "user_id": this.user_id,
      'invoice_number': this.form1_group.value.invoice_number,
      'comment': this.form1_group.value.comment,
      'invoice_date': this.form1_group.value.invoice_date,
      'settlement_date': this.form1_group.value.settlement_date,
      'sum_all': this.sum_all,
      'token': this.token,
      'creator': this.creator,
      'id': this.id,
      'i': this.i,
      'user': this.form1_group.value.user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          // this.update_order();
          // this.update_financial2_for_repairman();
          this.result = { result: res['result'][0], type_save: this.type_save, 'temp_user': this.temp_user, user: this.form1_group.value.user }
          this.matDialogRef.close(this.result);
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update 
  save(type: string) {
    this.type_save = type;
  }
  update_order() {
    var obj = {
      address: 6023,
      id: this.id,
      token: this.token,
      creator: this.creator,
      user: this.form1_group.value.user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var confirm_sms = false;
          if (this.i == -1 && res['result'][0].user_sms_invoice == 1 && (res['result'][0].wharehouse_invoice_type == 1 || res['result'][0].wharehouse_invoice_type == 3)) {
            confirm_sms = window.confirm("آیا میخواهید " + res['result'][0].user_sex_title + " " + res['result'][0].user_title + " از صدور فاکتور مطلع نمائید؟");
            res['result'][0].confirm_sms = confirm_sms;
          }

          if (this.form1_group.value.invoice_type == 6) {
            for (var i = 0; i < this.list_record.length; i++) {
              if (this.list_record[i].wharehouse_order_trash == 3 && this.list_record[i].wharehouse_order_material_type == 1) {
              }
            }
          }
          else {
            for (var i = 0; i < this.list_record.length; i++) {
              if (this.list_record[i].wharehouse_order_trash == 3 && this.list_record[i].wharehouse_order_material_type == 1) {
              }
            }
          }
          this.result = { result: res['result'][0], type_save: this.type_save, 'temp_user': this.temp_user, user: this.form1_group.value.user }
          this.matDialogRef.close(this.result);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************************************************* */
  open_user(): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(UserSearchComponent, {
      width: '40rem',
      height: 'auto',
      data: {
        'code': this.code,
        'user': this.form1_group.value.user,
        'creator': this.creator
      }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'user_title': res.title,
            'user': res.id
          });
        }
      }
    )
  }
  add_user(): any {
    if (this.list_record.length > 0) {
      var pe_message = "برای اضافه نام طرف حساب لازم است کالاهای اضافه شده در ابتدا پاک شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (this.readonly == true) {
      return false;
    }
    var dialogRef = this.dialog.open(UserDetaile2Component, {
      width: '45rem',
      height: 'auto',
      data: { creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.form1_group.patchValue({
          'user_title': res['result'][0].user_title,
          'user': res['result'][0].user_id,
        });
      }
    )
  }
  //******************************************************************************************************************* */
  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    var code_id;
    if (id == 161) {
      code_id = 6098;
    }
    if (id == 189) {
      code_id = 6596;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 161) {
            this.form1_group.patchValue({
              'user': res.id,
              'user_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number) {
    if (id == 161) {
      this.form1_group.patchValue({
        'user_title': null,
        'user': null
      })
    }

  }
  //**************************************************** */
  insert_service() {
    const dialogRef = this.dialog.open(WharehouseOrderIserviceDetaileComponent, {
      width: '30rem',
      height: 'auto',
      data: {
        invoice_id: this.id,
        creator: this.creator,
        code: this.code,
        type_task: 1,
        token: this.token
      }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        console.log(res);
        if (res) {
          this.list_record.push(res.result);
          this.dataSource.data = this.list_record;
          this.get_amounts();
        }
      }
    )
  }
  open_services(i: number, id: number) {
    var trash = this.list_record[i].wharehouse_order_trash;
    const dialogRef = this.dialog.open(WharehouseOrderIserviceDetaileComponent, {
      width: '30rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code, invoice_id: this.id, trash: trash, token: this.token }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].repairman_id = res.result.repairman_id;
          this.list_record[i].wharehouse_material_title = res.result.wharehouse_material_title;
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].wharehouse_order_comment = res.result.wharehouse_order_comment;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].tax = res.result.tax;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].title = res.result.title;
          this.list_record[i].cost = res.result.cost;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].sum_without_discount = res.result.sum_without_discount;
          this.get_amounts();
        }
      }
    )
  }
  delete_service(id: number, repairman: number) {
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 7201,
        "user_id": this.user_id,
        "invoice_id": this.id,
        "repairman": repairman,
        "creator": this.creator,
        'id': id,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.get_amounts();
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //****************************************************** */
  check_for_access(i: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var creator = this.list_record[i].wharehouse_order_creator;
    var id = this.list_record[i].wharehouse_order_id;
    var material_type = this.list_record[i].wharehouse_order_material_type;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 3) {
                this.open_cost(i, id);
              }
              else if (material_type == 2) {
                this.open_services(i, id);
              }
              else if (material_type == 1) {
                this.open_order_sale(i, id)
              }
              this.message(false, "", 1, this.messageService.close(this.lang));
            } else {
              var pe_message = "شما دسترسی برای تغییرات را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

        }
      }
    )
  }
  access_for_delete(i: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var repairman = this.list_record[i].wharehouse_order_repairman;
    var creator = this.list_record[i].wharehouse_order_creator;
    var id = this.list_record[i].wharehouse_order_id;
    var material_type = this.list_record[i].wharehouse_order_material_type;
    var material_id = this.list_record[i].wharehouse_order_material;
    var buy_order_id = this.list_record[i].wharehouse_order_consumed_invoice;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                //this.delete_material(id, repairman, material_id, buy_order_id);
              }
              else if (material_type == 2) {
                this.delete_service(id, repairman);
              }
              else if (material_type == 4) {
                //this.delete_costs(id);
              }
            }
            else {
              var pe_message = "شما دسترسی برای حذف را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

        }
      }
    )
  }
  //******************************************************************************************************************** */
  open_order_sale(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderSaleDetaileComponent, {
      width: '30rem',
      height: 'auto',
      data: { id: id, code: this.code, ref: 'invoice', token: this.token }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_status_title = res.result.wharehouse_order_status_title;
          this.list_record[i].wharehouse_order_status_color = res.result.wharehouse_order_status_color;
          this.list_record[i].wharehouse_invoice_title = res.result.wharehouse_invoice_title;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].tax = res.result.tax;
          this.get_amounts();
        }
      }
    )
  }
  open_cost(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderCostDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].title = res.result.finance_cost_type_title;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].sum = res.result.sum;
        }
        this.get_amounts();
        //if (res.result.wharehouse_order_trash == 2) this.update_financial2();
      }
    )
  }
  delete_order(id: number, trash: number, material_type: number): any {
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6028, "user_id": this.user_id
        , 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (trash == 2) {
              if (this.form1_group.value.invoice_type == 6) {
                for (var i = 0; i < this.list_record.length; i++) {
                  if (this.list_record[i].wharehouse_order_id == id && this.list_record[i].wharehouse_order_material_type == 1) {
                  }
                }
              }
              else {
                for (var i = 0; i < this.list_record.length; i++) {
                  if (this.list_record[i].wharehouse_order_id == id && this.list_record[i].wharehouse_order_material_type == 1) {
                  }
                }
              }
            }
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.get_amounts();
            this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  open_serial(i: number, id: number, title: string, order_buy_id: number) {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(WharehouseOrderSaleSearialComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, title: title, order_buy_id: order_buy_id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.list_record[i].searial = res.result.searial;
      }
    )
  }
  get_repairman_title(id: string, title: string) {
    this.repairman_id = id;
    this.repairman_title = title;
  }
  //******************************************************************************************************** */
  close() {
    var j = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      if (this.list_record[i].wharehouse_order_trash == 3) {
        j++;
      }
    }
    if (j > 0) {
      var message = "اینجا رکوردهای اضافه شده هنوز ذخیره نشده است.آیا می خواهید کنسل کنید؟";
      if (this.lang == 2) {
        message = "Here, the added records have not been saved yet. Do you want to cancel?";
      }
      var accept: boolean = confirm(message);
      if (accept == true) {
        this.matDialogRef.close();
      } else {
      }
    } else {
      this.matDialogRef.close();
    }
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

