<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>

    <span>
        <span>لیست چک های دریافتی</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="get_data(2)"
        placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    <div class="content-body1" class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}">
        <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll_bg pe1'>
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'
                    (click)="reset_sort('finance_cheque_id','desc')">
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i
                    + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'finance_cheque_cheque_title ' + asc_desc }"
                    (click)="sort('finance_cheque_cheque_title',asc_desc)">
                    <span>بابت</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)">{{
                    x.finance_cheque_cheque_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="service">
                <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'ref_title ' + asc_desc }"
                    (click)="sort('ref_title',asc_desc)">
                    <span>سرویس دریافت کننده چک</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)">{{
                    x.ref_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef style="min-width:130px"
                    [ngClass]="{active1:order == 'title ' + asc_desc }" (click)="sort('title',asc_desc)">
                    <span>عنوان</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" style="min-width:130px">{{
                    x.title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="bank">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'finance_cheque_cheque_bank ' + asc_desc }"
                    (click)="sort('finance_cheque_cheque_bank',asc_desc)">
                    <span>بانک</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)">{{
                    x.finance_cheque_cheque_bank }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cheque_number">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'finance_cheque_cheque_number ' + asc_desc }"
                    (click)="sort('finance_cheque_cheque_number',asc_desc)">
                    <span>شماره چک</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)">{{
                    x.finance_cheque_cheque_number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date3">
                <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'cheque_due_date ' + asc_desc }"
                    (click)="sort('cheque_due_date',asc_desc)">
                    <span>تاریخ سررسید</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)">{{
                    x.cheque_due_date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="price">
                <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'finance_cheque_amount ' + asc_desc }"
                    (click)="sort('finance_cheque_amount',asc_desc)">
                    <span>مبلغ چک</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)">{{
                    x.finance_cheque_amount | number}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'site_cheque_status_title ' + asc_desc }"
                    (click)="sort('site_cheque_status_title',asc_desc)">
                    <span>وضعیت چک</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)">{{
                    x.site_cheque_status_title }}</mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                [ngStyle]="{'backgroundColor': row.site_cheque_type == 2 ? 'aqua' : 'transparent'}" class="cursor">
            </mat-row>

        </mat-table>
    </div>
</div>