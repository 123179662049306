<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span> {{ title }} </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <mat-tab-group dynamicHeight>
            <mat-tab label="{{ tab1 }}">
                <div class="row pe" style="padding:0 1rem">

                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'>
                        <label for="sex" class='wh-label'>
                            <span> عنوان </span>
                            <span (click)="reset_input(10)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='sex_title' autocomplete="off"
                                [required]="required()" readonly (click)="load_services(10)">
                            <input class='wh-input' formControlName='sex' autocomplete="off" [required]="required()"
                                readonly [hidden]="true">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'>
                        <label for="title" class='wh-label'>
                            <span>نام طرف حساب</span>
                            <input class='wh-input' formControlName='title' autocomplete="off" [required]="required()">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'>
                        <label for="group" class='wh-label'>
                            <span> گروههای کاربری </span>
                            <span (click)="reset_input(155)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='group_title' autocomplete="off"
                                (click)="load_services(155)" [required]="required()" readonly>
                            <input class='wh-input' formControlName='group' autocomplete="off" [required]="required()"
                                [hidden]="true" readonly>
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'>
                        <label for="login_service_title" class='wh-label'>
                            <span> سرویس ورود </span>
                            <span (click)="reset_input(213)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='login_service_title' autocomplete="off"
                                (click)="load_services(213)" [required]="required()" readonly>
                            <input class='wh-input' formControlName='login_service' autocomplete="off"
                                [required]="required()" [hidden]="true" readonly>
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-3  col-xs-12 pe div_panding'>
                        <label for="cellphone" class='wh-label'>
                            <span> شماره همراه</span>
                            <input class='wh-input' formControlName='cellphone' autocomplete="off"
                                (keypress)="serverService.get_number($event)" minlength="11" maxlength="11" length="11"
                                [required]="required()" [attr.disabled]="level == 3 ? true : null">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-3  col-xs-12 pe div_panding'>
                        <label for="user" class='wh-label'>
                            <span>نام کاربری</span>
                            <input class='wh-input' formControlName='user' autocomplete="off" [required]="required()"
                                pattern="^\S+$">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding' [hidden]="type_task == 3">
                        <label for="password" class='wh-label'>
                            <span>رمز عبور</span>
                            <input type="text" class='wh-input' formControlName='password' [required]="required()">
                        </label>
                    </div>
                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'>
                        <label for="profit_sale" class='wh-label'>
                            <span> درصد سود فروش </span>
                            <input class='wh-input' formControlName='profit_sale' maxlength="3" min=0 max=100
                                [required]="required()" currencyMask>
                        </label>
                    </div>

                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'>
                        <label for="profit_repairman" class='wh-label'>
                            <span> درصد سود تعمیرات</span>
                            <input class='wh-input' formControlName='profit_repairman' maxlength="3" min=0 max=100
                                [required]="required()" currencyMask>
                        </label>
                    </div>

                    <div class='col-md-3 col-lg-3 col-sm-4 col-xs-12 pe div_panding'>
                        <label for="status_id" class='wh-label'>
                            <span> وضعیت </span>
                            <span (click)="reset_input(26)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='status_title' autocomplete="off"
                                (click)="load_services(26)" [required]="required()" readonly>
                            <input class='wh-input' formControlName='status_id' autocomplete="off"
                                [required]="required()" [hidden]="true" readonly>
                        </label>
                    </div>
                </div><!-- row -->
            </mat-tab>
            <mat-tab label="{{ tab2 }}">
                <div class="row" style="padding-top:1em">
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="access_change_in_invoice" class='wh-label'>
                            <mat-checkbox formControlName='access_change_in_invoice'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span> آیا کاربر می تواند آیتم های فاکتور انبار را تغییر بدهد؟</span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="access_change_in_repairman" class='wh-label'>
                            <mat-checkbox formControlName='access_change_in_repairman'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span> آیا کاربر می تواند آیتم های فاکتور پذیرش را تغییر بدهد؟</span>
                        </label>
                    </div>
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="user_can_only_see_his_own_list" class='wh-label'>
                            <mat-checkbox formControlName='user_can_only_see_his_own_list'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span>تعمیرکار فقط می تواند دستگاههای تعمیری مربوط به پنل خودش را
                                ببینید</span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="sending_sms_when_acceptance_of_the_new_device" class='wh-label'>
                            <mat-checkbox formControlName='sending_sms_when_acceptance_of_the_new_device'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span>ارسال پیامک هنگام ثبت پذیرش دستگاه برای این تعمیرکار</span>
                        </label>
                    </div>
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="access_finance_in_invoice" class='wh-label'>
                            <mat-checkbox formControlName='access_finance_in_invoice'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span> آیا کاربر می تواند درج مالی در فاکتور داشته باشد؟</span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="show_cellphone" class='wh-label'>
                            <mat-checkbox formControlName='show_cellphone'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span> آیا کاربر می تواند شماره موبایل ها را ببیند؟</span>
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="login_with_sms" class='wh-label'>
                            <mat-checkbox formControlName='login_with_sms'
                                style="vertical-align:text-top"></mat-checkbox>
                            <span>فعال سازی تایید دو مرحله ای از طریق پیامک</span>
                        </label>
                    </div>

                </div>

            </mat-tab>
            <mat-tab label="{{ tab3 }}">
                <div class='col-md-3 col-lg-3 col-sm-3 col-xs-12 pe' style="padding-top:5px">
                    <span>
                        <span>امضای</span>
                        {{ form1.get('title').value }}</span>
                    <mat-card class="example-card logo cursor backgroundImage"
                        style="padding:3px 16px 7px 16px;width:100%;height:10rem"
                        [ngStyle]="{'background-image':'url( ' + sign_logo + ')'}" (click)="logo2.click()">
                        <mat-card-header style="display:inline" class="pe1">
                        </mat-card-header>
                    </mat-card>
                    <span (click)="delete_sign_logo()" *ngIf="sign_logo_bin == true"><i class="far fa-trash-alt icon"
                            style="margin: 0px 4px;"></i></span>
                    <input type="file" (change)="change_sign_logo($event)" accept="image/*" style="display: none;"
                        #logo2>
                </div><!-- col-md-4 col-lg-4 col-sm-4 col-xs-12 pe  -->
            </mat-tab>
            <mat-tab label="{{ tab4 }}">

                <div class="row">
                    <div class="row user_div1" *ngFor="let s of list_sgroup" style="padding-top:1rem">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 user_div2">{{
                            s.site_software_title }}</div>
                        <span *ngFor="let x of list_sgroup_id[s.site_software_id]">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe service">
                                <mat-checkbox class="user-checkbox" [value]="x.site_services_id"
                                    (change)="change_select_service()" name="service[]" [checked]="x.checked">
                                </mat-checkbox>
                                {{ x.site_services_title }}
                            </div>
                        </span>
                    </div>
                </div>
            </mat-tab>

        </mat-tab-group>

    </div> <!-- content -->


    <div mat-dialog-actions class="matDialogActions" style="padding-right:25px">
        <button type="submit" [disabled]="!form1.valid" class="btn_pe" *ngIf="type_task == 1 || type_task == 2">
            <span>ذخیره</span>
        </button>
    </div><!-- row -->
</form>