<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>

    <span>
        <span *ngIf="lang == 1">لیست چک های دریافتی</span>
        <span *ngIf="lang == 2">List of received checks</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="content-body1" class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}">

        <mat-table [dataSource]="dataSource" style='min-width:100%'
            class='table-scroll mat_table_fa_without_scroll_bg pe1'
            [ngClass]="{mat_table_en_without_scroll_bg:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i
                    + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef style="max-width:100px">
                    <span *ngIf="lang == 1">نوع چک</span>
                    <span *ngIf="lang == 2">Types of checks</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" style="max-width:100px"
                    [ngClass]="{'en':dir == 'ltr'}">{{
                    x.site_cheque_type_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">بابت</span>
                    <span *ngIf="lang == 2">Description</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.site_cheque_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef style="min-width:130px">
                    <span *ngIf="lang == 1">نام طرف حساب</span>
                    <span *ngIf="lang == 2"> Name of the payee </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" [ngClass]="{'en':dir == 'ltr'}"
                    style="min-width:130px">{{
                    x.user_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="bank">
                <mat-header-cell *matHeaderCellDef style="max-width:90px">
                    <span *ngIf="lang == 1">بانک</span>
                    <span *ngIf="lang == 2">Bank</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" style="max-width:90px"
                    [ngClass]="{'en':dir == 'ltr'}">{{
                    x.site_bank_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="account">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span *ngIf="lang == 1">شماره حساب</span>
                    <span *ngIf="lang == 2">Account number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" style="max-width:120px"
                    [ngClass]="{'en':dir == 'ltr'}">{{
                    x.accounts_number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cheque_number">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span *ngIf="lang == 1">شماره چک</span>
                    <span *ngIf="lang == 2">Check number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" style="max-width:120px"
                    [ngClass]="{'en':dir == 'ltr'}">{{
                    x.cheque_number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date3">
                <mat-header-cell *matHeaderCellDef style="max-width: 90px;">
                    <span *ngIf="lang == 1">تاریخ سررسید</span>
                    <span *ngIf="lang == 2">Due date </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" style="max-width: 90px;"
                    [ngClass]="{'en':dir == 'ltr'}">{{
                    x.site_cheque_due_date1 }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="price">
                <mat-header-cell *matHeaderCellDef style="max-width:90px">
                    <span *ngIf="lang == 1">مبلغ چک</span>
                    <span *ngIf="lang == 2">Check amount</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" style="max-width:90px"
                    [ngClass]="{'en':dir == 'ltr'}">{{
                    x.site_cheque_price | number}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">وضعیت چک</span>
                    <span *ngIf="lang == 2">Check status</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.site_cheque_status_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="financial">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">سند دریافتی</span>
                    <span *ngIf="lang == 2">Received document</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="select(i)" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.finance_financial2_code }}</mat-cell>
            </ng-container>


            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                [ngStyle]="{'backgroundColor': row.site_cheque_type == 2 ? 'aqua' : 'transparent'}" class="cursor">
            </mat-row>

        </mat-table>
    </div>
</div>