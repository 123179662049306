<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span> هزینه </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">

        <div class="row pe1">
            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'>
                <label for="date1" class='wh-label'>
                    <span> تاریخ سند </span>
                    <input class="wh-input right" matInput [matDatepicker]="picker" formControlName="document_date"
                        autocomplete="off" required [readonly]="readonly">
                    <mat-datepicker-toggle matSuffix [for]="picker"
                        class="datepicker datepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'>
                <label for="group" class='wh-label'>
                    <span> گروه هزینه </span>
                    <span (click)="reset_input(254)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_group_title" (click)="load_services(254)" required
                        readonly>
                    <input class="wh-input" formControlName="cost_group" [hidden]="true" required readonly>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'>
                <label for="document" class='wh-label'>
                    <span> عنوان هزینه </span>
                    <span (click)="add_cost()" class="add_icon" [hidden]="readonly == true">
                        <mat-icon class="add-icon fleft"> add </mat-icon>
                    </span>
                    <span (click)="reset_input(221)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_title" (click)="load_services(221)" required readonly>
                    <input class="wh-input" formControlName="cost" [hidden]="true" required readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="comment" class='wh-label'>
                    <span> توضیحات </span>
                    <input class="wh-input" formControlName="comment" [readonly]="readonly">
                </label>
            </div>

            <!--************************************************************** -->
        </div><!-- row -->

        <div class="row">
            <div style="text-align:right;padding:0px;margin-bottom: 5px;">
                <button type="button" class="button_default" (click)='open_detaile_bank(0,1,0)' [hidden]="readonly">
                    <span>ثبت تراکنش بانکی</span>
                </button>
                <button type="button" class="button_default" (click)='open_detaile_cash(0,1,0)' [hidden]="readonly">
                    <span>ثبت تراکنش نقدی</span>
                </button>
                <ng-container>
                    <button type="button" class="button_default" (click)='select_cheque(1)' [hidden]="readonly">
                        <span>لیست چک های دریافتی</span>
                    </button>
                    <button type="button" class="button_default" (click)='open_detaile_cheque(0,1 ,0,0,false)'
                        [hidden]="readonly">
                        <span>
                            <span>صدور چک</span>
                        </span>
                    </button>
                </ng-container>
            </div>

            <div class="content-body1" class="content-body control-content-body">

                <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_fa_without_scroll pe1'>
                    <ng-container matColumnDef="row">
                        <mat-header-cell *matHeaderCellDef style='max-width:30px'>
                            <span>#</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:30px'>{{ i + 1 }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="payment_type">
                        <mat-header-cell *matHeaderCellDef style="max-width:130px">
                            <span>نوع تراکنش</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_cost2_id,x.finance_cost2_payment_type2,x.site_cheque_type,x.finance_cost2_cheque_myself)"
                            style="max-width:130px">{{
                            x.finance_payment_type_title }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef>
                            <span>مبلغ</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_cost2_id,x.finance_cost2_payment_type2,x.site_cheque_type,x.finance_cost2_cheque_myself)">
                            {{
                            x.finance_cost2_amount | number }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef style="max-width:100px">
                            <span>نوع چک</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_cost2_id,x.finance_cost2_payment_type2,x.site_cheque_type,x.finance_cost2_cheque_myself)"
                            style="max-width:100px">
                            {{ x.site_cheque_type_title }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="bank">
                        <mat-header-cell *matHeaderCellDef>
                            <span> بانک</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_cost2_id,x.finance_cost2_payment_type2,x.site_cheque_type,x.finance_cost2_cheque_myself)">
                            {{ x.site_bank_title | substr:0:15}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="account">
                        <mat-header-cell *matHeaderCellDef>
                            <span>شماره حساب</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_cost2_id,x.finance_cost2_payment_type2,x.site_cheque_type,x.finance_cost2_cheque_myself)">
                            {{ x.accounts_number }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="cheque">
                        <mat-header-cell *matHeaderCellDef>
                            <span> شماره چک </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_cost2_id,x.finance_cost2_payment_type2,x.site_cheque_type,x.finance_cost2_cheque_myself)">
                            {{ x.finance_cost2_cheque_number }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="due">
                        <mat-header-cell *matHeaderCellDef>
                            <span>تاریخ سررسید</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_cost2_id,x.finance_cost2_payment_type2,x.site_cheque_type,x.finance_cost2_cheque_myself)">
                            {{ x.due_date }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <mat-header-cell *matHeaderCellDef style='max-width:65px' [hidden]="readonly">
                            <span> عملیات </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:65px' [hidden]="readonly">
                            <span>
                                <span (click)="check_access2(i,3,0,x.finance_cost2_id,0,1,x.site_cheque_id)"><i
                                        class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                    </mat-header-row>
                    <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                        (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                        [ngStyle]="{backgroundColor: 1==1 ? row.color : 'transparent' }"
                        [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,'mat_table_hover': row === mat_table_hoverRow}"
                        class="cursor">
                    </mat-row>

                </mat-table>
            </div>
            <div style="text-align: right;padding-top:1rem;">
                <span> جمع کل به عدد : </span>
                <span>{{ amount | number }} </span>
                <span>{{ currency_title }}</span>
                <span> به حروف </span>
                <span> {{ sum_all_letter }} </span>
                <span> {{ currency_title }} </span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <div class="row" style="width: 100%;">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 hidden-xs" style="text-align: right;">
                <div *ngIf="document_user == true">
                    <div class="remain"> مانده حساب از قبل به عدد </div>
                    <div class="remain">
                        <div style="display: inline-block;direction: ltr;"> {{ remain |
                            number }} </div>
                    </div>
                    <div class="remain"> {{ currency_title }} </div>
                    <div class="remain"> به حروف </div>
                    <div class="remain">{{ remain_letter }}</div>
                    <div class="remain"> {{ currency_title }} </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12" style="text-align: right;padding-right:0px"
                [hidden]="readonly">
                <button type="submit" [disabled]="!form1_group.valid || (i == -1 && list_record.length == 0)"
                    class="btn_pe" [hidden]="readonly">
                    <span>ذخیره</span>
                </button>
            </div>

        </div>
    </div>
</form>