import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-wharehouse-order-buy-detaile',
  templateUrl: './wharehouse-order-buy-detaile.component.html',
  styleUrls: ['./wharehouse-order-buy-detaile.component.scss']
})
export class WharehouseOrderBuyDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public code: number | undefined;
  public invoice_id: number | undefined;
  public token: number | undefined;
  public trash: number | undefined;
  public creator: number | undefined;
  public check_order_id: number | undefined;
  public material_id: number;
  public result: any;
  document_type_text: string | undefined;


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public matDialogRef: MatDialogRef<WharehouseOrderBuyDetaileComponent>) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.document_type_text = dialog_data.document_type_text;
      this.type_task = dialog_data.type_task;
      this.trash = dialog_data.trash;
      this.invoice_id = dialog_data.invoice_id;
      this.create_form1();
      this.id = dialog_data.id;
      this.token = dialog_data.token;
      this.code = dialog_data.code;
      if (this.type_task == 2) {
        this.check_access(4);
      }
    }
  }//end consructor

  ngOnInit() {

  }

  create_form1() {
    this.form1_group = new FormGroup({
      'group_title': new FormControl({ value: null, disabled: true }, [Validators.required]),
      'cate_title': new FormControl({ value: null, disabled: true }, [Validators.required]),
      'material_title': new FormControl({ value: null, disabled: true }, [Validators.required]),
      'number': new FormControl(1, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'cost': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'discount': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
    });
  }

  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            if (type_task == 2) {
              this.check_for_update1();
            }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_data() {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6497, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'cost': res['result'][0].wharehouse_order_cost,
              'discount': res['result'][0].wharehouse_order_discount,
              'comment': res['result'][0].wharehouse_order_comment,
              'material_title': res['result'][0].wharehouse_material_title,
              'cate_title': res['result'][0].wharehouse_material_cate_title,
              'number': res['result'][0].wharehouse_order_number,
              'group_title': res['result'][0].wharehouse_group_title,
            });
            this.material_id = res['result'][0].wharehouse_material_id;
            this.check_order_id = res['result'][0].wharehouse_order_id;
          }//end if
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_for_update1() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6003
      , material: this.material_id
      , id: this.id, token: this.token
      , check_order_id: this.check_order_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          if (this.document_type_text == 'input' && res['num'] == 0) {
            this.update();
          }
          else if (this.document_type_text == 'input' && res['num'] > 0 && this.form1_group.value.number >= res['result'][0].order_consumed) {
            this.update();
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            var en_message = "The number of outputs of a product cannot be greater than the number of inputs";
            this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (this.form1_group.value.discount > this.form1_group.value.cost * this.form1_group.value.number) {
      var pe_message = "مقدار تخفیف نمی تواند بیشتر از قیمت اصلی باشد";
      var en_message = "The amount of the discount cannot be more than the original price";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6498, "user_id": this.user_id
      , 'comment': this.form1_group.value.comment
      , 'cost': this.form1_group.value.cost
      , 'material': this.material_id
      , 'number': this.form1_group.value.number
      , 'discount': this.form1_group.value.discount
      , 'id': this.id
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.update_all_financial2_id();

        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  update_all_financial2_id() {
    var obj = {
      address: 6684
      , order_id: this.id
      , cost_buy: this.form1_group.value.cost
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) { this.loading = false; }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

