<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr',en:lang == 2}" name="search_all"
            [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)" placeholder="جستجو..." autocomplete="off"
            style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='open_detaile(0,1,0)' [disabled]='new_status'>
                    <span>جدید</span>
                </button>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>فیلتر</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
                <div style="display: inline-block;">
                    <mat-checkbox (change)="get_select($event.checked)" [(indeterminate)]="indeterminate">
                    </mat-checkbox>
                </div>
                <div class="cursor" (click)="check_access(3,0,selected_record,1,1)">
                    <i class="far fa-trash-alt trash icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                <span>ردیف</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'>{{ i + page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="menu">
            <mat-header-cell *matHeaderCellDef>
                <span>نمایش در منو</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_menu(i,x.repairman_rstatus_show_menu,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_show_menu == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_show_menu == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sort">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span>ترتیب</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.repairman_rstatus_id)"
                style="max-width:70px" style="max-width:70px">{{
                x.repairman_rstatus_sort }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef style="min-width: 150px;">
                <span>عنوان وضعیت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.repairman_rstatus_id)"
                style="min-width: 150px;">
                <div class="right">{{ x.repairman_rstatus_title }}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="payment_dialog">
            <mat-header-cell *matHeaderCellDef>
                <span>پاپ آپ پرداخت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_payment_dialog(i,x.repairman_rstatus_payment_dialog,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_payment_dialog == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_payment_dialog == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="material_dialog">
            <mat-header-cell *matHeaderCellDef>
                <span>پاپ آپ کالا و خدمات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_material_dialog(i,x.repairman_rstatus_material_dialog,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_material_dialog == 0"><i class="fas fa-check icon icon"></i>
                    </span>
                    <span *ngIf="x.repairman_rstatus_material_dialog == 1"><i class="fas fa-check icon icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="default">
            <mat-header-cell *matHeaderCellDef>
                <span>پیش فرض</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_default(i,x.repairman_rstatus_default,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_default == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_default == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="default_wharhouse">
            <mat-header-cell *matHeaderCellDef>
                <span>قطعه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span
                    (click)='change_default_wharhouse(i,x.repairman_rstatus_default_wharhouse,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_default_wharhouse == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_default_wharhouse == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="payment">
            <mat-header-cell *matHeaderCellDef>
                <span>پرداخت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_payment(i,x.repairman_rstatus_payment,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_payment == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_payment == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="order">
            <mat-header-cell *matHeaderCellDef>
                <span>سفارشات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_order(i,x.repairman_rstatus_order,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_order == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_order == 1"><i class="fas fa-check icon" style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="default_belong">
            <mat-header-cell *matHeaderCellDef>
                <span>متعلقات و ایرادات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_belong(i,x.repairman_rstatus_belong,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_belong == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_belong == 1"><i class="fas fa-check icon" style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="empty_shelf">
            <mat-header-cell *matHeaderCellDef>
                <span>خالی شدن قفسه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_empty_shelf(i,x.repairman_rstatus_empty_shelf,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_empty_shelf == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_empty_shelf == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="ready_delivery">
            <mat-header-cell *matHeaderCellDef>
                <span>آماده تحویل</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="background:antiquewhite">
                <span (click)='change_ready_delivery(i,x.repairman_rstatus_ready_delivery,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_ready_delivery == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_ready_delivery == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delivered">
            <mat-header-cell *matHeaderCellDef>
                <span>زیر شاخه آماده تحویل</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="background:antiquewhite">
                <span (click)='change_delivered(i,x.repairman_rstatus_sub_ready_delivery,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_sub_ready_delivery == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_sub_ready_delivery == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ready_reparing">
            <mat-header-cell *matHeaderCellDef>
                <span>در حال تعمیر</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="background:aquamarine">
                <span (click)='change_ready_reparing(i,x.repairman_rstatus_ready_reparing,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_ready_reparing == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_ready_reparing == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="repairing">
            <mat-header-cell *matHeaderCellDef>
                <span>زیر شاخه در حال تعمیر</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="background:aquamarine">
                <span (click)='change_repairing(i,x.repairman_rstatus_repairing,x.repairman_rstatus_id)'>
                    <span *ngIf="x.repairman_rstatus_repairing == 0"><i class="fas fa-check icon"></i> </span>
                    <span *ngIf="x.repairman_rstatus_repairing == 1"><i class="fas fa-check icon"
                            style="color: blue;"></i> </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span>وضعیت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.repairman_rstatus_id)"
                style="max-width:70px">
                <span>{{ x.status_title }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef [hidden]="true">
                <span>شناسه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.repairman_rstatus_id)"
                [hidden]="true">{{
                x.repairman_rstatus_id }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:50px'>
                <span>عملیات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:50px'>
                <mat-checkbox (change)="get_select_records(i)" name="selete_records[]" [value]="x.repairman_rstatus_id"
                    [checked]="x.checked" class="hidden-sm hidden-xs"></mat-checkbox>
                <span (click)="check_access(3,0,x.repairman_rstatus_id,0,1)"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2" *ngIf="lang == 1">هیچ رکوردی برای نمایش وجود ندارد</div>
            <div class="preview2" *ngIf="lang == 2">There are no records to display</div>
        </div>
    </div>
</div>