<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>مانده اول دوره</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(2)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1 padding0">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' style='margin-top:5px'>
                <label for="finance_nature" class='wh-label'>
                    <span> ماهیت مانده </span>
                    <span (click)="reset_input(218)">
                        <mat-icon class="close" class="input_search input_search_pe">close
                        </mat-icon>
                    </span>
                    <input class='wh-input' formControlName='finance_nature_title' autocomplete="off"
                        (click)="load_services(218)" readonly required>
                    <input class='wh-input' formControlName='finance_nature' autocomplete="off" [hidden]="true" readonly
                        required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' style='margin-top:5px'>
                <label for="balance" class='wh-label'>
                    <span> مانده اول دوره </span>
                    <input class='wh-input' formControlName='balance' currencyMask
                        style="direction:ltr;text-align:right" required>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1.valid || loading" class="btn_pe">
            <span> ذخیره </span>
            <span [hidden]="!loading">
                <div class="spinner-border" style="width: 1rem; height: 1rem;margin-right:0.5rem" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </span>
        </button>
    </div>
</form>