<div class="print">
    <div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
        <span>
            گزارش دستگاهها
        </span>
        <span class="fleft" [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></span>
        <mat-menu #menu="matMenu" class="chart-mat-menu-panel" xPosition="before"
            [ngClass]="{'full-left':dir == 'ltr'}">
            <table class="table table-bordered" [ngClass]="{'full-left':dir == 'ltr'}">
                <tr>
                    <td class="right" [ngClass]="{'left':dir == 'ltr'}" (click)="get_charts(-1)">
                        <span> همه سالها </span>
                    </td>
                </tr>
                <tr>
                    <td class="right" [ngClass]="{'left':dir == 'ltr'}" (click)="get_charts(1402)">
                        <span> گزارش سال 1402 </span>
                    </td>
                </tr>
                <tr>
                    <td class="right" [ngClass]="{'left':dir == 'ltr'}" (click)="get_charts(1401)">
                        <span> گزارش سال 1401 </span>
                    </td>
                </tr>
            </table>
        </mat-menu>

        <span class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </span>
    </div>

    <div mat-dialog-content class="matDialogContent" style="min-height: 100vh">
        <div class="row">
            <div class="col-lg-4 col-xl-4 col-sm-4 col-sm-4 col-xs-12 div1">
                <span class="cursor" style="position: absolute;left: 0;"
                    [matMenuTriggerFor]="menu2"><mat-icon>menu</mat-icon></span>
                <mat-menu #menu2="matMenu" class="chart-mat-menu-panel2" xPosition="before"
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <table class="table table-bordered" [ngClass]="{'full-left':dir == 'ltr'}">
                        <tr *ngFor="let x of list_device">
                            <td class="right" [ngClass]="{'left':dir == 'ltr'}" (click)="chart3(x.wharehouse_device_id)"
                                class="cursor">
                                <span> {{ x.wharehouse_device_title }} </span>
                            </td>
                        </tr>
                    </table>
                </mat-menu>
                <div class="spinner-border" role="status" *ngIf="loading_container3 == true">
                    <span class="sr-only">Loading...</span>
                </div>
                <figure class="highcharts-figure" [hidden]="loading_container3 == true">
                    <div id="container3"></div>
                </figure>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-4 col-sm-4 col-xs-12 div1">
                <div class="spinner-border" role="status" *ngIf="loading_container2 == true">
                    <span class="sr-only">Loading...</span>
                </div>
                <figure class="highcharts-figure" [hidden]="loading_container2 == true">
                    <div id="container2"></div>
                </figure>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-4 col-sm-4 col-xs-12 div1">
                <div class="spinner-border" role="status" *ngIf="loading_container == true">
                    <span class="sr-only">Loading...</span>
                </div>
                <figure class="highcharts-figure" [hidden]="loading_container == true">
                    <div id="container"></div>
                </figure>
            </div>


            <div class="col-lg-12 col-xl-12 col-sm-12 col-sm-12 col-xs-12 div1">
                <div class="spinner-border" role="status" *ngIf="loading_container4 == true">
                    <span class="sr-only">Loading...</span>
                </div>
                <figure class="highcharts-figure">
                    <div id="container4"></div>
                </figure>
            </div>

        </div>
    </div>
</div>