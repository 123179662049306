<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="type == 'belong'">
            <span *ngIf="lang == 1">متعلقات</span>
            <span *ngIf="lang == 2">Accessory</span>
        </span>
        <span *ngIf="type == 'objections'">
            <span *ngIf="lang == 1">ایرادات</span>
            <span *ngIf="lang == 2">Problem</span>
        </span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="content-body control-content-body" *ngIf="type == 'belong'" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;'>

        <input class="search" (keyup)="get_belong(2)" [(ngModel)]="title"
            placeholder="{{ messageService.get_search(lang) }} " [ngClass]="{'en':dir == 'ltr'}">

        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span *ngIf="lang == 1">ردیف</span>
                    <span *ngIf="lang == 2">#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i
                    + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">عنوان</span>
                    <span *ngIf="lang == 2">Title</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{ x.repairman_belong2_title
                    }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style="max-width: 100px">
                    <span *ngIf="lang == 1">اضافه کردن</span>
                    <span *ngIf="lang == 2">Add</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 100px">
                    <span (click)="add_belong(x.repairman_belong2_id,x.repairman_belong2_title)">
                        <mat-icon class="mat-icon icon-add material-icons">
                            add_circle_outline </mat-icon>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_hover': row === mat_table_hoverRow,'green':list_belong_selected.indexOf(row.repairman_belong2_id) >= 0}"
                class="cursor"></mat-row>
        </mat-table>


    </div>
    <!-- **************************************************************************************************** -->
    <div class="content-body control-content-body" *ngIf="type == 'objections'" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;'>

        <input class="search" (keyup)="get_objections(2)" [(ngModel)]="title"
            placeholder="{{ messageService.get_search(lang) }}" [ngClass]="{'en':dir == 'ltr'}">

        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span *ngIf="lang == 1">ردیف</span>
                    <span *ngIf="lang == 2">#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i
                    + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">عنوان</span>
                    <span *ngIf="lang == 2">Title</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [ngClass]="{'en':dir == 'ltr'}">{{
                    x.repairman_objections2_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style="max-width: 100px">
                    <span *ngIf="lang == 1">اضافه کردن</span>
                    <span *ngIf="lang == 2">Add</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 100px">
                    <span (click)="add_objections(x.repairman_objections2_id,x.repairman_objections2_title)">
                        <mat-icon class="mat-icon icon-add material-icons">
                            add_circle_outline </mat-icon>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_hover': row === mat_table_hoverRow,'green':list_objections_selected.indexOf(row.repairman_objections2_id) >= 0}"
                class="cursor"></mat-row>
        </mat-table>


    </div>
    <!-- **************************************************************************************************** -->
</div>