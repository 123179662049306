import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-repairman-delivery',
  templateUrl: './repairman-delivery.component.html',
  styleUrls: ['./repairman-delivery.component.scss']
})
export class RepairmanDeliveryComponent implements OnInit, OnDestroy {
  public isOnline: any | undefined;
  public loading = false;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang = JSON.parse(<any>localStorage.getItem('lang'));
  user_id: number | undefined;
  public subscription: Subscription | any;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();

  id: any | undefined;
  device: string | undefined;
  brand: string | undefined;
  model: string | undefined;
  deliver_code: number | undefined;
  creator: number | undefined;
  cellphone: any | undefined;
  store_title: string | undefined;
  customer_title: string | undefined;
  form1: FormGroup;
  rstatus_title: string | undefined;
  result: any;
  shelf_temp: number | undefined;

  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<RepairmanDeliveryComponent>
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any, private dialog: MatDialog, private http: HttpClient) {
    this.id = dialog_data.id;
    this.creator = dialog_data.creator;
  }//end consructor

  ngOnInit() {
    this.create_form();
    this.get_reception();
  }

  create_form() {
    this.form1 = new FormGroup({
      'code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    })
  }

  get_reception() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6447, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.store_title = res['result'][0].store_title;
            this.cellphone = res['result'][0].user_cellphone;
            this.customer_title = res['result'][0].customer_title;
            this.rstatus_title = res['result'][0].repairman_rstatus_title;
            this.shelf_temp = res['result'][0].repairman_reception_shelf;
            this.deliver_code = Math.floor(this.id * this.id / 2);
            this.device = res['result'][0].wharehouse_device_title + " " + res['result'][0].repairman_brand_title + " " + res['result'][0].repairman_model_title;
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  check_charge_sms(step: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (this.cellphone && this.cellphone.match(/^\d{11}$/g) == null) {
      var pe_message = "فرمت شماره برای ارسال پیامک معتبر نمی باشد";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6312, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].amount >= 2000) {
              if (step == 1) this.send_code_delivery();
              if (step == 2) {
                this.send_message_delivery();
                this.update_rstatus()
              }
            }
            else {
              var pe_message = "قادر به ارسال پیامک نمی باشید لطفا شارژ نمائید";
              var pe_action = "بستن";
              this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
            }
          }
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update_rstatus() {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6119, delivered: true, id: this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.empty_shelf(this.shelf_temp, 0, 'empty_reception');
        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  empty_shelf(shelf: any, new_shelf: number, type: string) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1811, shelf: shelf, type: type, id: this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ result: this.result });
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }




  log_sms(cost: number) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6308, user_id: this.user_id, creator: this.creator, cost: cost }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_sms_charge();
        }//end if
        else {
          var pe_message = "خطا در ثبت لاگ پیامک ارسالی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //***************************************************************************************************** */
  check_code() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid) {
      return false;
    }
    if (this.deliver_code != this.form1.value.code) {
      var pe_message = "کد وارد شده معتبر نمی باشد.";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    } else {
      this.check_charge_sms(2);
    }
  }

  send_message_delivery(): any {
    var obj = {
      address: 6557, user_id: this.user_id, creator: this.creator
      , token20: this.device
      , token10: this.store_title
      , cellphone: this.cellphone
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var pe_message = "پیام با موفقیت ارسال شد .همچنین پذیرش به قسمت تحویل شده انتقال یافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          this.log_sms(res['response'].body.entries[0].cost);

        }//end if
        else {
          var pe_message = "خطا در ارسال پیامک";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  send_code_delivery(): any {
    var obj = {
      address: 6556, user_id: this.user_id, creator: this.creator
      , token20: this.device
      , token10: this.store_title
      , cellphone: this.cellphone
      , deliver_code: this.deliver_code
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var pe_message = "پیام با موفقیت ارسال شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          this.log_sms(res['response'].body.entries[0].cost);
        }//end if
        else {
          var pe_message = "خطا در ارسال پیامک";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 5000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}