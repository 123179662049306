import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import { ModulesComponent } from '../../../service/modules/modules.component';


@Component({
  selector: 'app-material-detaile3',
  templateUrl: './material-detaile3.component.html',
  styleUrls: ['./material-detaile3.component.scss']
})
export class MaterialDetaile3Component implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public code: number | undefined;
  public invoice_id: number | undefined;
  public group: number | undefined;
  public cate: number | undefined;
  public token: number | undefined;
  public trash: number | undefined;
  public creator: number | undefined;
  public check_order_id: number | undefined;
  public material_id: number;
  public document_type_text: string | undefined;
  public title: string;


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<MaterialDetaile3Component>) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.document_type_text = dialog_data.document_type_text;
      this.type_task = dialog_data.type_task;
      this.trash = dialog_data.trash;
      this.invoice_id = dialog_data.invoice_id;
      this.group = dialog_data.group;
      this.cate = dialog_data.cate;
      this.create_form1();
      this.id = dialog_data.id;
      this.token = dialog_data.token;
      this.code = dialog_data.code;
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          group2: dialog_data.search_array.group2,
          group_title2: dialog_data.search_array.group_title2,
          cate2: dialog_data.search_array.cate2,
          cate_title2: dialog_data.search_array.cate_title2,
          material_title: dialog_data.search_array.material_title,
        })
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.cate) {
      this.get_cate();
    }
    else if (this.group) {
      this.get_group();
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'group_title2': new FormControl(null, [Validators.required]),
      'group2': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'cate_title2': new FormControl(null, [Validators.required]),
      'cate2': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cost2': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')]),

      'material_title': new FormControl(null, [Validators.required]),
      'number': new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'cost': new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'discount': new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.get('material_title').disable();
      this.form1_group.get('group_title2').disable();
      this.form1_group.get('group2').disable();
      this.form1_group.get('cate_title2').disable();
      this.form1_group.get('cate2').disable();
    }
  }

  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            if (type_task == 2) {
              this.update();
            }
            if (type_task == 3) { this.search(excel); }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_data() {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6527, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'cost': res['result'][0].wharehouse_order_cost,
              'cost2': res['result'][0].wharehouse_order_cost2,
              'discount': res['result'][0].wharehouse_order_discount,
              'comment': res['result'][0].wharehouse_order_comment,
              'material_title': res['result'][0].wharehouse_material_title2,
              'number': res['result'][0].wharehouse_order_number,
              'group_title2': res['result'][0].wharehouse_group_title,
              'cate_title2': res['result'][0].wharehouse_material_cate_title,
            });
            this.title = res['result'][0].wharehouse_material_title2;
            this.material_id = res['result'][0].wharehouse_material_id;
            this.check_order_id = res['result'][0].wharehouse_order_id;
          }//end if
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_group() {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6576, group: this.group }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'group_title2': res['result'][0].wharehouse_group_title,
              'group2': res['result'][0].wharehouse_group_id,
            });
          }//end if
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_cate() {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6575, cate: this.cate }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'cate_title2': res['result'][0].wharehouse_material_cate_title,
              'cate2': res['result'][0].wharehouse_material_cate_id,
              'group_title2': res['result'][0].wharehouse_group_title,
              'group2': res['result'][0].wharehouse_group_id,
            });
          }//end if
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6528, "user_id": this.user_id
      , 'comment': this.form1_group.value.comment
      , 'cost2': this.form1_group.value.cost2
      , 'id': this.id
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end update

  search(excel: number) {
    this.matDialogRef.close({
      material_title: this.form1_group.value.material_title,
      group_title2: this.form1_group.value.group_title2,
      group2: this.form1_group.value.group2,
      cate_title2: this.form1_group.value.cate_title2,
      cate2: this.form1_group.value.cate2,
      export_excel: excel
    })
  }

  load_services(id: number): any {
    var where = "";
    var creator = this.creator;
    var code_id;
    creator = 2445;

    if (id == 184) {
      if (!this.form1_group.value.group2) {
        var pe_message = "در ابتدا باید گروه یکپارچه انتخاب شود";
        var pe_action = "بستن";
        this.recieve_message(true, '----', pe_message, 1, 'close', pe_action);
        return false;
      }
    }
    if (id == 184) {
      code_id = 6524;
    }

    if (id == 223) {
      code_id = 6346;
    }


    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: {
        id: id, where: where, creator: creator, code_id: code_id
        , obj: { group2: this.form1_group.value.group2 }
      }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {

          if (id == 223) {
            this.form1_group.patchValue({
              'group2': res.id,
              'group_title2': res.title,
              'cate2': null,
              'cate_title2': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate2': res.id,
              'cate_title2': res.title
            })
          }
        }
      }
    )
  }


  reset_input(id: number): any {
    if (id == 223) {
      this.form1_group.patchValue({
        'group_title2': null,
        'group2': null,
        'cate2': null,
        'cate_title2': null
      })
    }
    if (id == 184) {
      this.form1_group.patchValue({
        'cate_title2': null,
        'cate2': null
      })
    }
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

