import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserDetaileComponent } from "../user-detaile/user-detaile.component";
import { UserVideoComponent } from '../user-video/user-video.component';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../service/message/message.service';
import { ChartsComponent } from '../../finance/charts/charts/charts.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public server!: string;
  public server_main!: string;
  public site!: string;
  public loading: boolean = false;
  public loading_copy: boolean = false;
  public show_cellphone: boolean = true;
  public show_excel: boolean = false;
  public show_financial: boolean = false;
  public search_all_params: boolean = false;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public tsearch: string = 'all';
  public reportUsers: string = 'all';
  public asc_desc: string = 'asc';
  public sharj_all: number = 0;
  public count_total: number = 1;
  public groups: number = 0;
  public page: number = 1;
  public groups_id: number = 0;
  public excel: number = 1;
  public list_record: any = [];
  public list_status: any = [];
  public search_array: any = [];
  public selected_record: any = [];
  public list_groups: any = [];
  public subscription: Subscription | any;
  public user_id!: number;
  public task_type!: number;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public order!: string;
  public code!: any;
  public active!: boolean;
  public export_code!: string;
  public type_task!: number;
  public type!: number;
  public search_all!: string;
  public date!: string;
  public select_user!: number;
  public id!: number;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public status_id!: number;
  public title!: string;
  public select_all!: boolean;
  public back_i!: number;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'id', 'guilds', 'title', 'store', 'cellphone', 'last_login', 'amount', 'moarf_id', 'username', 'state', 'sms', 'expire', 'date', 'access', 'invoice', 'reception', 'finance', 'copy', 'similar_admin', 'clear_token', 'comment2', 'operation'];
  public creator!: number;
  public creator_temp!: number;
  public similar_admin!: number;
  public access_service!: number;
  public level!: number;
  public video!: string;
  public path_export!: string;
  public moarf!: string;
  public record_id!: number;
  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public http: HttpClient,
    public messageService: MessageService,
    public dialogRef: MatDialog) {
    this.server = this.serverService.get_server();
    this.server_main = this.serverService.get_server_main();
    this.site = this.serverService.get_site();
  }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
        this.serverService.send_report_users({})
      }
    )
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        if (params['back_i']) this.back_i = params['back_i'];
        if (params['page']) this.page = params['page'];
        this.tsearch = 'all';
        if (params['search_all']) {
          this.search_all = params['search_all'];
          this.search_all_params = true;
        }
        if (params['id']) this.groups_id = params['id'];
        else this.groups_id = 0;
        if (params['moarf']) this.moarf = params['moarf'];
        else this.moarf = '';
        if (params['reportUsers']) this.reportUsers = params['reportUsers'];
        this.check_access(4, 1, 0, 1, 1);
      }
    )
    //if (this.search_all_params == false) this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.creator_temp = res['creator'];
          this.similar_admin = res['similar_admin'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.show_cellphone = res['show_cellphone'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  send_sms(cellphone: any, username: string, password: string): any {
    if (!cellphone || !username || !password) {
      var pe_message = "خطا در فرمت ارسال";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    var x = confirm(this.messageService.message_sms_accept(this.lang));
    if (x == false) {
      this.message(false, "", 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      address: 6535,
      cellphone: cellphone,
      username: username,
      password: password,
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['response'].body.return.status == 200) {
          this.message(true, this.messageService.send_message(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_send(this.lang), 1, this.messageService.close(this.lang));

        }
      })
  }
  sort(sort: string, asc_desc: string) {
    if (asc_desc == 'asc') this.asc_desc = 'desc';
    else this.asc_desc = 'asc';
    this.order = sort + " " + this.asc_desc;
    this.load_data(1, 1, 1, -1);
  }

  reset_sort(sort: string, asc_desc: string) {
    this.order = sort + " " + asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  load_data(page: number, excel: number, loading: number, changedRows: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = Number(this.page) + Number(1); }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (page == 6 || page == 1) { this.page = 1; }
    var address = 1655;
    if (this.moarf == 'moarf_id') { address = 6613; }
    if (this.similar_admin == 1) {
      this.creator = 2445;
      this.level = 1;
    }
    var obj = {
      'address': address
      , "page": this.page
      , 'type': this.type
      , 'excel': excel
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'groups': this.groups
      , 'creator': this.creator
      , 'code': this.code
      , 'active': this.active
      , 'groups_id': this.groups_id, tsearch: this.tsearch
      , 'path_export': this.path_export, level: this.level
      , 'moarf': this.moarf
      , 'show_cellphone': this.show_cellphone
      , 'lang': this.lang
      , 'reportUsers': this.reportUsers
      , 'order': this.order
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.date = res['date'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.sharj_all = res['sharj_all'];
            this.pages(0);
            this.serverService.send_services_id(this.code);
            this.serverService.change_window_resize(2, '', 0);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  export_excel(code: number) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    var width = '65rem';
    if (type_task == 3) {
      width = '30rem'
    }
    const dialogRef = this.dialog.open(UserDetaileComponent, {
      width: width,
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, level: this.level, search_array: this.search_array, code: this.code, title: this.title, gropus: this.groups, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[i].user_groups_title = res.result.user_groups_title;
            this.list_record[i].site_state_title = res.result.site_state_title;
            this.list_record[i].user_store = res.result.user_store;
            this.list_record[i].user_title = res.result.user_title;
            this.list_record[i].user_cellPhone = res.result.user_cellPhone;
            this.list_record[i].status_title = res.result.status_title;
            this.list_record[i].user_expire_date = res.result.user_expire_date;
            this.list_record[i].user_username = res.result.user_username;
            this.list_record[i].user_repassword = res.result.user_repassword;
            this.list_record[i].user_sms_bought = res.result.user_sms_bought;
            this.list_record[i].user_sex_title = res.result.user_sex_title;
            this.list_record[i].user_amount = res.result.user_amount;
            this.list_record[i].site_guilds_title = res.result.site_guilds_title;
            this.list_record[i].site_guilds_user = res.result.site_guilds_user;
            this.list_record[i].user_comment2 = res.result.user_comment2;
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }
  set_and_get_number_access(i: number, id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6849, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record[i].user_count_access = res['count'];
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  go_to_user2(user_id: number, token: number) {
    localStorage.setItem('refrerr', '2');
    window.open("/login?token_login=" + token + "&userId=" + user_id, "_self");
  }
  clear_token(i: number, id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    var pe_message = " اگر گمان میکنید که نام کاربری یا کلمه عبور این کاربر در دسترس شخص دیگری قرار گرفته است با تغییر توکن  میتوانید دسترسی شخص مورد نظر را به صورت آنی قطع کنید . آیا از تغییر توکن اطمینان دارید؟.";
    confirm_delete = window.confirm(this.messageService.message(this.lang, pe_message, ''));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6705, id: id }).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.message(true, this.messageService.change(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
          }
        }
      )
    }
  }
  //*******************************************************************************
  delete(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var i = this.list_record.findIndex((index: any) => index.user_id == id);
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 1658,
        "user_id": this.user_id,
        'id': id,
        "page": this.page,
        'type': this.type,
        'search': this.search_array,
        'search_all': this.search_all,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(i, 1);
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.delete_financial2(id);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true
  }//end delete
  delete_financial2(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6310, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در تراکنش مالی کاربر";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************
  accept(i: number, service: number, user: number, copy_id: number): any {
    if (!copy_id) {
      var pe_message = "برای کپی دیتا بایستی اصناف  و شناسه کپی مشخص باشد";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    var confirm = window.confirm("آیا از بارگذاری اطلاعات اولیه اطمینان دارید ؟");
    if (confirm == true) {
      this.copy_default(i, service, user, copy_id)
    }
  }
  copy_default(i: number, service: number, user: number, copy_id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading_copy = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: service, id: this.id, user_id: this.user_id, user: user, copy_id: copy_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (service == 6140) this.copy_default(i, 6141, user, copy_id);
          if (service == 6141) this.copy_default(i, 6143, user, copy_id);
          if (service == 6143) this.copy_default(i, 6144, user, copy_id);
          if (service == 6144) this.copy_default(i, 6145, user, copy_id);
          if (service == 6145) this.copy_default(i, 6146, user, copy_id);
          if (service == 6146) this.copy_default(i, 6147, user, copy_id);
          if (service == 6147) this.copy_default(i, 6148, user, copy_id);
          if (service == 6148) this.copy_default(i, 6149, user, copy_id);
          if (service == 6149) this.copy_default(i, 6150, user, copy_id);
          if (service == 6150) this.copy_default(i, 6153, user, copy_id);
          if (service == 6153) this.copy_default(i, 6156, user, copy_id);
          if (service == 6156) this.copy_default(i, 6713, user, copy_id);
          if (service == 6713) this.copy_default(i, 6714, user, copy_id);
          if (service == 6714) this.copy_default(i, 6157, user, copy_id);
          if (service == 6157) this.copy_default(i, 6715, user, copy_id);
          if (service == 6715) this.copy_default(i, 6716, user, copy_id);
          if (service == 6716) {
            this.update_user(i, user);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_user(i: number, user: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6170, user: user }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record[i].user_copy = true;
          var pe_message = "با موفقیت کپی داده ها  انجام شد";
          var en_message = "The data was copied successfully";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
          this.loading_copy = false;
        }//end if
        else {
          this.loading_copy = false;
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end recieve_data
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  change_similar_admin(j: number, value: number, id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6693, value: !value, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.list_record[j].user_similar_admin = res['result'][0].user_similar_admin;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  openchart() {
    this.dialog.open(ChartsComponent, {
      width: '95%',
      height: '80vh',
      data: { creator: this.creator, title: " ثبت نام همه کاربران ", address1: 7095, address2: 0, display_chart1: true, display_chart2: false, has_price: false }
    });
  }
  get_bg2(id: number) {
    this.record_id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize

  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
