import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserSearchComponent } from '../../../user/user-search/user-search.component';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-cardex-detaile',
  templateUrl: './cardex-detaile.component.html',
  styleUrls: ['./cardex-detaile.component.scss']
})
export class CardexDetaileComponent implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public form1: FormGroup;
  public search_array: any = [];
  public obj: any;
  public type_task: number | undefined;
  public creator: number | undefined;
  public ref: string | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , public matDialogRef: MatDialogRef<CardexDetaileComponent>
    , public dialog: MatDialog
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {

    this.create_form();
    if (dialog_data) {
      this.ref = dialog_data.ref;
      this.creator = dialog_data.creator;
    }
    if (dialog_data.res) {
      this.form1.patchValue({
        material: dialog_data.res.material,
        material_title: dialog_data.res.material_title,
        user: dialog_data.res.user,
        user_title: dialog_data.res.user_title,
        date1: dialog_data.res.date1,
        date2: dialog_data.res.date2,
        creator: dialog_data.creator,
        ref: dialog_data.ref,
      })
    }
  }//end consructor

  ngOnInit() {
  }

  create_form() {
    this.form1 = new FormGroup({
      'material': new FormControl(null, Validators.pattern('[0-9]{1,}]')),
      'material_title': new FormControl(null),
      'user': new FormControl(null, Validators.pattern('[0-9]{1,}]')),
      'user_title': new FormControl(null),
      'date1': new FormControl(null),
      'date2': new FormControl(null),
    })
  }

  search(excel: number) {
    var date1;
    var date2;
    if (this.form1.value.date1) { date1 = `${this.form1.value.date1.toISOString()}`; }
    if (this.form1.value.date2) { date2 = `${this.form1.value.date2.toISOString()}`; }
    this.obj = {
      'material': this.form1.value.material, 'user': this.form1.value.user, search: true
      , 'date1': date1, 'date2': date2, id: 205
    };
    if (excel == 1) {
      var obj = {
        'material': this.form1.value.material
        , 'material_title': this.form1.value.material_title
        , 'date1': this.form1.value.date1
        , 'date2': this.form1.value.date2
        , 'user_title': this.form1.value.user_title
        , 'user': this.form1.value.user
        , 'export_excel': 1
      }
      this.matDialogRef.close(obj);
      this.router.navigate(['/home', 'cardex', 205], { queryParams: this.obj });
    }
    else {
      this.matDialogRef.close({
        date1: this.form1.value.date1,
        date2: this.form1.value.date2,
        material: this.form1.value.material,
        user: this.form1.value.user,
        export_excel: excel
      })
    };
    //this.matDialogRef.close(this.obj);
  }

  open_user() {
    const dialogRef = this.dialog.open(UserSearchComponent, {
      width: '40rem',
      height: 'auto',
      data: { code: 193 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1.patchValue({
            'user_title': res.title,
            'user': res.id
          })
        }
      }
    )
  }

  load_services(id: number) {
    var code_id;
    if (id == 171) {
      code_id = 6357;
    }
    if (id == 161) {
      code_id = 1731;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '60rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator, code_id: code_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 171) {
            this.form1.patchValue({
              'material': res.id,
              'material_title': res.title
            })
          }
          if (id == 161) {
            this.form1.patchValue({
              'user': res.id,
              'user_title': res.title
            })
          }
        }
      }
    )
  }


  reset_input(id: number) {
    if (id == 26) {
      this.form1.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
    if (id == 161) {
      this.form1.patchValue({
        'user': null,
        'user_title': null
      })
    }
    if (id == 171) {
      this.form1.patchValue({
        'material': null,
        'material_title': null
      })
    }
  }

  close() {
    var obj = {
      'material': this.form1.value.material
      , 'material_title': this.form1.value.material_title
      , 'date1': this.form1.value.date1
      , 'date2': this.form1.value.date2
      , 'user_title': this.form1.value.user_title
      , 'user': this.form1.value.user
    }
    if (this.ref == 'home') this.matDialogRef.close(obj);
    else this.matDialogRef.close();
  }

  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

