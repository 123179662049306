import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserDetaile2Component } from '../../../user/user-detaile2/user-detaile2.component';
import { UserSearchComponent } from '../../../user/user-search/user-search.component';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { WharehouseInvoiceInputComponent } from '../../wharehouse-invoice-input/wharehouse-invoice-input.component';
import { WharehouseInvoiceExitComponent } from '../../wharehouse-invoice-exit/wharehouse-invoice-exit.component';
import { WharehouseOrderCostDetaileComponent } from '../../wharehouse-order-cost-detaile/wharehouse-order-cost-detaile.component';
import { WharehouseOrderServiceDetaileComponent } from '../../wharehouse-order-service-detaile/wharehouse-order-service-detaile.component';
import { WharehouseInvoiceReturnBuyComponent } from '../../wharehouse-invoice-return-buy/wharehouse-invoice-return-buy.component';
import { WharehouseOrderBuyDetaileComponent } from '../../wharehouse-order-buy-detaile/wharehouse-order-buy-detaile.component';
import { WharehouseOrderReturnBuyDetaileComponent } from '../../wharehouse-order-return-buy-detaile/wharehouse-order-return-buy-detaile.component';
import { WharehouseOrderSaleDetaileComponent } from '../../wharehouse-order-sale-detaile/wharehouse-order-sale-detaile.component';
import { WharehouseInvoiceReturnSaleComponent } from '../../wharehouse-invoice-return-sale/wharehouse-invoice-return-sale.component';
import { MessageService } from '../../../service/message/message.service';
import { WharehouseOrderSaleSearialComponent } from '../../wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';


@Component({
  selector: 'app-invoice-detaile',
  templateUrl: './invoice-detaile.component.html',
  styleUrls: ['./invoice-detaile.component.scss']
})
export class InvoiceDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main = this.serverService.get_server_main();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));

  public loading = false;
  public subscription: Subscription | undefined;
  public subscription1: Subscription | undefined;
  public subscription2: Subscription | undefined;
  public subscription3: Subscription | undefined;
  public subscription4: Subscription | undefined;
  public type_save: string;
  public access_insert_finance: boolean = false;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public sum_tax_all: number = 0;
  public i!: number;
  public code: number | undefined;
  public title: string | undefined;
  public list_order: any = [];
  public search_array: any = [];
  public invoice_type_id: number | any;
  public new_invoice_id: number | undefined;
  public material: number | undefined;
  public sum_all: number = 0;
  public sum_all_real: number = 0;
  public currency_title: string | undefined;
  public sum_all_letter: string | undefined;
  public remain_letter: string | undefined;
  public token: any = Math.floor(Math.random() * 99999999999999);
  public remain: number = 0;
  public x: number = 0;
  public x2: number = 0;
  public x3: number = 0;
  public show_financial: boolean = false;
  public search_all: string | undefined;
  public url: string = this.router.url.split("?")[0];
  public page: number | undefined;
  public back_i: number | undefined;
  public trash: number | undefined;
  public video: string | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public access_invoice_return_buy: Boolean;
  public access_invoice_buy: Boolean;
  public access_invoice_sale: Boolean;
  public order_id: number;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | any;
  public displayedColumns = ['row', 'material_type', 'material', 'number', 'weight', 'price', 'discount', 'tax', 'sum', 'repairman', 'searial', 'operation'];
  public list_record_financial2: any = [];
  public list_cheque: any = [];
  public amount: number | undefined;
  public document_type: number | undefined;
  public document_type_text: any | undefined;
  public list_not: any = [];
  public result: any;
  public readonly: boolean = false;
  public creator: number | undefined;
  public expire: boolean | undefined;
  public back: number | undefined;
  public temp_user: number;
  public target_user_id: number = 0;
  public target_user_title: string;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<InvoiceDetaileComponent>) {
    this.subscription3 = this.serverService.get_order().subscribe(
      (res) => {
        this.get_order();

      }
    )

    this.subscription1 = this.serverService.get_invoice_material().subscribe(
      (res) => {
        if (res) {
          this.list_order.push(res);
          this.dataSource.data = this.list_order;
          this.get_sum_all(2);
          if (this.form1_group.value.user) this.get_account(this.form1_group.value.user);
        }
      }
    );

    this.subscription4 = this.serverService.get_update_invoice_material().subscribe(
      (res) => {
        this.get_order();

      }
    )

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.readonly = dialog_data.readonly;
      this.order_id = dialog_data.order_id;
      this.invoice_type_id = dialog_data.invoice_type_id;
      this.type_task = dialog_data.type_task;
      this.new_invoice_id = dialog_data.new_invoice_id;
      this.creator = dialog_data.creator;
      this.create_form1();
      this.id = dialog_data.id;
      this.page = dialog_data.page;
      this.code = dialog_data.code;
      this.trash = dialog_data.trash;
      this.i = dialog_data.i;


      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          invoice_number: dialog_data.search_array.invoice_number,
          comment: dialog_data.search_array.comment,
          invoice_date: dialog_data.search_array.invoice_date,
          invoice_Expire_date: dialog_data.search_array.invoice_Expire_date,
          invoice_settlement_date: dialog_data.search_array.invoice_settlement_date,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
          user: dialog_data.search_array.user,
          user_title: dialog_data.search_array.user_title,
          currency: dialog_data.search_array.currency,
          currency_title: dialog_data.search_array.currency_title,
          rate: dialog_data.search_array.rate,
          invoice_type_title: dialog_data.search_array.invoice_type_title,
          invoice_type: dialog_data.search_array.invoice_type,

        });
        this.get_info_invoice(dialog_data.invoice_type_id);
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.change_access_insert_finance();

    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        if (params['user_invoice'] != null) {

          this.target_user_id = params['user_invoice'];

          this.get_user(this.target_user_id);
        }
      })
  }

  get_user(searched_user_id) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1953, user: searched_user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['num'] == 1) {
              this.target_user_title = res['result'][0].user_title;
            }
          }
        }//end if
      }
    )
  }

  change_access_insert_finance() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6504, user_id: this.user_id }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.access_insert_finance = res['result'][0].access;
            this.access_invoice_return_buy = res['result'][0].user_access_invoice_return_buy;
            this.access_invoice_buy = res['result'][0].user_access_invoice_buy;
            this.access_invoice_sale = res['result'][0].user_access_invoice_sale;
          } else {
            this.access_insert_finance = false;
            this.access_invoice_return_buy = false;
            this.access_invoice_buy = false;
            this.access_invoice_sale = false;
          }

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'invoice_number': new FormControl(null),
      'comment': new FormControl(null),
      'invoice_date': new FormControl(null),
      'invoice_Expire_date': new FormControl(null),
      'invoice_settlement_date': new FormControl(null),
      'invoice_type_title': new FormControl(null),
      'invoice_type': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'user_title': new FormControl(null),
      'user': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'currency_title': new FormControl(null),
      'currency': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'rate': new FormControl(1, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['status_id'].setValidators([Validators.required])
      this.form1_group.controls['rate'].setValidators([Validators.required])
    }
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);

        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, excel: number, access_service: number): any {


    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) {

            this.get_data();

            this.patch_selected_user();
          }
          if (type_task == 5) {
            this.form1_group.patchValue({
              'invoice_number': this.new_invoice_id
            });
            //this.get_status();
            if (this.invoice_type_id > 0) {
              this.get_invoice_type();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  patch_selected_user(): any {

    if (this.target_user_id > 0) {
      this.form1_group.patchValue({
        'user_title': this.target_user_title,
        'user': this.target_user_id
      });

      this.get_account(this.target_user_id)
    }
  }

  open_user(): any {
    if (this.readonly == true) {
      return false;
    }
    if (this.back == 1 && this.list_order.length > 0) {
      var pe_message = "برای تغییر نام طرف حساب لازم است کالاهای اضافه شده در ابتدا پاک شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(UserSearchComponent, {
      width: '40rem',
      height: 'auto',
      data: { code: 193, back: this.back, document_type_text: this.document_type_text, user: this.form1_group.value.user, ref: 'invoice', creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'user_title': res.title,
            'user': res.id
          });
          this.get_account(res.id)
        }
      }
    )
  }

  get_account(user: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: user, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.remain = 0;
          this.remain = res['result'][0].balance;
          this.x = 0;
          for (var j = 0; j < this.list_order.length; j++) {
            if (this.list_order[j].wharehouse_order_trash == 3) {
              this.x = this.x + Number(this.list_order[j].wharehouse_order_cost * this.list_order[j].wharehouse_order_number) - this.list_order[j].wharehouse_order_discount;
            }
          }
          if (this.document_type == 1) this.remain = Number(this.remain) + Number(this.x);
          if (this.document_type == 2) this.remain = Number(this.remain) - Number(this.x);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در $";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      invoice_number: this.form1_group.value.invoice_number,
      comment: this.form1_group.value.comment,
      invoice_date: this.form1_group.value.invoice_date,
      invoice_settlement_date: this.form1_group.value.invoice_settlement_date,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      user: this.form1_group.value.user,
      user_title: this.form1_group.value.user_title,
      currency: this.form1_group.value.currency,
      currency_title: this.form1_group.value.currency_title,
      invoice_type_title: this.form1_group.value.invoice_type_title,
      invoice_type: this.form1_group.value.invoice_type,
      rate: this.form1_group.value.rate,
      export_excel: excel,
      invoice_Expire_date: this.form1_group.value.invoice_Expire_date
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6019, id: this.id, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].wharehouse_invoice_title,
            'invoice_number': res['result'][0].wharehouse_invoice_number,
            'comment': res['result'][0].wharehouse_invoice_comment,
            'invoice_date': res['result'][0].wharehouse_invoice_date3,
            'invoice_settlement_date': res['result'][0].wharehouse_invoice_settlement_date_main,
            'status_id': res['result'][0].wharehouse_invoice_status,
            'status_title': res['result'][0].status_title,
            'user': res['result'][0].wharehouse_invoice_user,
            'user_title': res['result'][0].user_title,
            'currency': res['result'][0].wharehouse_invoice_currency,
            'currency_title': res['result'][0].site_currency_title,
            'rate': res['result'][0].wharehouse_invoice_rate,
            'invoice_type_title': res['result'][0].wharehouse_invoice_type_title,
            'invoice_type': res['result'][0].wharehouse_invoice_type,
          }
          );
          this.temp_user = res['result'][0].wharehouse_invoice_user;
          this.document_type = res['result'][0].document_type;
          this.document_type_text = res['result'][0].document_type_text;
          this.back = res['result'][0].wharehouse_invoice_type_back;
          this.currency_title = res['result'][0].site_currency_title;
          this.title = res['result'][0].wharehouse_invoice_type_title;
          if (res['result'][0].wharehouse_invoice_user) this.get_account(res['result'][0].wharehouse_invoice_user);
          if (!res['result'][0].wharehouse_invoice_number) {
            this.form1_group.patchValue({
              'invoice_number': this.new_invoice_id
            });
          }
          this.get_order();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6020, "user_id": this.user_id
      , 'status_id': this.form1_group.value.status_id
      , 'invoice_number': this.form1_group.value.invoice_number
      , 'comment': this.form1_group.value.comment
      , 'invoice_date': this.form1_group.value.invoice_date
      , 'invoice_settlement_date': this.form1_group.value.invoice_settlement_date
      , 'user': this.form1_group.value.user
      , 'currency': this.form1_group.value.currency
      , 'rate': this.form1_group.value.rate
      , 'invoice_type': this.form1_group.value.invoice_type
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert


  update(): any {
    if (this.i == -1 && this.list_order.length == 0) {
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6218, "user_id": this.user_id
      , 'invoice_number': this.form1_group.value.invoice_number
      , 'comment': this.form1_group.value.comment
      , 'invoice_date': this.form1_group.value.invoice_date
      , 'invoice_settlement_date': this.form1_group.value.invoice_settlement_date
      , 'currency': this.form1_group.value.currency
      , 'rate': this.form1_group.value.rate
      , 'status_id': this.form1_group.value.status_id
      , 'invoice_type': this.form1_group.value.invoice_type
      , 'sum_all': this.sum_all
      , 'token': this.token
      , 'creator': this.creator
      , 'trash': this.trash
      , 'id': this.id
      , 'user': this.form1_group.value.user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          //this.server_update_count_invoice(res['result'][0].count1);
          this.update_order();
          this.update_financial2_for_repairman();
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update 
  update_financial2_for_repairman() {
    var obj = {
      address: 6633
      , creator: this.creator
      , token: this.token
      , id: this.id
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  save(type: string) {
    this.type_save = type;
  }
  update_order() {
    var obj = {
      address: 6023,
      id: this.id,
      token: this.token,
      creator: this.creator,
      user: this.form1_group.value.user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          var confirm_sms = false;
          if (this.i == -1 && res['result'][0].user_sms_invoice == 1 && (res['result'][0].wharehouse_invoice_type == 1 || res['result'][0].wharehouse_invoice_type == 3)) {
            confirm_sms = window.confirm("آیا میخواهید " + res['result'][0].user_sex_title + " " + res['result'][0].user_title + " از صدور فاکتور مطلع نمائید؟");
            res['result'][0].confirm_sms = confirm_sms;
          }

          if (this.form1_group.value.invoice_type == 6) {
            for (var i = 0; i < this.list_order.length; i++) {
              if (this.list_order[i].wharehouse_order_trash == 3 && this.list_order[i].wharehouse_order_material_type == 1) {
                this.get_update_material_remain(this.list_order[i].wharehouse_order_material);
                this.get_update_return_invoice(this.list_order[i].wharehouse_order_return_invoice);
                this.get_update_order_consumed(this.list_order[i].wharehouse_order_consumed_returned_invoice);
              }
            }
          }
          else {
            for (var i = 0; i < this.list_order.length; i++) {
              if (this.list_order[i].wharehouse_order_trash == 3 && this.list_order[i].wharehouse_order_material_type == 1) {
                this.get_update_material_remain(this.list_order[i].wharehouse_order_material);
                this.get_update_order_consumed(this.list_order[i].wharehouse_order_consumed_invoice);
              }
            }
          }
          this.result = { result: res['result'][0], type_save: this.type_save, back: this.back, temp_user: this.temp_user, user: this.form1_group.value.user }
          this.matDialogRef.close(this.result);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_remain_material_delete(number: number, material_id: number) {
    var obj = {
      address: 6444,
      number: number,
      material_id: material_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          var pe_message = "خطا در آپدیت";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

        }
      }
    )
  }


  update_order_material_delete(number: number, consumed_invoice: number, material_id: number) {
    var obj = {
      address: 6442,
      number: number,
      consumed_invoice: consumed_invoice,
      material_id: material_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          var pe_message = "خطا در آپدیت";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

        }
      }
    )
  }


  add_user(): any {
    if (this.back == 1 && this.list_order.length > 0) {
      var pe_message = "برای اضافه نام طرف حساب لازم است کالاهای اضافه شده در ابتدا پاک شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (this.readonly == true) {
      return false;
    }
    var dialogRef = this.dialog.open(UserDetaile2Component, {
      width: '45rem',
      height: 'auto',
      data: { creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.form1_group.patchValue({
          'user_title': res['result'][0].user_title,
          'user': res['result'][0].user_id,
        });
      }
    )
  }


  get_status() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  user_counter(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6099, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_info_invoice(id) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6207, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.document_type = res['result'][0].document_type;
            this.document_type_text = res['result'][0].document_type_text;
            this.back = res['result'][0].wharehouse_invoice_type_back;
            this.title = res['result'][0].wharehouse_invoice_type_title
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }




  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    if (id == 189 && this.list_order.length > 0) {
      var pe_message = "برای تغییر نوع فاکتور در ابتدا باید کالاهای اضافه شده حذف شوند";
      var en_message = "To change the invoice type, the added goods must be removed first";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    var where = "";
    var code_id;
    if (id == 161) {
      code_id = 6098;
    }
    if (id == 189) {
      code_id = 6596;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, where: where, code_id: code_id, creator: this.creator, obj: { lang: this.lang } }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 26) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
          if (id == 161) {
            this.form1_group.patchValue({
              'user': res.id,
              'user_title': res.title
            })
            this.user_counter(res.id);
            this.get_account(res.id);
          }
          if (id == 156) {
            this.form1_group.patchValue({
              'currency': res.id,
              'currency_title': res.title
            });
            this.currency_title = res.title;
          }
          if (id == 189) {
            this.form1_group.patchValue({
              'invoice_type': res.id,
              'invoice_type_title': res.title
            });
            if (this.type_task != 3) {
              this.get_info_invoice(res.id);
              this.get_new_number(res.id);
            }
          }
        }
      }
    )
  }

  get_new_number(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6217, id: id, creator: this.creator, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) this.new_invoice_id = Number(res['result'][0].wharehouse_invoice_number) + 1;
          else this.new_invoice_id = 1;
          this.form1_group.patchValue({
            'invoice_number': this.new_invoice_id
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  reset_input(id: number) {
    if (id == 26) {
      this.form1_group.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
    if (id == 161) {
      this.form1_group.patchValue({
        'user_title': null,
        'user': null
      })
    }
    if (id == 156) {
      this.form1_group.patchValue({
        'currency': null,
        'currency_title': null
      });
      this.currency_title = '';
    }
    if (id == 189) {
      this.form1_group.patchValue({
        'invoice_type': null,
        'invoice_type_title': null
      })
    }

  }

  get_invoice_type() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6026, id: this.invoice_type_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              invoice_type: res['result'][0].wharehouse_invoice_type_id,
              invoice_type_title: res['result'][0].wharehouse_invoice_type_title
            });
            this.title = res['result'][0].wharehouse_invoice_type_title;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }


  get_number(event) {
    this.serverService.get_number(event)
  }

  show_financial2() {
    this.show_financial = true;
    this.router.navigate(['/home/financial2/193'], { queryParams: { user: this.form1_group.value.user } })
  }

  get_back() {
    this.show_financial = false;
    var obj = { 'page': this.page, 'all': this.search_all, back_i: this.i };
    this.router.navigate([this.url], { queryParams: obj });
  }
  //**************************************************** */
  get_order() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    this.loading = true;
    //6027
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6456, invoice: this.id, token: this.token, lang: this.lang }).subscribe(
      (res: any) => {
        this.list_order = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_order.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_order);
          this.get_sum_all(1);
          this.get_account(this.form1_group.value.user);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  add_to_invoice(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.creator) {
      var pe_message = "نام صاحب اکانت مشخص نیست . لطفا یکبار دیگر لاگین شوید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

      return false;
    }
    if (this.readonly == true) {
      return false;
    }
    if (this.back == 1 && !this.form1_group.value.user) {
      var pe_message = "برای درج قطعه می بایست نام طرف حساب مشخس باشد.";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

      return false;
    }
    if (!this.form1_group.value.invoice_type) {
      var pe_message = "برای درج قطعه می بایست نوع فاکتور مشخص باشد.";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

      return false;
    }
    var dialog;
    if (this.document_type_text == 'input' && this.back == 0) {
      dialog = WharehouseInvoiceInputComponent;
    }
    else if (this.document_type_text == 'exit' && this.back == 0) {
      dialog = WharehouseInvoiceExitComponent;
    }
    else if (this.document_type_text == 'input' && this.back == 1) {
      dialog = WharehouseInvoiceReturnSaleComponent;
    }
    else {
      dialog = WharehouseInvoiceReturnBuyComponent;
    }

    const dialogRef = this.dialog.open(dialog, {
      'width': '80rem',
      'height': 'auto',
      'data': { token: this.token, invoice_id: this.id, ref: 'invoice', code: this.code, type: 1, document_type_text: this.document_type_text, back: this.back, user: this.form1_group.value.user, document_type: this.document_type, count_order: this.list_order.length, level: this.level }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_order.unshift(res.result);
          this.dataSource.data = this.list_order;
          this.get_sum_all(2);
        }
      }
    )
  }

  check_for_access(i: number, type_task: number, id: number, material_type: number, creator: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 3) {
                this.open_cost(i, id);
              }
              else if (material_type == 2) {
                this.open_services(i, id);
              }
              else if (material_type == 1 && this.document_type_text == 'exit' && this.back == 0) {
                this.open_order_sale(i, id)
              }
              else if (material_type == 1 && this.document_type_text == 'exit' && this.back == 1) {
                this.open_order_return_buy(i, id)
              }
              else if (material_type == 1 && this.document_type_text == 'input') {
                this.open_order_buy(i, id);
              }

              this.message(false, "", 1, this.messageService.close(this.lang));
            } else {
              var pe_message = "شما دسترسی برای تغییرات را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

        }
      }
    )
  }

  open_order_return_buy(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderReturnBuyDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, code: this.code, ref: 'invoice', token: this.token, type_task: 2 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_order[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_order[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_order[i].wharehouse_order_discount = 0;
          this.get_update_material_remain(res.result.wharehouse_order_material);
          this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          this.list_order[i].sum = res.result.sum;
          this.get_sum_all(2);
          //if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.form1_group.value.user) this.get_account(this.form1_group.value.user);
        }
      })
  }

  open_order_sale(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderSaleDetaileComponent, {
      width: '30rem',
      height: 'auto',
      data: { id: id, code: this.code, ref: 'invoice', token: this.token }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_order[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_order[i].wharehouse_order_status_title = res.result.wharehouse_order_status_title;
          this.list_order[i].wharehouse_order_status_color = res.result.wharehouse_order_status_color;
          this.list_order[i].wharehouse_invoice_title = res.result.wharehouse_invoice_title;
          this.list_order[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_order[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_order[i].repairman_title = res.result.repairman_title;
          this.list_order[i].sum = res.result.sum;
          this.list_order[i].tax = res.result.tax;
          if (res.result.wharehouse_order_material_type == 1) {
            this.get_update_material_remain(res.result.wharehouse_material_id);
            this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          }
          this.get_sum_all(2);
          //if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.form1_group.value.user) this.get_account(this.form1_group.value.user);
        }
      }
    )
  }

  open_services(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderServiceDetaileComponent, {
      width: '30rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code, ref: 'invoice' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_order[i].title = res.result.title;
          this.list_order[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_order[i].wharehouse_order_status_title = res.result.wharehouse_order_status_title;
          this.list_order[i].wharehouse_order_status_color = res.result.wharehouse_order_status_color;
          this.list_order[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_order[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_order[i].repairman_title = res.result.repairman_title;
          this.list_order[i].sum = res.result.sum;
          this.list_order[i].tax = res.result.tax;
          this.get_sum_all(2);
          //if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.form1_group.value.user) this.get_account(this.form1_group.value.user);
        }
      }
    )
  }

  open_cost(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderCostDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_order[i].title = res.result.finance_cost_type_title;
          this.list_order[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_order[i].sum = res.result.sum;
        }
        this.get_sum_all(2);
        //if (res.result.wharehouse_order_trash == 2) this.update_financial2();
      }
    )
  }

  open_order_buy(i: number, id: number): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(WharehouseOrderBuyDetaileComponent, {
      width: '22rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 2, id: id, material_type: 1, document_type_text: this.document_type_text, back: this.back, i: i, trash: 3, creator: this.creator, code: this.code, invoice_id: this.id, ref: 'invoice', token: this.token }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_order[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_order[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_order[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_order[i].sum = res.result.sum;
          this.get_update_material_remain(res.result.wharehouse_material_id);
          this.get_update_order_consumed(res.result.wharehouse_order_consumed_invoice);
          this.get_sum_all(2);
          //if (res.result.wharehouse_order_trash == 2) this.update_financial2();
          if (res.result.wharehouse_order_trash == 3 && this.form1_group.value.user) this.get_account(this.form1_group.value.user);
        }
      }
    )
  }

  //******************************************************************************* */
  get_update_material_remain(material_id) {
    var obj = {
      address: 6445,
      material_id: material_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_update_order_consumed(order_id: number) {
    var obj = {
      address: 6446,
      order_id: order_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_update_return_invoice(order_id: number) {
    var obj = {
      address: 6508,
      order_id: order_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************* */
  update_financial2(sum_all: number) {
    var obj = {
      address: 6920,
      id: this.id,
      amount: sum_all,
      date3: this.form1_group.value.invoice_date,
      document_type: this.document_type,
      user_id: this.user_id,
      creator: this.creator,
      user: this.form1_group.value.user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_all_financial2() {
    var obj = {
      address: 6111,
      id: this.id,
      user: this.form1_group.value.user,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.form1_group.value.user) this.get_account(this.form1_group.value.user);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_sum_all(type: number) {
    this.x2 = 0;
    for (var j = 0; j < this.list_order.length; j++) {
      if (this.list_order[j].wharehouse_order_trash == 3) {
        this.x2 = Number(this.x2) + Number(this.list_order[j].wharehouse_order_cost * this.list_order[j].wharehouse_order_number - this.list_order[j].wharehouse_order_discount);
      }
    }
    this.sum_all = 0;
    for (var i = 0; i < this.list_order.length; i++) {
      this.sum_all += this.list_order[i].sum;
    }
    if (this.document_type == 1) this.sum_all_real = Number(this.sum_all) + Number(this.x2);
    if (this.document_type == 2) this.sum_all_real = Number(this.sum_all) - Number(this.x2);
    this.update_amount();
  }

  update_amount() {
    var sum_all = 0;
    for (var i = 0; i < this.list_order.length; i++) {
      if (this.list_order[i].wharehouse_order_trash == 2) {
        sum_all += this.list_order[i].sum;
      }
    }
    var obj = {
      address: 6692,
      id: this.id,
      sum_all: sum_all
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_financial2(sum_all);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  access_for_delete(id: number, material: number, trash: number, material_type: number, creator: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;

    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_invoice == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                if (this.document_type_text == 'exit' && this.back == 0) {
                  this.delete_order(id, trash, material_type);//اینجا باید وقتی برگشت از فروش راه بیفتد باید اصلاحاتی انجام بدم
                }
                else if (this.document_type_text == 'exit' && this.back == 1) {
                  this.delete_order(id, trash, material_type);
                }
                else if (this.document_type_text == 'input' && this.back == 0) {
                  this.check_to_delete(id, material, trash, material_type);
                }
                else if (this.document_type_text == 'input' && this.back == 1) {
                  this.delete_order(id, trash, material_type);//اینجا باید وقتی برگشت از فروش راه بیفتد باید اصلاحاتی انجام بدم
                }
              }
              else this.delete_order(id, trash, material_type);

            }
            else {
              var pe_message = "شما دسترسی برای حذف را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

        }
      }
    )
  }

  check_to_delete(id: number, material: number, trash: number, material_type: number): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6003, material: material, check_order_id: id, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.document_type_text == 'input' && res['remain'] == res['result'][0].order_number) {
            this.delete_order(id, trash, material_type);
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));

          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_order(id: number, trash: number, material_type: number): any {
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6028, "user_id": this.user_id
        , 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (trash == 2) {
              if (this.form1_group.value.invoice_type == 6) {
                for (var i = 0; i < this.list_order.length; i++) {
                  if (this.list_order[i].wharehouse_order_id == id && this.list_order[i].wharehouse_order_material_type == 1) {
                    this.get_update_material_remain(this.list_order[i].wharehouse_order_material);
                    this.get_update_return_invoice(this.list_order[i].wharehouse_order_return_invoice);
                    this.get_update_order_consumed(this.list_order[i].wharehouse_order_consumed_returned_invoice);
                  }
                }
              }
              else {
                for (var i = 0; i < this.list_order.length; i++) {
                  if (this.list_order[i].wharehouse_order_id == id && this.list_order[i].wharehouse_order_material_type == 1) {
                    this.get_update_material_remain(this.list_order[i].wharehouse_order_material);
                    this.get_update_order_consumed(this.list_order[i].wharehouse_order_consumed_invoice);
                  }
                }
              }
            }
            this.list_order.splice(this.list_order.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_order;
            this.get_sum_all(2);
            if (material_type == 1) this.delete_financial2_id_for_repairman(id, 6636);
            if (material_type == 2) this.delete_financial2_id_for_repairman(id, 6634);
            // if (trash == 2) {
            //   this.update_financial2();
            // }
            this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  delete_financial2_id_for_repairman(id: number, address: number) {
    var obj = {
      address: address
      , id: id
      , user_id: this.user_id
      , creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['result'][0].finance_financial2_id > 0) {
            this.delete_financial2_id_for_repairman2(res['result'][0].finance_financial2_id);
          }
        }//end if
        else {
          //this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  delete_financial2_id_for_repairman2(id: number) {
    var obj = {
      address: 6631
      , id: id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_serial(i: number, id: number, title: string, order_buy_id: number) {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(WharehouseOrderSaleSearialComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, title: title, document_type_text: this.document_type_text, order_buy_id: order_buy_id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.list_order[i].searial = res.result.searial;
      }
    )
  }
  //******************************************************************************************************** */
  close() {
    var j = 0;
    for (var i = 0; i < this.list_order.length; i++) {
      if (this.list_order[i].wharehouse_order_trash == 3) {
        j++;
      }
    }
    if (j > 0) {
      var message = "اینجا رکوردهای اضافه شده هنوز ذخیره نشده است.آیا می خواهید کنسل کنید؟";
      if (this.lang == 2) {
        message = "Here, the added records have not been saved yet. Do you want to cancel?";
      }
      var accept: boolean = confirm(message);
      if (accept == true) {
        this.matDialogRef.close();
      } else {
      }
    } else {
      this.matDialogRef.close();
    }

  }


  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if

    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }//end if

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if

    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }//end if

    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }//end if




  }//end OnDestroy
}

