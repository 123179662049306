import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModulesComponent } from '../../service/modules/modules.component';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-detaile2',
  templateUrl: './user-detaile2.component.html',
  styleUrls: ['./user-detaile2.component.scss']
})
export class UserDetaile2Component implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription | any;
  public user_id = this.user_info.user_id;
  public level: number | undefined;
  public form1: FormGroup | any;
  public creator: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<UserDetaile2Component>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.creator = dialog_data.creator;
      this.level = dialog_data.level;
    }

  }//end consructor

  ngOnInit() {
    this.create_form();
    this.get_default_sex();
    this.get_default_groups();
  }


  create_form() {
    this.form1 = new FormGroup({
      'sex_title': new FormControl(null),
      'sex': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'groups_title': new FormControl(null),
      'groups': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'title': new FormControl(null),
      'store': new FormControl(null),
      'user': new FormControl(null),
      'email': new FormControl(null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      'code_meli': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'code_posti': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'economic_code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cellphone': new FormControl(null),
      'phone': new FormControl(null),
      'address': new FormControl(null),
      'comment': new FormControl(null),
    });
  }

  get_default_groups() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6363, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            groups: res['result'][0].user_groups_id,
            groups_title: res['result'][0].user_groups_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid) {
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 1734, "user_id": this.user_id
      , 'title': this.form1.value.title
      , 'store': this.form1.value.store
      , 'sex': this.form1.value.sex
      , 'user': this.form1.value.user
      , 'email': this.form1.value.email
      , 'code_meli': this.form1.value.code_meli
      , 'code_posti': this.form1.value.code_posti
      , 'economic_code': this.form1.value.economic_code
      , 'cellphone': this.form1.value.cellphone
      , 'phone': this.form1.value.phone
      , 'address1': this.form1.value.address
      , 'comment': this.form1.value.comment
      , 'groups': this.form1.value.groups
      , creator: this.creator
    };
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.matDialogRef.close(res);
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "این شماره همراه قبلا وجود داشته است";
          var en_message = "This mobile number already exists.";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }//end if 
    )
  }//end insert


  get_default_sex() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1733, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1.patchValue({
              sex_title: res['result'][0].user_sex_title,
              sex: res['result'][0].user_sex_id,
            })
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  load_services(id: number) {
    var code_id;
    if (id == 155) {
      code_id = 6470;
    }

    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      data: { id: id, creator: this.creator, code_id: code_id, obj: { level: this.level } }
    });


    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          if (id == 10) {
            this.form1.patchValue({
              'sex_title': result.title,
              'sex': result.id
            })
          }
          if (id == 155) {
            this.form1.patchValue({
              'groups_title': result.title,
              'groups': result.id
            })
          }
        }
      }
    )
  }

  reset_input(id: number) {
    if (id == 10) {
      this.form1.patchValue({
        'sex_title': null,
        'sex': null
      })
    }
    if (id == 155) {
      this.form1.patchValue({
        'groups_title': null,
        'groups': null
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


