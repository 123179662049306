<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span>فروش کالا</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="material" class='wh-label'>
                    <span (click)="reset_input(171)" [hidden]="type_task == 2">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <span> عنوان کالا </span>
                    <span (click)="add_material()" [hidden]="type_task == 2">
                        <mat-icon class="add-icon fleft">
                            add </mat-icon>
                    </span>
                    <input class="wh-input" formControlName="material_title" (click)="get_materials()" required readonly
                        placeholder="برای مشاهده لیست کالا ها کلیک کنید">
                    <input class="wh-input" formControlName="material" [hidden]="true" required readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="group" class='wh-label'>
                    <span> گروه | زیر گروه | کد کالا </span>
                    <input class="wh-input" formControlName="group_title" required readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="price_buy" class='wh-label'>
                    <span> قیمت خرید </span>
                    <input class='wh-input' formControlName="price_buy" autocomplete="off" currencyMask required
                        readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="inventory" class='wh-label'>
                    <span class="fleft" [matMenuTriggerFor]="menu" *ngIf="material_id">
                        <i class="fas fa-info-circle icon icon-info" style="font-size: 0.7rem !important;"></i>
                    </span>
                    <span> موجودی با این قیمت خرید </span>
                    <input class='wh-input' formControlName="inventory" autocomplete="off" required readonly>
                </label>
            </div>
            <mat-menu #menu="matMenu" class="cardex-mat-menu-panel" xPosition="before">
                <table class="table table-bordered" (click)="$event.stopPropagation()">
                    <tr>
                        <td class="cursor right">
                            <a href="home/cardex/207?material={{ material_id }}&buyorderId={{ buy_order_id }}"
                                target="_blank">کاردکس گردش رکورد خرید</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="cursor right">
                            <a href="home/material/171?materialId={{ material_id }}" target="_blank">تعریف کالا</a>
                        </td>
                    </tr>
                </table>
            </mat-menu>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="inventory_all_material" class='wh-label'>
                    <span class="fleft" [matMenuTriggerFor]="menu2" *ngIf="material_id">
                        <i class="fas fa-info-circle icon icon-info" style="font-size: 0.7rem !important;"></i>
                    </span>
                    <span> موجودی کل کالا </span>
                    <input class='wh-input' formControlName="inventory_all_material" autocomplete="off" required
                        readonly>
                </label>
            </div>
            <mat-menu #menu2="matMenu" class="cardex-mat-menu-panel" xPosition="before">
                <table class="table table-bordered" (click)="$event.stopPropagation()">
                    <tr>
                        <td class="cursor right">
                            <a href="home/cardex/207?material={{ material_id }}" target="_blank">کاردکس گردش کالا</a>
                        </td>
                    </tr>
                </table>
            </mat-menu>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="cost" class='wh-label'>
                    <span> قیمت فروش </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="discount" class='wh-label'>
                    <span> تخفیف </span>
                    <input class='wh-input' formControlName="discount" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="number" class='wh-label'>
                    <span> تعداد فروش </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required
                        style="background: #cbf3c3;">
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="repairman" class='wh-label'>
                    <span> نام فروشنده </span>
                    <span (click)="reset_input(220)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220)" readonly
                        required>
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding' [hidden]="level == 3">
                <label for="profit" class='wh-label'>
                    <span>کمیسیون سود فروشنده</span>
                    <input class='wh-input' formControlName="profit" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding'>
                <label for="tax" class='wh-label'>
                    <span (click)="reset_input(268)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <span> انتخاب مالیات </span>
                    <input class="wh-input" formControlName="tax_title" (click)="load_services(268)" readonly>
                    <input class="wh-input" formControlName="tax" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pe div_panding' [hidden]="true">
                <label for="tax_price" class='wh-label'>
                    <span> درصد مالیات </span>
                    <input class="wh-input" formControlName="tax_price" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="comment" class='wh-label'>
                    <span> توضیحات </span>
                    <input class="wh-input" formControlName="comment">
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid || loading" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            <span>ذخیره</span>
        </button>
    </div>
</form>