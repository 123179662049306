<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span> کاردکس اشخاص</span>
    </span>
    <span (click)="close(true)">
        <mat-icon class="close">close</mat-icon>
    </span>
    <div class="video_div1 fleft" (click)="open_video()" *ngIf="video">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1" (ngSubmit)="search()">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" pe1>
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                    <label for="user" class='wh-label'>
                        <span> نام طرف حساب </span>
                        <span (click)="reset_customer()">
                            <mat-icon style="text-align: left;" class="close"
                                class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class='wh-input' formControlName="user_title" (click)="open_user()" autocomplete="off"
                            required>
                        <input class='wh-input' formControlName="user" autocomplete="off" readonly [hidden]="true">
                    </label>
                </div>
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                    <label for="date1" class='wh-label'>
                        <span> از تاریخ </span>
                        <input class="wh-input" matInput [matDatepicker]="picker" formControlName="date1"
                            autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker"
                            class="datepicker datepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </label>
                </div>
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                    <label for="date2" class='wh-label'>
                        <span> تا تاریخ </span>
                        <input class="wh-input" matInput [matDatepicker]="picker2" formControlName="date2"
                            autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker2"
                            class="datepicker datepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe" [disabled]="!form1.valid">
            <span>مشاهده</span>
        </button>
    </div>
</form>