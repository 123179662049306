<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row dflex" style="display: flex !important;">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,3,0,0,0)'>
                    <span>گزارش بانک</span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
            <span class="bank_title">
                {{ bank_title }} / {{ account_number }}
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_fa_without_scroll_bg pe1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="text-align: center !important;" style="max-width:150px">
                <span>تاریخ سند / سررسید</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:150px">
                {{ x.date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:150px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="service">
            <mat-header-cell *matHeaderCellDef style="text-align: center !important;" style="max-width:100px">
                <span>سرویس</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px">
                {{ x.ref_text }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef style="min-width:400px">
                <span>شرح</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:400px">
                <div class="right">
                    <span>
                        {{ x.alias }} {{ x.finance_payment_type_title }}
                        <span>براساس سند شماره</span>
                        <span> {{
                            x.id2 }}</span>
                    </span>
                    <div class="invoice_type_title fleft">
                        <span (click)="order_ref(x.id2,x.ref,x.finance_financial_document)">
                            <i class="fas fa-info-circle icon"></i>
                        </span>
                    </div>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:400px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width:200px">
                <span>عنوان</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:200px">
                <div class="right">{{ x.title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:200px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef>
                <span>بدهکار</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <div>{{x.bed | number }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div>{{ bed | number }}</div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="best">
            <mat-header-cell *matHeaderCellDef>
                <span>بستانکار</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <div>{{x.best | number }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div>{{ best | number }}</div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="remain">
            <mat-header-cell *matHeaderCellDef>
                <span>مانده</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <div class="money">{{ x.remain2 | number }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="footer1 money">{{ remain | number }}</div>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>