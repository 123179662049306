import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import * as Highcharts from 'highcharts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);
import exportData from 'highcharts/modules/export-data';
exportData(Highcharts);

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang = JSON.parse(<any>localStorage.getItem('lang'));
  public server: any = this.serverService.get_server();
  public loading: boolean = false;
  public user_id!: number;
  public creator!: number;
  public subscription: Subscription | any;
  public Highcharts1 = Highcharts;
  public Highcharts2 = Highcharts;
  public chartOptions1: any;
  public chartOptions2: any;
  public list_month: any = [];
  public list_count: any = [];
  public list_chart_data: any = [];
  public year: string = "1403";
  public chartType: number = 1;
  public hidechart1: boolean = false;
  public hidechart2: boolean = true;
  public chart_title: string = "";
  public address1: number = 0;
  public address2: number = 0;
  public currecny_title: string = "";
  public display_chart1: boolean = true;
  public display_chart2: boolean = true;
  public has_price: boolean = true;
  public sumtotal_chart: string = "";
  myForm: FormGroup;
  constructor(@Inject(DOCUMENT) private document: Document, private formBuilder: FormBuilder,
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public _matDialogRef: MatDialogRef<ChartsComponent>,
    public matSnackBar: MatSnackBar) {
    if (this.dialog_data) {
      this.creator = dialog_data.creator;
      this.chart_title = dialog_data.title;
      this.address1 = dialog_data.address1;
      this.address2 = dialog_data.address2;
      this.display_chart1 = dialog_data.display_chart1;
      this.display_chart2 = dialog_data.display_chart2;
      this.has_price = dialog_data.has_price;
    }
  }//end consructor
  ngOnInit() {
    this.get_default_currency();
    this.myForm = this.formBuilder.group({
      selectControl1: ['', Validators.required],
      selectControl2: ['', Validators.required],
    });
    this.myForm.get('selectControl1')!.valueChanges.subscribe((value: string) => {
      this.year = value;
      if (this.year == "") {
        this.year = "1403";
      }
      this.drawChart();
    });
    this.myForm.get('selectControl2')!.valueChanges.subscribe((value: string) => {
      this.chartType = Number(value);
      this.drawChart();
    });
    this.drawChart();
  }
  get_chart1_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: this.address1,
      year: this.year,
      creator: this.creator
    }
    this.list_month = [];
    this.list_count = [];
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 0) {
            this.message(true, " موردی برای  سال " + this.year + " پیدا نشد ", 1, this.messageService.close(this.lang));
          }
          if (res['sum_total']) {
            this.sumtotal_chart = this.formatNumberWithCommas(res['sum_total']);
          }
          for (var i = 0; i < res['num']; i++) {
            let monthName = this.getMonthName(Number(res['result'][i].month));
            this.list_month.push(monthName);
            this.list_count.push(res['result'][i].count);
          }
          for (let i = 0; i < this.list_count.length; i++) {
            let count = this.list_count[i].count;
            if (count) {
              this.list_count[i].totalamount = this.formatNumberWithCommas(count);
            }
          }
          let result: { [month: string]: number } = {};
          for (let i = 0; i < this.list_month.length; i++) {
            result[this.list_month[i]] = this.list_count[i];
          }
          let allMonths: string[] = ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];
          for (let month of allMonths) {
            if (!result[month]) {
              result[month] = 0;
            }
          }
          let arrangedResult: { [month: string]: number } = {};
          for (let month of allMonths) {
            arrangedResult[month] = result[month];
          }
          this.list_month = [];
          this.list_count = [];
          this.list_month = Object.keys(arrangedResult);
          this.list_count = Object.values(arrangedResult);
          this.draw_line_chart();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_chart2_data() {
    if (this.address2 == 0) {
      return;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: this.address2,
      year: this.year,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          let sum: number = 0;
          this.list_chart_data = []
          for (var i = 0; i < res['num']; i++) {
            sum = sum + res['result'][i].totalamount;
            this.list_chart_data.push(res['result'][i]);
          }
          if (sum > 0) {
            this.sumtotal_chart = this.formatNumberWithCommas(sum);
          }
          for (var i = 0; i < this.list_chart_data.length; i++) {
            this.list_chart_data[i].y = parseFloat(((this.list_chart_data[i].totalamount * 100) / sum).toFixed(2));
            if (i == 0) {
              this.list_chart_data[i].selected = true;
            }
            else {
              this.list_chart_data[i].selected = false;
            }
            this.list_chart_data[i].strAmount = this.formatNumberWithCommas(this.list_chart_data[i].totalamount);
            this.list_chart_data[i].strPercentage = this.list_chart_data[i].y + '%';
          }
          this.draw_pie_chart();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_currency() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6115,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.currecny_title = res['result'][0].site_currency_title;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  draw_line_chart() {
    this.chartOptions1 = {
      chart: {
        type: 'line'
      },
      title: {
        text: " نمودار خطی " + this.chart_title + " سال " + this.year,
        align: 'center'
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
               menuItems: [
                'printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'

            ],
              menuItemDefinitions: {
                printChart: {
                    text:'پرینت نمودار'
                },
                downloadPNG: {
                    text: 'دانلود png'
                },
                downloadJPEG: {
                    text: ' دانلود jpeg '
                },
                downloadPDF: {
                    text: ' دانلود pdf '
                },
                downloadSVG: {
                    text: ' دانلود svg '
                }
            }
          }
        }
      },
      xAxis: {
        categories: this.list_month
      },
      yAxis: {
        min: 0,
        title: {
          text: '-'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'light',
            color: 'gray',
            textOutline: 'none'
          }
        }
      },
      tooltip: {
        pointFormat: '{point.y:1f}'
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: true
        }
      },
      series: [{
        data: this.list_count,
        showInLegend: false
      }]
    };
  }
  draw_pie_chart() {
    let extra_info_template = "مبلغ : {point.strAmount}";
    if (this.has_price == false) {
      extra_info_template = " تعداد : {point.strAmount}";
    }
    this.chartOptions2 = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
               menuItems: [
                'printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'

            ],
              menuItemDefinitions: {
                printChart: {
                    text:'پرینت نمودار'
                },
                downloadPNG: {
                    text: 'دانلود png'
                },
                downloadJPEG: {
                    text: ' دانلود jpeg '
                },
                downloadPDF: {
                    text: ' دانلود pdf '
                },
                downloadSVG: {
                    text: ' دانلود svg '
                }
            }
          }
        }
      },
      title: {
        text: " نمودار دایره ای " + this.chart_title + " سال " + this.year,
        align: 'center'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>%{point.percentage:.1f}</b><br/>' + extra_info_template
      },
      legend: {
        itemStyle: {
          fontWeight: 'normal' // or a numeric value like 700
        }
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [{
        name: 'بر اساس درصد',
        colorByPoint: true,
        keys: ['name', 'y', 'selected', 'sliced'],
        data: this.list_chart_data,
      }]
    };
  }
  drawChart() {
    this.get_chart1_data();
    this.get_chart2_data();
  }
  formatNumberWithCommas(num: number): string {
    if (this.has_price == true) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + this.currecny_title;
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + " عدد ";
  }
  getMonthName(monthNumber: number): string {
    switch (monthNumber) {
      case 1:
        return 'فروردین';
      case 2:
        return 'اردیبهشت';
      case 3:
        return 'خرداد';
      case 4:
        return 'تیر';
      case 5:
        return 'مرداد';
      case 6:
        return 'شهریور';
      case 7:
        return 'مهر';
      case 8:
        return 'آبان';
      case 9:
        return 'آذر';
      case 10:
        return 'دی';
      case 11:
        return 'بهمن';
      case 12:
        return 'اسفند';
      default:
        return '';
    }
  }

  //************************************************************************************************************************** */
  close() {
    this._matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
