<div mat-dialog-content class="mat-dialog-content" style="padding:0px">
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <div class="row" style="position:fixed;z-index:9999999999">
        <span (click)="close()">
            <mat-icon class="close">close</mat-icon>
        </span>
    </div>

    <div class="row" style="margin:0px !important">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" style="float: right;padding:2rem 1rem 2rem 0">

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: right;">
                    <h1> {{ cate_title2 }} {{ title2 }}</h1>
                </div>
            </div>

            <div class="row" style="padding-top:1rem">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right fright">
                    <p class="p1">وضعیت موجودی</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right">
                    <p>دارد</p>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right fright">
                    <p class="p1">قیمت فروش</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right">
                    <p>{{ cost2 | number }} ریال</p>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right fright">
                    <p class="p1">نام فروشگاه</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right">
                    <p>{{ store_title }}</p>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right fright">
                    <p class="p1">آدرس</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right">
                    <p>{{ address }} </p>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right fright">
                    <p class="p1">شماره تماس</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 right">
                    <p>{{ phone }}</p>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right fright">
                    <p class="p1">شماره همراه</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 right">
                    <p>{{ cellphone }}</p>
                </div>
            </div>
            <hr>

            <div class="row" *ngIf="comment">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 right fright">
                    <p class="p1">توضیحات</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 right">
                    <p>{{ comment }}</p>
                </div>
            </div>
            <hr *ngIf="comment">

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 right fright">
                    <p class="p1" style="color: red;">
                        با توجه به این که فروشگاه به صورت کاربر محور اداره می گردد ، فیکس اسمارت هیچ
                        مسئولیتی در قبال خرید و فروش شما ندارد از این رو قبل از خرید نسبت به شناخت فروشنده اطمینان حاصل
                        فرمائید.
                    </p>
                </div>
            </div>

        </div><!-- col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 -->
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" style="text-align: center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-top:5rem"
                *ngIf="list_attachment.length == 0">
                <div>
                    <img src="../../../assets/images/preview2.png">
                </div>
                <div class="preview2" *ngIf="lang == 1">عکسی برای نمایش در حال حاضر وجود ندارد</div>
                <div class="preview2" *ngIf="lang == 2">There are currently no pictures to display</div>
            </div>
            <div id="demo_album" class="carousel slide" data-ride="carousel">
                <!-- Indicators -->
                <ul class="carousel-indicators">
                    <li data-target="#demo_album" *ngFor="let x of list_attachment;let i = index"
                        [ngClass]="{active: i == 0}" data-slide-to="i"></li>
                </ul>
                <!-- The slideshow -->
                <div class="carousel-inner">
                    <div class="carousel-item" style="height:25rem" *ngFor="let x of list_attachment;let i = index"
                        [ngClass]="{active: i == 0}" [ngStyle]="{'background-image':'url( ' + x.logo + ')'}">
                    </div>
                </div>
                <!-- Left and right controls -->
                <a class="carousel-control-prev" href="#demo_album" data-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#demo_album" data-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </a>
            </div>
        </div>
    </div>

    <div class="row" style="padding:0rem 1rem 2rem 1rem " *ngIf="comment">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12  right" style="margin-bottom:1rem">
            <p class="p1">توضیحات</p>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12  right">
            <p style="white-space:pre-wrap">
                {{ comment }}
            </p>
        </div>
    </div>

</div>