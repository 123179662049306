<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        {{ serverService.get_title(type_task,lang) }}
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان </span>
                    <span *ngIf="lang == 2"> Title </span>
                    <input class="wh-input" formControlName="title" [required]="required()">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="alpha_code" class='wh-label'>
                    <span *ngIf="lang == 1"> آلفا کد </span>
                    <span *ngIf="lang == 2"> Alpha code </span>
                    <input class="wh-input" formControlName="alpha_code" (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="area_code" class='wh-label'>
                    <span *ngIf="lang == 1"> پیش شماره </span>
                    <span *ngIf="lang == 2"> Area code </span>
                    <input class="wh-input" formControlName="area_code" (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="price" class='wh-label'>
                    <span *ngIf="lang == 1"> مبلغ ثبت نام و تمدید </span>
                    <span *ngIf="lang == 2"> Registration and Renewal Fee in Dollars </span>
                    <input class="wh-input" formControlName="price" (keypress)="serverService.get_number($event)"
                        currencyMask>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="price2" class='wh-label'>
                    <span *ngIf="lang == 1"> مبلغ به ازای هر کاربر </span>
                    <span *ngIf="lang == 2"> Fee per user in Dollars </span>
                    <input class="wh-input" formControlName="price2" (keypress)="serverService.get_number($event)"
                        currencyMask>
                </label>
            </div>


            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب </span>
                    <span *ngIf="lang == 2"> Sort </span>
                    <input class='wh-input' formControlName="sort" (keyup.enter)="check_access(type_task,1)"
                        [ngClass]="{'en':dir == 'ltr'}" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت نمایش </span>
                    <span *ngIf="lang == 2"> Display status </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12  pe padding0' [hidden]="type_task == 3">
                <div class='wh-label logo_title'> پرچم کشورها </div>
                <div *ngIf="logo_loading == true" class="upload_div_loading">
                    <div class="spinner-border spinner-border-sm upload_spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <a (click)="open_logo()" class="cursor">
                    <div class="upload_div_file" *ngIf="logo_type == 'pdf' && logo_loading == false">
                        <i class="far fa-file-pdf upload_div_file_icon" style="color: red;"></i>
                    </div>
                    <mat-card class="example-card logo cursor backgroundImage upload_div_file2"
                        *ngIf="logo_type != 'pdf' && logo_loading == false"
                        [ngStyle]="{'background-image':'url( ' + logo + ')'}" accept="image/*">
                        <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
                        </mat-card-header>
                    </mat-card>
                </a>
                <span (click)="delete_logo()" *ngIf="logo_bin == true">
                    <button class="upload_button_change">حذف فایل</button>
                </span>
                <div class="upload_image_change cursor" (click)="logo3.click()">
                    <button type="button" class="upload_button_change">آپلود فایل</button>
                </div>
                <input type="file" (change)="change_logo($event)" style="display: none;" #logo3
                    accept="image/*,application/pdf">
            </div><!-- col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe  -->

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
    </div>
</form>