import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { BankAccountsComponent } from '../../../finance/bank/bank-accounts/accounts-list/bank-accounts.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-recieve-detaile-bank',
  templateUrl: './recieve-detaile-bank.component.html',
  styleUrls: ['./recieve-detaile-bank.component.scss']
})
export class ReceiveDetaileBankComponent implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public loading: boolean = false;
  public payment_bank: any = false;
  public sum: number = 0;
  public search_array: any = [];
  public result: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public financial!: number;
  public document!: number;
  public document_type!: number;
  public token!: number;
  public creator!: number;
  public obj!: any;
  public video!: string;
  public video2!: string;

  constructor(
    public serverService: ServerService,
    public messageService: MessageService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReceiveDetaileBankComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.sum = dialog_data.sum;
      this.token = dialog_data.token;
      this.financial = dialog_data.financial;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.creator = dialog_data.creator;
      if (this.type_task == 1) {
        this.check_access(5);
      }
      if (this.type_task == 2) {
        this.check_access(4);
      }
      if (this.type_task == 3) {
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    if (this.type_task == 3) { }
    this.form1_group = new FormGroup({
      'amount': new FormControl(this.sum),
      'payment_type_title': new FormControl(null),
      'payment_type': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'bank_title': new FormControl(null),
      'bank': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'account_title': new FormControl(null),
      'account': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
    });
  }
  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 1) { this.insert(); }
            if (type_task == 2) { this.update(); }
            if (type_task == 3) { }
            if (type_task == 4) { this.get_data(); }
            if (type_task == 5) {
              this.get_default_payment_type();
              this.get_bank();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7056, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'amount': res['result'][0].finance_financial2_amount,
            'comment': res['result'][0].finance_financial2_comment,
            'payment_type': res['result'][0].finance_financial2_payment_type,
            'payment_type_title': res['result'][0].finance_payment_type_title,
            'account_title': res['result'][0].site_bank_accounts_number,
            'account': res['result'][0].site_bank_accounts_id,
            'bank_title': res['result'][0].site_bank_title,
            'bank': res['result'][0].site_bank_accounts_bank,
          }
          );
          this.payment_bank = res['result'][0].finance_payment_type_bank;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7057,
      "user_id": this.user_id,
      'amount': this.form1_group.value.amount,
      'comment': this.form1_group.value.comment,
      'bank': this.form1_group.value.bank,
      'payment_type': this.form1_group.value.payment_type,
      'document_type': this.document_type,
      'financial': this.financial,
      'account': this.form1_group.value.account,
      'token': this.token,
      'trash': 3,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ result: res['result'][0] });
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7058,
      "user_id": this.user_id,
      'amount': this.form1_group.value.amount,
      'comment': this.form1_group.value.comment,
      'financial_id': this.financial,
      'bank': this.form1_group.value.bank,
      'payment_type': this.form1_group.value.payment_type,
      'account': this.form1_group.value.account,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close({ result: res['result'][0] });
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));

        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  open_accounts() {
    if (!this.form1_group.value.bank) {
      var pe_message = "لطفا در ابتدا بانک خود را انتخاب نمائید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
    } else {
      const dialogRef = this.dialog.open(BankAccountsComponent, {
        width: '60rem',
        height: 'auto',
        data: { id: this.form1_group.value.bank, code: this.code }
      });
      dialogRef.afterClosed().subscribe(
        (res) => {
          console.log(res);
          this.form1_group.patchValue({
            'account_title': res.number,
            'account': res.id
          })
        }
      )
    }
  }
  get_default_payment_type(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7005, user_id: this.user_id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'payment_type_title': res['result'][0].finance_payment_type_title,
            'payment_type': res['result'][0].finance_payment_type_id,
          });
          this.payment_bank = res['result'][0].finance_payment_type_bank;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_bank(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7006, user_id: this.user_id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'bank_title': res['result'][0].site_bank_title,
              'bank': res['result'][0].site_bank_accounts_bank,
              'account_title': res['result'][0].site_bank_accounts_number,
              'account': res['result'][0].site_bank_accounts_id,
            });
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  save(type_task: number, close: string) {
    this.check_access(type_task);
  }
  load_services(id: number): any {
    var code_id;
    if (id == 234) {
      if (!this.form1_group.value.bank) {
        var pe_message = "لطفا در ابتدا بانک خود را انتخاب نمائید.";
        this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        return false;
      }
      code_id = 7007;
    }
    if (id == 177) {
      code_id = 7055;
    }
    var rem = '40rem';
    if (id == 234) rem = '45rem';
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: rem,
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator, obj: { bank_id: this.form1_group.value.bank } }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 177) {
            this.form1_group.patchValue({
              'payment_type': res.id,
              'payment_type_title': res.title,
              'bank': null,
              'bank_title': null,
              'account': null,
              'account_title': null
            });
          }
          if (id == 178) {
            this.form1_group.patchValue({
              'bank': res.id,
              'bank_title': res.title,
              'account': null,
              'account_title': null
            })
          }
          if (id == 234) {
            this.form1_group.patchValue({
              'account': res.id,
              'account_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number) {
    if (id == 177) {
      this.form1_group.patchValue({
        'payment_type_title': null,
        'payment_type': null
      })
    }
    if (id == 178) {
      this.form1_group.patchValue({
        'bank_title': null,
        'bank': null,
        'account_title': null,
        'account': null
      })
    }
    if (id == 180) {
      this.form1_group.patchValue({
        'account_title': null,
        'account': null
      })
    }
  }
  close() {
    this.matDialogRef.close();

  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

