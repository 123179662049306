<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading" class="detaile">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span *ngIf="lang == 1">درج متعلقات</span>
        <span *ngIf="lang == 2"> Add accessory </span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="insert()">
    <div mat-dialog-content class="matDialogContent" style="padding:1rem 0px !important">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe'>
                <label for="sort" class='wh-label'
                    [ngClass]="{'en':dir == 'ltr','ltr':dir == 'ltr','alignLeft':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> عنوان متعلقات </span>
                    <span *ngIf="lang == 2"> Accessory name </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" [ngClass]="{'en':dir == 'ltr'}"
                        required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe'>
                <label for="sort" class='wh-label'
                    [ngClass]="{'en':dir == 'ltr','ltr':dir == 'ltr','alignLeft':dir == 'ltr'}">
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <textarea class='wh-input' style="height:10rem" formControlName="comment" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}"></textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
    </div>
</form>