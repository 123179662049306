<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span>{{ serverService.get_title(type_task,lang) }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent" style="padding-top: 1rem !important">
        <div class="row pe1">
            <div class=' col-md-4 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{'col-lg-6 col-xl-6 ':ref=='items','col-lg-3 col-xl-3':ref=='services'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> نام سرویس : </span>
                    <span *ngIf="lang == 2"> Service : </span>
                    <input class='wh-input' formControlName="title" autocomplete="off">
                </label>
            </div>


            <div class='col-md-4 col-sm-4 col-xs-12 pe div_panding'
                [ngClass]="{'col-lg-6 col-xl-6 ':ref=='items','col-lg-3 col-xl-3':ref=='services'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت : </span>
                    <span *ngIf="lang == 2"> Status : </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26)"
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>
        </div>

        <div [hidden]="type_task == 3" class="row" style="padding:0px;;border:2px dashed #e6e7ef;height:22rem">
            <div class="col-xl-4 col-lg-4  col-md-4 col-sm-4 col-xs-12">

            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">

            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 upload_film_div1" [ngClass]="{'col-lg-12 col-xl-12 ':ref=='items'}"
                style="padding:20px !important">
                <div class="col-xl-12 col-lg-12  col-md-12 col-sm-12 col-xs-12"
                    style="padding:0px !important;padding:0px !important;min-height:16rem;text-align: center;">

                    <div class="row" *ngIf="video_stop == true" style="padding:0px">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            style="float:none;margin:0 auto;padding:0px">
                            <div class="upload_film_div1 pe1">
                                <div class="upload_film_volumn" *ngIf="loaded >0 && loaded < 100 ">
                                    {{ volumn_loaded }}
                                    مگ از {{ volumn }} مگ</div>
                                <div class="upload_film_text" *ngIf="loaded >0 && loaded < 100 ">در حال بارگذاری
                                    فایل
                                    ....</div>
                                <div class="upload_film_text" *ngIf="loaded == 100 ">ویدئوی شما با موفقیت بارگذاری
                                    شد
                                </div>
                            </div>
                            <div class="upload_film_container">
                                <div class="upload_film_video">
                                    <span class="material-icons upload_film_stop cursor"
                                        (click)="video_stop_upload()">stop</span>
                                    <!-- 
                                    <video *ngIf="video_poster_default > 0" height="150" class="video"
                                         poster="{{video_poster}}" controls ></video>
                                    -->
                                    <video [src]="video_url" height="150" poster="{{video_poster}}" class="video_box"
                                        controls style="height:14rem"></video>
                                </div>
                                <div class="upload_film_process" [ngStyle]="{width:video_width}" style="height:14rem">
                                </div>
                                <div class="upload_film_progress">
                                    <div class="upload_film_progress-bar" [style.width]="loaded + '%'"></div>
                                </div>
                                <div id="back_status_message1" class="upload_film_text_publish pe1"
                                    [hidden]="loaded < 100">
                                </div>
                            </div>
                        </div><!-- col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 -->
                    </div><!-- row  -->

                    <div class="row cursor" *ngIf="video_stop == false" (click)="open_upload()">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src="../../../../assets//images/film_logo.svg" style="width:18rem">
                            <div>
                                <button type="button" class="btn_pe upload_film_btn1 btn1"
                                    style="z-index:99999999999 !important" *ngIf="!video_stop">
                                    <span>انتخاب فایل</span>
                                    <input id="uploadButton" (change)="onFileSelected($event,'back_status_message1')"
                                        type="file" style="display: none;" accept="video/*">
                                </button>
                            </div>
                        </div><!-- film_logo -->
                    </div>

                </div><!-- 36 -->
            </div>
        </div>



    </div><!-- content -->

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid || (loaded > 0 && loaded < 100)" class="btn_pe"
            [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3"
            (click)="check_access(type_task,2)">
            <span *ngIf="lang == 2">Export Excel file</span>
            <span *ngIf="lang == 1">خروجی اکسل</span>
        </button>
    </div>
</form>