import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-wharehouse-order-material-list',
  templateUrl: './wharehouse-order-material-list.component.html',
  styleUrls: ['./wharehouse-order-material-list.component.scss']
})
export class WharehouseOrderMaterialListComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading: boolean = false;
  public subscription: Subscription | any;
  public isOnline: any | undefined;
  public user_id: number | undefined;
  public list_record: any = [];
  public list_wharehouse_cate: any = [];
  public list_wharehouse_group: any = [];
  public wharehouse_group: number = 0;
  public wharehouse_cate: number = 0;
  public search!: string;
  public creator!: any;
  public code!: any;

  public search_code!: string;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns2 = ['row', 'material', 'coding', 'group', 'cate', 'inventory', 'invoice_price', 'cost'];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<WharehouseOrderMaterialListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,) {
    if (this.dialog_data) {
      this.code = dialog_data.code;
      this.creator = dialog_data.creator;
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_material(1);
    this.get_wharehouse_group();
  }
  get_material_with_group() {
    this.loading = true;
    this.wharehouse_cate = 0;
    this.get_material(2);
    this.get_wharehouse_material_cate();
  }
  get_material_with_cate() {
    this.loading = true;
    this.get_material(2);
  }
  get_material(loading: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      'address': 7165,
      'search': this.search,
      'creator': this.creator,
      'search_code': this.search_code,
      'wharehouse_group': this.wharehouse_group,
      'wharehouse_cate': this.wharehouse_cate
    }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  select(buy_order_id: number, material_id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 7172,
      'buy_order_id': buy_order_id,
      'material_id': material_id,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ id: buy_order_id });
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  close() {
    this.matDialogRef.close();
  }
  //*********************************************************************************************************** */
  get_wharehouse_group() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7099, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_wharehouse_group = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_wharehouse_group.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_wharehouse_material_cate() {
    var obj = {
      'address': 7100,
      'creator': this.creator,
      'group': this.wharehouse_group,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_wharehouse_cate = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_wharehouse_cate.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************** */
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end ifcription2.unsubscribe();
  }//end OnDestroy
}
