import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-repairman-shelf-log',
  templateUrl: './repairman-shelf-log.component.html',
  styleUrls: ['./repairman-shelf-log.component.scss']
})
export class RepairmanShelfLogComponent implements OnInit, OnDestroy {
  public user_info: any | undefined = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public id: number | undefined;
  public list_log: any = [];
  public reception: number | undefined;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'shelf', 'reception', 'customer', 'brand', 'model', 'date', 'date_exit', 'creator'];
  //*************

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , public matDialogRef: MatDialogRef<RepairmanShelfLogComponent>
    , @Inject(MAT_DIALOG_DATA) public dialogg_data: any) {
    if (dialogg_data) {
      this.id = dialogg_data.id;
      this.reception = dialogg_data.reception;
    }
  }//end consructor

  ngOnInit() {
    this.get_data();
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6123, id: this.id, reception: this.reception }).subscribe(
      (res: any) => {
        this.list_log = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_log.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_log);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}