<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span>{{ title }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>


<div mat-dialog-content class="matDialogContent" style="overflow-y: hidden !important;">
    <div class="row" style="direction: rtl;display: flex !important;">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 padding0">
            <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
                placeholder="جستجو..." autocomplete="off" style="width:100%;height:30px">
        </div>

    </div>
    <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
        style='overflow-x:auto;direction:rtl;overflow-y: scroll;max-height: 22rem;overflow:auto'
        (window:resize)="change_resize()">
        <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}"
            [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                    <span *ngIf="lang == 1">ردیف</span>
                    <span *ngIf="lang == 2">Row</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'>{{ i + page_number_first }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">عنوان</span>
                    <span *ngIf="lang == 2">Title</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='change_task(2,x.repairman_model2_id,x.repairman_model2_title,x.status_id,x.repairman_model2_sort)'>
                    {{ x.repairman_model2_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort">
                <mat-header-cell *matHeaderCellDef [hidden]="true">
                    <span *ngIf="lang == 1">ترتیب</span>
                    <span *ngIf="lang == 2">Sort</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [hidden]="true">{{ x.repairman_model2_sort }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1"> وضعیت نمایش </span>
                    <span *ngIf="lang == 2"> Display status</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='change_task(2,x.repairman_model2_id,x.repairman_model2_title,x.status_id,x.repairman_model2_sort)'>
                    <span>{{ x.status_title }}</span>
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                    <span *ngIf="lang == 1"> عملیات </span>
                    <span *ngIf="lang == 2">Operation</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:70px;text-align:center'>
                    <span (click)="check_access(3,0,x.repairman_model2_id,0,1)"><i class="far fa-trash-alt icon"
                            style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
                (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>

        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets/images/preview2.png">
                </div>
                <div class="preview2" *ngIf="lang == 1">هیچ رکوردی برای نمایش وجود ندارد</div>
                <div class="preview2" *ngIf="lang == 2">There are no records to display</div>
            </div>
        </div>

    </div>


    <ngx-smart-modal #myModal identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
        <form [formGroup]="form1_group">
            <div style='text-align:center;padding:12px !important'>
                <span>{{ title }}</span>
            </div>
            <div class="row">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                    <label for="title" class='wh-label'>
                        <span *ngIf="lang == 1"> عنوان : </span>
                        <span *ngIf="lang == 2"> Title : </span>
                        <input class='wh-input' formControlName="title" autocomplete="off">
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}" [hidden]="true">
                    <label for="sort" class='wh-label'>
                        <span *ngIf="lang == 1"> ترتیب : </span>
                        <span *ngIf="lang == 2"> Sort : </span>
                        <input class='wh-input' formControlName="sort" autocomplete="off">
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                    <label for="status_id" class='wh-label'>
                        <span *ngIf="lang == 1"> وضعیت نمایش : </span>
                        <span *ngIf="lang == 2"> Display status : </span>
                        <select class='wh-input' formControlName="status_id">
                            <option value=""></option>
                            <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                                <span>{{ x.status_title }}</span>
                            </option>
                        </select>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'
                    *ngIf="lang == 1 ">
                    <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 1'
                        (click)="check_access(1,0,0,0,1)" [disabled]="!form1_group.valid">ارسال و ذخيره</button>
                    <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 2'
                        (click)="check_access(2,0,0,0,1)" [disabled]="!form1_group.valid">ويرايش</button>
                </div>
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'
                    *ngIf="lang == 2 ">
                    <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 1'
                        (click)="check_access(1,0,0,0,1)" [disabled]="!form1_group.valid"> Save </button>
                    <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf='type_task == 2'
                        (click)="check_access(2,0,0,0,1)" [disabled]="!form1_group.valid">Update</button>
                </div>
            </div>
        </form>
    </ngx-smart-modal>

    <ngx-smart-modal #myModalSearch identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
        <form [formGroup]="form1_search_group">
            <div style='text-align:center;padding:12px !important'>{{ title }}</div>
            <div class="row">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                    <label for="title" class='wh-label'>
                        <span *ngIf="lang == 1"> عنوان : </span>
                        <span *ngIf="lang == 2"> Title : </span>
                        <input class='wh-input' formControlName='title_search' autocomplete="off">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                    <label for="status_id" class='wh-label'>
                        <span *ngIf="lang == 1"> وضعيت نمايش : </span>
                        <span *ngIf="lang == 2"> Display status : </span>
                        <select class='wh-input' formControlName='status_id_search' pattern="[0-9]{1,}">
                            <option value=""></option>
                            <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                                <span>{{ x.status_title }}</span>
                            </option>
                        </select>
                    </label>
                </div>

            </div>
            <div class="row">
                <div class='col-xl-12 col-lg-12  col-md-12  col-sm-12 col-xs-12'
                    style='margin-top:5px;text-align:center;font-family:irsans' *ngIf="lang == 1 ">
                    <button class="btn_pe" (click)="check_access(4,1,0,1,1)">فیلتر</button>
                    <button class="btn_pe" (click)="check_access(4,1,0,2,1)">خروجی اکسل</button>
                    <div *ngIf="show_excel == true "><a href="{{ site }}/export/{{ export_code }}.xlsx">دانلود فایل</a>
                    </div>
                </div>
                <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12'
                    style='margin-top:5px;text-align:center;font-family:en' *ngIf="lang == 2 ">
                    <button class="btn_en" (click)="check_access(4,1,0,1,1)">Search</button>
                    <button class="btn_en" (click)="check_access(4,1,0,2,1)">Export Excel</button>
                    <div *ngIf="show_excel == true "><a href="{{ site }}/export/{{ export_code }}.xlsx">Download
                            File</a>
                    </div>
                </div>

            </div>
        </form>
    </ngx-smart-modal>
</div>


<div mat-dialog-actions class="matDialogActions">
    <button (click)='change_task(1,1,"",1,"")' class="btn_pe" [ngClass]="{btn_en:lang == 2}">
        <span *ngIf="lang == 2">New</span>
        <span *ngIf="lang == 1">جدید</span>
    </button>
</div>