import { Component, OnInit, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService, RichTextEditor } from '@syncfusion/ej2-angular-richtexteditor';


@Component({
  selector: 'app-ticket-detaile',
  templateUrl: './ticket-detaile.component.html',
  styleUrls: ['./ticket-detaile.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService]
})
export class TicketDetaileComponent implements OnInit, OnDestroy {

  public tools: object = {
    items: ['Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', '|', 'FontSize', 'FontColor', 'BackgroundColor']
  };

  fileInput: ElementRef;

  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public search_array: any = [];
  public list_departments: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public creator!: number;
  public expire!: boolean;
  public access_service!: number;
  public level!: number;
  showControl = false;
  constructor(
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<TicketDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    setTimeout(() => {
      this.showControl = true;
    }, 5000); // 5000 milliseconds = 5 seconds
    this.create_form1();
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        if (this.dialog_data) this.creator = this.dialog_data.creator;
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          ticket_department_id: dialog_data.search_array.ticket_department_id,

        })
      }
    }
  }//end consructor
  ngOnInit() {

    this.get_Departments();

  }

  create_form1() {
    this.form1_group = new FormGroup({
      'userTicketTitle': new FormControl(null, [Validators.required]),
      'userTicketText': new FormControl(null),
      'userTicketDepartmentId': new FormControl('', Validators.required),
      'userTicketAttachment': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['userTicketTitle'].setValidators([Validators.required]);

      this.form1_group.controls['userTicketText'].setValidators([Validators.required]);

      this.form1_group.controls['userTicketDepartmentId'].setValidators([Validators.required]);

      this.form1_group.controls['userTicketAttachment'].setValidators([Validators.required]);
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    var obj = { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          //if (type_task == 5) { this.get_departmans(); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.userTicketTitle,
      ticket_department_id: this.form1_group.value.userTicketDepartmentId,
      status_title: this.form1_group.value.status_title,
      export_excel: excel
    })
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6968, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.form1_group.patchValue({
            'userTicketTitle': res['result'][0].user_ticket_title,
            'userTicketDepartmentId': res['result'][0].user_ticket_status,

          }
          )
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6965,
      "user_id": this.user_id,
      'ticket_department_id': this.form1_group.value.userTicketDepartmentId,
      'title': this.form1_group.value.userTicketTitle,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert

  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6966,
      "user_id": this.user_id,
      'title': this.form1_group.value.title,
      'ticket_department_id': this.form1_group.value.ticket_department_id,
      'creator': this.creator,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0], i: this.i }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat2(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update


  get_Departments(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6976 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_departments.push(res['result'][i]);
          }//

          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files;
      this.form1_group.patchValue({
        userTicketAttachment: file
      });
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

export class Ticket {
  userTicketId: number;
  userTicketTitle?: string;
  userTicketText?: string;
  userTicketAttachment?: File;
  userTicketDepartmentId=1;
  userTicketStatus?: string='1';
  userTicketSort?: number;
  userTicketCreator?: number;
  userTicketCreator2?: number;
  userTicketEditor?: string;
  userTicketDate?: string;
  userTicketDate2?: string;
  userTicketTime?: string;
  userTicketIp?: string;
  userTicketEditorDate?: string;
  userTicketEditorDate2?: string;
  userTicketEditorTime?: string;
  userTicketEditorIp?: string;
  userTicketTrash?: string= '2';
}





