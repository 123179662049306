import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-open-belong',
  templateUrl: './open-belong.component.html',
  styleUrls: ['./open-belong.component.scss']
})
export class OpenBelongComponent implements OnInit, OnDestroy {
  public server_main: string = this.serverService.get_server();
  public server: string = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public type: string | undefined;
  public device: number | undefined;
  public title: string | undefined;
  public list_belong: any = [];
  public list_belong_selected: any = [];
  public list_objections: any = [];
  public list_objections_selected: any = [];
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  displayedColumns = ['row', 'title', 'operation'];
  //*************

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , public matDialogRef: MatDialogRef<OpenBelongComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.type = dialog_data.type;
      this.device = dialog_data.device;
      this.list_belong_selected = dialog_data.belong_selected;
      this.list_objections_selected = dialog_data.objections_selected;
    }
  }//end consructor

  ngOnInit() {
    if (this.type == 'belong') {
      this.get_belong(1);
    }
    if (this.type == 'objections') {
      this.get_objections(1);
    }
  }

  get_belong(step: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (step == 1) this.loading = true;
    var obj = { address: 1764, device: this.device, title: this.title }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_belong = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_belong.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_belong);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_belong(id: number, title: string) {
    if (this.list_belong_selected.indexOf(id) >= 0) {
      var pe_message = "این گزینه قبلا اضافه شده است";
      var en_message = "This option has already been added";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    } else {
      this.serverService.send_belong({
        id: id, title: title
      });
      this.list_belong_selected.push(id)
    }
  }

  //******************************************************************************************************  */
  get_objections(step: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (step == 1) this.loading = true;
    var obj = { address: 1765, device: this.device, title: this.title }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_objections = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_objections.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_objections);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_objections(id: number, title: string) {
    if (this.list_objections_selected.indexOf(id) >= 0) {
      var pe_message = "این گزینه قبلا اضافه شده است";
      var en_message = "This option has already been added";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    } else {
      this.serverService.send_objections({
        id: id, title: title
      });
      this.list_objections_selected.push(id)
    }
  }
  //******************************************************************************************************  */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}