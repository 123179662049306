<span (click)="close()" style="float:right">
  <mat-icon>close</mat-icon>
</span>
<div class="container-fluid p-2">
  <div class="row d-flex justify-content-start">
    <div class="col-12">
      <form [formGroup]="myForm">
        <div class="row">
          <div class="col-md-4 pull-right col-12 col-sm-6 col-lg-3">
            <div class="form-group d-inline">
              <select class="form-control" id="selectControl1" formControlName="selectControl1">
                <option value="">سال 1403</option>
                <option value="1402">سال 1402</option>
                <option value="1401">سال 1401 </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">

    <ng-container *ngIf="display_chart1">
      <div [ngClass]="{'mx-auto': display_chart2==false}"  [class.col-lg-8]="display_chart2==false" [class.col-lg-6]="display_chart2==true" class="col-12 col-md-12" *ngIf="display_chart1">
        <highcharts-chart [Highcharts]="Highcharts1" [options]="chartOptions1"
          style="width: 100%; height: 65vh; display: block;"></highcharts-chart>
      </div>
    </ng-container>

    <ng-container *ngIf="display_chart2">
      <div class="col-lg-6 col-12 col-md-12" >
        <highcharts-chart [Highcharts]="Highcharts2" [options]="chartOptions2"
          style="width: 100%; height:65vh; display: block;font-weight: normal !important;"></highcharts-chart>
      </div>
    </ng-container>

  </div>
  <div class="row" *ngIf="has_price==true">

    <div class="col-12 offset-sm-6 col-sm-6 offset-md-8 col-md-4 offset-lg-8 col-lg-4 offset-xl-10 col-xl-2">
      <table class="table table-bordered table-hover mt-2 w-100">
        <tr>
          <td> مجموع کل </td>
          <td>
            <h5 class="mx-auto">{{sumtotal_chart}}</h5>

          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="row" *ngIf="!has_price">

    <div class="col-12 offset-sm-6 col-sm-6 offset-md-8 col-md-4 offset-lg-8 col-lg-4 offset-xl-10 col-xl-2">
      <table class="table table-bordered table-hover mt-2 w-100">
        <tr>
          <td>تعداد کل</td>
          <td>
            <h5 class="mx-auto">{{sumtotal_chart}}</h5>

          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row d-none d-print-block" id="printable">
    <div class="col-12">
      <highcharts-chart [Highcharts]="Highcharts1" [options]="chartOptions1"
        style="width: 100%; height: 400px; display: block;"></highcharts-chart>
    </div>
    <div class="col-12 d-none d-print-block">
      <highcharts-chart [Highcharts]="Highcharts2" [options]="chartOptions2"
        style="width: 100%; height: 400px; display: block;"></highcharts-chart>
    </div>
  </div>
</div>
