<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>سرویس دریافت - دریافت چک</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" style="direction: rtl;">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="title" class='wh-label'>
                        <span> بابت</span>
                        <input class='wh-input' formControlName="title" autocomplete="off">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="amount" class='wh-label'>
                        <span> مبلغ چک </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required
                            readonly>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="bank" class='wh-label'>
                        <span> بانک </span>
                        <input class="wh-input" formControlName="bank" required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="account_number" class='wh-label'>
                        <span> شماره حساب </span>
                        <input class="wh-input" formControlName="account_number" required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="number" class='wh-label'>
                        <span> شماره چک </span>
                        <input class="wh-input" formControlName="number" required>
                    </label>
                </div>
                <!--************************************************************************************ -->
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="due" class="wh-label">
                        <span>تاریخ سررسید</span>
                        <div style="width:100%">
                            <input class="wh-input" [matDatepicker]="picker2" formControlName="due" required
                                autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="picker2"
                                class="datepicker datepicker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </div>
                    </label>
                </div>
                <!--************************************************************************************ -->
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="branch_name" class='wh-label'>
                        <span> عنوان شعبه </span>
                        <input class="wh-input" formControlName="branch_name">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="branch_code" class='wh-label'>
                        <span> کد شعبه </span>
                        <input class="wh-input" formControlName="branch_code">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="status" class='wh-label'>
                        <span> وضعیت چک </span>
                        <span (click)="reset_input(190)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="status_title" (click)="load_services(190) " required
                            readonly>
                        <input class="wh-input" formControlName="status_id" [hidden]="true" required readonly>
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding' [hidden]="status_pass == 0">
                    <label for="receipt_date" class="wh-label">
                        <span>تاریخ وصول </span>
                        <div style="width:100%">
                            <input class="wh-input" [matDatepicker]="picker" formControlName="receipt_date"
                                [required]="status_pass == 1" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="picker"
                                class="datepicker datepicker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding' [hidden]="status_pass == 0">
                    <label for="bank2" class='wh-label'>
                        <span> واریز به بانک </span>
                        <span (click)="reset_input(178)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="bank2_title" (click)="load_services(178) " readonly
                            [required]="status_pass == 1">
                        <input class="wh-input" formControlName="bank2" [hidden]="true" readonly
                            [required]="status_pass == 1">
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding' [hidden]="status_pass == 0">
                    <label for="account2" class='wh-label'>
                        <span> واریز به حساب </span>
                        <span (click)="reset_account(2)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="account2_title" (click)="select_account2() " readonly
                            [required]="status_pass == 1">
                        <input class="wh-input" formControlName="account2" readonly [hidden]="true"
                            [required]="status_pass == 1">
                    </label>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------- -->
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid || cheque_status == 2" class="btn_pe">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <span *ngIf="cheque_status == 2">چکهای خرج شده غیر قابل ویرایش می باشد</span>
    </div>
</form>