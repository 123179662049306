import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ServerService } from '../../../service/server/server.service';
import { PaccessComponent } from '../paccess/paccess.component';
import { GaccessComponent } from '../gaccess/gaccess.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { UserServiceDetaileComponent } from '../../../user/user-service-detaile/user-service-detaile.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public isOnline: any | undefined;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public title: string | undefined;
  public form1: FormGroup | any;
  public type_task: number | undefined;
  public user_id: number | undefined;
  public id: number | undefined;
  public site_services_img: string | undefined;
  public site_services_site: string | undefined;
  public error: boolean = false;
  public menu_section: number | undefined;
  public menu_id: number | undefined;
  public logo: any = this.serverService.get_default_image();
  public logo_bin: boolean = false;
  public logo_type: any;
  public logo_info: any;
  public logo_loading: boolean = false;
  public level: number | undefined;
  public creator: number | undefined;
  public video: string | undefined;
  public default: number | undefined;
  public access_service: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public dialog: MatDialog
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , private matDialogRef: MatDialogRef<MenuItemsComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (this.dialog_data) {
      this.title = dialog_data.title;
      this.type_task = dialog_data.type_task;
      this.id = dialog_data.id;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form();
    this.check_access(4);
  }

  create_form() {
    this.form1 = new FormGroup({
      'title': new FormControl(null, Validators.required)
    })
  }

  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6
      , type: 'user'
      , user_id: this.user_id
      , id: 1
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, access_service: number): any {
    var obj = {
      'address': 6
      , user_id: this.creator
      , lang: this.lang
      , id: 1
    }

    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.loading = false;
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_data(): any {
    this.loading = true;
    var obj = {
      address: 1028,
      id: this.id,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            'title': res['result'][0].site_services_title
          });
          this.logo_info = {
            site: res['result'][0].site_services_site,
            path: res['result'][0].site_services_img
          }
          if (res['result'][0].site_services_img) {
            this.logo = res['result'][0].site_services_site + "/" + res['result'][0].site_services_img;
            this.logo_bin = true;
            this.logo_type = res['result'][0].site_services_img.split(".").pop();
          }
          else {
            this.logo = this.serverService.get_default_image();
            this.logo_bin = false;
          }
          this.menu_id = res['result'][0].site_menu_id;
          this.menu_section = res['result'][0].site_menu_section;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.error = true;
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end get_data

  update(): any {
    this.loading = true;
    var obj = { address: 1017, id: this.id, title: this.form1.value.title, logo_info: this.logo_info };
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.menu_section) {
            this.serverService.send_menu({ data: this.logo_info, menu_id: this.menu_id, menu_section: this.menu_section }, 'logo')
          }
          this.matDialogRef.close({ result: res['result'][0] });
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_paccess() {
    const dialogRef = this.dialog.open(PaccessComponent, {
      width: '40rem',
      height: 'auto',
      data: { id: this.id, title: this.title }
    });
  }

  open_video() {
    const dialogRef = this.dialog.open(UserServiceDetaileComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: this.id, title: this.title, ref: 'items', type_task: 2, code1: true }
    });
  }
  //********************************************************************************************************************** */
  change_logo(event: any): any {
    var selectedFile = <File>event.target.files[0];
    if (selectedFile.type.split("/").shift() != 'image' && selectedFile.type != 'application/pdf') {
      var message = "فرمت ارسالی برای این فیلد فقط می بایست عکس و یا pdf باشد.";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    else {
      this.logo_loading = true;
      var fd = new FormData();
      fd.append("image", selectedFile, selectedFile.name);
      this.serverService.post_address_file(this.server, "uploadImageMenu", fd).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            ///this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
          }
          else if (event.type === HttpEventType.Response) {
            var a = <any>event.body;
            this.logo = this.serverService.get_site_upload_image() + "/" + this.serverService.get_path_upload_image_menu() + a.result.filename;
            this.logo_bin = true;
            this.logo_type = selectedFile.type.split("/").pop();
            this.logo_loading = false;
            this.logo_info = {
              'site': this.serverService.get_site_upload_image(),
              'path': this.serverService.get_path_upload_image_menu() + a.result.filename
            }
          }
        }
      )
    }
  }

  delete_logo() {
    this.logo = this.serverService.get_default_image();
    this.logo_bin = false;
    this.logo_info = {
      path: '',
      site: ''
    }
    this.logo_type = '';
  }
  open_logo(): any {
    if (this.logo == '../../../../assets/images/default_image.png') {
      return false;
    } else {
      window.open(this.logo, "_blank");
    }
  }
  //********************************************************************************************************************** */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}