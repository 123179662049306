<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span> هزینه </span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_reception_for_material()">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pe div_panding'>
                <label for="status_id" class='wh-label'>
                    <span (click)="add_cost()" class="fleft">
                        <mat-icon class="add-icon fright">
                            add </mat-icon>
                    </span>
                    <span> عنوان هزینه </span>
                    <span (click)="reset_input(281)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="costs_title" (click)="load_services(281)" readonly required
                        placeholder="برای مشاهده لیست هزینه ها کلیک کنید">
                    <input class="wh-input" formControlName="costs" [hidden]="true" readonly required>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pe div_panding'>
                <label for="cost" class='wh-label'>
                    <span> هزینه </span>
                    <input class='wh-input' formControlName="cost" currencyMask autocomplete="off" required>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid || loading" class="btn_pe">
            <span>ذخیره</span>
        </button>
    </div>
</form>