<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span> بانک به صندوق </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>
<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'>
                <label for="date1" class='wh-label'>
                    <span> تاریخ سند </span>
                    <input class="wh-input" matInput [matDatepicker]="picker" formControlName="document_date"
                        autocomplete="off" [required]="required()"
                        [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}" [readonly]="readonly">
                    <mat-datepicker-toggle matSuffix [for]="picker"
                        class="datepicker datepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="comment" class='wh-label'>
                    <span> توضیحات </span>
                    <input class="wh-input" formControlName="comment" [readonly]="readonly">
                </label>
            </div>
            <!--************************************************************** -->
        </div><!-- row -->

        <div class="row" *ngIf="type_task != 3">
            <div style="text-align:right;padding:0px;margin-bottom: 5px;">
                <button type="button" class="button_default" (click)='open_detaile(0,1,0,1,0,false,false)'
                    [hidden]="readonly">
                    <span>ثبت تراکنش مالی</span>
                </button>
            </div>

            <div class="content-body1" class="content-body control-content-body">
                <mat-table [dataSource]="dataSource" style='min-width:100%;direction:rtl'
                    class='table-scroll mat_table_fa_without_scroll pe1'>
                    <ng-container matColumnDef="row">
                        <mat-header-cell *matHeaderCellDef style='max-width:30px'>
                            <span>#</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:30px'>{{ i + 1 }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef>
                            <span>مبلغ</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_bank_to_cash2_id)">
                            {{
                            x.finance_bank_to_cash2_amount | number }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="bank">
                        <mat-header-cell *matHeaderCellDef>
                            <span> بانک</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_bank_to_cash2_id)">
                            {{ x.site_bank_title | substr:0:15}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="account">
                        <mat-header-cell *matHeaderCellDef>
                            <span>شماره حساب</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="open_detaile(i,2,x.finance_bank_to_cash2_id)">
                            {{ x.accounts_number }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <mat-header-cell *matHeaderCellDef style='max-width:65px' [hidden]="readonly">
                            <span> عملیات </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:65px' [hidden]="readonly">
                            <span [hidden]="x.footer == true">
                                <span (click)="check_access2(i,3,0,x.finance_bank_to_cash2_id,0,1,x.site_cheque_id)"><i
                                        class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                    </mat-header-row>
                    <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                        (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                        [ngStyle]="{backgroundColor: 1==1 ? row.color : 'transparent' }"
                        [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,'mat_table_hover': row === mat_table_hoverRow}"
                        class="cursor">
                    </mat-row>

                </mat-table>
            </div>

            <div style="text-align: right;padding-top:1rem;">
                <span> جمع کل به عدد : </span>
                <span>{{ amount | number }} </span>
                <span>{{ currency_title }}</span>
                <span> به حروف </span>
                <span> {{ sum_all_letter }} </span>
                <span> {{ currency_title }} </span>
            </div>
        </div>

    </div>

    <div mat-dialog-actions class="matDialogActions">
        <div class="row" style="width: 100%;">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" [ngClass]="{width100:type_task == 3}"
                style="text-align: right;padding-right:0px" [hidden]="readonly">
                <button type="submit" [disabled]="!form1_group.valid || (i == -1 && list_record.length == 0)"
                    class="btn_pe" [hidden]="readonly" *ngIf="type_task == 1 || type_task == 2">
                    <span>ذخیره</span>
                </button>
            </div>
        </div>
    </div>
</form>