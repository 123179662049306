<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>گزارش بانک</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="content-body control-content-body" style='overflow-x:auto;direction:rtl;'>
            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'>

                <ng-container matColumnDef="check">
                    <mat-header-cell *matHeaderCellDef style="max-width:70px">

                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:70px">
                        <mat-radio-group aria-label="Select an option" name="account" formControlName="account"
                            (click)="get_bg(x.id)">
                            <mat-radio-button [value]="x.id"></mat-radio-button>
                        </mat-radio-group>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                        <span>شماره حساب</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;"
                        [ngStyle]="{backgroundColor:x.requests_reception == true ? '#e1b4b4' : 'transparent'}">
                        <div class="right">{{ x.title }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_number">
                    <mat-header-cell *matHeaderCellDef>
                        <span>نام دارنده حساب</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;">{{
                        x.site_bank_accounts_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_bank_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>نام بانک</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;">{{
                        x.site_bank_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>نام شعبه</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;">{{
                        x.site_bank_accounts_branch_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_remain">
                    <mat-header-cell *matHeaderCellDef>
                        <span>مانده حساب</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;direction: ltr;">{{
                        x.balance | number }}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
                <mat-row (click)="mat_table_selectedRow = row" *matRowDef="let x;let i = index;columns:displayedColumns"
                    (keyup.enter)="select(x.id,x.title)" [ngClass]="{mat_table_selected:row_id == x.id}"></mat-row>
            </mat-table>
        </div>

        <div class="row pe1">
            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> تاریخ از </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker3" formControlName="date3" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker3"
                            class="datepicker datepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> تا تاریخ</span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker4" formControlName="date4" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker4"
                            class="datepicker datepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </div>
                </label>
            </div>

        </div>

    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="button" class="btn_pe" (click)="search()">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>