<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row dflex" style="display: flex !important;">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,1,0)'
                    *ngIf="code == 197 || code == 160 || rstatus == 3">
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <button class="search1" (click)='openchart()'>
                    <span>
                        نمودار
                    </span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_fa_without_scroll pe1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>ردیف</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'
                (click)="open_exit(i,x.repairman_reception_id)">{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 40px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px;max-width: 250px;">
                <span>نام و نام خانوادگی</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width: 250px;max-width: 250px;">
                <div class="right fright" (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)">
                    {{ x.user_sex_title }} {{ x.user_title }}
                </div>
                <div style="text-align:left;padding-left: 6px;">
                    <span class="fleft" [matMenuTriggerFor]="menu" (click)="get_menu(i,x)"><i
                            class="fas fa-info-circle icon"></i></span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 200px;max-width: 200px;"></mat-footer-cell>
        </ng-container>
        <mat-menu #menu="matMenu" class="reception-mat-menu-panel" xPosition="before">
            <div class="div_loading">
                <div class="my-container" [hidden]="!loading2">
                    <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
                </div>
            </div>
            <table class="table table-bordered" (click)="$event.stopPropagation()">
                <tr>
                    <td class="cursor right" (click)="check_charge_sms_for_new_reception(i,true)">
                        <span class="eye"><i class="far fa-comments"></i></span>
                        <span>ارسال پیامک پذیرش دستگاه</span>
                    </td>
                </tr>
                <tr style="border-bottom:solid 2px #ccc">
                    <td class="cursor right" (click)="show_factor1()">
                        <span class="eye">
                            <i class="fas fa-print icon"></i>
                        </span>
                        <span>
                            <span *ngIf="rstatus == 3">چاپ قبض پذیرش دستگاه</span>
                            <span [hidden]="rstatus == 3"> چاپ قبض پیش فرض</span>
                        </span>
                    </td>
                </tr>
                <tr style="border-bottom:solid 2px #ccc">
                    <td class="cursor right" (click)="show_factor4()">
                        <span class="eye">
                            <i class="fas fa-print icon"></i>
                        </span>
                        <span (click)="show_factor4()">تایم لاین دستگاه</span>
                    </td>
                </tr>
                <tr>
                    <td class="cursor right"
                        (click)="check_charge_sms('reception2',i,x.repairman_reception_id,x.repairman_reception_id2,x.repairman_reception_code,x.user_cellPhone, x.repairman_rstatus_title  , true, x.user_store, x.user_phone)">
                        <span class="eye"><i class="far fa-comments"></i></span>
                        <span>ارسال پیامک فاکتور تعمیرگاه</span>
                    </td>
                </tr>
                <tr style="border-bottom:solid 2px #ccc">
                    <td class="cursor right" (click)="show_factor2()">
                        <span class="eye">
                            <i class="fas fa-print icon"></i>
                        </span>
                        <span>
                            <span>چاپ فاکتور تعمیرگاه</span>
                        </span>
                    </td>
                </tr>
                <tr style="border-bottom:solid 2px #ccc">
                    <td class="cursor right" (click)="check_cellphone_sms2()">
                        <span class="eye">
                            <i class="fas fa-print icon"></i>
                        </span>
                        <span>
                            <span>ارسال پیامک پذیرش به تعمیرکار</span>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="rstatus == 6">
                    <td class="cursor right" (click)="show_factor3()">
                        <span class="eye"><i class="fa fa-eye icon"></i></span>
                        <span>چاپ استیکر لیبل پرینتر</span>
                    </td>
                </tr>
                <tr>
                    <td class="cursor right" (click)="barcode()"
                        *ngIf="code == 160 || code == 197 || rstatus == 3 || rstatus == 4">
                        <span class="eye"><i class="fas fa-print icon"></i></span>
                        <span>چاپ QR Code پذیرش</span>
                    </td>
                </tr>
                <tr>
                    <td class="cursor right" (click)="open_delivery(i,x.repairman_reception_id)"
                        [hidden]="rstatus != 6">
                        <span class="eye"><i class="fas fa-truck icon"></i></span>
                        <span> کد تحویل</span>
                    </td>
                </tr>
            </table>
        </mat-menu>

        <ng-container matColumnDef="cellphone">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span>شماره همراه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:100px">
                <div *ngIf="show_cellphone == true">{{ x.user_cellPhone }}</div>
                <div *ngIf="show_cellphone == false" class="cellphone">{{
                    x.user_cellPhone | hide_cellphone
                    }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:100px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span>فاکتور</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px"
                (click)="open_exit(i,x.repairman_reception_id)">
                <span>درج فاکتور</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:100px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id2">
            <mat-header-cell *matHeaderCellDef style="max-width:90px" (click)="show_id()">
                <span>ش پذیرش</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:90px">
                {{ x.repairman_reception_id2 }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:110px" [hidden]="!status_show_id == true">
                <span>ش پذیرش قدیم</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:110px"
                [hidden]="!status_show_id == true">
                {{ x.repairman_reception_id }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:110px;"
                [hidden]="!status_show_id == true"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="shelf">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span>شماره قفسه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:90px">
                {{ x.repairman_shelf_title }}
                <span (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="float: left;"><i
                        class="fas fa-info-circle icon"></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 90px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="device">
            <mat-header-cell *matHeaderCellDef style="max-width:110px">
                <span>دستگاه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:110px">
                {{ x.wharehouse_device_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:110px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="brand">
            <mat-header-cell *matHeaderCellDef style="max-width:90px">
                <span>برند</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width:90px">
                {{ x.repairman_brand_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:90px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="model">
            <mat-header-cell *matHeaderCellDef style="min-width:140px;max-width:140px">
                <span>مدل</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)"
                style="min-width:140px;max-width:140px">
                <div class="left font-en" style="direction: ltr;padding-right:0.3rem"> {{ x.repairman_model_title |
                    substr:0:10}}
                    <span style="float: right;height:0.8rem" [ngStyle]="{color:x.repairman_reception_color}">
                        <i class="fas fa-circle"></i>
                    </span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:140px;max-width:140px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="searial">
            <mat-header-cell *matHeaderCellDef style="min-width:150px">
                <span>سریال</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:150px" class="font-en">
                <div class="left" (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)">
                    {{ x.repairman_reception_searial }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery == 1" style="max-width:110px">
                <span>تاریخ ورود</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery == 1"
                style="max-width:110px">
                {{ x.repairman_reception_date }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 110px;" [hidden]="delivery == 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="time">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery == 1" style="max-width:110px">
                <span>ساعت ورود</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery == 1"
                style="max-width:110px">
                {{ x.repairman_reception_time }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 110px;" [hidden]="delivery == 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date2">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery != 1" style="max-width:90px">
                <span>تاریخ خروج</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery != 1"
                style="max-width:90px">
                {{ x.repairman_reception_editor_date }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 90px;" [hidden]="delivery != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="time2">
            <mat-header-cell *matHeaderCellDef [hidden]="delivery != 1" style="max-width:90px">
                <span>ساعت خروج</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" [hidden]="delivery != 1"
                style="max-width:90px">
                {{ x.repairman_reception_editor_time }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 90px;" [hidden]="delivery != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="estimated_time">
            <mat-header-cell *matHeaderCellDef style="min-width: 170px;"
                [hidden]="rstatus == 7 || code == 160 || code == 197">
                <span>موعد تقریبی تحویل</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="min-width: 170px;"
                [hidden]="rstatus == 7 || code == 160 || code == 197">
                {{ x.repairman_reception_estimated_time }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 170px;"
                [hidden]="rstatus == 7 || code == 160 || code == 197"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef style="max-width: 200px;min-width: 150px;">
                <span>وضعیت پذیرش</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)"
                style="max-width: 200px;min-width: 150px">
                {{ x.repairman_rstatus_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 200px;min-width: 150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="device_status">
            <mat-header-cell *matHeaderCellDef style="max-width: 120px;">
                <span>وضعیت دستگاه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.repairman_reception_id,x.repairman_reception_id2)" style="max-width: 120px;">
                {{ x.repairman_device_status_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 120px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:100px;min-width:100px'>
                <span>عملیات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px;min-width:100px'>
                <mat-checkbox (change)="get_select_records(i)" name="selete_records[]"
                    [value]="x.repairman_reception_id" [checked]="x.checked" class="hidden-sm hidden-xs"
                    [hidden]="true"></mat-checkbox>
                <span style="font-size: 1rem;" class="icon"
                    (click)="open_payment(i,x.repairman_reception_id,x.repairman_reception_id2)"
                    *ngIf="rstatus == 4 || rstatus == 6" [title]=" 'پرداخت' ">
                    <mat-icon class="matIcon1">credit_card</mat-icon>
                </span>
                <span style="font-size: 1rem;margin:0 2px" class="icon" (click)="open_exit(i,x.repairman_reception_id)"
                    [title]=" 'فاکتور' ">
                    <mat-icon class="matIcon1"> add_circle_outline </mat-icon>
                </span>

                <span class="icon" (click)='open_detaile(i,2,x.repairman_reception_id)'>
                    <i class="fas fa-desktop"></i>
                </span>
                <span class="icon" (click)="check_access(3,0,x.repairman_reception_id,0,1)">
                    <i class="far fa-trash-alt"></i>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:100px;min-width:100px"></mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            [ngStyle]="{'background':row.urgent <= 3 && row.repairman_rstatus_empty_shelf != 1 && row.repairman_rstatus_sub_ready_delivery != 1 ? 'rgb(237 202 206)' : '' }"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>