import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialogRef } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-report-device',
  templateUrl: './report-device.component.html',
  styleUrls: ['./report-device.component.scss']
})
export class ReportDeviceComponent implements OnInit, OnDestroy {
  public user_info: any | undefined = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public isOnline: any | undefined;
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  //******************************************************************************
  public list_record: any = [];
  public level: number;
  public creator: number;
  public video: string | undefined;
  public path_export: string | undefined;
  public access_service: number | undefined;
  public search_all: string | undefined;
  public year: number;
  public device: number;

  public loading_container: boolean = false;
  public loading_container2: boolean = false;
  public loading_container3: boolean = false;
  public loading_container4: boolean = false;

  public list_chart1: any = [];

  public list_chart2_device: any = [];
  public list_chart2: any = [];

  public list_chart3_device: any = [];
  public list_chart3: any = [];

  public list_chart4_device: any = [];
  public list_chart4: any = [];
  public list_chart4_title: any = [];

  public list_device: any = [];

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public matDialogRef: MatDialogRef<ReportDeviceComponent>
    , public messageService: MessageService) {
  }//end consructor


  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access();
    Highcharts.setOptions({
      colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
    });

  }

  check_access(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: 197 }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: 197 }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.video = res['result'][0].video;
          this.loading = false;
          this.chart1();
          //this.chart4();
          this.get_device();

        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  /*
   public ngAfterViewInit(): void {
     this.chart1();
   }
   */

  get_charts(year: number) {
    this.year = year;
    this.chart1();
    this.get_device();
  }

  public chart1(): void {
    this.loading_container = true;
    this.loading_container2 = true;
    this.loading_container3 = true;
    var obj = {
      address: 6727
      , creator: this.creator
      , year: this.year
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var text;
          if (res['year'] > 0) text = 'تعداد دستگاههای پذیرش شده به درصد ' + " سال " + res['year'];
          else text = 'تعداد دستگاهها پذیرش شده به درصد ';
          this.list_chart1 = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_chart1.push({
              name: res['result'][i].title, y: res['result'][i].count
            })
          }
          this.loading_container = false;
          this.chart2();
          // @ts-ignore
          Highcharts.chart('container', {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              type: 'pie'
            },
            title: {
              text: text
            },
            tooltip: {

            },
            accessibility: {
              point: {
                valueSuffix: '%'
              }
            },
            plotOptions: {
              pie: {
                size: 180,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  connectorColor: 'silver'
                }
              }
            },
            series: [{
              name: '',
              data: this.list_chart1
            }]
          });
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  private chart2(): void {
    var obj = {
      address: 6728
      , creator: this.creator
      , year: this.year
      , device: this.device
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var text;
          if (res['year'] > 0) text = 'تعداد دستگاههای پذیرش شده ' + " سال " + res['year'];
          else text = 'تعداد دستگاههای پذیرش شده ';
          this.list_chart2 = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_chart2.push([res['result'][i].title, res['result'][i].count]);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.loading_container2 = false;
          this.chart3(0);
          // @ts-ignore
          Highcharts.chart('container2', {
            chart: {
              type: 'column'
            },
            title: {
              text: text
            },
            xAxis: {
              type: 'category',
              labels: {
                rotation: -45,
                style: {
                  fontSize: '13px',
                  fontFamily: 'Verdana, sans-serif'
                }
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'تعداد پذیرش'
              }
            },
            legend: {
              enabled: false
            },
            tooltip: {
              pointFormat: ''
            },
            series: [{
              name: 'Population',
              data: this.list_chart2,
              dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#000',
                align: 'right',
                format: '{point.y}', // one decimal
                y: -25, // 10 pixels down from the top
                style: {
                  fontSize: '0.85rem',
                  fontFamily: 'irsans',
                  'font-weight': 'normal'
                }
              }
            }]
          });
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  private chart3(device: number): void {
    this.device = device;
    if (device > 0) this.loading_container3 = true;
    var obj = {
      address: 6729
      , creator: this.creator
      , year: this.year
      , device: this.device
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var text;
          if (res['year'] > 0) text = 'تعداد برندها به تفکیک ' + res['device'] + " سال " + res['year'];
          else text = 'تعداد برندها به تفکیک ' + res['device'];
          this.list_chart3 = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_chart3.push([res['result'][i].title, res['result'][i].count]);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.loading_container3 = false;
          // @ts-ignore
          Highcharts.chart('container3', {
            chart: {
              type: 'line'
            },
            title: {
              text: text
            },
            xAxis: {
              type: 'category',
              labels: {
                rotation: -45,
                style: {
                  fontSize: '13px',
                  fontFamily: 'Verdana, sans-serif'
                }
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: 'تعداد پذیرش'
              }
            },
            legend: {
              enabled: false
            },
            tooltip: {
              pointFormat: ''
            },
            series: [{
              name: 'Population',
              data: this.list_chart3,
              dataLabels: {
                enabled: true,
                rotation: -90,
                color: '#000',
                align: 'right',
                format: '{point.y}', // one decimal
                y: -25, // 10 pixels down from the top
                style: {
                  fontSize: '0.85rem',
                  fontFamily: 'irsans',
                  'font-weight': 'normal'
                }
              }
            }]
          });
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  private chart4(): void {
    this.loading_container4 = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6731, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.loading_container4 = false;
          this.list_chart4_device = [];
          this.list_chart4_title = [];
          var year: any; var title: any;

          for (var i = 0; i < res['num']; i++) {
            year = this.list_chart4_device.findIndex((index: any) => index.name == res['result'][i].year);
            if (year == -1) {
              this.list_chart4_device.push({ name: res['result'][i].year, data: [] });
              year = this.list_chart4_device.findIndex((index: any) => index.name == res['result'][i].year);
            }

            if (year >= 0) this.list_chart4_device[year].data.push(res['result'][i].count);

            title = this.list_chart4_title.indexOf(res['result'][i].title);
            if (title == -1) {
              this.list_chart4_title.push(res['result'][i].title);
            }
          }

          this.message(false, "", 1, this.messageService.close(this.lang));
          // @ts-ignore
          Highcharts.chart('container4', {
            chart: {
              type: 'line'
            },
            title: {
              text: 'مقایسه پذیرش دستگاهها به تفکیک سالها',
              align: 'center'
            },
            xAxis: {
              categories: this.list_chart4_title
            },
            yAxis: {
              min: 0,
              title: {
                text: 'تعداد'
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: 'light',
                  color: 'gray',
                  textOutline: 'none'
                }
              }
            },
            tooltip: {
              headerFormat: '<b>{point.x}</b><br/>',
              pointFormat: '{series.name}: {point.y}<br/>جمع: {point.stackTotal}'
            },
            plotOptions: {
              line: {
                dataLabels: {
                  enabled: true
                },
                enableMouseTracking: false
              }
            },
            series: this.list_chart4_device
          });
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_device() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6730, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_device = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_device.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
