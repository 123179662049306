<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span>{{ serverService.get_title(type_task,lang) }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="material" class='wh-label'>
                    <span>تعمیرکار</span>
                    <span (click)="reset_input(219)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="seller_title" (click)="load_services(219)" readonly>
                    <input class="wh-input" formControlName="seller" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="material" class='wh-label'>
                    <span> نام طرف حساب </span>
                    <span (click)="reset_input(161)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="customer_title" (click)="load_services(161)" readonly>
                    <input class="wh-input" formControlName="customer" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="customer" class='wh-label'>
                    <span> تاریخ خدمات از </span>
                    <input class="wh-input" matInput [matDatepicker]="picker" formControlName="date1"
                        autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="picker"
                        class="datepicker datepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="customer" class='wh-label'>
                    <span>تاریخ خدمات تا </span>
                    <input class="wh-input" matInput [matDatepicker]="picker2" formControlName="date2"
                        autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="picker2"
                        class="datepicker datepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="material" class='wh-label'>
                    <span>ورودی </span>
                    <select class="wh-input" formControlName="place">
                        <option></option>
                        <option [value]="'invoice'">
                            <span>فاکتور فروش</span>
                        </option>
                        <option [value]="'reception'">
                            <span>تعمیرگاه</span>
                        </option>
                    </select>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>