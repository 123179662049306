<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr',en:lang == 2}" name="search_all"
            [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile()'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll pe1' [ngClass]="{en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>
                <span *ngIf="x.header == false" [ngClass]="{'en':dir == 'ltr'}">{{ x.i + page_number_first }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="max-width:120px">
                <span *ngIf="lang == 1">تاریخ</span>
                <span *ngIf="lang == 2">Date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:120px">
                <span *ngIf="x.header == true" [ngClass]="{'en':dir == 'ltr'}">{{ x.date }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef style="min-width:540px">
                <span *ngIf="lang == 1">شرح</span>
                <span *ngIf="lang == 2">Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:540px">
                <div class="right" [ngClass]="{'left':dir == 'ltr'}" *ngIf="x.header == true">
                    <span *ngIf="x.ref == 'invoice'">
                        {{ x.type_title }}
                        <span *ngIf="lang == 1">با شماره فاکتور</span>
                        <span *ngIf="lang == 2"> Invoice no </span>
                        <span [ngClass]="{'en':dir == 'ltr'}"> {{ x.code }} </span>
                    </span>
                    <span *ngIf="x.ref == 'reception'">
                        <span *ngIf="lang == 1">پذیرش با شماره</span>
                        <span *ngIf="lang == 2">Reception no</span>
                        <span [ngClass]="{'en':dir == 'ltr'}">{{ x.code }} - {{ x.device_title }}</span>
                    </span>
                    <span *ngIf="x.ref == 'finance'">
                        {{ x.type_title }}
                        <span *ngIf="lang == 1">با شماره سند</span>
                        <span *ngIf="lang == 2">Document no</span>
                        <span [ngClass]="{'en':dir == 'ltr'}">{{ x.code }}</span>
                    </span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:540px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width:150px">
                <span *ngIf="lang == 1">نام طرف حساب</span>
                <span *ngIf="lang == 2">Account holder</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:150px">
                <span *ngIf="x.header == true" [ngClass]="{'en':dir == 'ltr'}">{{ x.user_title }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span *ngIf="lang == 1">بدهکار</span>
                <span *ngIf="lang == 2">Debtor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width:100px">
                <span *ngIf="x.header == true" [ngClass]="{'en':dir == 'ltr'}">{{x.bed | number }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <span></span>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="best">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span *ngIf="lang == 1">بستانکار</span>
                <span *ngIf="lang == 2">Creditor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width:100px">
                <span *ngIf="x.header == true" [ngClass]="{'en':dir == 'ltr'}">{{x.best | number }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="material">
            <mat-header-cell *matHeaderCellDef style="min-width:200px">
                <span *ngIf="lang == 1">عنوان</span>
                <span *ngIf="lang == 2">Title</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:200px">
                <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">{{x.title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span *ngIf="lang == 1">تعداد</span>
                <span *ngIf="lang == 2">Number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:70px">
                <span [ngClass]="{'en':dir == 'ltr'}">{{x.number }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span *ngIf="lang == 1">قیمت واحد</span>
                <span *ngIf="lang == 2">Unit price</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px">
                <span [ngClass]="{'en':dir == 'ltr'}">{{x.cost | number }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="discount">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span *ngIf="lang == 1">تخفیف</span>
                <span *ngIf="lang == 2">Discount</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px">
                <span [ngClass]="{'en':dir == 'ltr'}">{{x.wharehouse_order_discount | number }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sum">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span *ngIf="lang == 1">جمع کل</span>
                <span *ngIf="lang == 2">Sum total</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px">
                <span [ngClass]="{'en':dir == 'ltr'}">{{x.sum | number }}</span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>

            </mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>

        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'aquamarine' : row.header == false, 'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>