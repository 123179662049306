<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>حساب ها</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div class="matDialogContent" mat-dialog-content style="overflow-y: hidden !important;">
    <div class="content-body control-content-body" (window:resize)="change_resize()">

        <div class="dialog_div_buttons">
            <button type="button" (click)="open_detaile(0,1,0,0,'','')" [disabled]='new_status'
                [hidden]="ref == 'cheque'">جديد</button>
        </div>

        <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_fa_without_scroll pe1'>

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i
                    + page_number_first }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span>نام دارنده حساب</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'>
                    {{ x.site_bank_accounts_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="branch_title">
                <mat-header-cell *matHeaderCellDef>
                    <span>نام شعبه</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'>
                    {{ x.site_bank_accounts_branch_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cart_number">
                <mat-header-cell *matHeaderCellDef>
                    <span>شماره حساب</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'>
                    {{ x.site_bank_accounts_number }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="account_number">
                <mat-header-cell *matHeaderCellDef>
                    <span>شماره کارت</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='open_detaile(i,2,x.site_bank_accounts_id,x.site_bank_accounts_number,x.site_bank_accounts_branch_title,x.site_bank_accounts_branch_code)'>
                    {{ x.site_bank_accounts_cart }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="default">
                <mat-header-cell *matHeaderCellDef [hidden]="ref == 'reception' || ref == 'cheque'">
                    <span>پیش فرض</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" [hidden]="ref == 'reception' || ref == 'cheque'">
                    <span (click)='change_default(i,x.site_bank_accounts_default,x.site_bank_accounts_id)'>
                        <span *ngIf="x.site_bank_accounts_default == 0"><i class="far fa-star icon"></i> </span>
                        <span *ngIf="x.site_bank_accounts_default == 1"><i class="fas fa-star icon"
                                style="color: blue;"></i> </span>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:70px'
                    [hidden]="ref == 'cheque' || ref == 'reception'">
                    <span> عملیات </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'
                    [hidden]="ref == 'cheque' || ref == 'reception'">
                    <span (click)="check_access(3,0,x.site_bank_accounts_id,0,1)"><i class="far fa-trash-alt icon"
                            style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
                (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>

        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets//images/preview.png">
                </div>
                <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
            </div>
        </div>

    </div>
</div>