import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../../service/modules/modules.component';
import { ServerService } from '../../../../service/server/server.service';
import { MessageService } from '../../../../service/message/message.service';

@Component({
  selector: 'app-accounts-detaile',
  templateUrl: './accounts-detaile.component.html',
  styleUrls: ['./accounts-detaile.component.scss']
})
export class AccountsDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();;
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading: boolean = false;
  public count_finance: number = 0;
  public finance_id: number = 0;
  public search_array: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public bank_id!: number;
  public creator!: number;
  public expire!: boolean;
  public result!: any;
  constructor(
    public serverService: ServerService,
    public messageService: MessageService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<AccountsDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.bank_id = dialog_data.bank_id;
      this.title = dialog_data.title;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.get_status();
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
        })
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, [Validators.required]),
      'branch_title': new FormControl(null),
      'branch_code': new FormControl(null),
      'branch_address': new FormControl(null),
      'number': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cart': new FormControl(null, [Validators.pattern('[0-9-]{1,}'), Validators.minLength(19), Validators.maxLength(19)]),
      'sheba': new FormControl(null, [Validators.pattern('[0-9 ]{1,}'), Validators.minLength(30), Validators.maxLength(30)]),
      'balance': new FormControl(0, [Validators.pattern('[0-9 ]{1,}')]),
      'status_title': new FormControl(null, [Validators.required]),
      'status_id': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')])
    });
    if (this.type_task == 2 || this.type_task == 1) {
    }
  }
  cart(event: any): any {
    var value = event.target.value;
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 45) {
      event.preventDefault();
      return false;
    } else {
      if (value.length == 4 || value.length == 9 || value.length == 14) {
        this.form1_group.patchValue({
          'cart': value + '-'
        })
      }
    }
  }
  sheba(event: any): any {
    var value = event.target.value;
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 32) {
      event.preventDefault();
      return false;
    } else {
      if (value.length == 2 || value.length == 7 || value.length == 12 || value.length == 17 || value.length == 22 || value.length == 27) {
        this.form1_group.patchValue({
          'sheba': value + ' '
        })
      }
    }
  }
  get_account(event: any): any {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            this.expire = this.serverService.check_expire(res['creator']);
            if (type_task == 1) {
              if (this.expire == true) {
                this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
                return false;
              } else {
                this.insert();
              }
            }
            if (type_task == 2) { this.update(); }
            if (type_task == 3) { this.search(excel); }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      export_excel: excel
    })
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1946, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].site_bank_accounts_title,
            'branch_title': res['result'][0].site_bank_accounts_branch_title,
            'branch_code': res['result'][0].site_bank_accounts_branch_code,
            'branch_address': res['result'][0].site_bank_accounts_branch_address,
            'number': res['result'][0].site_bank_accounts_number,
            'cart': res['result'][0].site_bank_accounts_cart,
            'sheba': res['result'][0].site_bank_accounts_sheba,
            'balance': res['result'][0].site_bank_accounts_balance,
            'status_id': res['result'][0].site_bank_accounts_status,
            'status_title': res['result'][0].status_title,
          }
          )
          this.finance_id = res['result'][0].finance_financial2_id;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1942, "user_id": this.user_id,
      'status_id': this.form1_group.value.status_id,
      'title': this.form1_group.value.title,
      'branch_title': this.form1_group.value.branch_title,
      'branch_code': this.form1_group.value.branch_code,
      'branch_address': this.form1_group.value.branch_address,
      'number': this.form1_group.value.number,
      'cart': this.form1_group.value.cart,
      'sheba': this.form1_group.value.sheba,
      'balance': this.form1_group.value.balance,
      'bank_id': this.bank_id,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.insert_new_finnace(res['result'][0].site_bank_accounts_id);
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1943,
      "user_id": this.user_id,
      "creator": this.creator,
      'status_id': this.form1_group.value.status_id,
      'title': this.form1_group.value.title,
      'branch_title': this.form1_group.value.branch_title,
      'branch_code': this.form1_group.value.branch_code,
      'branch_address': this.form1_group.value.branch_address,
      'number': this.form1_group.value.number,
      'cart': this.form1_group.value.cart,
      'sheba': this.form1_group.value.sheba,
      'balance': this.form1_group.value.balance,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = { result: res['result'][0], i: this.i };
          if (this.finance_id > 0) {
            this.update_finance();
          } else {
            this.insert_new_finnace(this.id);
          }
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  insert_new_finnace(account: any) {
    var obj = {
      address: 6351, account: account, creator: this.creator, user_id: this.user_id
      , amount: this.form1_group.value.balance
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_finance() {
    var obj = {
      address: 6225, finance_id: this.finance_id, user_id: this.user_id, creator: this.creator
      , amount: this.form1_group.value.balance
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_status(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_services(id: number) {
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (id == 26) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
        }
      }
    )

  }
  reset_input(id: number) {
    if (id == 26) {
      this.form1_group.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
