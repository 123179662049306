import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { ChequePayDetaileComponent } from '../../cheque/cheque-pay-detaile/cheque-pay-detaile.component';
import { ChequeRecieveDetaileComponent } from '../../cheque/cheque-recieve-detaile/cheque-recieve-detaile.component';
import { UserSearchComponent } from '../../../user/user-search/user-search.component';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { InsertComponent } from '../../../service/insert/insert.component';
import { UserDetaile2Component } from '../../../user/user-detaile2/user-detaile2.component';
import { MessageService } from '../../../service/message/message.service';
import { FinancialDetaile3Component } from '../financial-detaile3/financial-detaile3.component';
import { ChequeListRecieveComponent } from '../../cheque/cheque-list-recieve/cheque-list-recieve.component';
import Num2persian from 'num2persian';


@Component({
  selector: 'app-financial-detaile2',
  templateUrl: './financial-detaile2.component.html',
  styleUrls: ['./financial-detaile2.component.scss']
})
export class FinancialDetaile2Component implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1_group: FormGroup;
  public user_id: number | any;
  public id: number | undefined;
  public i!: number;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public document: number | undefined;
  public list_record: any = [];
  public list_document: any = [];
  public amount: number | undefined;
  public document_type: number | undefined;
  public count_check_pay: number = 0;
  public count_check_recieve: number = 0;
  public result: any | undefined;
  public list_not: any = [];
  public list_cheque: any = [];
  public token: any = Math.floor(Math.random() * 99999999999999);
  public readonly: boolean = false;
  public user: number;
  public user_title: string;
  public video: string | undefined;
  public ref: string | undefined;
  public invoice_number: number;
  public reception_number: number;
  public transfer: number = 0;
  public remain: number = 0;
  public list_order: any = [];
  public list_record_financial2: any = [];
  public x: number = 0;
  public x3: number = 0;
  public remain_letter: string;
  public currency_title: string | undefined;
  public creator: number | undefined;
  public expire: boolean | undefined;
  public sum: number = 0;
  public sum_all_letter: string | undefined;
  public document_user: boolean;
  public document_cost: boolean;
  public document_income: boolean;
  public access_service: number | undefined;
  public level: number | undefined;
  public invoice_title: string;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'payment_type', 'amount', 'type', 'bank', 'account', 'cheque', 'due', 'status', 'operation'];

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public matDialogRef: MatDialogRef<FinancialDetaile2Component>) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

    this.serverService.get_financial2().subscribe(
      (res) => {
        this.list_record.unshift(res);
        this.dataSource.data = this.list_record;
        //this.get_amount(2);
      }
    )
    if (dialog_data) {
      this.readonly = dialog_data.readonly;
      this.type_task = dialog_data.type_task;
      this.document = dialog_data.document;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.user = dialog_data.user;
      this.sum = dialog_data.sum;
      this.ref = dialog_data.ref;
      this.invoice_title = dialog_data.invoice_title;
      this.invoice_number = dialog_data.invoice_number;
      this.reception_number = dialog_data.reception_number;
      this.user_title = dialog_data.user_title;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.check_access(3, 1);
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'user_title': new FormControl(null),
      'user': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
      'cost_title': new FormControl(null),
      'cost': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'document': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'date3': new FormControl(null),
      'date4': new FormControl(null),
      'document_date': new FormControl(null),
      'document_title': new FormControl(null),
      'document_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'income_title': new FormControl(null),
      'income': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cost_group_title': new FormControl(null),
      'cost_group': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['document'].setValidators([Validators.required]);
      //if (this.document != 16) this.form1_group.controls['user'].setValidators([Validators.required]);
      //if (this.document == 16) this.form1_group.controls['cost'].setValidators([Validators.required]);
    }
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access3(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access3(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            this.insert();
          }
          if (type_task == 2) {
            if (this.expire == true && this.i == -1) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.update();
            }
          }
          if (type_task == 3) {
            this.form1_group.patchValue({
              user: this.dialog_data.search_array.user,
              user_title: this.dialog_data.search_array.user_title,
              cost: this.dialog_data.search_array.cost,
              cost_title: this.dialog_data.search_array.cost_title,
              cost_group_title: this.dialog_data.search_array.cost_group_title,
              cost_group: this.dialog_data.search_array.cost_group,
              comment: this.dialog_data.search_array.comment,
              date3: this.dialog_data.search_array.date3,
              date4: this.dialog_data.search_array.date4,
              document_title: this.dialog_data.search_array.document_title,
              document_id: this.dialog_data.search_array.document_id,
            })
          }
          if (type_task == 4) { this.get_list_document(); }
          if (type_task == 5) { this.get_list_document(); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      comment: this.form1_group.value.comment,
      cost: this.form1_group.value.cost,
      cost_title: this.form1_group.value.cost_title,
      cost_group: this.form1_group.value.cost_group,
      cost_group_title: this.form1_group.value.cost_group_title,
      user: this.form1_group.value.user,
      user_title: this.form1_group.value.user_title,
      date3: this.form1_group.value.date3,
      date4: this.form1_group.value.date4,
      export_excel: excel,
      document_title: this.form1_group.value.document_title,
      document_id: this.form1_group.value.document_id,
    })
  }


  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1917, id: this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'sort': res['result'][0].finance_financial_sort,
              'user': res['result'][0].finance_financial_user,
              'user_title': res['result'][0].user_title,
              'cost': res['result'][0].finance_cost_type_id,
              'cost_title': res['result'][0].finance_cost_type_title,
              'income': res['result'][0].finance_income_type_id,
              'income_title': res['result'][0].finance_income_type_title,
              'comment': res['result'][0].finance_financial_comment,
              'document_date': res['result'][0].finance_financial_document_date_ad,
              'document': parseInt(res['result'][0].finance_financial_document),
            });
            this.document_type = res['result'][0].document_type;
            this.document = res['result'][0].finance_financial_document;
            this.transfer = res['result'][0].finance_document_transfer;

            if (this.ref == 'invoice') {
              this.form1_group.patchValue({
                'comment': 'بابت فاکتور ' + this.invoice_title + " " + this.invoice_number
              })
            }
            if (this.ref == 'reception') {
              this.form1_group.patchValue({
                'comment': ' بابت شماره پذیرش ' + this.reception_number
              })
            }

            this.set_cost_or_user();
            this.get_financial2();
            this.get_currency();
            if (res['result'][0].finance_financial_user) this.get_account(res['result'][0].finance_financial_user);
            if (this.sum < 0) {
              this.change_defaut();
            }
          }

          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_user() {
    var dialogRef = this.dialog.open(UserDetaile2Component, {
      width: '45rem',
      height: 'auto',
      data: { creator: this.creator, level: this.level }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        this.form1_group.patchValue({
          'user_title': res['result'][0].user_title,
          'user': res['result'][0].user_id,
        });
      }
    )
  }


  add_income(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'income' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'income': res.finance_income_type_id,
            'income_title': res.finance_income_type_title
          })
        }
      }
    )
  }

  add_cost(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'cost' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'cost': res.finance_cost_type_id,
            'cost_title': res.finance_cost_type_title
          })
        }
      }
    )
  }


  change_defaut() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6183, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'document': res['result'][0].finance_document_id
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_currency() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6115, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.currency_title = res['result'][0].site_currency_title;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1913, "user_id": this.user_id
      , 'sort': this.form1_group.value.sort
      , 'user': this.form1_group.value.user
      , 'document': this.form1_group.value.document
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert


  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    /*
    if (this.document_type == 1) {
      for (var i = 0; i < this.list_record.length; i++) {
        if (this.list_record[i].site_cheque_type == 2) {
          var pe_message = "در سند نوع دریافتی نمی توانید تراکنش ار نوع پرداختی داشته باشید";
          var en_message = "You cannot have a payment type transaction in the receipt type document";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
          return false;
        }
      }
    }
    */
    this.loading = true;
    var obj = {
      'address': 6775
      , "lang": this.lang
      , "user_id": this.user_id
      , 'sort': this.form1_group.value.sort
      , 'user': this.form1_group.value.user
      , 'cost': this.form1_group.value.cost
      , 'income': this.form1_group.value.income
      , 'comment': this.form1_group.value.comment
      , 'amount': this.amount
      , 'document': this.form1_group.value.document
      , 'document_date': this.form1_group.value.document_date
      , 'document_type': this.document_type
      , 'token': this.token
      , 'id': this.id
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.update_financial2_trash3_to_2();
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "در سند نوع دریافتی نمی توانید تراکنش ار نوع پرداختی داشته باشید";
          var en_message = "You cannot have a payment type transaction in the receipt type document";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  update_financial2_trash3_to_2() {
    var obj = {
      address: 6784
      , id: this.id
      , token: this.token
      , ref: 1
      , user: this.form1_group.value.user
      , creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.transfer == 1) {
            this.matDialogRef.close(
              { result: this.result }
            )
            this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            if (this.document_cost == true) this.check_financial_document(1);
            else this.matDialogRef.close({ result: this.result })
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_financial_document(type: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6238, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (this.document_cost == true) this.update_financial_document(res['result'][0].finance_financial2_id, 2);
            else this.update_financial_document(res['result'][0].finance_financial2_id, 3);
          }
          else {
            if (this.document_cost == true) this.insert_financial_document();
          }
          if (type == 1) this.matDialogRef.close({ result: this.result })
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert_financial_document() {
    var obj = {
      address: 6240, finance_id: this.id
      , user_id: this.user_id
      , creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  update_financial_document(id: number, trash: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6239, id: id, finance_id: this.id, trash: trash }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_financial2() {
    var obj = {
      address: 6112,
      id: this.id,
      user: this.form1_group.value.user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
          this.serverService.send_remain_account();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_document() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if 
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1952, document: this.form1_group.value.document, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['num'] == 1) {
              this.document_type = res['result'][0].document_type;
              this.document = res['result'][0].finance_document_id;
              this.transfer = res['result'][0].finance_document_transfer;
            }
          }

          this.set_cost_or_user();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access_for_change_user(id: number): any {
    var count = 0;
    if (id == 161) {
      for (var i = 0; i < this.list_record.length; i++) {
        if (this.list_record[i].finance_financial2_payment_type == 2 && this.list_record[i].site_cheque_type == 2) {
          count++;
        }
      }
      if (count > 0) {
        var pe_message = "چک یا چک هایی به نام ایشان در لیست ریز تراکنش ها وجود دارد.قادر به تغییر نام طرف حساب نیستید";
        var en_message = "There is a check or checks in his name in the list of microtransactions. You are not able to change the name of the account party";
        this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        return false;
      }
    }
  }


  open_user(): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(UserSearchComponent, {
      width: '40rem',
      height: 'auto',
      data: { code: 193 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'user_title': res.title,
            'user': res.id
          });
          this.get_account(res.id)
        }
      }
    )
  }


  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    var code_id;
    var x = this.check_access_for_change_user(id);
    if (x == false) {
      return false;
    }
    if (id == 221) {
      code_id = 6606;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator, code_id: code_id, obj: { cost_group: this.form1_group.value.cost_group } }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 161) {
            this.form1_group.patchValue({
              'user': res.id,
              'user_title': res.title
            });
            this.get_account(res.id);
          }
          if (id == 221) {
            this.form1_group.patchValue({
              'cost': res.id,
              'cost_title': res.title
            });
          }
          if (id == 191) {
            this.form1_group.patchValue({
              'document_id': res.id,
              'document_title': res.title
            });
            if (this.type_task == 3) {
              this.get_document_for_only_search();
            }
          }
          if (id == 242) {
            this.form1_group.patchValue({
              'income': res.id,
              'income_title': res.title
            });
          }
          if (id == 254) {
            this.form1_group.patchValue({
              'cost_group': res.id,
              'cost_group_title': res.title,
              'cost': null,
              'cost_title': null,
            });
          }
        }
      }
    )
  }

  get_document_for_only_search() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if 
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1952, document: this.form1_group.value.document_id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['num'] == 1) {
              this.document_type = res['result'][0].document_type;
              this.document = res['result'][0].finance_document_id;
              this.transfer = res['result'][0].finance_document_transfer;
            }
          }

          this.set_cost_or_user();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }





  get_account(user: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: user, token: this.token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.remain = 0;
          this.remain = res['result'][0].balance;
          this.x = 0;
          for (var j = 0; j < this.list_order.length; j++) {
            if (this.list_order[j].wharehouse_order_trash == 3) {
              this.x = this.x + Number(this.list_order[j].wharehouse_order_cost * this.list_order[j].wharehouse_order_number);
            }
          }
          this.x3 = 0;
          for (var j = 0; j < this.list_record_financial2.length; j++) {
            if (this.list_record_financial2[j].finance_financial2_trash == 3) {
              this.x3 = this.x3 + Number(this.list_record_financial2[j].finance_financial2_amount);
            }
          }
          if (this.document_type == 1) this.remain = Number(this.remain) + Number(this.x) - Number(this.x3);
          if (this.document_type == 2) this.remain = Number(this.remain) - Number(this.x) + Number(this.x3);
          this.remain_letter = Num2persian(this.remain);

          //this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در $";
          var en_message = "Error in $";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  reset_input(id: number): any {
    var x = this.check_access_for_change_user(id);
    if (x == false) {
      return false;
    }
    if (id == 161) {
      this.form1_group.patchValue({
        'user': null,
        'user_title': null
      });
    }
    if (id == 221) {
      this.form1_group.patchValue({
        'cost': null,
        'cost_title': null
      });
    }
    if (id == 191) {
      this.form1_group.patchValue({
        'document_id': null,
        'document_title': null
      });
    }
    if (id == 242) {
      this.form1_group.patchValue({
        'income': null,
        'income_title': null
      });
    }
    if (id == 254) {
      this.form1_group.patchValue({
        'cost_group': null,
        'cost_group_title': null,
        'cost': null,
        'cost_title': null,
      });
    }
  }


  close() {
    var j = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      if (this.list_record[i].finance_financial2_trash == 3) {
        j++;
      }
    }
    if (j > 0) {
      var message = "اینجا تراکنشهای انجام شده هنوز ذخیره نشده است؟";
      if (this.lang == 2) {
        message = "Are the transactions done here not saved yet?";
      }
      var accept: boolean = confirm(message);
      if (accept == true) {
        this.matDialogRef.close();
      } else {
      }
    } else {
      this.matDialogRef.close();
    }

    /*
    if (this.i == -1 && this.list_record.length > 1) {
      this.update(1);
    } else {
      this.matDialogRef.close();
    }
    */
  }
  //*********************************************************************************************** */
  check_access2(i: number, type_task: number, page: number, id: any, excel: number, loading: number, cheque: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            if (type_task == 3) {
              this.delete(i, id, cheque);
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_financial2() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    //6029
    var address = 6778;
    var obj = {
      'address': address
      , user_id: this.user_id
      , id: this.id
      , ref: 1
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.get_amount();
          this.check_disabled();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  get_amount() {
    this.amount = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.amount += Number(this.list_record[i].finance_financial2_amount);
    }
    this.sum_all_letter = Num2persian(this.amount);
  }

  set_cost_or_user() {
    if (this.transfer == 0 && (this.document == 6 || this.document == 7)) {
      this.document_user = true;
      this.document_cost = false;
      this.document_income = false;
      this.form1_group.patchValue({
        'cost': null,
        'cost_title': null
      })
    }
    else if (this.transfer == 0 && this.document == 16) {
      this.document_user = false;
      this.document_cost = true;
      this.document_income = false;
      this.form1_group.patchValue({
        'user': null,
        'user_title': null
      })
    }
    else if (this.document == 18) {
      this.document_user = false;
      this.document_cost = false;
      this.document_income = true;
    }
    else if (this.transfer == 1) {
      this.document_user = false;
      this.document_cost = false;
      this.document_income = false;
    }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  open_detaile(index: number, type_task: number, id: number, cheque_id: number, cheque_type: number, footer: boolean): any {
    if (this.readonly == true || footer == true) {
      return false;
    }
    if (cheque_type == 1) {
      this.open_cheque_recieve_detaile(index, 2, cheque_id);
      return false;
    }
    else if (cheque_type == 2) {
      this.open_cheque_pay_detaile(index, 2, cheque_id);
      return false;
    }
    var x = 0;
    if (this.document_type == 1) {
      for (var i = 0; i < this.list_record.length; i++) {
        if (this.list_record[i].site_cheque_type == 2) {
          var pe_message = "در سند نوع دریافتی نمی توانید تراکنش ار نوع پرداختی داشته باشید";
          var en_message = "You cannot have a payment type transaction in the receipt type document";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
          return false;
        }
      }
    }
    if ((!this.form1_group.value.user && this.document_user == true) || (!this.form1_group.value.cost && this.document_cost == true
      || (!this.form1_group.value.income && this.document_income == true))) {
      var pe_message = "برای ثبت و یا ویرایش تراکنش در ابتدا باید نام طرف حساب یا عنوان هزینه و یا عنوان درآمد مشخص شود";
      var en_message = "To register or edit a transaction, the name of the account party or the title of the expense or the title of the income must be specified at first";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(FinancialDetaile3Component, {
      width: '22rem',
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task, id: id, search_array: this.search_array
        , code: this.code, title: this.title, financial: this.id
        , user: this.form1_group.value.user
        , document: this.form1_group.value.document
        , token: this.token
        , sum: Math.abs(this.sum) - Number(this.amount)
        , ref: 1
        , transfer: this.transfer
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            for (var j = 0; j < res.result.length; j++) {
              this.list_record.unshift(res.result[j]);
            }
            this.dataSource.data = this.list_record;
            this.get_amount();
          }
          else if (type_task == 2) {
            if (res.change == true) {
              this.list_record[index].user_title = res.result.user_title;
              this.list_record[index].finance_financial2_amount = res.result.finance_financial2_amount;
              this.list_record[index].finance_payment_type_title = res.result.finance_payment_type_title;
              this.list_record[index].site_cheque_type_title = res.result.site_cheque_type_title;
              this.list_record[index].site_cheque_status_title = res.result.site_cheque_status_title;
              this.list_record[index].accounts_number = res.result.accounts_number;
              this.list_record[index].finance_financial2_cheque_number = res.result.finance_financial2_cheque_number;
              this.list_record[index].site_bank_title = res.result.site_bank_title;
              this.list_record[index].due_date = res.result.due_date;
              this.get_amount();
              this.check_financial_document(2);
              if (res.result.finance_financial2_trash == 2) {
                this.update_financial2_amount();
              }
            }
          }
          this.get_account(this.form1_group.value.user);
        }
      }
    )
  }

  update_financial2_amount() {
    var obj = {
      'address': 6452, "user_id": this.user_id, id: this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }

  get_bg(id: number) {
    //this.id = id;
  }

  delete(i: number, id: number, cheque: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      var obj = {
        'address': 6031, "user_id": this.user_id
        , 'id': id
        , cheque: cheque
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {

            if (this.list_record[i].site_cheque_type == 2 || (
              this.list_record[i].site_cheque_type == 1 && this.document_type == 1)) {
              this.update_cheque2(id, cheque);
            } else if (this.list_record[i].site_cheque_type == 1 && this.document_type == 2) {
              this.update_cheque3(id, cheque);
            } else {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.finance_financial2_id == id), 1);
              this.dataSource.data = this.list_record;
              this.message(false, "", 1, this.messageService.close(this.lang));
            }
            this.get_amount();
            this.get_account(this.form1_group.value.user);
            this.check_financial_document(2);
          }//end if
          else {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete


  update_cheque2(id: number, cheque: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', {
      address: 6032, cheque: cheque, document_type: this.document_type
    }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record.splice(this.list_record.findIndex((index: any) => index.finance_financial2_id == id), 1);
          this.dataSource.data = this.list_record;
          this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  update_cheque3(id: number, cheque: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', {
      address: 6033, cheque: cheque, creator: this.creator
    }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record.splice(this.list_record.findIndex((index: any) => index.finance_financial2_id == id), 1);
          this.dataSource.data = this.list_record;
          this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_list_document() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1947, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        this.list_document = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_document.push(res['result'][i]);
          }//end for
          if (this.type_task == 2) this.get_data();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  open_cheque_pay_detaile(index: number, type_task: number, id: number): any {
    if (this.readonly == true) {
      return false;
    }
    if ((!this.form1_group.value.user && this.document_user == true) || (!this.form1_group.value.cost && this.document_cost == true)
      || (!this.form1_group.value.income && this.document_income == true)) {
      var pe_message = "برای ثبت و یا ویرایش تراکنش در ابتدا باید نام طرف حساب یا عنوان هزینه و یا عنوان درآمد مشخص شود";
      var en_message = "To register or edit a transaction, the name of the account party or the title of the expense or the title of the income must be specified at first";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(ChequePayDetaileComponent, {
      width: '32rem',
      height: 'auto',
      data: {
        type_task: type_task
        , code: this.code
        , type: this.document_type
        , id: id
        , token: this.token
        , creator: this.creator
        , financial_id: this.id
      }

    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource = new MatTableDataSource(this.list_record);
            this.get_amount();
          }
          else {
            this.list_record[index].finance_financial2_amount = res.result.finance_financial2_amount;
            this.list_record[index].site_bank_title = res.result.site_bank_title;
            this.list_record[index].accounts_number = res.result.accounts_number;
            this.list_record[index].finance_financial2_cheque_number = res.result.finance_financial2_cheque_number;
            this.list_record[index].due_date = res.result.due_date;
            this.list_record[index].site_cheque_status_title = res.result.site_cheque_status_title;
            this.get_amount();
            this.check_disabled();
          }
        }
      }
    )
  }
  open_cheque_recieve_detaile(index: number, type_task: number, id: number): any {
    if (this.readonly == true) {
      return false;
    }
    if ((!this.form1_group.value.user && this.document_user == true) || (!this.form1_group.value.cost && this.document_cost == true)
      || (!this.form1_group.value.income && this.document_income == true)) {
      var pe_message = "برای ثبت و یا ویرایش تراکنش در ابتدا باید نام طرف حساب یا عنوان هزینه و یا عنوان درآمد مشخص شود";
      var en_message = "To register or edit a transaction, the name of the account party or the title of the expense or the title of the income must be specified at first";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(ChequeRecieveDetaileComponent, {
      width: '32rem',
      height: 'auto',
      data: {
        type_task: type_task
        , code: this.code
        , type: this.document_type
        , id: id
        , token: this.token
        , creator: this.creator
        , financial_id: this.id
      }

    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource = new MatTableDataSource(this.list_record);
            this.get_amount();
          }
          else {
            this.list_record[index].finance_financial2_amount = res.result.finance_financial2_amount;
            this.list_record[index].site_bank_title = res.result.site_bank_title;
            this.list_record[index].accounts_number = res.result.accounts_number;
            this.list_record[index].finance_financial2_cheque_number = res.result.finance_financial2_cheque_number;
            this.list_record[index].due_date = res.result.due_date;
            this.list_record[index].site_cheque_status_title = res.result.site_cheque_status_title;
            this.get_amount();
            this.check_disabled();
          }
        }
      }
    )
  }

  check_disabled() {
    this.count_check_pay = 0;
    this.count_check_recieve = 0;

    for (var i = 0; i < this.list_record.length; i++) {

      if (this.document_type == 2) {
        if (this.list_record[i].finance_financial2_payment_type == 2) {
          this.count_check_pay++;
        }
      }
      else if (this.document_type == 1) {
        if (this.list_record[i].finance_financial2_payment_type == 2) {
          this.count_check_recieve++;
        }
      }

    }//end for
  }
  //********************************************************************************************** */
  select_cheque(type): any {
    if (!this.form1_group.value.user && this.document_user == true) {
      var pe_message = "برای ثبت و یا ویرایش تراکنش در ابتدا باید نام طرف حساب مشخص شود";
      var en_message = "To register or edit a transaction, the name of the account party must be specified first";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(ChequeListRecieveComponent, {
      width: '74rem',
      height: 'auto',
      data: { user: this.form1_group.value.user, document_type: this.document_type, token: this.token, type: type, creator: this.creator }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.insert_cheque(res.result.finance_financial2_cheque_type, res.result.finance_financial2_id);
        }
      }
    )
  }
  insert_cheque(cheque_type: number, cheque: number) {
    this.loading = true;
    var obj = {
      'address': 6796
      , "user_id": this.user_id
      , 'cheque': cheque
      , 'payment_type': 2
      , 'cheque_type': cheque_type
      , 'document_type': this.document_type
      , 'financial': this.id
      , 'token': this.token
      , 'trash': 3
      , 'ref': 1.
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          res.result[0].color = 'transparent';
          this.list_record.unshift(res.result[0]);
          this.dataSource.data = this.list_record;
          this.check_disabled();
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شماره چک انتخابی قبلا در سیستم دریافت و پرداخت استفاده شده است";
          var en_message = "The selected check number has already been used in the receiving and payment system";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

