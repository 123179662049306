<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row dflex">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button (click)="load_data2('all')" [ngClass]="{active1:type == 'all'}">همه</button>
                <button (click)="load_data2('bed')" [ngClass]="{active1:type == 'bed'}">بدهکاران</button>
                <button (click)="load_data2('best')" [ngClass]="{active1:type == 'best'}">بستانکاران</button>
                <div class="pager_pe">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body control-content-body" (window:resize)="change_resize()">
        <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll_bg pe1'>

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px' (click)="reset_sort('user_id','desc')">
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' class="cell1 ">{{ i +
                    page_number_first
                    }}</mat-cell>
                <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef style="min-width: 180px;"
                    [ngClass]="{active1:order == 'user_title ' + asc_desc || active_sort == 'user_title'}"
                    (click)="sort('user_title',asc_desc)">
                    <span>نام طرف حساب</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 180px;">
                    <div class="div2 right" style="float: right;">
                        {{ x.user_title }}
                    </div>
                    <span (click)="show_financial2(i,x.user_id)" class="cursor fleft">
                        <i class="fa fa-eye icon"></i>
                    </span>
                    <span class="info fleft" (click)="open_menu(i)" [matMenuTriggerFor]="menu2">
                        <i class="fas fa-info-circle icon"></i>
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef style="min-width: 180px;"></mat-footer-cell>
            </ng-container>
            <mat-menu #menu2="matMenu" class="invoice2-mat-menu-panel" xPosition="before">
                <table class="table table-bordered" (click)="$event.stopPropagation()">
                    <tr>
                        <td class="right" (click)="go_to_balance()">
                            <a target="_blank" class="cursor">
                                <span> نمایش صورت وضعیت کاربر </span>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td class="right">
                            <a target="_blank" class="cursor" (click)="send_sms()">
                                <span> ارسال پیامک صورت وضعیت</span>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td class="right">
                            <a target="_blank" class="cursor" (click)="send_sms_for_remain()">
                                <span> ارسال پیامک اعلام مانده حساب</span>
                            </a>
                        </td>
                    </tr>
                </table>
            </mat-menu>

            <ng-container matColumnDef="cellphone">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'user_cellPhone ' + asc_desc  || active_sort == 'user_cellPhone' }"
                    (click)="sort('user_cellPhone',asc_desc)">
                    <span>شماره همراه</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" class="cell1">
                    <div *ngIf="show_cellphone == true">{{ x.user_cellPhone }}</div>
                    <div *ngIf="show_cellphone == false" class="cellphone">{{
                        x.user_cellPhone | hide_cellphone
                        }}</div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="bed">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'bed ' + asc_desc   || active_sort == 'bed' }"
                    (click)="sort('bed',asc_desc)">
                    <span>بدهکار</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index">
                    {{ x.bed | number }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{ bed | number }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="best">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'best ' + asc_desc   || active_sort == 'best' }"
                    (click)="sort('best',asc_desc)">
                    <span>بستانکار</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index">
                    {{ x.best | number }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{ best | number }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="remain">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'remain ' + asc_desc || active_sort == 'remain' }"
                    (click)="sort('remain',asc_desc)">
                    <span>مانده حساب</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index" [ngClass]="{alarm:x.remain < 0 }">
                    <div class="amount fright right">{{ x.remain | number }}</div>
                    <div class="icons left" style="margin-top:-4px">
                        <span (click)="check_new_balance(i,x.user_id,x.remain)">
                            <mat-icon>paid</mat-icon>
                        </span>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    {{ remain | number }}
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="log_date">
                <mat-header-cell *matHeaderCellDef
                    [ngClass]="{active1:order == 'log_date ' + asc_desc || active_sort == 'log_date' }"
                    (click)="sort('log_date',asc_desc)">
                    <span>لاگ مشاهده</span>
                </mat-header-cell>
                <mat-cell class=" cursor" *matCellDef="let x;let i = index">
                    <span class="amount">{{ x.log_date }}</span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
            <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
                *matFooterRowDef="displayedColumns;sticky : true">
            </mat-footer-row>

        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets/images/preview.png">
                </div>
                <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
            </div>
        </div>

    </div>