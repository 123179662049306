<!-- ************************************************************************************************************************************* -->
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span *ngIf="lang == 1"> پروفایل </span>
    <span *ngIf="lang == 2"> Profile </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>

    <div class="video_div1" (click)="open_video()" *ngIf="video && ref == 'menu'" style="float: left;">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent"
        style="padding:0px !important;padding-bottom:1rem !important;max-height:25rem">
        <mat-tab-group dynamicHeight [ngStyle]="{'direction':dir == 'ltr' ? 'ltr' : 'rtl'}">
            <mat-tab label="{{ tab1 }}">
                <div class="example-small-box mat-elevation-z4">
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe'>
                        <div class="row">
                            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="title" class='wh-label'>
                                    <span *ngIf="lang == 1">نام طرف حساب</span>
                                    <span *ngIf="lang == 2">Account holder</span>
                                    <input class='wh-input' formControlName='title' autocomplete="off"
                                        [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [hidden]="level == 3" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="store" class='wh-label'>
                                    <span *ngIf="lang == 1"> نام شرکت / فروشگاه </span>
                                    <span *ngIf="lang == 2">Company / store name</span>
                                    <input class='wh-input' formControlName='store' autocomplete="off"
                                        [required]="level == 2" [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="cellphone" class='wh-label'>
                                    <span *ngIf="lang == 1"> شماره همراه </span>
                                    <span *ngIf="lang == 2"> Cellphone number </span>
                                    <input class='wh-input' formControlName='cellphone' pattern="[0-9]{1,}"
                                        (keypress)="get_number($event)" minlength=11 maxlength=11 autocomplete="off"
                                        [required]="required()" [attr.disabled]="true" [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="level == 3 || dir == 'ltr'">
                                <label for="State" class='wh-label'>
                                    <span *ngIf="lang == 1"> استان</span>
                                    <span *ngIf="lang == 2"> State/ province </span>
                                    <span (click)="reset_input(233)">
                                        <mat-icon class="close" class="input_search input_search_pe"
                                            [ngClass]="{input_search_en:lang==2}">close
                                        </mat-icon>
                                    </span>
                                    <input class='wh-input' formControlName='state_title' autocomplete="off"
                                        (click)="load_services(233)" readonly [ngClass]="{'en':dir == 'ltr'}">
                                    <input class='wh-input' formControlName='state' autocomplete="off" [hidden]="true"
                                        readonly>
                                </label>
                            </div>

                            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="level == 3">
                                <label for="currency" class='wh-label'>
                                    <span *ngIf="lang == 1"> واحد پولی من</span>
                                    <span *ngIf="lang == 2"> My currency </span>
                                    <span (click)="reset_input(156)">
                                        <mat-icon class="close" class="input_search input_search_pe"
                                            [ngClass]="{input_search_en:lang==2}">close
                                        </mat-icon>
                                    </span>
                                    <input class='wh-input' formControlName='currency_title' autocomplete="off"
                                        (click)="load_services(156)" readonly [ngClass]="{'en':dir == 'ltr'}">
                                    <input class='wh-input' formControlName='currency' autocomplete="off"
                                        [hidden]="true" readonly>
                                </label>
                            </div>


                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding marginTop5'
                                [hidden]="level == 3" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="address" class='wh-label'>
                                    <span *ngIf="lang == 1"> آدرس</span>
                                    <span *ngIf="lang == 2"> Address </span>
                                    <input class='wh-input' formControlName='address' autocomplete="off" length="100"
                                        maxlength="100" [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="true">
                                <label for="repairman" class='wh-label'>
                                    <mat-checkbox formControlName='repairman'
                                        style="vertical-align:text-top"></mat-checkbox>
                                    <span *ngIf="lang == 1"> آیا در لیست تعمیرکاران هم قرار می گیرید؟ </span>
                                    <span *ngIf="lang == 2"> Are you also in list of repairmen? </span>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="level == 3">
                                <label for="show_cellphone_in_bill" class='wh-label'>
                                    <mat-checkbox formControlName='show_cellphone_in_bill'
                                        style="vertical-align:text-top"></mat-checkbox>
                                    <span *ngIf="lang == 1"> آیا می خواهید شماره موبایل تعمیرگاه در قبضها دیده
                                        شود؟</span>
                                    <span *ngIf="lang == 2"> Do you want the mobile phone number of the repair shop to
                                        be seen in the bills? </span>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="level == 3">
                                <label for="show_cellphone" class='wh-label'>
                                    <mat-checkbox formControlName='show_cellphone'
                                        style="vertical-align:text-top"></mat-checkbox>
                                    <span *ngIf="lang == 1"> آیا می خواهید شماره موبایل ها را در لیست ببینید؟</span>
                                    <span *ngIf="lang == 2"> Do you want to see phone numbers on the list? </span>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="level == 3">
                                <label for="document_finance" class='wh-label'>
                                    <mat-checkbox formControlName='document_finance'
                                        style="vertical-align:text-top"></mat-checkbox>
                                    <span *ngIf="lang == 1"> آیا می خواهید برای تعمیرکاران و پرسنل سند مالی در فاکتور
                                        خروج زده شود
                                        ؟</span>
                                    <span *ngIf="lang == 2">
                                        Do you want to add financial documents for your staff and repairmen in
                                        the invoice?
                                    </span>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="login_with_sms" class='wh-label'>
                                    <mat-checkbox formControlName='login_with_sms'
                                        style="vertical-align:text-top"></mat-checkbox>
                                    <span *ngIf="lang == 1">فعال سازی تایید دو مرحله ای از طریق پیامک</span>
                                    <span *ngIf="lang == 2">Enable two-step verification via SMS</span>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}" style="padding-right:1.2rem">
                                <label for="login_with_sms" class='wh-label'>
                                    <span *ngIf="lang == 1">
                                        اگر گمان میکنید که نام کاربری یا کلمه عبور شما در دسترس شخصی قرار گرفته است با
                                        تغییر توکن
                                        <span (click)="clear_token()" class="cursor here">از اینجا</span>
                                        میتوانید دسترسی شخص مورد نظر را به صورت آنی قطع کنید.
                                    </span>
                                    <span *ngIf="lang == 2">
                                        If you suspect that your username or password has been shared among others,
                                        you can change token here
                                        <span (click)="clear_token()" class="cursor here">from here</span>
                                        and immediately block their access.
                                    </span>
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ tab6 }}">
                <div class="example-large-box mat-elevation-z4">
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                        <div class="row">
                            <div class='col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pe div_panding marginTop5'>
                                <label for="instagram" class='wh-label'>
                                    <span>اینستاگرام</span>
                                    <input class='wh-input direction_ltr' formControlName='instagram' autocomplete="off"
                                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)">
                                </label>
                            </div>

                            <div class='col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pe div_panding marginTop5'>
                                <label for="gmap" class='wh-label'>
                                    <span>گوگل مپ</span>
                                    <input type="text" class='wh-input direction_ltr' formControlName='gmap'
                                        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ tab2 }}">
                <div class="example-large-box mat-elevation-z4">
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                        <div class="row">
                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="user" class='wh-label'>
                                    <span *ngIf="lang == 1">نام کاربری</span>
                                    <span *ngIf="lang == 2">Username</span>
                                    <input class='wh-input' formControlName='user' autocomplete="off" pattern="^\S+$">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [hidden]="type_task == 3" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="password" class='wh-label'>
                                    <span *ngIf="lang == 1">رمز عبور</span>
                                    <span *ngIf="lang == 2">Password</span>
                                    <input type="text" class='wh-input' formControlName='password'
                                        [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="phone" class='wh-label'>
                                    <span class="input-info" [matMenuTriggerFor]="menuPhone"><i
                                            class="fas fa-info-circle icon"></i></span>
                                    <span class="input-info-text">
                                        <span *ngIf="lang == 1">شماره ثابت</span>
                                        <span *ngIf="lang == 2">Landline number</span>
                                    </span>
                                    <input class='wh-input' formControlName="phone" autocomplete="off"
                                        [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>
                            <mat-menu #menuPhone="matMenu" class="input-mat-menu-panel" XPosition="after">
                                <span *ngIf="lang == 1">کاراکترهای مجاز فقط اعداد و یا کاراکتر -</span>
                                <span *ngIf="lang == 2">Only numbers and characters are allowed -</span>
                            </mat-menu>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="email" class='wh-label'>
                                    <span *ngIf="lang == 1"> پست الکترونیک</span>
                                    <span *ngIf="lang == 2">Email</span>
                                    <input class='wh-input' formControlName='email' autocomplete="off"
                                        [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [hidden]="level == 3" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="code_meli" class='wh-label'>
                                    <span *ngIf="lang == 1">کد ملی / شناسه </span>
                                    <span *ngIf="lang == 2">National ID number / ID</span>
                                    <input class='wh-input' formControlName='code_meli' autocomplete="off"
                                        (keypress)="serverService.get_number($event)" length="11" maxlength="11"
                                        [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [hidden]="level == 3" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="code_posti" class='wh-label'>
                                    <span *ngIf="lang == 1"> کد پستی </span>
                                    <span *ngIf="lang == 2">Postal code</span>
                                    <input class='wh-input' formControlName='code_posti' autocomplete="off"
                                        (keypress)="serverService.get_number($event)" length="10" maxlength="10"
                                        minlength="10" [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [hidden]="level == 3" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="economic_code" class='wh-label'>
                                    <span *ngIf="lang == 1"> کد اقتصادی </span>
                                    <span *ngIf="lang == 2">Economic code</span>
                                    <input class='wh-input' formControlName='economic_code' autocomplete="off"
                                        (keypress)="serverService.get_number($event)" length="12" maxlength="12"
                                        [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [hidden]="level == 3" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="economic_code" class='wh-label'>
                                    <span *ngIf="lang == 1"> ارزش افزوده </span>
                                    <span *ngIf="lang == 2">Value added</span>
                                    <input class='wh-input' formControlName='tax' autocomplete="off"
                                        (keypress)="serverService.get_number($event)" minlength="0" maxlength="3"
                                        length="3" min="0" max="100" [required]="required()"
                                        [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [hidden]="level != 1" [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="price_dollar" class='wh-label'>
                                    <span *ngIf="lang == 1"> قیمت دلار به ریال </span>
                                    <span *ngIf="lang == 2">Dollar to Rial rate</span>
                                    <input class='wh-input' formControlName='price_dollar' autocomplete="off"
                                        currencyMask [required]="required() && level == 1"
                                        [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}"
                                [hidden]=" (ref == 'menu' && level != 2) || (code == 161 && level != 1)  || level == 1">
                                <label for="profit_sale" class='wh-label'>
                                    <span class="input-info"><i class="fas fa-info-circle icon"></i></span>
                                    <span class="input-info-text">
                                        <span *ngIf="lang == 1"> درصد سود فروش </span>
                                        <span *ngIf="lang == 2">Sales profit commission</span>
                                    </span>
                                    <input class='wh-input' formControlName='profit_sale' maxlength="3" min=0 max=100
                                        [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                            <div class='col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                [ngClass]="{'full-left':dir == 'ltr'}"
                                [hidden]=" (ref == 'menu' && level != 2) || (code == 161 && level != 1) || level == 1">
                                <label for="profit_repairman" class='wh-label'>
                                    <span class="input-info"><i class="fas fa-info-circle icon"></i></span>
                                    <span class="input-info-text">
                                        <span *ngIf="lang == 1"> درصد سود تعمیرات </span>
                                        <span *ngIf="lang == 2">Repair profit commission</span>
                                    </span>
                                    <input class='wh-input' formControlName='profit_repairman' maxlength="3" min=0
                                        max=100 [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ tab3 }}">
                <div class="example-large-box mat-elevation-z4">
                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                        <div class="row">
                            <div class='col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-12 pe'>
                                <div class='wh-label logo_title'> آواتار من </div>
                                <div *ngIf="logo_loading == true" class="upload_div_loading">
                                    <div class="spinner-border spinner-border-sm upload_spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                                <a (click)="open_logo_avatar()" class="cursor">
                                    <div class="upload_div_file" *ngIf="logo_type == 'pdf' && logo_loading == false">
                                        <i class="far fa-file-pdf upload_div_file_icon" style="color: red;"></i>
                                    </div>
                                    <mat-card class="example-card logo cursor backgroundImage upload_div_file2"
                                        *ngIf="logo_type != 'pdf' && logo_loading == false"
                                        [ngStyle]="{'background-image':'url( ' + logo + ')'}" accept="image/*">
                                        <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
                                        </mat-card-header>
                                    </mat-card>
                                </a>
                                <span (click)="delete_logo_avatar()" *ngIf="logo_bin == true">
                                    <button type="button" class="upload_button_change">حذف</button>
                                </span>
                                <div class="upload_image_change cursor" (click)="logo3.click()">
                                    <button type="button" class="upload_button_change">آپلود</button>
                                </div>
                                <input type="file" (change)="change_avatar($event)" style="display: none;" #logo3
                                    accept="image/*,application/pdf">
                            </div><!-- col-md-6 col-lg-6 col-sm-6 col-xs-12 pe  -->

                            <div class='col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-12 pe'>
                                <div class='wh-label logo_title'> امضا </div>
                                <div *ngIf="sign_logo_loading == true" class="upload_div_loading">
                                    <div class="spinner-border spinner-border-sm upload_spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                                <a (click)="open_logo_avatar()" class="cursor">
                                    <div class="upload_div_file"
                                        *ngIf="logo_type == 'pdf' && sign_logo_loading == false">
                                        <i class="far fa-file-pdf upload_div_file_icon" style="color: red;"></i>
                                    </div>
                                    <mat-card class="example-card logo cursor backgroundImage upload_div_file2"
                                        *ngIf="logo_type != 'pdf' && sign_logo_loading == false"
                                        [ngStyle]="{'background-image':'url( ' + sign_logo + ')'}" accept="image/*">
                                        <mat-card-header style="display:inline" class="pe1">
                                        </mat-card-header>
                                    </mat-card>
                                </a>
                                <span (click)="delete_sign_logo()" *ngIf="sign_logo_bin == true">
                                    <button type="button" class="upload_button_change">حذف</button>
                                </span>
                                <div class="upload_image_change cursor" (click)="logo2.click()">
                                    <button type="button" class="upload_button_change">آپلود فایل</button>
                                </div>
                                <input type="file" (change)="change_sign_logo($event)" style="display: none;" #logo2
                                    accept="image/*,application/pdf">
                            </div><!-- col-md-6 col-lg-6 col-sm-6 col-xs-12 pe  -->

                        </div>
                        <div class="row">
                            <div class='col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-12 pe' style="padding:0 5px"
                                [hidden]="level == 3">
                                <div class='wh-label logo_title'> لوگوی شرکت </div>
                                <div *ngIf="logo1_loading == true" class="upload_div_loading">
                                    <div class="spinner-border spinner-border-sm upload_spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                                <a (click)="open_logo1()" class="cursor">
                                    <div class="upload_div_file" *ngIf="logo_type == 'pdf' && logo1_loading == false">
                                        <i class="far fa-file-pdf upload_div_file_icon" style="color: red;"></i>
                                    </div>
                                    <mat-card class="example-card logo cursor backgroundImage upload_div_file2"
                                        *ngIf="logo_type != 'pdf' && logo1_loading == false"
                                        [ngStyle]="{'background-image':'url( ' + logo1 + ')'}" accept="image/*">
                                        <mat-card-header style="display:inline" class="pe1">
                                        </mat-card-header>
                                    </mat-card>
                                </a>
                                <span (click)="delete_logo1()" *ngIf="logo1_bin == true">
                                    <button type="button" class="upload_button_change">حذف</button>
                                </span>
                                <div class="upload_image_change cursor" (click)="logos.click()">
                                    <button type="button" class="upload_button_change">آپلود</button>
                                </div>
                                <input type="file" (change)="change_logo1($event)" style="display: none;" #logos
                                    accept="image/*,application/pdf">
                            </div><!-- col-md-6 col-lg-6 col-sm-6 col-xs-12 pe  -->


                            <div class='col-md-6 col-lg-6 col-xl-6 col-sm-6 col-xs-12 pe' style="padding:0 5px"
                                [hidden]="level == 3">
                                <div class='wh-label logo_title'> مهر و امضای شرکت </div>
                                <div *ngIf="sign_logo2_loading == true" class="upload_div_loading">
                                    <div class="spinner-border spinner-border-sm upload_spinner-border-sm"
                                        role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                                <a (click)="open_sign_logo2()" class="cursor">
                                    <div class="upload_div_file"
                                        *ngIf="logo_type == 'pdf' && sign_logo2_loading == false">
                                        <i class="far fa-file-pdf upload_div_file_icon" style="color: red;"></i>
                                    </div>
                                    <mat-card class="example-card logo cursor backgroundImage upload_div_file2"
                                        *ngIf="logo_type != 'pdf' && sign_logo2_loading == false"
                                        [ngStyle]="{'background-image':'url( ' + sign_logo2 + ')'}" accept="image/*">
                                        <mat-card-header style="display:inline" class="pe1">
                                        </mat-card-header>
                                    </mat-card>
                                </a>
                                <span (click)="delete_sign_logo2()" *ngIf="sign_logo2_bin == true">
                                    <button type="button" class="upload_button_change">حذف</button>
                                </span>
                                <div class="upload_image_change cursor" (click)="logos3.click()">
                                    <button type="button" class="upload_button_change">آپلود</button>
                                </div>
                                <input type="file" (change)="change_sign_logo2($event)" style="display: none;" #logos3
                                    accept="image/*,application/pdf">
                            </div><!-- col-md-4 col-lg-4 col-sm-4 col-xs-12 pe  -->

                            <div class="row">
                                <div class='ccol-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                    [ngClass]="{'full-left':lang==2}" [hidden]="level == 3">
                                    <label for="width_logo" class='wh-label'>
                                        <span *ngIf="lang == 1">پهنای لوگو</span>
                                        <span *ngIf="lang == 2">Logo width</span>
                                        <input class='wh-input' formControlName="width_logo" autocomplete="off"
                                            (keypress)="serverService.get_number($event)" required
                                            [ngClass]="{'en':lang==2}">
                                    </label>
                                </div>

                                <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                    [ngClass]="{'full-left':lang==2}" [hidden]="level == 3">
                                    <label for="height_logo" class='wh-label'>
                                        <span *ngIf="lang == 1">ارتفاع لوگو</span>
                                        <span *ngIf="lang == 2">Logo height</span>
                                        <input class='wh-input' formControlName="height_logo" autocomplete="off"
                                            (keypress)="serverService.get_number($event)" required
                                            [ngClass]="{'en':lang==2}">
                                    </label>
                                </div>

                                <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding marginTop5'
                                    [ngClass]="{'full-left':lang==2}" [hidden]="true">
                                    <label for="bg_logo" class='wh-label'>
                                        <div *ngIf="lang == 1"> رنگ پس زمینه لوگو </div>
                                        <div *ngIf="lang == 2"> Logo background color </div>
                                        <input type="color" class='wh-input' formControlName="bg_logo"
                                            autocomplete="off" [ngClass]="{'en':lang==2}">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ tab4 }}" *ngIf="level == 2">
                <div class="example-large-box mat-elevation-z4">
                    <div class="row" style="padding:1rem">
                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                            [ngClass]="{en:lang==2}" style="margin-top:0px !important;">

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="sms" class=' wh-label'>
                                    <span *ngIf="lang == 1"> ارسال پیامک در پذیرش جدید </span>
                                    <span *ngIf="lang == 2"> Send SMS when receiving new devices </span>
                                    <mat-checkbox [ngStyle]="{float:dir == 'ltr' ? 'left' : 'right'}"
                                        formControlName="sms" autocomplete="off"
                                        [ngClass]="{'en':dir == 'ltr'}"></mat-checkbox>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="sms2" class=' wh-label'>
                                    <span *ngIf="lang == 1"> ارسال پیامک درتغییر وضعیت </span>
                                    <span *ngIf="lang == 2"> Send SMS when device's status changes </span>
                                    <mat-checkbox [ngStyle]="{float:dir == 'ltr' ? 'left' : 'right'}"
                                        formControlName="sms2" autocomplete="off"
                                        [ngClass]="{'en':dir == 'ltr'}"></mat-checkbox>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="sms_invoice_buy" class=' wh-label'>
                                    <span *ngIf="lang == 1"> ارسال پیامک برای صدور فاکتور خرید </span>
                                    <span *ngIf="lang == 2"> Send SMS when issuing purchase invoice </span>
                                    <mat-checkbox [ngStyle]="{float:dir == 'ltr' ? 'left' : 'right'}"
                                        formControlName="sms_invoice_buy" autocomplete="off"
                                        [ngClass]="{'en':dir == 'ltr'}"></mat-checkbox>
                                </label>
                            </div>

                            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                                [ngClass]="{'full-left':dir == 'ltr'}">
                                <label for="sms_invoice_sale" class=' wh-label'>
                                    <span *ngIf="lang == 1"> ارسال پیامک برای صدور فاکتور فروش </span>
                                    <span *ngIf="lang == 2"> Send SMS when issuing sales invoice </span>
                                    <mat-checkbox [ngStyle]="{float:dir == 'ltr' ? 'left' : 'right'}"
                                        formControlName="sms_invoice_sale" autocomplete="off"
                                        [ngClass]="{'en':dir == 'ltr'}"></mat-checkbox>
                                </label>
                            </div>


                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ tab5 }}" *ngIf="level == 2">
                <div class="example-large-box mat-elevation-z4">
                    <div class="row" style="padding:1rem">
                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                            style="margin-top:0px !important;" [ngClass]="{'full-left':dir == 'ltr'}">
                            <label for="rule_site" class='wh-label'>
                                <span *ngIf="lang == 1"> قرارداد پذیرش </span>
                                <span *ngIf="lang == 2"> Reception contract</span>
                                <ejs-richtexteditor class="pe1 richtexteditor"
                                    [ngClass]="{'richtexteditor_en' : dir == 'ltr'}"
                                    [ngStyle]="{direction:dir == 'ltr' ? 'ltr' : 'rtl'}" [toolbarSettings]='tools'
                                    formControlName="rule_site">
                                </ejs-richtexteditor>
                            </label>
                            <!-- <div class="char_number">{{ rule_site_numbers }}/500</div> -->
                        </div>

                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                            [ngClass]="{'full-left':dir == 'ltr'}" style="margin-top:0px !important">
                            <label for="rule_delivery" class='wh-label'>
                                <span *ngIf="lang == 1"> آماده تحویل</span>
                                <span *ngIf="lang == 2"> Ready for delivery </span>
                                <ejs-richtexteditor class="pe1 richtexteditor" [toolbarSettings]='tools'
                                    [ngStyle]="{direction:dir == 'ltr' ? 'ltr' : 'rtl'}" formControlName="rule_delivery"
                                    [ngClass]="{'richtexteditor_en' : dir == 'ltr'}">
                                </ejs-richtexteditor>
                            </label>
                        </div>

                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                            style="margin-top:0px !important" [ngClass]="{'full-left':dir == 'ltr'}">
                            <label for="rule_invoice_buy" class='wh-label'>
                                <span *ngIf="lang == 1"> قرارداد فاکتور خرید </span>
                                <span *ngIf="lang == 2"> Purchase invoice contract </span>
                                <ejs-richtexteditor class="pe1 richtexteditor" [toolbarSettings]='tools'
                                    [ngStyle]="{direction:dir == 'ltr' ? 'ltr' : 'rtl'}"
                                    formControlName="rule_invoice_buy" [ngClass]="{'richtexteditor_en' : dir == 'ltr'}">
                                </ejs-richtexteditor>
                            </label>
                        </div>

                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                            style="margin-top:0px !important" [ngClass]="{'full-left':dir == 'ltr'}">
                            <label for="rule_invoice_sale" class='wh-label'>
                                <span *ngIf="lang == 1"> قرارداد فاکتور فروش </span>
                                <span *ngIf="lang == 2"> Sales invoice contract </span>
                                <ejs-richtexteditor class="pe1 richtexteditor" [toolbarSettings]='tools'
                                    [ngStyle]="{direction:dir == 'ltr' ? 'ltr' : 'rtl'}"
                                    formControlName="rule_invoice_sale"
                                    [ngClass]="{'richtexteditor_en' : dir == 'ltr'}">
                                </ejs-richtexteditor>
                            </label>
                        </div>

                        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [hidden]="true"
                            [ngStyle]="{direction:dir == 'ltr' ? 'ltr' : 'rtl'}">
                            <label for="wharehouse" class=' wh-label'>
                                <span>قوانین را در
                                    <span (click)="open_content(1)" class="cursor" style="color:blue">اینجا</span>
                                    خواندم و قبول میکنم و میخواهم در انبار یکپارچه عضو شوم.
                                </span>
                                <mat-checkbox style="float: right;" formControlName="wharehouse"
                                    autocomplete="off"></mat-checkbox>
                            </label>
                        </div>
                    </div>
                </div>
            </mat-tab>


        </mat-tab-group>
        <!-- ************************************************************************************* -->
        <!--- ماژول ها -->

    </div> <!-- content -->


    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 1">ذخیره</span>
            <span *ngIf="lang == 2">Save</span>
        </button>
    </div><!-- row -->
</form>