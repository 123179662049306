<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        {{ serverService.get_title(type_task,lang) }}
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe1 div_panding'
                [ngClass]="{width100:type_task == 3,'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان </span>
                    <span *ngIf="lang == 2"> Title </span>
                    <input class="wh-input" formControlName="title" [required]="required()">
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe1 div_panding'
                [ngClass]="{width100:type_task == 3,'full-left':dir == 'ltr'}">
                <label for="title2" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان انگلیسی </span>
                    <span *ngIf="lang == 2"> English title </span>
                    <input class="wh-input" formControlName="title2" [required]="required()">
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe1 div_panding'
                [ngClass]="{width100:type_task == 3,'full-left':dir == 'ltr'}" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب </span>
                    <span *ngIf="lang == 2"> Sort </span>
                    <input class='wh-input' formControlName="sort" (keyup.enter)="check_access(type_task,1)"
                        [ngClass]="{'en':dir == 'ltr'}" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe1 div_panding'
                [ngClass]="{width100:type_task == 3,'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت نمایش </span>
                    <span *ngIf="lang == 2"> Display status </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>
        </div>
        <div class="row pe1" [ngClass]="{en1:lang == 2}" [hidden]="type_task == 3">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe padding0"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <span *ngIf="lang == 1">لطفا در پایین دستگاههای این برند را انتخاب نمائید:</span>
                <span *ngIf="lang == 2">Please select devices of this brand.</span>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe padding0" [ngClass]="{en:lang == 2}"
                *ngFor="let x of list_device">
                <label class="wh-label">
                    <mat-checkbox class="pe1" [ngClass]="{en1:lang == 2}" name="device[]"
                        value="{{ x.wharehouse_device_id }}" (change)="select_items()" [checked]="x.checked">
                        <span>{{ x.wharehouse_device_title }}</span>
                    </mat-checkbox>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="start" class="pe1 matDialogActions" [ngClass]="{en1:lang==2}"
        style="padding-right:25px">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang==2}" [disabled]="!form1_group.valid"
            *ngIf="type_task != 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div><!-- row -->
</form>