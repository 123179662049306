<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        درج جدید
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding' [hidden]="material_type == 2">
                <label for="group" class='wh-label'>
                    <span> گروه </span>
                    <span (click)="add_group()" class="add_icon fleft">
                        <i class="fas fa-bookmark cursor"></i>
                    </span>
                    <select class="wh-input" formControlName="group" (change)="get_cate()"
                        [required]="material_type == 1">
                        <option value=""></option>
                        <option *ngFor="let x of list_group" [value]="x.wharehouse_group_id">
                            {{ x.wharehouse_group_title }}
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding' [hidden]="material_type == 2">
                <label for="cate" class='wh-label'>
                    <span> زیر گروه </span>
                    <span (click)="add_cate()" class="add_icon fleft">
                        <i class="fas fa-bookmark cursor"></i>
                    </span>
                    <select class="wh-input" formControlName="cate" [required]="material_type == 1">
                        <option value=""></option>
                        <option *ngFor="let x of list_cate" [value]="x.wharehouse_material_cate_id">
                            {{ x.wharehouse_material_cate_title }}
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="title" class='wh-label'>
                    <span *ngIf="material_type == 1">
                        <span> عنوان کالا </span>
                    </span>
                    <span *ngIf="material_type == 2">
                        <span> عنوان خدمات </span>
                    </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" [required]="true">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding' [hidden]="material_type == 2">
                <label for="inventory" class='wh-label'>
                    <span> موجودی اول دوره </span>
                    <input class='wh-input' formControlName="inventory" autocomplete="off"
                        [required]="material_type == 1">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding' [hidden]="pending == 1">
                <label for="cost" class='wh-label'>
                    <span> قیمت </span>
                    <span *ngIf="material_type == 1">
                        <span> خرید </span>
                    </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'
                [hidden]="material_type == 2 || pending == 1">
                <label for="price2" class='wh-label'>
                    <span> قیمت فروش </span>
                    <input class='wh-input' formControlName="price2" autocomplete="off" currencyMask
                        [required]="material_type == 1">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding' [hidden]="material_type == 2">
                <label for="coding" class='wh-label'>
                    <span> کد کالا </span>
                    <input class='wh-input' formControlName="coding" autocomplete="off">
                </label>
            </div>

        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" (click)="save('close')">
            <span>ذخیره و بستن</span>
        </button>
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" (click)="save('new')"
            [hidden]="ref == 'reception' ">
            <span>ذخیره و جدید</span>
        </button>
    </div>
</form>