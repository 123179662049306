import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import { BankAccountsComponent } from '../../bank/bank-accounts/accounts-list/bank-accounts.component';

@Component({
  selector: 'app-cheque-filter',
  templateUrl: './cheque-filter.component.html',
  styleUrls: ['./cheque-filter.component.scss']
})
export class ChequeFilterComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public loading = false;
  public subscription: Subscription;
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public code: number | undefined;
  public search_array: any = [];
  public document_type: number | undefined;
  public result: any;
  public creator: number | undefined;
  public token: any;
  public expire: boolean | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public financial_id: number;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<ChequeFilterComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.creator = dialog_data.creator;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
    }
    if (this.type_task == 3) {
      if (this.dialog_data) this.creator = this.dialog_data.creator;
      this.form1_group.patchValue({
        title: dialog_data.search_array.title,
        amount: dialog_data.search_array.amount,
        bank: dialog_data.search_array.bank,
        bank_title: dialog_data.search_array.bank_title,
        account: dialog_data.search_array.account,
        account_title: dialog_data.search_array.account_title,
        number: dialog_data.search_array.number,
        due: dialog_data.search_array.due,
        issue_date: dialog_data.search_array.issue_date,
        status_id: dialog_data.search_array.status_id,
        status_title: dialog_data.search_array.status_title,
      })
    }
  }//end consructor
  search() {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      amount: this.form1_group.value.amount,
      bank: this.form1_group.value.bank,
      bank_title: this.form1_group.value.bank_title,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      account: this.form1_group.value.account,
      account_title: this.form1_group.value.account_title,
      number: this.form1_group.value.number,
      due: this.form1_group.value.due,
      issue_date: this.form1_group.value.issue_date,
    })
  }
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'number': new FormControl(null, [Validators.pattern('[0-9/-]{1,}')]),
      'title': new FormControl(null),
      'issue_date': new FormControl(null),
      'due': new FormControl(null),
      'amount': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'bank_title': new FormControl(null),
      'bank': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'account_title': new FormControl(null),
      'account': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          //this.loading = false;
          if (type_task == 1) { }
          if (type_task == 2) { }
          if (type_task == 3) { this.search(); }
          if (type_task == 4) { }
          if (type_task == 5) { }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  select_account(): any {
    if (!this.form1_group.value.bank) {
      var pe_message = "لطفا در ابتدا بانک خود را انتخاب نمائید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialog_data = this.dialog.open(BankAccountsComponent, {
      width: '55rem',
      height: 'auto',
      data: { ref: 'cheque', id: this.form1_group.value.bank, code: this.code }
    });
    dialog_data.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'account_title': res.number,
            'account': res.id,
          });
        }
      }
    )
  }
  reset_account(type: number) {
    this.form1_group.patchValue({
      'account_title': null,
      'account': null,
    })
  }
  load_services(id: number): any {
    var code_id;
    if (id == 190) {
      code_id = 6805;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 178) {
            this.form1_group.patchValue({
              'bank': res.id,
              'bank_title': res.title
            })
          }
          if (id == 190) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (id == 178) {
      this.form1_group.patchValue({
        'bank': null,
        'bank_title': null
      })
    }
    if (id == 190) {
      this.form1_group.patchValue({
        'status_id': null,
        'status_title': null
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


