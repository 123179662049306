import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MenuItemsComponent } from '../menu-items/menu-items.component';


@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, OnDestroy {
  //**********************************************************
  public username: string | undefined;
  user_info: any | undefined;
  user_id: number | undefined;
  public server: string | undefined;
  public lang: any | undefined;
  public loading = false;
  public subscription: Subscription | undefined;
  public limit_forms: boolean | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined;
  public page_number_second: number | undefined; public page: number = 1;
  public code: any | undefined;
  sort: string | undefined;

  tab_id: any | undefined;
  tab_i: any | undefined;
  tab_status: any | undefined;

  services_id: any | undefined;
  services_title: any | undefined;
  services_i: any | undefined;
  services_status: any | undefined;
  services_type: any | undefined;
  event: any | undefined;

  section_id: any | undefined;
  section_i: any | undefined;
  section_status: any | undefined;
  section_title: any | undefined;
  section_tab: any | undefined;

  menu_id: any | undefined;
  menu_i: any | undefined;

  public type: number | undefined; public type_search: number | undefined;
  public pattern_title_with_persian: any | undefined;
  //******************************************************************************
  public obj = {};
  public data = {};
  public list_tab: any = [];
  list_section: any = [];
  list_services: any = [];
  list_services_selected: any = [];
  public list_status: any = [];
  error: boolean = false;

  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined; public status_id_search: number | undefined;
  public title: string | undefined; public title_search: string | undefined;
  //************
  @ViewChild('myDialog', { 'static': true }) myDialog: ElementRef<any> | any;
  //***********
  public mat_table_selectedRow: any | undefined;
  public mat_table_hoverRow: any | undefined;
  dataSource: any | undefined;
  displayedColumns = ['row', 'title', 'sort', 'status', 'id', 'operation'];
  //************* 
  form1_group: FormGroup | any;
  form1_search_group: FormGroup | any;
  //************** */
  //************** */
  constructor(public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , private matDialogRef: MatDialogRef<TabComponent>
    , private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )

    this.serverService.status1(2);
    this.server = this.serverService.get_server();
    this.lang = JSON.parse(<any>localStorage.getItem('lang'));
    this.user_info = JSON.parse(<any>localStorage.getItem('user_info'));

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
      this.username = this.user_info.username;
    }

    this.create_form1();
    this.create_search_form1();
    this.check_access(4);
    //**************
  }//end ngOnInit




  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, [Validators.required]),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_id': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,}')])
    })
  }

  create_search_form1() {
    this.form1_search_group = new FormGroup({
      'title_search': new FormControl(null),
      'status_id_search': new FormControl(null)
    })
  }


  drop_tab(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.save_sort_tab(event.container.data);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex, event.currentIndex);
    }
  }

  save_sort_tab(list: any): any {
    var obj = {
      address: 1022,
      list: list,
      user_id: this.user_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.recieve_message(false, "", "", 1, "", "");
          this.save_sort_tab2(list);
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  save_sort_tab2(list: any): any {
    var obj = {
      address: 1059,
      software_id: this.serverService.get_software_id(),
      list: list,
      user_id: this.user_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_tab({ data: list }, 'sort');
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  //****************************************************************************
  check_access(type_task: number): any {
    if (this.error == true) {
      var pe_message = "خطایی در سیستم وجود دارد";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }//end if 
    else { this.matSnackBar.dismiss(); }
    //this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: 1 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
            return false;
          }
          else {
            if (type_task == 4) this.get_tab();
            return true;
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          return false;
        }
      }
    )
  }

  get_tab(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1006, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_tab = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_tab.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
          //this.get_section('');
        }//end if
        else {
          this.error = true;
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data


  select_tab(i: number, id: any, status: number) {
    this.tab_id = id;
    this.tab_i = i;
    this.tab_status = status;
    this.section_id = '';
    this.services_id = '';
    this.list_services_selected = [];
    if (status == 1) {
      this.serverService.send_tab(id, 'select');
    }
    this.get_section(id);
  }
  //***************************************************************************
  insert_tab(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1007, "user_id": this.user_id, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.list_tab.push(res['result'][0]);
          this.tab_id = res['result'][0].site_tab_id;
          this.tab_i = this.list_tab.length - 1;
          this.tab_status = res['result'][0].site_tab_status;
          this.list_section = [];
          this.serverService.send_tab(res['result'][0], 'insert')
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در ایجاد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end insert_tab

  update_tab(id: number, event: any): any {
    //this.loading = true;
    var obj = {
      'address': 1008
      , "user_id": this.user_id
      , 'title': event.target.value
      , 'id': id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_tab({ title: res['result'][0].site_tab_title, id: res['result'][0].site_tab_id }, 'update');
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end update_tab

  change_tab_publish(status: number, id: number, i: number): any {
    this.loading = true;
    var obj = { address: 1052, status_id: status, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_tab[i].site_tab_status = res['result'][0].site_tab_status;
          this.serverService.send_tab(res['result'][0], 'status');
          if (res['result'][0].site_tab_status == 1) {
            this.serverService.send_tab(res['result'][0].site_tab_id, 'select');
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  delete_tab(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1009, "user_id": this.user_id, 'id': this.tab_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_tab.splice(this.tab_i, 1);
          this.serverService.send_tab(this.tab_id, 'delete');
          this.tab_id = "";
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "در این تب یک دسته بندی وجود دارد . قادر به حذف نیستید";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }//end if
        else {
          var pe_message = "خطا در حذف";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }//end else
      }//end res:Response
    )//end this.subscription
  }//end delete

  contextmenu_tab(i: number, id: number, status: number, event: any) {
    const droppedElement = this.myDialog.nativeElement.parentNode;
    var { top, left } = droppedElement.getBoundingClientRect();
    this.tab_id = id;
    this.tab_i = i;
    this.tab_status = status;
    event.preventDefault();
    var contextElement = <any>document.getElementById("context-menu-tab");
    contextElement.style.top = event.clientY - top + "px";
    contextElement.style.left = event.clientX - left + "px";
    contextElement.classList.add("active");

    var x = <any>document.getElementById("context-menu-section");
    x.classList.remove("active");
  }

  close_contextmenu() {
    if (document.getElementById("context-menu-tab")) {
      var x = <any>document.getElementById("context-menu-tab");
      x.classList.remove("active");
    }

    if (document.getElementById("context-menu-section")) {
      var x = <any>document.getElementById("context-menu-section");
      x.classList.remove("active");
    }

    if (document.getElementById("context-menu-services")) {
      var x = <any>document.getElementById("context-menu-services");
      x.classList.remove("active");
    }
  }

  //*******************************************************************************
  get_section(id: string): any {
    //this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1011, id: id, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        this.list_section = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_section.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
          this.get_services('0');
          //this.get_services_selected('tab', '0');
        }//end if
        else {
          this.error = true;
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  insert_section(): any {
    if (!this.tab_id) {
      var pe_message = "ابتدا یک تب را به حالت انتخاب در آورید";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1012, "user_id": this.user_id, tab_id: this.tab_id, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.list_section.push(res['result'][0]);
          this.section_id = res['result'][0].site_section_id;
          this.section_status = res['result'][0].site_section_status;
          this.list_services_selected = [];
          this.serverService.send_section({ data: res['result'][0], tab_id: this.tab_id }, 'insert');
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در ایجاد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end insert_tab

  update_section(id: number, event: any): any {
    //this.loading = true;
    var obj = {
      'address': 1013
      , "user_id": this.user_id
      , 'title': event.target.value
      , 'id': id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_section({ title: res['result'][0].site_section_title, id: res['result'][0].site_section_id }, 'update');
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end update_section



  delete_section(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1014, "user_id": this.user_id, 'id': this.section_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_section.splice(this.section_i, 1);
          this.serverService.send_section({ section_id: this.section_id, tab_id: this.section_tab }, 'delete');
          this.section_id = "";
          this.recieve_message(false, "", "", 1, "", "");
        }
        else if (res['status'] == 4) {
          var pe_message = "در این دسته بندی سرویس هایی وجود دارد . قادر به حذف نیستید";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در حذف";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }//end else
      }//end res:Response
    )//end this.subscription
  }//end delete section

  select_section(i: number, id: any, status: number, tab: number) {
    this.tab_id = tab;
    this.section_id = id;
    this.services_id = '';
    this.section_i = i;
    this.section_status = status;
    if (status == 1) {
      this.serverService.send_tab(this.tab_id, 'select');
    }
    this.get_services_selected('section', id);
    this.get_services(id);
  }

  contextmenu_section(i: number, id: number, title: string, status: number, tab: number, event: any) {
    const droppedElement = this.myDialog.nativeElement.parentNode;
    var { top, left } = droppedElement.getBoundingClientRect();
    this.tab_id = tab;
    this.section_id = id;
    this.section_title = title;
    this.section_tab = tab;
    this.section_i = i;
    this.section_status = status;
    event.preventDefault();
    var contextElement = <any>document.getElementById("context-menu-section");
    contextElement.style.top = event.clientY - top + "px";
    contextElement.style.left = event.clientX - left + "px";
    contextElement.classList.add("active");
    var x = <any>document.getElementById("context-menu-tab");
    x.classList.remove("active");
  }

  change_section_publish(status: number, i: number): any {
    this.loading = true;
    var obj = { address: 1051, status_id: status, id: this.section_id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.list_section[i].site_section_status = res['result'][0].site_section_status;
            this.serverService.send_section({ data: res['result'][0], tab_id: this.section_tab }, 'status');
            if (res['result'][0].site_section_status == 1) {
              this.serverService.send_section(res['result'][0].site_section_id, 'select');
            }
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  drop_section(event: CdkDragDrop<string[]>): any {
    if (!this.tab_id) {
      var pe_message = "لطفا در ابتدا یک تب را انتخاب کنید";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
      return false;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.save_sort_section(event);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex, event.currentIndex);
    }
  }

  save_sort_section(event: any): any {
    var obj = {
      address: 1022,
      list: event.container.data,
      user_id: this.user_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.recieve_message(false, "", "", 1, "", "");
          this.save_sort_section2(event);
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  save_sort_section2(event: any): any {
    var obj = {
      address: 1060,
      software_id: this.serverService.get_software_id(),
      list: event.container.data,
      user_id: this.user_id,
      tab: this.tab_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_section({ data: event.container.data, tab_id: this.tab_id }, 'sort')
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }
  //*******************************************************************************
  get_services_selected(type: string, id: string): any {
    //this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1050, section: id, type: type, tab_id: this.tab_id }).subscribe(
      (res: any) => {
        this.list_services_selected = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_services_selected.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          this.error = true;
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  drop_services_selected(event: CdkDragDrop<string[]>): any {
    if (!this.section_id) {
      var pe_message = "لطفا در ابتدا یک دسته بندی را انتخاب کنید";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
      return false;
    }

    this.event = event;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.sort_services_selected();
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.services_adding_to_section();
    }
  }

  services_adding_to_section(): any {
    var obj = {
      address: 1057,
      software_id: this.serverService.get_software_id(),
      service_id: this.event.container.data[this.event.currentIndex].site_services_id,
      section_id: this.section_id,
      tab_id: this.tab_id
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.event.container.data[this.event.currentIndex].site_menu_id = res['result'][0].site_menu_id;
          this.event.container.data[this.event.currentIndex].site_menu_section = res['result'][0].site_menu_section;
          this.event.container.data[this.event.currentIndex].site_menu_sort = res['result'][0].site_menu_sort;
          this.event.container.data[this.event.currentIndex].id = res['result'][0].site_menu_id;

          this.list_services_selected[this.event.currentIndex].site_menu_id = res['result'][0].site_menu_id;
          this.list_services_selected[this.event.currentIndex].site_menu_section = res['result'][0].site_menu_section;
          this.list_services_selected[this.event.currentIndex].site_menu_sort = res['result'][0].site_menu_sort;
          this.list_services_selected[this.event.currentIndex].id = res['result'][0].site_menu_id;

          this.sort_services_selected();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  sort_services_selected(): any {
    this.loading = true;
    var obj = {
      address: 1022,
      section: this.section_id,
      id: this.event.container.data[this.event.currentIndex].site_menu_id,
      sort: this.event.currentIndex,
      list: this.event.container.data
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.sort_services_selected2();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  sort_services_selected2(): any {
    this.loading = true;
    var obj = {
      address: 1023,
      section: this.section_id,
      list: this.event.container.data
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_menu({ data: this.event.container.data, section_id: this.section_id }, 'insert');

          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  //*******************************************************************************
  get_services(id: string): any {
    //this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1015, section: id, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        this.list_services = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_services.push(res['result'][i]);
          }//end for
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  select_services(type: string, i: number, id: any, title: string, status: number, section_id: number, menu_id: number) {
    this.services_id = id;
    this.services_title = title;
    this.services_status = status;
    this.services_i = i;
    if (type == 'selected') {
      this.section_id = section_id;
      this.menu_id = menu_id;
    }


  }

  insert_service(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1053, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.list_services.push(res['result'][0]);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در ایجاد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update_service(type: string, id: number, status: number, event: any): any {
    var obj = { address: 1054, user_id: this.user_id, id: id, title: event.target.value }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (type == 'selected') {
            this.list_services_selected[this.services_i].site_services_title = res['result'][0].site_services_title;
            this.serverService.send_menu({ title: res['result'][0].site_services_title, id: res['result'][0].site_services_id, section_id: this.section_id }, 'update');
          } else {
            this.list_services[this.services_i].site_services_title = res['result'][0].site_services_title;
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در ایجاد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  change_services_publish(status: number, i: number): any {
    this.loading = true;
    var obj = { address: 1055, status_id: status, id: this.services_id, menu_id: this.menu_id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (this.services_type == 'selected') {
              this.list_services_selected[i].site_services_status = res['result'][0].site_services_status;
              this.serverService.send_menu({ data: res['result'][0], section_id: this.section_id, i: i }, 'status')
            } else {
              this.list_services[i].site_services_status = res['result'][0].site_services_status;
            }
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  contextmenu_services(type: string, i: number, id: number, title: string, status: number, section_id: number, menu_id: number, event: any) {
    const droppedElement = this.myDialog.nativeElement.parentNode;
    var { top, left } = droppedElement.getBoundingClientRect();
    this.services_id = id;
    this.services_title = title;
    this.services_i = i;
    this.services_status = status;
    this.services_type = type;
    if (type == 'selected') {
      this.section_id = section_id;
      this.menu_id = menu_id;
    }
    event.preventDefault();
    var contextElement = <any>document.getElementById("context-menu-services");
    contextElement.style.top = event.clientY - top + "px";
    contextElement.style.left = event.clientX - left + "px";
    contextElement.classList.add("active");

    var tab = <any>document.getElementById("context-menu-tab");
    tab.classList.remove("active");
    var section = <any>document.getElementById("context-menu-section");
    section.classList.remove("active");
  }

  delete_services(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1056, "user_id": this.user_id, 'id': this.services_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_services.splice(this.services_i, 1);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در حذف";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }//end else
      }//end res:Response
    )//end this.subscription
  }

  change_service_image(): any {
    const dialogRef = this.dialog.open(MenuItemsComponent, {
      width: '40rem',
      height: 'auto',
      data: { id: this.services_id, title: this.services_title }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {

          if (this.services_type == 'all') {
            if (res.result.site_services_img) {
              this.list_services[this.services_i].site_services_site = res.result.site_services_site;
              this.list_services[this.services_i].site_services_img = res.result.site_services_img;
              this.list_services[this.services_i].src = res.result.site_services_site + "/" + res.result.site_services_img;
            } else {
              this.list_services[this.services_i].site_services_img = "";
              this.list_services[this.services_i].src = this.serverService.get_default_image();
            }
            this.list_services[this.services_i].site_services_status = res.result.site_services_status;
            this.list_services[this.services_i].site_services_title = res.result.site_services_title;
          }


          else {
            this.list_services_selected[this.services_i].site_services_status = res.result.site_services_status;
            this.list_services_selected[this.services_i].site_services_title = res.result.site_services_title;
            if (res.result.site_services_img) {
              this.list_services_selected[this.services_i].site_services_site = res.result.site_services_site;
              this.list_services_selected[this.services_i].site_services_img = res.result.site_services_img;
              this.list_services_selected[this.services_i].src = res.result.site_services_site + "/" + res.result.site_services_img;
            } else {
              this.list_services_selected[this.services_i].site_services_img = "";
              this.list_services_selected[this.services_i].src = this.serverService.get_default_image();
            }
          }

        }
      }
    )

  }
  //*******************************************************************************
  drop_services(event: CdkDragDrop<string[]>): any {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (!this.section_id) {
        var pe_message = "لطفا در ابتدا یک دسته بندی را انتخاب کنید";
        var pe_action = "بستن";
        this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        return false;
      }
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.services_removing_to_section(event);
    }
  }

  services_removing_to_section(event: any): any {
    var obj = {
      address: 1058,
      section_id: this.section_id,
      menu_id: event.container.data[event.currentIndex].site_menu_id,
      service_id: event.container.data[event.currentIndex].site_service_id
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_menu({ menu_id: event.container.data[event.currentIndex].site_menu_id, section_id: this.section_id }, 'remove');
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }




  //*******************************************************************************
  close() {
    this.matDialogRef.close();
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize2(2, '', 150);
  }//end change_resize
  //*******************************************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 



  ModalClose() {
    this.recieve_message(false, "", "", 2, "", "");
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}