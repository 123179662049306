<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>پرداخت چک</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" style="direction: rtl;">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="title" class='wh-label'>
                        <span> بابت</span>
                        <input class='wh-input' formControlName="title" autocomplete="off">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="amount" class='wh-label'>
                        <span> مبلغ چک </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="bank" class='wh-label'>
                        <span> بانک </span>
                        <span (click)="reset_input(178)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="bank_title" (click)="load_services(178) " required
                            readonly>
                        <input class="wh-input" formControlName="bank" [hidden]="true" required readonly>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="account_title" class='wh-label'>
                        <span> شماره حساب </span>
                        <span (click)="reset_account(1)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="account_title" (click)="select_account() " readonly
                            required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="number" class='wh-label'>
                        <span> شماره چک </span>
                        <input class="wh-input" formControlName="number" required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="due" class="wh-label">
                        <span>تاریخ سررسید</span>
                        <div style="width:100%">
                            <input class="wh-input" [matDatepicker]="picker2" formControlName="due"
                                [required]="required()" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="picker2"
                                class="datepicker datepicker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </div>
                    </label>
                </div>
                <!-- -------------------------------------------------------------------------------------------------------------- -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>