<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>{{ serverService.get_title(type_task,lang) }}</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="Device" class='wh-label'>
                    <span> عنوان </span>
                    <input class="wh-input" formControlName="title" [required]="required()">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="status_id" class='wh-label'>
                    <span> وضعیت </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid || loading" class="btn_pe"
            *ngIf="type_task == 1 || type_task == 2">
            <span>ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" *ngIf="type_task == 3">
            <span>فیلتر</span>
        </button>
    </div>
</form>