<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<mat-drawer-container autosize style="background-color: transparent;">
    <mat-drawer #drawer mode="side" opened position="{{ position }}" [ngClass]="{'mat-drawer-en':dir == 'ltr'}"
        style="max-width: 13rem;">
        <div class="nav_button cursor" (click)="open_show_extra_menu()" [ngClass]="{nav2:show_extra_menu}">
            <span class="material-icons" style="font-size:1.5rem">
                reorder
            </span>
        </div>
        <input type="file" name="image" accept="image/*" (change)="change_avater($event)" #logo style="display: none;">
        <div class="row nav1" *ngIf="show_extra_menu" style="min-width: 13rem;">
            <app-menu-logo></app-menu-logo>
            <app-home-right></app-home-right>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb nav_exit" style="text-align:right">
                <span class="material-icons cursor password" (click)="access_setting_menu()"
                    *ngIf="setting_menu == false && user_setting_menu == true" style="font-size:22px">
                    settings
                </span>

                <span class="material-icons cursor password" (click)="access_setting_menu()"
                    *ngIf="user_setting_menu == true && setting_menu == true" style="font-size:22px">
                    highlight_off
                </span>

                <span class="material-icons cursor" (click)="signout()">
                    settings_power
                </span>
            </div>
        </div>

        <div *ngIf="!show_extra_menu">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb">
                    <img [src]="user_logo" alt="Avatar" class="nav_avatar_thumb">
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb nav_setting"
                    *ngIf="user_setting_menu == true">
                    <span class="material-icons cursor" (click)="access_setting_menu()" *ngIf="setting_menu == false">
                        settings
                    </span>

                    <span class="material-icons cursor" (click)="access_setting_menu()" *ngIf="setting_menu == true">
                        highlight_off
                    </span>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb nav_exit" (click)="signout()">
                    <span class="material-icons cursor">
                        settings_power
                    </span>
                </div>
            </div>

        </div>
    </mat-drawer>

    <mat-drawer-content [ngClass]="{'mat-drawer-content-en':dir == 'ltr'}">
        <div style="width:100%;min-height:5px;background:#d93025;" class="spinner"></div>
        <div style="position:absolute;left:0;width:15rem !important" class="software1 mat-menu-content1">
            <mat-menu #menu="matMenu">
                <div class="row">
                    <a *ngFor="let x of list_software" href="{{ x.site_software_link }}">
                        <div class="col-lg-4 col-xl-4 col-md-4 software_div cursor">
                            <div class="software_logo" [ngStyle]="{'background-image':'url( ' + x.logo + ')'}">
                            </div>
                            <div class="software_text">{{ x.title }}</div>
                        </div>
                    </a>
                </div>
            </mat-menu>
        </div>
        <nav data-role="ribbonmenu" class="ribbon_pe" [ngClass]="{'ribbon_en':dir == 'ltr'}">

            <ul class="tabs-holder">
                <span style="vertical-align: middle;color:#E44E6E" class="material-icons cursor visible-xs"
                    (click)="signout()">
                    settings_power
                </span>
                <span *ngFor="let x of list_tab;let i = index" [ngClass]="{active:i == 0}"
                    (click)="select(x.site_tab_id)" id="a{{ x.site_tab_id }}">
                    <li *ngIf="x.show == true" [ngClass]="{tabActive:tab_id == x.site_tab_id}"
                        [ngStyle]="{backgroundColor:x.site_tab_color}">
                        <a href="#{{ x.site_tab_id }}">
                            {{ x.site_tab_title }}
                            <span *ngIf="x.site_tab_id == 215">{{ count_material_wharehouse }}</span>
                        </a>
                    </li>
                </span>

                <li class="active desktop1" [ngClass]="{'desktop1_en':dir == 'ltr'}" style="min-width:fit-content">
                    <span style="vertical-align: middle;color:#E44E6E;float: right;" class="cursor" (click)="signout()"
                        *ngIf="dir == 'ltr'">
                        <span class="material-icons cursor" (click)="signout()">
                            settings_power
                        </span>
                    </span>
                    <div class="lastupdate alarm1 desktop" [hidden]="true">
                        <span class="ringing-bell cursor " [matMenuTriggerFor]="matMenuUpdate" (click)="get_content()">
                            آخرین آپدیت
                            <span>
                                {{ last_date_update }}
                            </span>
                        </span>
                    </div>
                    <mat-menu #matMenuUpdate="matMenu" class="matMenuAccountCharge">
                        <div class="div_loading">
                            <div class="my-container" [hidden]="!loading2">
                                <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
                            </div>
                        </div>
                        <mat-icon class="close">close</mat-icon>
                        <div class="row" (click)="$event.stopPropagation()" *ngIf="list_content.length > 0">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content cursor"
                                *ngFor="let x of list_content" (click)="open_video2(x.site_content_id)">
                                <span class="content_smart_display">
                                    <mat-icon style="vertical-align:bottom">smart_display</mat-icon>
                                </span>
                                {{ x.site_content_title }}
                                <span style="margin-right: 1rem;">{{ x.site_content_date }}</span>
                            </div>
                        </div>
                        <div class="row" (click)="$event.stopPropagation()" *ngIf="list_content.length == 0">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center">
                                <div>
                                    <img src="../../../assets/images/preview.png" class="preview">
                                </div>
                                <div class="preview2">{{ no_record }}</div>
                            </div>
                        </div>
                    </mat-menu>
                    <!---->
                    <div class="expire_date alarm1 cursor" *ngIf="level == 2 || level == 3"
                        [ngClass]="{alarm2:p7 <= now_date,'fright' : dir == 'ltr'}">
                        <span [title]="lang == 2 ? 'wallet' : 'کیف پول' " class="ringing-bell"
                            [routerLink]="['/home/network/253']" (click)="get_wallet()" *ngIf="network_access == true">
                            <span> {{ wallet | number }} </span>
                            <span><img src="../../assets/images/coin.png" class="coin_icon"></span>
                        </span>
                    </div>
                    <!---->
                    <div class="expire_date alarm1 cursor" [matMenuTriggerFor]="matMenuAcountCharge"
                        (click)="open_account()" *ngIf="level == 2 || level == 3"
                        [ngClass]="{alarm2:p7 <= now_date,'fright' : dir == 'ltr'}">
                        <span class="ringing-bell" [title]="lang == 2 ? 'Membership renewal' : 'تمدید عضویت' ">
                            <span [ngClass]="{'en':dir == 'ltr'}"> {{ expire_date }} </span>
                            <span *ngIf="lang == 1">تمدید عضویت</span>
                            <span *ngIf="lang == 2">Membership renewal</span>
                        </span>
                    </div>
                    <mat-menu #matMenuAcountCharge="matMenu" class="matMenuAccountCharge">
                        <div class="div_loading">
                            <div class="my-container" [hidden]="!loading2">
                                <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
                            </div>
                        </div>
                        <mat-icon class="close">close</mat-icon>
                        <div class="row" (click)="$event.stopPropagation()">
                        </div>
                        <div class="row" (click)="$event.stopPropagation()">
                            <form #f2="ngForm">
                                <table class="table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th colspan="2" (click)="$event.stopPropagation()"
                                                style="padding: 0px !important;">
                                                <h5>
                                                    تمدید عضویت
                                                </h5>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td (click)="$event.stopPropagation()">مبلغ تمدید ماهانه</td>
                                            <td (click)="$event.stopPropagation()"> {{ user_amount | number }} {{
                                                currency_title }} </td>
                                        </tr>
                                        <tr>
                                            <td (click)="$event.stopPropagation()"> تعداد ماه تمدید</td>
                                            <td (click)="$event.stopPropagation()"> <input type="number"
                                                    style="width:6rem !important" min="1" max-length="2"
                                                    class="wh-input input1" name="account_month"
                                                    [(ngModel)]="account_month" required
                                                    (change)="change_account_price()" (keyup)="change_account_price()"
                                                    max="{{ month_extension }}"></td>
                                        </tr>
                                        <tr>
                                            <td (click)="$event.stopPropagation()">مبلغ قابل پرداخت</td>
                                            <td (click)="$event.stopPropagation()"> {{ account_price_final | number }}
                                                {{ currency_title }}</td>
                                        </tr>
                                        <tr>
                                            <td (click)="$event.stopPropagation()">
                                                <button type="button" class="btn_pe" (click)="charge_account()"
                                                    [disabled]="!f2.valid || account_month < 1"
                                                    (click)="$event.stopPropagation()">
                                                    <span *ngIf="lang == 1">پرداخت آنلاین</span>

                                                </button>
                                                <button type="button" class="btn_pe" (click)="get_wallet(2)"
                                                    [disabled]="!f2.valid || account_month < 1"
                                                    (click)="$event.stopPropagation()" *ngIf="network_access == true"
                                                    [hidden]="true">پرداخت کیف
                                                    پول</button>
                                            </td>
                                            <td (click)="$event.stopPropagation()">

                                                پشتیبانی 0173333332
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </form>
                        </div>
                    </mat-menu>
                    <!---->
                    <div class="sharg cursor" *ngIf="sms_access == true && (level == 2 || level == 3) && lang == 1"
                        [matMenuTriggerFor]="matMenuSmsCharge" (click)="get_sms_charge()"
                        [ngClass]="{alarm2:sms_charge <= 10000,'fright' : dir == 'ltr','sharg_en' : dir == 'ltr'}">
                        <span [ngClass]="{'full-left':dir == 'ltr'}">{{ sms_charge | number }}</span>
                        <span title="شارژ پیامک"><img src="../../assets/images/sms.png" class="sms_icon"></span>
                    </div>
                    <mat-menu #matMenuSmsCharge="matMenu" class="matMenuSmsCharge">
                        <div class="div_loading">
                            <div class="my-container" [hidden]="!loading2">
                                <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
                            </div>
                        </div>
                        <mat-icon class="close">close</mat-icon>
                        <div class="row" (click)="$event.stopPropagation()">
                        </div>
                        <div class="row">
                            <form #f="ngForm">
                                <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12 charge"
                                    (click)="$event.stopPropagation()">
                                    <span>شارژ پنل پیامک</span>
                                    <input class="wh-input" style="text-align: center;" name="amount"
                                        (keyup)="get_charge2()" [(ngModel)]="amount" required currencyMask
                                        placeholder="حداقل شارژ 100،0000 ریال">
                                </div>
                                <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12 charge2" *ngIf="amount2 > 0">
                                    با احتساب ارزش افزوده :
                                    {{ amount2 | number}}
                                    ریال
                                </div>
                                <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
                                    <span (click)="payment($event)" class="cursor reorder1">
                                        <mat-icon>reorder</mat-icon>
                                    </span>
                                    <button type="button" class="btn_pe" (click)="charge()"
                                        [disabled]="!f.valid || amount < 1000"
                                        (click)="$event.stopPropagation()">پرداخت</button>
                                </div>
                            </form>
                        </div>
                    </mat-menu>
                    <span style="vertical-align: middle;color:#E44E6E" class="cursor" (click)="signout()"
                        *ngIf="dir == 'rtl'">
                        <span><img src="../../assets/images/exit.png" class="exit_icon"></span>
                    </span>
                    <!---->
                </li>
                <div (click)="go_to_user()" class="hidden-xs" *ngIf="refrerr == 2">
                    <i class="fas fa-arrow-alt-circle-left icon back"></i>
                </div>
                <span (click)="open_add_section()" class="cursor" [hidden]="setting_menu == false"
                    style="margin-right: 5px;"><i class="fas fa-plus"></i></span>
            </ul>
            <div class="content-holder">
                <div class="logo logo-pe div-logo-pe desktop" [ngClass]="{'div-logo-en':dir == 'ltr'}">
                    <a href="https://finty.ir" target="_blank" [ngClass]="{'logo-en':dir == 'ltr'}">
                        <img src="../../assets/images/logo-finty.png" style="width:10rem" *ngIf="dir == 'rtl'">
                        <img src="../../assets/images/logo-finty.png" style="width:10rem" *ngIf="dir == 'ltr'">
                    </a>
                </div>

                <div class="section" *ngFor="let y of list_tab;let i = index" id="{{ y.site_tab_id }}"
                    [ngClass]="{active:i == 0}">
                    <div class="group">
                        <span *ngFor="let x of list_tab_temp[y.site_tab_id];let i = index">
                            <span *ngIf="list_menus[x.site_section_id] && list_menus[x.site_section_id].length > 0">
                                <section>
                                    <div class="group flex-column" style="border-right:solid 1px #ccc;">
                                        <span *ngFor="let xx of list_menus[x.site_section_id];let menu = index">
                                            <mat-card
                                                class="example-card pe1 example-header-image service_card_logo cursor"
                                                (click)="open_menu(menu,xx.site_services_link,xx.site_services_id,xx.title,x.site_section_id)"
                                                [ngClass]="{active_menu:menu_id == xx.site_services_id}">
                                                <div class="mat-card-avatar service_logo" mat-card-avatar
                                                    [ngStyle]="{'background-image':'url( ' + xx.src + ')'}"></div>
                                                <div class="service_text">{{ xx.title }}</div>
                                            </mat-card>
                                        </span>
                                        <span class="title cursor section_title">
                                            {{ x.title }}
                                        </span>
                                    </div><!-- group flex-column -->
                                    <div class="group-divider"></div>
                                </section>
                            </span>
                        </span>

                        <span *ngIf="tab_id == 198 && list_rstatus_in_menu.length > 0 && level != 1">
                            <section>
                                <div class="group flex-column" style="border-right:solid 1px #ccc;">
                                    <span *ngFor="let x of list_rstatus_in_menu"
                                        (click)="go_to_status(x.repairman_rstatus_id)">
                                        <mat-card class="example-card pe1 example-header-image service_card_logo cursor"
                                            [ngClass]="{active_menu:rstatus_id  == x.repairman_rstatus_id}">
                                            <div class="mat-card-avatar service_logo" mat-card-avatar
                                                [ngStyle]="{'background-image':'url( ' + x.logo + ')'}"></div>
                                            <div class="service_text">{{ x.repairman_rstatus_title }}</div>
                                        </mat-card>
                                    </span>
                                    <span class="title cursor" (click)="get_rstatus()">
                                        <span *ngIf="lang == 1">وضعیت ها</span>
                                        <span *ngIf="lang == 2"> Status </span>
                                    </span>
                                </div><!-- group flex-column -->
                                <div class="group-divider"></div>
                            </section>
                        </span>

                        <span *ngIf="tab_id == 215 && list_wharehouse_groups.length > 0">
                            <section>
                                <div class="group flex-column" style="border-right:solid 1px #ccc;">
                                    <span *ngFor="let x of list_wharehouse_groups"
                                        (click)="go_to_wharehouse_group(x.wharehouse_group_id)">
                                        <mat-card class="example-card pe1 example-header-image service_card_logo cursor"
                                            [ngClass]="{active_menu:wharehouse_group_id  == x.wharehouse_group_id}">
                                            <div class="mat-card-avatar service_logo" mat-card-avatar
                                                [ngStyle]="{'background-image':'url( ' + x.logo + ')'}"></div>
                                            <div class="service_text">{{ x.wharehouse_group_title }}</div>
                                        </mat-card>
                                    </span>
                                    <span class="title cursor">
                                        گروهها
                                    </span>
                                </div><!-- group flex-column -->
                                <div class="group-divider"></div>
                            </section>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
        <!-- *********************************************************************************************************** -->
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>