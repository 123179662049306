import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { concat, Observable, of, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-gaccess',
  templateUrl: './gaccess.component.html',
  styleUrls: ['./gaccess.component.scss']
})
export class GaccessComponent implements OnInit, OnDestroy {
  public isOnline: any | undefined;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  user_id: number = this.user_info.user_id;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined;
  public err_validation: boolean = false;
  public err_internet_text: string | undefined;
  public err_internet_validation: boolean | undefined;
  public server: any | undefined;
  user: string | undefined;
  lang: any | undefined;
  list_groups: any = [];
  list_groups_select: any = [];
  list_groups_selected: any = [];
  arr_users: any = [];
  id: number | undefined;
  pattern_title_with_persian: string | undefined;
  title: string | undefined;
  change_alter: number = 2;

  error: boolean = false;



  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any
    , private matDialogRef: MatDialogRef<GaccessComponent>) {
    if (dialog_data) {
      this.id = this.dialog_data.id;
      this.title = this.dialog_data.title;
    }
  }//end consructor

  ngOnInit() {
    this.server = this.serverService.get_server();
    this.serverService.status1(2);
    this.lang = localStorage.getItem("lang");
    this.check_access(4);
  }//

  check_access(type_type: number): any {
    if (this.error == true) {
      var pe_message = "خطایی در سیستم وجود دارد";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }//end if 
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: 1 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
            return false;
          }
          else {
            if (type_type == 2) { this.update(); }
            if (type_type == 4) { this.get_data(); }
            return true;
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          return false;
        }
      }
    )
  }



  get_data(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1028, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.list_groups_selected = res['result'][0].site_services_groups;
          }
          this.get_groups();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          this.error = true;
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }//end get_selected_users

  get_groups(): any {
    var list_groups_selected: any = [];
    if (this.list_groups_selected) {
      list_groups_selected = this.list_groups_selected.split(",").map((items: any) => {
        return parseInt(items);
      });
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1033 }).subscribe(
      (res: any) => {
        this.list_groups = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_groups.push(res['result'][i]);
            for (var x = 0; x < list_groups_selected.length; x++) {
              if (list_groups_selected.indexOf(this.list_groups[i].user_groups_id) != -1) {
                this.list_groups[i].checked = true;
              }
            }
          }//end for

          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  select_groups() {
    this.list_groups_selected = [];
    var groups_array = <any>document.getElementsByName("groups[]");
    for (var i = 0; i < groups_array.length; i++) {
      if (groups_array[i].checked == true) {
        this.list_groups_selected.push(groups_array[i].value);
      }
    }
    this.list_groups_selected = this.list_groups_selected.map((items: any) => {
      return parseInt(items)
    });

  }



  update(): any {
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 1035, id: this.id, groups: this.list_groups_selected.toString() }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close();
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      })
  }//end update



  close() {
    this.matDialogRef.close({ alter: this.change_alter });
  }

  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 2000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 2000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 


  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}



