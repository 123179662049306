import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { InsertComponent } from '../../../service/insert/insert.component';
import { MessageService } from '../../../service/message/message.service';
import { IncomeDetaileCashComponent } from '../income-detaile-cash/income-detaile-cash.component';
import { IncomeDetaileBankComponent } from '../income-detaile-bank/income-detaile-bank.component';
import { IncomeChequeRecieveDetaileComponent } from '../income-cheque-recieve-detaile/income-cheque-recieve-detaile.component';
import Num2persian from 'num2persian';

@Component({
  selector: 'app-income-detaile',
  templateUrl: './income-detaile.component.html',
  styleUrls: ['./income-detaile.component.scss']
})
export class IncomeDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public token: any = Math.floor(Math.random() * 99999999999999);
  public loading: boolean = false;
  public readonly: boolean = false;
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public document_type: number;
  public count_check_pay: number = 0;
  public count_check_recieve: number = 0;
  public remain: number = 0;
  public sum: number = 0;
  public search_array: any = [];
  public list_record: any = [];
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public amount!: number;
  public result!: any;
  public remain_letter!: string;
  public currency_title!: string;
  public creator!: number;
  public expire!: boolean;
  public sum_all_letter!: string;
  public access_service!: number;
  public level!: number;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'payment_type', 'amount', 'bank', 'account', 'cheque', 'due', 'operation'];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<IncomeDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.readonly = dialog_data.readonly;
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.sum = dialog_data.sum;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.check_access(3, 1);
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'income_title': new FormControl(null),
      'income': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
      'document_date': new FormControl(new Date()),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['document_date'].setValidators([Validators.required]);
      this.form1_group.controls['income'].setValidators([Validators.required]);
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access3(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access3(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
          }
          if (type_task == 2) {
            if (this.expire == true && this.i == -1) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.update();
            }
          }
          if (type_task == 3) {
          }
          if (type_task == 4) {
            this.get_data();
          }
          if (type_task == 5) { }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search(excel: number) {

  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6929,
      'id': this.id,
      'creator': this.creator,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'income': res['result'][0].finance_income_type_id,
              'income_title': res['result'][0].finance_income_type_title,
              'comment': res['result'][0].finance_income_comment,
              'document_date': res['result'][0].finance_income_document_date_main,
            });
            if (this.i == -1) {
              this.form1_group.patchValue({
                'document_date': new Date()
              })
            }
            this.get_financial2();
            this.get_currency();
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6927,
      "lang": this.lang,
      "user_id": this.user_id,
      'income': this.form1_group.value.income,
      'comment': this.form1_group.value.comment,
      'amount': this.amount,
      'document': this.form1_group.value.document,
      'document_date': this.form1_group.value.document_date,
      'document_type': this.document_type,
      'token': this.token,
      'id': this.id,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close({ result: this.result })
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "در سند نوع دریافتی نمی توانید تراکنش ار نوع پرداختی داشته باشید";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    var code_id;
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: {
        id: id, creator: this.creator, code_id: code_id, obj: {
        }
      }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 242) {
            this.form1_group.patchValue({
              'income': res.id,
              'income_title': res.title
            });
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (id == 242) {
      this.form1_group.patchValue({
        'income': null,
        'income_title': null,
      });
    }
  }
  close() {
    var j = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      if (this.list_record[i].finance_income2_trash == 3) {
        j++;
      }
    }
    if (j > 0) {
      var message = "اینجا تراکنشهای انجام شده هنوز ذخیره نشده است؟";
      var accept: boolean = confirm(message);
      if (accept == true) {
        this.matDialogRef.close();
      } else {
      }
    } else {
      this.matDialogRef.close();
    }
  }
  add_income(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'income' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'income': res.finance_income_type_id,
            'income_title': res.finance_income_type_title
          })
        }
      }
    )
  }
  get_currency(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6115, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.currency_title = res['result'][0].site_currency_title;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*********************************************************************************************** */
  check_access2(i: number, type_task: number, page: number, id: any, excel: number, loading: number, cheque: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            if (type_task == 3) {
              this.delete(i, id, cheque);
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_financial2() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    //6029
    var address = 6934;
    var obj = {
      'address': address,
      'user_id': this.user_id,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.get_amount();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  get_amount() {
    this.amount = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.amount += Number(this.list_record[i].finance_income2_amount);
    }
    this.sum_all_letter = Num2persian(this.amount);
  }
  check_for_open_detaile(index: number, type_task: number, id: number, payment_type: number, cheque_id: number, cheque_type: number) {
    if (payment_type == 1) {
      this.open_detaile_cash(index, type_task, id);
    }
    else if (payment_type == 2) {
      this.open_detaile_bank(index, type_task, id);
    }
    else if (payment_type == 3) {
      this.open_detaile_cheque(index, type_task, id);
    }
  }
  open_detaile_cash(index: number, type_task: number, id: number): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(IncomeDetaileCashComponent, {
      width: '21rem',
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task,
        id: id,
        code: this.code,
        financial: this.id,
        user: this.form1_group.value.user,
        token: this.token,
        sum: Math.abs(this.sum) - Number(this.amount),
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource.data = this.list_record;
            this.get_amount();
          }
          else if (type_task == 2) {
            this.list_record[index].finance_income2_amount = res.result.finance_income2_amount;
            this.get_amount();
          }
        }
      }
    )
  }
  open_detaile_bank(index: number, type_task: number, id: number): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(IncomeDetaileBankComponent, {
      width: '30rem',
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task,
        id: id,
        code: this.code,
        financial: this.id,
        token: this.token,
        sum: Math.abs(this.sum) - Number(this.amount)
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            if (this.dataSource) this.dataSource.data = this.list_record;
            else this.dataSource = new MatTableDataSource(this.list_record);
            this.get_amount();
          }
          else if (type_task == 2) {
            this.list_record[index].finance_income2_amount = res.result.finance_income2_amount;
            this.list_record[index].finance_payment_type_title = res.result.finance_payment_type_title;
            this.list_record[index].accounts_number = res.result.accounts_number;
            this.list_record[index].site_bank_title = res.result.site_bank_title;
            this.list_record[index].due_date = res.result.due_date;
            this.get_amount();
          }
        }
      }
    )
  }
  open_detaile_cheque(index: number, type_task: number, id: number): any {
    if (this.readonly == true) {
      return false;
    }
    if ((!this.form1_group.value.income)) {
      var pe_message = "برای ثبت و یا ویرایش تراکنش در ابتدا باید عنوان درآمد مشخص شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(IncomeChequeRecieveDetaileComponent, {
      width: '32rem',
      height: 'auto',
      data: {
        type_task: type_task,
        code: this.code,
        type: this.document_type,
        id: id,
        token: this.token,
        creator: this.creator,
        financial_id: this.id
      }

    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource = new MatTableDataSource(this.list_record);
            this.get_amount();
          }
          else {
            this.list_record[index].finance_income2_amount = res.result.finance_income2_amount;
            this.list_record[index].site_bank_title = res.result.site_bank_title;
            this.list_record[index].accounts_number = res.result.accounts_number;
            this.list_record[index].finance_income2_cheque_number = res.result.finance_income2_cheque_number;
            this.list_record[index].due_date = res.result.due_date;
            this.get_amount();
          }
        }
      }
    )
  }
  get_bg(id: number) {
    //this.id = id;
  }
  delete(i: number, id: number, cheque: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    var address = 7086;
    if (this.list_record[i].site_cheque_type > 0) {
      address = 6936;
    }
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': address,
        "user_id": this.user_id,
        'id': id,
        'financial': this.id,
        'cheque': cheque
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.finance_income2_id == id), 1);
            this.dataSource.data = this.list_record;
            this.message(false, "", 1, this.messageService.close(this.lang));
            this.get_amount();
            //****************** */
          }
          else if (res['status'] == 3) {
            var message = "این کورد در جای دیگه ای خرج شده است و نمی توانید حذف کنید";
            this.message(true, this.messageService.message(this.lang, message, ''), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.serverService.message_action());
          }
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  //********************************************************************************************** */
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

