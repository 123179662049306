<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title class="matDialogTitle relative" cdkDragHandle
        style="background-color: transparent;box-shadow:none">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
        <span (click)="close()" class="close" *ngIf="refrerr == 2">
            <mat-icon>close</mat-icon>
        </span>
    </div>
</div>

<div mat-dialog-content class="matDialogContent padding0">
    <div class="row">
        <div class="col-12 mx-auto p-4">
            <form #f2="ngForm">
                <table class="table table-bordered ">

                    <thead>
                        <tr>
                            <th colspan="2">
                                <h5>

                                    اکانت شما منقضی شده است لطفا شارژ نمایید.
                                </h5>

                            </th>
                        </tr>

                    </thead>
                    <tbody>

                        <tr>
                            <td> تاریخ انقضای اکانت</td>
                            <td>
                                {{ expire_date }}
                            </td>
                        </tr>
                        <tr>
                            <td>هزینه تمدید ماهیانه</td>
                            <td>{{ user_amount / 10 | number }} {{ currency_title }}</td>
                        </tr>
                        <tr>

                            <td>تعداد ماه برای تمدید</td>
                            <td> <input type="number" class="wh-input input1 center" styl max="2" name="account_month"
                                    style="width:6rem !important" min="1" max-length="2" [(ngModel)]="account_month"
                                    required (change)="change_amount()" (keyup)="change_amount()" max="{{12}}"
                                    [ngClass]="{'left':dir == 'ltr','en':dir == 'ltr'}"></td>
                        </tr>
                        <tr>
                            <td>مبلغ قابل پرداخت</td>
                            <td> {{ amount_final | number }} {{ currency_title }}</td>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" class="btn_pe" (click)="pay()"
                                    [disabled]="!f2.valid || account_month < 1">
                                    <span *ngIf="lang == 1">پرداخت آنلاین</span>
                                </button>
                                <button type="button" class="btn_pe" (click)="get_wallet(2)"
                                    [disabled]="!f2.valid || account_month < 1" *ngIf="network_access == true"
                                    [hidden]="true">پرداخت کیف
                                    پول</button>
                            </td>
                            <td>
                                پشتیبانی 0173333332
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>



</div>