<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr',en:lang == 2}" name="search_all"
            [(ngModel)]="search_all" (keyup)="check_access('',4,1,'',1,2)" placeholder="{{ lang_text[2] }}"
            autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile2(0,3,0)'>
                    <span>{{ lang_text[1] }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access('',4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access('',4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access('',4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access('',4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(2,1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span><input (change)="set_title('title0',$event)" [(ngModel)]="title0" name="title0" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i + page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="edie_date">
            <mat-header-cell *matHeaderCellDef style="max-width:150px">
                <span><input (change)="set_title('title1',$event)" [(ngModel)]="title1" name="title1" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:150px;direction: rtl;"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)"
                [ngClass]="{green: x.wharehouse_order_editor_date == date}">
                {{ x.wharehouse_order_editor_time }} {{ x.wharehouse_order_editor_date }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="wharehouse">
            <mat-header-cell *matHeaderCellDef style="max-width:130px" [hidden]="code == 248 || group">
                <span><input (change)="set_title('title2',$event)" [(ngModel)]="title2" name="title2" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:130px;direction: rtl;"
                [hidden]="code == 248 || group">
                <span *ngIf="x.wharehouse_order_wharehouse == 1"
                    (click)="change_wharehouse(i,x.wharehouse_order_id,false)"><i class="fas fa-check icon"
                        style="color:green;"></i></span>
                <span *ngIf="x.wharehouse_order_wharehouse == 0"
                    (click)="change_wharehouse(i,x.wharehouse_order_id,true)"><i class="fas fa-ban cursor icon"
                        style="font-size: 1rem;"></i></span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creator">
            <mat-header-cell *matHeaderCellDef style="min-width:20px">
                <span><input (change)="set_title('title3',$event)" [(ngModel)]="title3" name="title3" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:200px;direction: rtl;"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)">
                <div class="right">{{x.creator_title | substr:0:30 }}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span><input (change)="set_title('title4',$event)" [(ngModel)]="title4" name="title4" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:100px;direction: rtl;"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)">
                {{ x.site_state_title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px;">
                <span><input (change)="set_title('title5',$event)" [(ngModel)]="title5" name="title5" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)" class="right"
                style="min-width: 250px;">
                <div class="right" style="direction: rtl;">
                    {{x.wharehouse_material_cate_title }}
                    {{x.wharehouse_material_title2 | substr:0:30 }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="inventory">
            <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="group || code == 248">
                <span><input (change)="set_title('title6',$event)" [(ngModel)]="title6" name="title6" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)" style="max-width:100px"
                [hidden]="group  || code == 248">
                {{x.inventory }}

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef style="max-width:120px">
                <span><input (change)="set_title('title7',$event)" [(ngModel)]="title7" name="title7" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="direction: rtl;max-width:120px"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)">
                <div>{{ x.wharehouse_order_cost2 | number }}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef style="min-width:200px">
                <span><input (change)="set_title('title8',$event)" [(ngModel)]="title8" name="title8" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:200px;direction: rtl;"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)">
                <div class="right">{{ x.wharehouse_order_comment | substr:0:45 }}</div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width: 50px;">
                <span><input (change)="set_title('title9',$event)" [(ngModel)]="title9" name="title9" autocomplete="off"
                        [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)" style="max-width: 50px;">{{
                x.wharehouse_order_id }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:100px'>
                <span><input (change)="set_title('title20',$event)" [(ngModel)]="title20" name="title20"
                        autocomplete="off" [readonly]="user_id != 20098"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px'>
                <span (click)='open(i,2,x.wharehouse_material_id,x.wharehouse_order_id)'><i
                        class="fas fa-desktop icon"></i></span>
            </mat-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,red:row.wharehouse_order_cost2 == 0,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>


    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2" *ngIf="lang == 1">هیچ رکوردی برای نمایش وجود ندارد</div>
            <div class="preview2" *ngIf="lang == 2">There are no records to display</div>
        </div>
    </div>
</div>