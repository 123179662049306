import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-country-detaile',
  templateUrl: './country-detaile.component.html',
  styleUrls: ['./country-detaile.component.scss']
})
export class CountryDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public isOnline: any;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public creator: number | undefined;
  public expire: boolean | undefined;
  public access_service: number | undefined;
  public level: number | undefined;

  public logo: any = this.serverService.get_default_image();
  public logo_bin: boolean = false;
  public logo_type: any;
  public logo_info: any;
  public logo_loading: boolean = false;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<CountryDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form1();

    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          title: dialog_data.search_array.title,
          area_code: dialog_data.search_array.area_code,
          alpha_code: dialog_data.search_array.alpha_code,
          price: dialog_data.search_array.price,
          price2: dialog_data.search_array.price2,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
        })
      }
    }
  }//end consructor

  ngOnInit() {

  }

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null, [Validators.required]),
      'area_code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'alpha_code': new FormControl(null),
      'price': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'price2': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')])
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['title'].setValidators([Validators.required]);
      this.form1_group.controls['status_id'].setValidators([Validators.required]);
    }
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, excel: number, access_service: number): any {
    var obj = { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) { this.get_status(); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      area_code: this.form1_group.value.area_code,
      alpha_code: this.form1_group.value.alpha_code,
      price: this.form1_group.value.price,
      price2: this.form1_group.value.price2,
      status_id: this.form1_group.value.status_id,
      status_title: this.form1_group.value.status_title,
      export_excel: excel
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6700, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].user_country_title,
            'area_code': res['result'][0].user_country_area_code,
            'alpha_code': res['result'][0].user_country_alpha_code,
            'price': res['result'][0].user_country_price,
            'price2': res['result'][0].user_country_price2,
            'sort': res['result'][0].user_country_sort,
            'status_id': res['result'][0].user_country_status,
            'status_title': res['result'][0].status_title,
          }
          )
          this.logo_info = {
            site: res['result'][0].user_country_site_logo,
            path: res['result'][0].user_country_logo
          }
          if (res['result'][0].user_country_logo) {
            this.logo = res['result'][0].user_country_site_logo + "/" + res['result'][0].user_country_logo;
            this.logo_bin = true;
            this.logo_type = res['result'][0].user_country_logo.split(".").pop();
          }
          else {
            this.logo = this.serverService.get_default_image();
            this.logo_bin = false;
          }

          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6695, "user_id": this.user_id
      , 'status_id': this.form1_group.value.status_id
      , 'title': this.form1_group.value.title
      , 'area_code': this.form1_group.value.area_code
      , 'alpha_code': this.form1_group.value.alpha_code
      , 'price': this.form1_group.value.price
      , 'price2': this.form1_group.value.price2
      , 'sort': this.form1_group.value.sort
      , 'creator': this.creator
      , 'lang': this.lang
      , 'site': this.serverService.get_site()
      , 'logo_info': this.logo_info
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6696, "user_id": this.user_id
      , 'title': this.form1_group.value.title
      , 'area_code': this.form1_group.value.area_code
      , 'alpha_code': this.form1_group.value.alpha_code
      , 'price': this.form1_group.value.price
      , 'price2': this.form1_group.value.price2
      , 'sort': this.form1_group.value.sort
      , 'status_id': this.form1_group.value.status_id
      , 'creator': this.creator
      , 'id': this.id
      , 'lang': this.lang
      , 'site': this.serverService.get_site()
      , 'logo_info': this.logo_info
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0], i: this.i }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat2(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  get_status() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  load_services(id: number) {

    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 26) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            })
          }
        }
      }
    )

  }


  reset_input(id: number) {
    if (id == 26) {
      this.form1_group.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //***************************************************** */
  change_logo(event: any): any {
    var selectedFile = <File>event.target.files[0];
    if (selectedFile.type.split("/").shift() != 'image' && selectedFile.type != 'application/pdf') {
      var message = "فرمت ارسالی برای این فیلد فقط می بایست عکس و یا pdf باشد.";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    else {
      this.logo_loading = true;
      var fd = new FormData();
      fd.append("image", selectedFile, selectedFile.name);
      this.serverService.post_address_file(this.server, "uploadImage", fd).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            ///this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
          }
          else if (event.type === HttpEventType.Response) {
            var a = <any>event.body;
            this.logo = this.serverService.get_site_upload_image() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
            this.logo_bin = true;
            this.logo_type = selectedFile.type.split("/").pop();
            this.logo_loading = false;
            this.logo_info = {
              'site': this.serverService.get_site_upload_image(),
              'path': this.serverService.get_path_upload_image() + a.result.filename
            }
          }
        }
      )
    }
  }

  delete_logo() {
    this.logo = this.serverService.get_default_image();
    this.logo_bin = false;
    this.logo_info = {
      path: '',
      site: ''
    }
    this.logo_type = '';
  }

  open_logo(): any {
    if (this.logo == '../../../../assets/images/default_image.png') {
      return false;
    } else {
      window.open(this.logo, "_blank");
    }
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
