<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title class="matDialogTitle" cdkDragHandle>
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
        {{ serverService.get_title(type_task,lang) }}
        <span class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </span>
    </div>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid pe">
            <div class="row" style="direction: rtl;">
                <div class='col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}" style='margin-top:5px'>
                    <label for="title" class='wh-label'>
                        <div style="margin-bottom: 1rem;">
                            <span *ngIf="lang == 1">کد معرف من (در باکس پایین کد معرف خود را وارد کنید) </span>
                            <span *ngIf="lang == 2">My identifier code (enter your identifier code in the box
                                below)</span>
                        </div>
                        <input class='wh-input moarf' formControlName='moarf' autocomplete="off" placeholder="...">
                    </label>
                </div>
            </div><!-- row -->
        </div>
    </div> <!-- content -->

    <div mat-dialog-actions class="pe1 matDialogActions">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang==2}" [disabled]="!form1.valid"
            *ngIf="type_task != 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div><!-- row -->
</form>