import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { HttpEventType } from '@angular/common/http';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-user-detaile3',
  templateUrl: './user-detaile3.component.html',
  styleUrls: ['./user-detaile3.component.scss']
})
export class UserDetaile3Component implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();;
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_logo: string = this.serverService.get_default_user_logo();
  public sign_logo: string = this.serverService.get_default_image();
  public user_sign_logo2: string = this.serverService.get_default_image();
  public user_logo1: string = this.serverService.get_default_image();
  public loading: boolean = false;
  public user_logo_bin: boolean = false;
  public sign_logo_bin: boolean = false;
  public Presentable: boolean = false;
  public search_array: any = [];
  public list_sgroup: any = [];
  public list_sgroup_id: any = [];
  public gropus: number = 0;
  public subscription: Subscription | any;
  public form1: FormGroup | any;
  public type_task!: number;
  public user_id!: number;
  public logo_site!: string;
  public logo_info!: any;
  public ref!: string
  public result!: any;
  public sign_logo_info!: any;
  public id1: number;
  public i!: number;
  public id!: number;
  public code!: number;
  public title!: string;
  public creator!: number;
  public level!: number;
  public expire!: boolean;
  public count_finance!: number;
  public finance_id!: number;
  public access_service!: number;
  public user_logo1_bin!: boolean;
  public logo1_info!: any;
  public user_sign_logo2_bin!: boolean;
  public sign_logo2_info!: any;
  public sex_title!: string;
  public type_group!: string;
  public tab1: string = "اطلاعات اولیه";
  public tab2: string = "دسترسی های سفارشی";
  public tab3: string = "امضا";
  public tab4: string = "دسترسی ها";
  public default_service: any = "270,271,277,278,269,273,274,276,255,242,161,230,232,221,223,231,225,226,227,222,207,205,204,203,202,201,199,198,197,195,194,224,192,269,188,185,181,179,178,177,175,173,228,229,172,171,170,169,167,164,163,162,160,157,154,10,155,212,235,210,272,209,228,193";
  public list_service_select: any = [];
  public list_service: any = [];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UserDetaile3Component>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.level = dialog_data.level;

      this.create_form1();
      this.id = dialog_data.id;
      this.creator = dialog_data.creator;
      this.ref = dialog_data.ref;
      this.code = dialog_data.code;
      this.gropus = dialog_data.gropus;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.list_service_select = "";
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1.patchValue({
          title: dialog_data.search_array.title,
          group_title: dialog_data.search_array.group_title,
          group: dialog_data.search_array.group,
          status_title: dialog_data.search_array.status_title,
          status_id: dialog_data.search_array.status_id,
          login_service_title: dialog_data.search_array.login_service_title,
          login_service: dialog_data.search_array.login_service,
          user: dialog_data.search_array.user,
        })
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1 = new FormGroup({
      'title': new FormControl(null),
      'user': new FormControl(null),
      'sex': new FormControl(null),
      'sex_title': new FormControl(null),
      'group': new FormControl(null),
      'group_title': new FormControl(null),
      'status_id': new FormControl(null),
      'status_title': new FormControl(null),
      'access_change_in_invoice': new FormControl(null),
      'access_change_in_repairman': new FormControl(null),
      'user_can_only_see_his_own_list': new FormControl(null),
      'user_can_only_see_his_own_customers_list': new FormControl(null),
      'sending_sms_when_acceptance_of_the_new_device': new FormControl(null),
      'access_finance_in_invoice': new FormControl(null),
      'show_cellphone': new FormControl(null),
      'login_with_sms': new FormControl(null),
      'password': new FormControl(null),
      'profit_sale': new FormControl(0, [Validators.pattern('[0-9]{1,}'), Validators.maxLength(3), Validators.max(100)]),
      'profit_repairman': new FormControl(0, [Validators.pattern('[0-9]{1,}'), Validators.maxLength(3), Validators.max(100)]),
      'login_service_title': new FormControl(null),
      'login_service': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cellphone': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 1 || this.type_task == 2) {
      this.form1.controls['title'].setValidators([Validators.required]);
      this.form1.controls['group'].setValidators([Validators.required]);
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6,
      'type': 'user',
      'user_id': this.user_id,
      'id': this.code,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.title = res['result'][0].title;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) { this.insert(); }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
            this.list_service_select = this.default_service;
            this.get_sgroup();
            this.get_status();
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_number(event: any) {
    this.serverService.get_number(event)
  }
  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1.value.title,
      group_title: this.form1.value.group_title,
      group: this.form1.value.group,
      status_title: this.form1.value.status_title,
      status_id: this.form1.value.status_id,
      sex_title: this.form1.value.sex_title,
      sex: this.form1.value.sex,
      login_service_title: this.form1.value.login_service_title,
      login_service: this.form1.value.login_service,
      user: this.form1.value.user,
      export_excel: excel
    })
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7042, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          //res['result'][0].user_sex_title
          this.sex_title = res['result'][0].user_sex_title;

          if (res['result'][0].user_groups_title == "تعمیرکاران") {
            this.Presentable = true;
          }
          this.form1.patchValue({
            'title': res['result'][0].user_title,
            'login_service_title': res['result'][0].site_services_title,
            'login_service': res['result'][0].site_services_id,
            'user': res['result'][0].user_userName,
            'group': res['result'][0].user_group,
            'group_title': res['result'][0].user_groups_title,
            'status_id': res['result'][0].status_id,
            'status_title': res['result'][0].status_title,
            'sex': res['result'][0].user_sex_id,
            'sex_title': res['result'][0].user_sex_title,
            'password': res['result'][0].user_repassword,
            'profit_sale': res['result'][0].user_profit_sale,
            'profit_repairman': res['result'][0].user_profit_repairman,
            'access_change_in_invoice': res['result'][0].user_access_change_in_invoice,
            'access_change_in_repairman': res['result'][0].user_access_change_in_repairman,
            'user_can_only_see_his_own_list': res['result'][0].user_can_only_see_his_own_list,
            'user_can_only_see_his_own_customers_list': res['result'][0].user_can_only_see_his_own_customers_list,
            'sending_sms_when_acceptance_of_the_new_device': res['result'][0].sending_sms_when_acceptance_of_the_new_device,
            'access_finance_in_invoice': res['result'][0].user_access_finance_in_invoice,
            'show_cellphone': res['result'][0].user_show_cellphone,
            'login_with_sms': res['result'][0].user_login_with_sms,
            'cellphone': res['result'][0].user_cellphone,
          }
          );
          this.sign_logo_info = {
            site: res['result'][0].user_logo_site,
            path: res['result'][0].user_sign_logo
          }
          if (res['result'][0].user_sign_logo) {
            this.sign_logo = res['result'][0].user_logo_site + "/" + res['result'][0].user_sign_logo;
            this.sign_logo_bin = true;
          }
          else {
            this.sign_logo = this.serverService.get_default_image();
            this.sign_logo_bin = false;
          }
          this.list_service_select = res['result'][0].user_services;
          this.get_sgroup();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert(): any {
    var x: any;
    if (typeof this.list_service_select == 'string') {
      x = this.list_service_select.split(",").map((index: any) => {
        return parseInt(index)
      })
    } else {
      x = this.list_service_select;
    }
    if (x.indexOf(157) > 0 && x.indexOf(197) == -1) {
      var pe_message = "برای استفاده از سرویس جدید در تعمیرگاه ، نیاز است سرویس پذیرش ها هم انتخاب شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (x.indexOf(198) > 0 && x.indexOf(199) == -1) {
      var pe_message = "برای استفاده از سرویس صدور فاکتور در انبار ، نیاز است سرویس لیست فاکتورها هم انتخاب شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (x.indexOf(204) > 0 && x.indexOf(192) == -1) {
      var pe_message = "برای استفاده از سرویس درج مالی در حسابداری ، نیاز است سرویس امور مالی هم انتخاب شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 7039,
      "user_id": this.user_id,
      'title': this.form1.value.title,
      'sex': this.form1.value.sex,
      'group': this.form1.value.group,
      'status_id': this.form1.value.status_id,
      'login_service': this.form1.value.login_service,
      'user': this.form1.value.user,
      'password': this.form1.value.password,
      'profit_sale': this.form1.value.profit_sale,
      'profit_repairman': this.form1.value.profit_repairman,
      'sign_info': this.sign_logo_info,
      'logo_site': this.serverService.get_site_upload_image(),
      'id': this.id,
      'list_service_select': this.list_service_select,
      'creator': this.creator,
      'access_change_in_invoice': this.form1.value.access_change_in_invoice,
      'access_change_in_repairman': this.form1.value.access_change_in_repairman,
      'user_can_only_see_his_own_list': this.form1.value.user_can_only_see_his_own_list,
      'sending_sms_when_acceptance_of_the_new_device': this.form1.value.sending_sms_when_acceptance_of_the_new_device,
      'access_finance_in_invoice': this.form1.value.access_finance_in_invoice,
      'show_cellphone': this.form1.value.show_cellphone,
      'login_with_sms': this.form1.value.login_with_sms,
      'cellphone': this.form1.value.cellphone,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
        }//end if
        else if (res['status'] == 4) {
          var message = "نام کاربری باید منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  update(): any {
    var x: any;
    if (typeof this.list_service_select == 'string') {
      x = this.list_service_select.split(",").map((index: any) => {
        return parseInt(index)
      })
    } else {
      x = this.list_service_select;
    }
    if (x.indexOf(157) > 0 && x.indexOf(197) == -1) {
      var pe_message = "برای استفاده از سرویس جدید در تعمیرگاه ، نیاز است سرویس پذیرش ها هم انتخاب شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (x.indexOf(198) > 0 && x.indexOf(199) == -1) {
      var pe_message = "برای استفاده از سرویس صدور فاکتور در انبار ، نیاز است سرویس لیست فاکتورها هم انتخاب شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (x.indexOf(204) > 0 && x.indexOf(192) == -1) {
      var pe_message = "برای استفاده از سرویس درج مالی در حسابداری ، نیاز است سرویس امور مالی هم انتخاب شود";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 7040,
      "user_id": this.user_id,
      'title': this.form1.value.title,
      'sex': this.form1.value.sex,
      'group': this.form1.value.group,
      'status_id': this.form1.value.status_id,
      'login_service': this.form1.value.login_service,
      'user': this.form1.value.user,
      'password': this.form1.value.password,
      'profit_sale': this.form1.value.profit_sale,
      'profit_repairman': this.form1.value.profit_repairman,
      'sign_info': this.sign_logo_info,
      'logo_site': this.serverService.get_site_upload_image(),
      'id': this.id,
      'list_service_select': this.list_service_select,
      'creator': this.creator,
      'access_change_in_invoice': this.form1.value.access_change_in_invoice,
      'access_change_in_repairman': this.form1.value.access_change_in_repairman,
      'user_can_only_see_his_own_list': this.form1.value.user_can_only_see_his_own_list,
      'sending_sms_when_acceptance_of_the_new_device': this.form1.value.sending_sms_when_acceptance_of_the_new_device,
      'access_finance_in_invoice': this.form1.value.access_finance_in_invoice,
      'show_cellphone': this.form1.value.show_cellphone,
      'login_with_sms': this.form1.value.login_with_sms,
      'cellphone': this.form1.value.cellphone,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
        }//end if
        else if (res['status'] == 4) {
          var message = "نام کاربری باید منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  get_sgroup(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7045, lang: this.lang }).subscribe(
      (res: any) => {
        this.list_sgroup = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_sgroup_id[res['result'][i].site_software_id] = new Array();
            this.list_sgroup.push(res['result'][i]);
          }//end for
          this.get_services();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_status() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_services(): any {
    var selected_array: any = [];
    if (this.list_service_select) {
      selected_array = this.list_service_select.split(",").map((items) => {
        return parseInt(items);
      });
    }
    var obj = {
      'address': 7046
      , 'user_id': this.user_id
      , 'level': this.level
      , 'creator': this.creator
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_service = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            for (var j = 0; j < this.list_sgroup.length; j++) {
              if (this.list_sgroup[j].site_software_id == res['result'][i].site_services_sgroup) {
                this.list_sgroup_id[this.list_sgroup[j].site_software_id].push(res['result'][i]);
              }
            }
            if (selected_array.indexOf(res['result'][i].site_services_id) != -1) {
              res['result'][i].checked = true;
              res['result'][i].disabled = false;
            }
            this.list_service.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  change_select_service(): any {
    this.list_service_select = [];
    var service_array = <any>document.getElementsByName("service[]");
    for (var i = 0; i < service_array.length; i++) {
      if (service_array[i].checked == true) {
        this.list_service_select.push(service_array[i].value);
      }
    }
    this.list_service_select = this.list_service_select.map((items) => {
      return parseInt(items);
    });
  }
  //************************************************************************************************ */
  change_sign_logo(event: any) {
    var selectedFile = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    this.serverService.post_address_file(this.server, "uploadImage", fd).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          ///this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
        }
        else if (event.type === HttpEventType.Response) {
          var a = <any>event.body;
          this.sign_logo = this.serverService.get_site_upload_image() + "/" + this.serverService.get_path_upload_image() + a.result.filename;
          this.sign_logo_bin = true;
          this.sign_logo_info = {
            'site': this.serverService.get_site_upload_image(),
            'path': this.serverService.get_path_upload_image() + a.result.filename
          }
        }
      }
    )
  }
  delete_sign_logo() {
    this.sign_logo = this.serverService.get_default_image();
    this.sign_logo_bin = false;
    this.sign_logo_info = {
      path: '',
      site: ''
    }
  }
  //************************************************************************************************ */
  load_services(id: number): any {
    var where; var code_id;
    if (id == 213) {
      code_id = 6568;
    }
    if (id == 155) {
      code_id = 6953;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      data: {
        id: id, where: where, creator: this.creator, code_id: code_id
        , obj: { level: this.level, lang: this.lang }
      }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          if (id == 213) {
            this.form1.patchValue({
              'login_service_title': result.title,
              'login_service': result.id
            })
          }
          if (id == 10) {
            this.form1.patchValue({
              'sex_title': result.title,
              'sex': result.id
            });
          }
          if (id == 155) {
            this.form1.patchValue({
              'group_title': result.title,
              'group': result.id
            });
          }
          if (id == 26) {
            this.form1.patchValue({
              'status_title': result.title,
              'status_id': result.id
            });
          }
        }
      }
    )
  }
  reset_input(id: number) {
    if (id == 213) {
      this.form1.patchValue({
        'login_service_title': null,
        'login_service': null
      })
    }
    if (id == 10) {
      this.form1.patchValue({
        'sex_title': null,
        'sex': null
      });
    }
    if (id == 155) {
      this.form1.patchValue({
        'group_title': null,
        'group': null
      })
    }
    if (id == 26) {
      this.form1.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}