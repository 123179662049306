<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title class="matDialogTitle" cdkDragHandle>
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
        {{ title }}
        <span class="close" (click)="close()">
            <mat-icon>close</mat-icon>
        </span>
    </div>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent" style="padding:0px !important">
        <div class="container-fluid" style="padding:0px">
            <div class=" row" style="direction: rtl;">
                <div class='col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                    style='margin-top:0px'>
                    <label for="text" class='wh-label'>
                        <textarea style="width:100%;height:26rem" class='wh-input' formControlName='text'
                            autocomplete="off" [attr.disabled]="level != 1 ? true : null">
                        </textarea>
                    </label>
                </div>
            </div><!-- row -->
        </div>
    </div> <!-- content -->


    <div mat-dialog-actions align="start" class="pe1 matDialogActions" [ngClass]="{en1:lang==2}"
        style="padding-right:25px" *ngIf="level == 1">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang==2}" [disabled]="!form1.valid"
            *ngIf="type_task != 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div><!-- row -->
</form>