import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import * as Highcharts from 'highcharts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);


@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent implements OnInit {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang = JSON.parse(<any>localStorage.getItem('lang'));
  public server: any = this.serverService.get_server();
  public loading: boolean = false;
  public user_id!: number;
  public creator!: number;
  public subscription: Subscription | any;
  public Highcharts1 = Highcharts;
  public Highcharts2 = Highcharts;
  public currecny_title: string = "";
  public chartOptions1: any;
  public chartOptions2: any;
  public year: number = 1403;
  public person_count: number = 0;

  constructor(@Inject(DOCUMENT) private document: Document, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public _matDialogRef: MatDialogRef<BarchartComponent>,
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public matSnackBar: MatSnackBar) {

    if (this.dialog_data) {
      this.creator = dialog_data.creator;
      this.person_count = dialog_data.count;
    }

  }

  ngOnInit() {

    this.get_default_currency().then((data) => {

      this.currecny_title = data.toString();

      this.fetch_chart_Data();

    });


  }

  fetch_chart_Data() {

    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }

    this.loading = true;

    let categories: any = [];

    let sale_data_list: any = [];

    let service_data_list: any = [];

    for (var i = 1403; i > 1400; i--) {

      categories.push(i.toString());

      this.CalculateYearData(i).then((res) => {

        let f_data: SaleData = { sum_total: res['sum_total_sale'], data: res['result_sale'], year: i.toString() };

        sale_data_list.push(f_data);

        //khademat

        let k_data: SaleData = { sum_total: res['sum_total_service'], data: res['result_service'], year: i.toString() };

        service_data_list.push(k_data);

        if (sale_data_list.length == 3) {

          this.loading = false;

          const dataMap: { [key: string]: number[], } = {};

          sale_data_list.forEach((element) => {

            element.data.forEach((innerElement) => {

              if (!dataMap[innerElement.name]) {

                dataMap[innerElement.name] = [];
              }

              dataMap[innerElement.name].push(...innerElement.data);
            });
          });

          let sale_result = Object.keys(dataMap).map((name) => ({ name, data: dataMap[name] }));

          this.chartOptions1 = {
            chart: {
              type: 'bar'
            },
            title: {
              text: 'نمودار میله ای سود ناخاص فروش براساس پرسنل'
            },
            xAxis: {
              categories: categories
            },
            exporting: {
              enabled: true,
              buttons: {
                contextButton: {
                  menuItems: [
                    'printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'

                  ],
                  menuItemDefinitions: {
                    printChart: {
                      text: 'پرینت نمودار'
                    },
                    downloadPNG: {
                      text: 'دانلود png'
                    },
                    downloadJPEG: {
                      text: ' دانلود jpeg '
                    },
                    downloadPDF: {
                      text: ' دانلود pdf '
                    },
                    downloadSVG: {
                      text: ' دانلود svg '
                    }
                  }
                }
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              }
            },
            tooltip: {
              pointFormat: this.currecny_title + '{point.y:1f}'
            },
            legend: {
              reversed: true
            },
            plotOptions: {
              series: {
                stacking: 'normal',
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: sale_result
          };

          {
            const dataMap: { [key: string]: number[] } = {};

            service_data_list.forEach((element: any) => {

              element.data.forEach((innerElement: any) => {

                if (!dataMap[innerElement.name]) {

                  dataMap[innerElement.name] = [];

                }

                dataMap[innerElement.name].push(...innerElement.data);

              });
            });

            const servie_result = Object.keys(dataMap).map((name) => ({ name, data: dataMap[name] }));


            this.chartOptions2 = {
              chart: {
                type: 'bar'
              },
              title: {
                text: ' نمودار میله ای سود ناخاص خدمات براساس پرسنل'
              },
              xAxis: {
                categories: categories
              },
              tooltip: {
                pointFormat: '{point.y:1f}'+ this.currecny_title 
              },
              yAxis: {
                min: 0,
                title: {
                  text: ''
                }
              },
              exporting: {
                enabled: true,
                buttons: {
                  contextButton: {
                    menuItems: [
                      'printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'

                    ],
                    menuItemDefinitions: {
                      printChart: {
                        text: 'پرینت نمودار'
                      },
                      downloadPNG: {
                        text: 'دانلود png'
                      },
                      downloadJPEG: {
                        text: ' دانلود jpeg '
                      },
                      downloadPDF: {
                        text: ' دانلود pdf '
                      },
                      downloadSVG: {
                        text: ' دانلود svg '
                      }
                    }
                  }
                }
              },
              legend: {
                reversed: true
              },
              plotOptions: {
                series: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: true
                  }
                }
              },
              series: servie_result
            };

            this.loading = false;
          }
        }
      });
    }
  }


  async CalculateYearData(year: number): Promise<any> {
    return new Promise((resolve, reject) => {

      var obj = {
        address: 7097,
        year: year,
        creator: this.creator
      }

      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {

          this.year = year;

          if (res['status'] == 1) {

            resolve(res);
          }
          else {
            reject(new Error("It broke"));
          }

        });
    });

  }


  formatNumberWithCommas(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + this.currecny_title;
  }

  get_default_currency(): Promise<string> {

    return new Promise((resolve, reject) => {

      if (this.serverService.check_internet() == false) {
        this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
        return false;
      }//end if
      else { this.matSnackBar.dismiss(); }
      this.loading = true;
      var obj = {
        address: 6115,
        creator: this.creator
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {

            this.currecny_title = res['result'][0].site_currency_title;

            this.message(false, "", 1, this.messageService.close(this.lang));

            resolve(this.currecny_title)

          }//end if
          else {

            this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));

            reject("error");
          }
        }
      )
    });

  }


  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end

  close() {
    this._matDialogRef.close();
  }

  getMonthName(monthNumber: number): string {
    switch (monthNumber) {
      case 1:
        return 'فروردین';
      case 2:
        return 'اردیبهشت';
      case 3:
        return 'خرداد';
      case 4:
        return 'تیر';
      case 5:
        return 'مرداد';
      case 6:
        return 'شهریور';
      case 7:
        return 'مهر';
      case 8:
        return 'آبان';
      case 9:
        return 'آذر';
      case 10:
        return 'دی';
      case 11:
        return 'بهمن';
      case 12:
        return 'اسفند';
      default:
        return '';
    }
  }
}
interface SaleData {
  sum_total: number;
  data: [number][];
  year: string;
}
