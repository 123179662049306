<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="direction: rtl;display: flex !important;">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="جستجو..." autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager">
            <span>
                <button (click)='open_detaile(0,1,0)' [disabled]='new_status' *ngIf="lang == 1">جديد</button>
                <button (click)='open_detaile(0,1,0)' [disabled]='new_status' *ngIf="lang == 2"
                    [ngClass]="{en_navigation_number:lang==2}">New</button>
                <button (click)='open_detaile(0,3,0)' *ngIf="lang == 1">فیلتر</button>
                <button (click)='open_detaile(0,3,0)' *ngIf="lang == 2"
                    [ngClass]="{en_navigation_number:lang==2}">Filter</button>
                <a id="download" href="{{ site }}/export/{{ export_code }}.xlsx" *ngIf="show_excel == true">
                    <button [ngClass]="{en_navigation_number:lang==2}">
                        <span *ngIf="lang == 2">Export Excel file</span>
                        <span *ngIf="lang == 1">خروجی اکسل</span>
                    </button>
                </a>
                <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                        class="fas fa-fast-forward"></i></button>
                <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                        class="fas fa-forward"></i></button>
                <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                        class="fas fa-backward"></i></button>
                <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                        class="fas fa-fast-backward"></i></button>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="material-icons pager_icons cursor" (click)="check_access(3,0,selected_record,1,1)">
                delete
            </div>
            <div style="display: inline-block;">
                <mat-checkbox (change)="get_select($event.checked)" [(indeterminate)]="indeterminate"></mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span><input (change)="set_title('title0',$event)" [(ngModel)]="title0" name="title0" autocomplete="off"
                        [readonly]="user_id != 20098 && user_id != 2445"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i + page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>
                <span><input (change)="set_title('title1',$event)" [(ngModel)]="title1" name="title1" autocomplete="off"
                        [readonly]="user_id != 20098 && user_id != 2445"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_services_id)">{{
                x.site_services_title }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="sgroup">
            <mat-header-cell *matHeaderCellDef>
                <span><input (change)="set_title('title2',$event)" [(ngModel)]="title2" name="title2" autocomplete="off"
                        [readonly]="user_id != 20098 && user_id != 2445"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_services_id)">{{
                x.site_software_title }}</mat-cell>
        </ng-container>


        <ng-container matColumnDef="sort">
            <mat-header-cell *matHeaderCellDef [hidden]="true">
                <span><input (change)="set_title('title3',$event)" [(ngModel)]="title3" name="title3" autocomplete="off"
                        [readonly]="user_id != 20098 && user_id != 2445"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="true" (click)="open_detaile(i,2,x.site_services_id)">
                {{ x.site_services_sort }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <span><input (change)="set_title('title4',$event)" [(ngModel)]="title4" name="title4" autocomplete="off"
                        [readonly]="user_id != 20098 && user_id != 2445"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_services_id)">
                <span>{{ x.status_title }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span><input (change)="set_title('title5',$event)" [(ngModel)]="title5" name="title5" autocomplete="off"
                        [readonly]="user_id != 20098 && user_id != 2445"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.site_services_id)"
                style="max-width:70px">{{
                x.site_services_id }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:100px'>
                <span><input (change)="set_title('title20',$event)" [(ngModel)]="title20" name="title20"
                        autocomplete="off" [readonly]="user_id != 20098 && user_id != 2445"></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px'>
                <span (click)='open_detaile(i,2,x.site_services_id)'><i class="fas fa-desktop icon"></i></span>
                <mat-checkbox class="operation-icon" (change)="get_select_records(i)" name="selete_records[]"
                    [value]="x.site_services_id" [checked]="x.checked" class="hidden-sm hidden-xs"></mat-checkbox>
                <span class="operation-icon" (click)="check_access(3,0,x.site_services_id,0,1)"><i
                        class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
            </mat-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ no_record }}</div>
        </div>
    </div>
</div>