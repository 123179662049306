import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';
import { WharehouseOrderMaterialListComponent } from '../../wharehouse-reception/wharehouse-order-material-list/wharehouse-order-material-list.component';
import { WharehouseOrderMaterialAddComponent } from '../wharehouse-order-material-add/wharehouse-order-material-add.component';

@Component({
  selector: 'app-wharehouse-order-rmaterial-detaile',
  templateUrl: './wharehouse-order-rmaterial-detaile.component.html',
  styleUrls: ['./wharehouse-order-rmaterial-detaile.component.scss']
})
export class WharehouseOrderRmaterialDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading: boolean = false;
  public group: number = 0;
  public temp_number: number = 0;
  public material_id: number = 0;
  public type_task!: number;
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public server!: any;
  public user_id!: number;
  public id!: number;
  public code!: number;
  public reception_id!: number;
  public ref!: string;
  public ref2!: string;
  public invoice_id!: number;
  public token!: number;
  public trash!: number;
  public creator!: number;
  public level!: number;
  public buy_order_id!: number;
  public financial_id!: number;
  public result!: any;
  public price_buy!: number;

  constructor(
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<WharehouseOrderRmaterialDetaileComponent>) {
    this.server = this.serverService.get_server();

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.ref = dialog_data.ref;
      this.ref2 = dialog_data.ref2;
      this.trash = dialog_data.trash;
      this.invoice_id = dialog_data.invoice_id;
      this.reception_id = dialog_data.reception_id;
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.token = dialog_data.token;
      this.code = dialog_data.code;
      if (this.type_task == 1) {
        this.check_access(5);
      }
      if (this.type_task == 2) {
        this.check_access(4);
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'comment': new FormControl(null),
      'cost': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'discount': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'profit': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'inventory': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'inventory_all_material': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'number': new FormControl(null, [Validators.pattern('[0-9.]{1,}')]),
      'material_title': new FormControl(null),
      'material': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'tax_title': new FormControl(null),
      'tax': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'tax_price': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'group_title': new FormControl(null),
      'repairman_title': new FormControl(null),
      'repairman': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'price_buy': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2) {
      this.form1_group.controls['material_title'].disable();
      this.form1_group.controls['material'].disable();
    }
    this.form1_group.controls['inventory'].disable();
    this.form1_group.controls['inventory_all_material'].disable();
    this.form1_group.controls['group_title'].disable();
    this.form1_group.controls['price_buy'].disable();
  }
  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            this.level = res['level'];
            if (type_task == 1 || type_task == 2) {
              if (this.form1_group.value.cost < this.price_buy) {
                var x = confirm("قیمت فروش کمتر از قیمت خرید می باشد آیا ادامه می دهید ؟");
                if (x == true) {
                  if (type_task == 1) {
                    this.check_for_insert();
                  }
                  if (type_task == 2) {
                    this.check_for_update1();
                  }
                }
              }
              else {
                if (type_task == 1) {
                  this.check_for_insert();
                }
                if (type_task == 2) {
                  this.check_for_update1();
                }
              }
            }
            if (type_task == 5) {
              this.get_default_repairman();
            }
            if (type_task == 4) {
              this.get_data();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7169, id: this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'price_buy': res['result_order_buy'][0].price_buy,
              'inventory': res['result_order_buy'][0].inventory,
              'inventory_all_material': res['result_order_sale'][0].wharehouse_material_remain,
              'cost': res['result_order_sale'][0].wharehouse_order_cost,
              'discount': res['result_order_sale'][0].wharehouse_order_discount,
              'profit': res['result_order_sale'][0].wharehouse_order_profit,
              'comment': res['result_order_sale'][0].wharehouse_order_comment,
              'material_title': res['result_order_sale'][0].wharehouse_material_title,
              'material': res['result_order_sale'][0].wharehouse_material_id,
              'tax_price': res['result_order_sale'][0].wharehouse_order_tax_price,
              'number': res['result_order_sale'][0].wharehouse_order_number,
              'group_title': res['result_order_sale'][0].wharehouse_group_title + " | " + res['result_order_sale'][0].wharehouse_material_cate_title,
              'repairman': res['result_order_sale'][0].repairman,
              'repairman_title': res['result_order_sale'][0].repairman_title,
            });
            if (res['result_order_sale'][0].wharehouse_material_coding) {
              this.form1_group.patchValue({
                'group_title': res['result_order_sale'][0].wharehouse_group_title + " | " + res['result_order_sale'][0].wharehouse_material_cate_title + " | " + res['result_order_sale'][0].wharehouse_material_coding,
              })
            }
            if (res['result_order_sale'][0].finance_tax_id) {
              this.form1_group.patchValue({
                'tax_title': res['result_order_sale'][0].finance_tax_title + " | " + res['result_order_sale'][0].wharehouse_order_tax_price + "%",
                'tax': res['result_order_sale'][0].finance_tax_id,
              })
            }
            this.price_buy = res['result_order_buy'][0].price_buy;
            this.material_id = res['result_order_sale'][0].wharehouse_material_id;
            this.buy_order_id = res['result_order_sale'][0].wharehouse_order_consumed_invoice;
            this.temp_number = res['result_order_sale'][0].wharehouse_order_number;
          }//end if
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //********************************************************************************************************************* */
  check_for_insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (this.form1_group.value.discount > (this.form1_group.value.cost * this.form1_group.value.number)) {
      var message = "مقدار تخفیف نمی تواند بیشتر از مقدار قیمت کالا باشد";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 7170,
      'id': this.id,
      'token': this.token,
      'order_id': this.buy_order_id,
      'number': this.form1_group.value.number,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if ((this.form1_group.value.number <= res['remain'])
          ) {
            this.insert();
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert() {
    var obj = {
      'address': 7167,
      'creator': this.creator,
      "user_id": this.user_id,
      'buy_order_id': this.buy_order_id,
      'repairman': this.form1_group.value.repairman,
      'service': this.form1_group.value.service,
      'profit': this.form1_group.value.profit,
      'tax': this.form1_group.value.tax,
      'tax_price': this.form1_group.value.tax_price,
      'number': this.form1_group.value.number,
      'cost': this.form1_group.value.cost,
      'comment': this.form1_group.value.comment,
      'discount': this.form1_group.value.discount,
      'reception_id': this.reception_id,
      'material_id': this.material_id,
      'price_buy': this.price_buy
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close({ result: this.result })
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  //*************************************************************************************************************** */
  check_for_update1(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (this.form1_group.value.number == this.temp_number || this.form1_group.value.number < this.temp_number) {
      this.update();
    }
    else {
      this.loading = true;
      //6003 check_order_id
      var obj = {
        'address': 7170,
        'token': this.token,
        'order_id': this.buy_order_id,
        'creator': this.creator,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if ((this.form1_group.value.number <= (res['remain'] + Number(this.temp_number)))
            ) {
              this.update();
            }
            else {
              var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
            }
          }//end if
          else {
            this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
          }
        }
      )
    }
  }
  update(): any {
    if (this.form1_group.value.discount > this.form1_group.value.cost * this.form1_group.value.number) {
      var pe_message = "مقدار تخفیف نمی تواند بیشتر از قیمت اصلی باشد";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 7168,
      "user_id": this.user_id,
      "price_buy": this.price_buy,
      'comment': this.form1_group.value.comment,
      'cost': this.form1_group.value.cost,
      'profit': this.form1_group.value.profit,
      'material': this.material_id,
      'tax': this.form1_group.value.tax,
      'tax_price': this.form1_group.value.tax_price,
      'number': this.form1_group.value.number,
      'discount': this.form1_group.value.discount,
      'repairman': this.form1_group.value.repairman,
      'id': this.id,
      'buy_order_id': this.buy_order_id,
      'reception_id': this.reception_id,
      'material_id': this.material_id,
      'creator': this.creator,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  //*************************************************************************************************************** */
  get_default_repairman() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7164, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'repairman_title': res['result'][0].user_title,
              'repairman': res['result'][0].user_id,
              'profit': res['result'][0].user_profit_repairman
            })
          }
          this.get_default_tax();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_tax() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7150, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'tax_title': res['result'][0].finance_tax_title + " | " + res['result'][0].finance_tax_percent + "%",
              'tax': res['result'][0].finance_tax_id,
              'tax_price': res['result'][0].finance_tax_percent
            })
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_profit(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 6648, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'profit': res['result'][0].user_profit_sale
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_tax_amount(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6855, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.form1_group.patchValue({
            'tax_title': res['result'][0].finance_tax_title + " | " + res['result'][0].finance_tax_percent + "%",
            'tax_price': res['result'][0].finance_tax_percent
          });

          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************************************** */
  get_materials() {
    const dialogRef = this.dialog.open(WharehouseOrderMaterialListComponent, {
      width: '75rem',
      height: 'auto',
      data: { creator: this.creator, code: this.code }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.get_order_id(res.id)
        }
      }
    )
  }
  get_order_id(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7166, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'material_title': res['result'][0].wharehouse_material_title,
            'material': res['result'][0].wharehouse_material_id,
            'inventory': res['result'][0].inventory,
            'number': 1,
            'inventory_all_material': res['result'][0].wharehouse_material_remain,
            'group_title': res['result'][0].wharehouse_group_title + " | " + res['result'][0].wharehouse_material_cate_title,
            'cost': res['result'][0].wharehouse_material_price2,
            'price_buy': res['result'][0].wharehouse_order_cost,
          });
          if (res['result'][0].wharehouse_material_coding) {
            this.form1_group.patchValue({
              'group_title': res['result'][0].wharehouse_group_title + " | " + res['result'][0].wharehouse_material_cate_title + " | " + res['result'][0].wharehouse_material_coding,
            })
          }
          this.price_buy = res['result'][0].wharehouse_order_cost;
          this.buy_order_id = res['result'][0].wharehouse_order_id;
          this.material_id = res['result'][0].wharehouse_material_id;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  add_material() {
    const dialogRef = this.dialog.open(WharehouseOrderMaterialAddComponent, {
      width: '30rem',
      height: 'auto',
      data: { creator: this.creator, ref: 'reception' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'material_title': res.wharehouse_material_title,
            'material': res.wharehouse_material_id,
            'inventory': res.inventory,
            'inventory_all_material': res.inventory,
            'group_title': res.wharehouse_group_title + " | " + res.wharehouse_material_cate_title,
            'cost': res.wharehouse_material_price2,
            'price_buy': res.wharehouse_order_cost,
            'number': 1,
          });
          if (res.wharehouse_material_coding) {
            this.form1_group.patchValue({
              'group_title': res.wharehouse_group_title + " | " + res.wharehouse_material_cate_title + " | " + res.wharehouse_material_coding,
            })
          }
          this.price_buy = res.wharehouse_order_cost;
          this.buy_order_id = res.wharehouse_order_id;
          this.material_id = res.wharehouse_material_id;
        }
      }
    )
  }
  //******************************************************************************************************************* */
  load_services(id: number): any {
    var code_id;
    if (id == 223) {
      code_id = 6346;
    }
    if (id == 220) {
      code_id = 6227;
    }
    if (id == 268) {
      code_id = 6856;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator, code_id: code_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 171) {
            this.form1_group.patchValue({
              'material': res.id,
              'material_title': res.title
            })
          }
          if (id == 220) {
            this.form1_group.patchValue({
              'repairman': res.id,
              'repairman_title': res.title
            });
            this.get_profit(res.id);
          }
          if (id == 268) {
            this.form1_group.patchValue({
              'tax': res.id,
              'tax_title': res.title,
            });
            this.get_tax_amount(res.id);
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (id == 171) {
      this.form1_group.patchValue({
        'material': null,
        'material_title': null,
        'group_title': null,
        'price': null,
        'inventory': null,
        'inventory_all_material': null,
      })
    }
    if (id == 220) {
      this.form1_group.patchValue({
        'repairman': null,
        'repairman_title': null
      })
    }
    if (id == 268) {
      this.form1_group.patchValue({
        'tax': null,
        'tax_title': null,
        'tax_price': null,
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required(): any {
    return true;
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

