import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../../service/server/server.service';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-finance-bank-detaile',
  templateUrl: './finance-bank-detaile.component.html',
  styleUrls: ['./finance-bank-detaile.component.scss']
})
export class FinanceBankDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public change: boolean = false;
  public payment_bank: boolean = false;
  public transfer: any = false;
  public sum: number = 0;
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public result: any = [];
  public list_record: any = [];
  public list_record2: any = [];
  public user_id!: number;
  public type_task!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public financial!: number;
  public user!: number;
  public close_after_save!: string;
  public document!: number;
  public document_type!: number;
  public token!: number;
  public ref!: any;
  public creator!: number;
  public obj!: any;
  public video!: string;
  public video2!: string;
  public row_id!: number;
  public mat_table_selectedRow!: any;
  public dataSource!: any;
  public displayedColumns = ['check', 'title', 'account_bank_name', 'account_branch_name', 'account_branch_number', 'account_branch_remain'];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<FinanceBankDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.ref = dialog_data.ref;
      this.payment_bank = dialog_data.payment_bank;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.creator = dialog_data.creator;
      if (this.type_task == 3) {
        if (dialog_data) {
          this.form1_group.patchValue({
            account: dialog_data.search_array.account,
            date3: dialog_data.search_array.date3,
            date4: dialog_data.search_array.date4,
          })
        }
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_movie();
    this.get_accounts();
  }
  get_accounts(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    this.loading = true;
    //6802
    //6896
    var obj = { 'address': 7130, creator: this.creator }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        this.list_record2 = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end recieve_data
  get_bg(id: number) {
    this.row_id = id;
  }
  get_movie(): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: 157 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.video = res['result'][0].video;
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  create_form1() {
    if (this.type_task == 3) { }
    this.form1_group = new FormGroup({
      'account': new FormControl(),
      'date3': new FormControl(),
      'date4': new FormControl(),
    });
  }
  search() {
    this.matDialogRef.close({
      date3: this.form1_group.value.date3,
      date4: this.form1_group.value.date4,
      account: this.form1_group.value.account,
      export_excel: 1
    })
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6095, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'amount': res['result'][0].finance_financial2_amount,
            'comment': res['result'][0].finance_financial2_comment,
            'date': res['result'][0].finance_financial2_date,
            'user': res['result'][0].finance_financial_user,
            'user_title': res['result'][0].user_title,
            'payment_type': res['result'][0].finance_financial2_payment_type,
            'payment_type_title': res['result'][0].finance_payment_type_title,
            'account_title': res['result'][0].site_bank_accounts_number,
            'account': res['result'][0].site_bank_accounts_id,
            'bank_title': res['result'][0].site_bank_title,
            'bank': res['result'][0].site_bank_accounts_bank,

          }
          );
          this.payment_bank = res['result'][0].finance_payment_type_bank;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
