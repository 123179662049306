<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="div_loading">
    <div class="my-container" [hidden]="!loading">
      <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
  </div>
  <span>
    {{ serverService.get_title(type_task,lang) }}
  </span>
  <span (click)="close()">
    <mat-icon class="close">close</mat-icon>
  </span>
</div>
<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
  <div mat-dialog-content class="matDialogContent">

    <div class="row" dir="rtl">


      <div class="offset-lg-1 col-lg-11 col-md-12 col-12">

        <div class="row pe1">

          <div class='col-12 col-lg-12 pe1 div_panding'>
            <label for="title" class='wh-label'>
              <span> موضوع تیکت را وارد کنید</span>

              <input class="wh-input mt-1" formControlName="userTicketTitle">
            </label>
          </div>

          <div class='col-xl-5 col-lg-10 col-md-8 col-sm-10 col-12 pe1 div_panding'>
            <label for="userTicketDepartmentId" class='wh-label'>

              <mat-form-field appearance="fill">
                <mat-label>دپارتمان خود را انتخاب کنید</mat-label>
                <mat-select formControlName="userTicketDepartmentId">
                  <mat-option *ngFor="let x of list_departments" [value]="x.ticket_department_id">
                    {{ x.ticket_department_title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </label>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ejs-richtexteditor id='defaultRTE' formControlName="ticket_text">
          <ng-template #valueTemplate>
            <p></p>
          </ng-template>
        </ejs-richtexteditor>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6 col-md-6 col-sm-8 pt-2" dir="rtl" style="text-align: right;">
        <span > ضمیمه</span>
        <input type="file" class="form-control-file mt-1" id="attachment" (change)="onFileChange($event)" formControlName="userTicketAttachment">
        <div class="invalid-feedback" *ngIf="form1_group.get('userTicketAttachment').invalid && (form1_group.get('userTicketAttachment').dirty || form1_group.get('userTicketAttachment').touched)">
         یک فایل انتخاب کنید
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="matDialogActions">
    <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" *ngIf="type_task == 1 || type_task == 2">
      {{ serverService.get_event_title(type_task,lang) }}
    </button>
    <button type="submit" class="btn_pe" *ngIf="type_task == 3">
      {{ serverService.get_event_title(type_task,lang) }}
    </button>
  </div>
</form>
