<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span> تراکنش تراکنش بانکی</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row">

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding fright'>
                    <label for="payment_type" class='wh-label'>
                        <span> نوع تراکنش </span>
                        <span (click)="reset_input(177)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="payment_type_title" (click)="load_services(177) "
                            readonly required>
                        <input class="wh-input" formControlName="payment_type" [hidden]="true" required readonly>
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                    <label for="amount" class='wh-label'>
                        <span> مبلغ تراکنش </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required>
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                    <label for="bank" class='wh-label'>
                        <span> بانک </span>
                        <span (click)="reset_input(178)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="bank_title" (click)="load_services(178)" readonly
                            required>
                        <input class="wh-input" formControlName="bank" [hidden]="true" readonly required>
                    </label>
                </div>

                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                    <label for="account_title" class='wh-label'>
                        <span> شماره حساب </span>
                        <span (click)="reset_input(180)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="account_title" (click)="load_services(234)" readonly
                            required>
                        <input class="wh-input" formControlName="account" [hidden]="true" readonly required>
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                    <label for="comment" class='wh-label'>
                        <span> توضیحات </span>
                        <textarea style="height:5rem" class="wh-input" formControlName="comment">
                        </textarea>
                    </label>
                </div>

            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button [disabled]="!form1_group.valid || loading" class="btn_pe" (click)="check_access(type_task)">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>