import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from '../../service/message/message.service';
import { WharehouseOrderSaleSearialComponent } from '../../wharehouse/wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';
import { DecimalPipe } from '@angular/common';
import { RepairmanRstatusComponent } from '../../repairman/repairman-rstatus/repairman-rstatus.component';
import { WharehouseOrderCostsDetaileComponent } from '../../wharehouse-reception/wharehouse-order-costs-detaile/wharehouse-order-costs-detaile.component';
import { WharehouseOrderRserviceDetaileComponent } from '../../wharehouse-reception/wharehouse-order-rservice-detaile/wharehouse-order-rservice-detaile.component';
import { WharehouseOrderRmaterialDetaileComponent } from '../../wharehouse-reception/wharehouse-order-rmaterial-detaile/wharehouse-order-rmaterial-detaile.component';

import Num2persian from 'num2persian';

@Component({
  selector: 'app-wharehouse-exit2',
  templateUrl: './wharehouse-exit2.component.html',
  styleUrls: ['./wharehouse-exit2.component.scss'],
  providers: [DecimalPipe]
})
export class WharehouseExit2Component implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id!: number;
  public subscription: Subscription | any;
  public subscription2: Subscription | any;
  public list_record: any = [];
  public search!: string;
  public search_code!: string;
  public group!: number;
  public cate!: number;
  public reception_id!: number;
  public rstatus_title: string;
  public list_device_status: any = [];
  public ref: string | undefined;
  public type: number | undefined;
  public list_invoice: any = [];
  public new_invoice_id: number;
  public sum_all: number = 0;
  public sum_all_services: number;
  public sum_all_material: number;
  public sum_all_costs: number;
  public code: number | undefined;
  public id: number | undefined;
  public status_id: number | undefined;
  public shelf_temp: number | undefined;
  public level: number = 0;
  public creator: number | undefined;
  public discount_all: number = 0;
  public sum_tax_all: number = 0;
  public sum_all_without_discount: number = 0;
  public readonly: boolean = false;
  public count: number = 0;
  public count_order: number = 0;
  public repairing: boolean = false;
  public show_cellphone: boolean = true;
  public count_total: number = 1;
  public list_repairman: any = [];
  public currency_title!: string;
  public sum_all_letter!: string;
  public customer_cellphone!: string;
  public customer_title!: string;
  public customer_id!: string;
  public repairman_title!: string;
  public repairman_id!: string;
  public rstatus_temp!: number;
  public default_rstatus!: number;
  public repairman!: any;
  public delivery!: number;
  public device_status!: number;
  public pre_status!: boolean;
  public new_status!: boolean;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public device_title!: string;
  public brand_title!: string;
  public model_title!: string;
  public displayedColumns3 = ['row', 'type', 'material', 'number', 'unit', 'cost', 'discount', 'tax', 'sum', 'repairman', 'searial', 'operation'];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<WharehouseExit2Component>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public essageService: MessageService,
    public _decimalPipe: DecimalPipe) {

    this.subscription2 = this.serverService.get_material().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift(res);
          this.dataSource.data = this.list_record;
        }
      }
    )
    if (dialog_data) {
      this.default_rstatus = dialog_data.default_rstatus;
      this.ref = dialog_data.ref;
      this.group = dialog_data.group;
      this.cate = dialog_data.cate;
      this.type = dialog_data.type;
      this.reception_id = dialog_data.reception_id;
      this.code = dialog_data.code;
      this.id = dialog_data.reception_id;
      this.creator = dialog_data.creator;
      this.level = dialog_data.level;
      if (dialog_data.readonly) this.readonly = dialog_data.readonly;
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_data(1);
    this.get_rstatus_title();
    this.get_currnecy();
    this.get_device_status();
  }
  get_search() {
    this.get_data(2);
  }
  get_data(loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) {
      this.loading = true;
      this.search = '';
    }
    var obj = {
      address: 1862,
      'reception_id': this.id,
      'user_id': this.user_id,
      'search': this.search,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record = [];
          this.sum_all = 0;
          this.sum_all_material = 0;
          this.sum_all_services = 0;
          this.sum_all_costs = 0;
          this.discount_all = 0;
          this.sum_all_without_discount = 0;
          this.count_order = res['num'];
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.get_amounts();
          this.count = res['num'];
          this.dataSource = new MatTableDataSource(this.list_record);
          this.count_total = res['count'];
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end recieve_data
  get_amounts() {
    this.sum_all = 0;
    this.sum_all_material = 0;
    this.sum_all_services = 0;
    this.sum_all_costs = 0;
    this.discount_all = 0;
    this.sum_tax_all = 0;
    this.sum_all_without_discount = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.sum_all = this.sum_all + this.list_record[i].sum;
      this.sum_all_without_discount = this.sum_all_without_discount + this.list_record[i].cost;
      this.sum_all_letter = Num2persian(this.sum_all);
      if (this.list_record[i].wharehouse_order_material_type == 1) {
        this.sum_all_material += this.list_record[i].wharehouse_order_cost * this.list_record[i].wharehouse_order_number;
      }
      if (this.list_record[i].wharehouse_order_material_type == 2) {
        this.sum_all_services += this.list_record[i].wharehouse_order_cost * this.list_record[i].wharehouse_order_number;
      }
      if (this.list_record[i].wharehouse_order_material_type == 4) {
        this.sum_all_costs += this.list_record[i].wharehouse_order_cost * this.list_record[i].wharehouse_order_number;
      }
      this.sum_tax_all += this.list_record[i].tax;
      this.discount_all += this.list_record[i].wharehouse_order_discount;
    }
  }
  //**************************************************************************** */
  check_for_access(i: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var creator = this.list_record[i].wharehouse_order_creator;
    var id = this.list_record[i].wharehouse_order_id;
    var material_type = this.list_record[i].wharehouse_order_material_type;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_repairman == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                this.open_material(i, id)
              }
              else if (material_type == 2) {
                this.open_services(i, id);
              }
              else if (material_type == 4) {
                this.open_costs(i, id);
              }
              this.message(false, "", 1, this.messageService.close(this.lang));
            } else {
              var pe_message = "شما دسترسی برای تغییرات را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  access_for_delete(i: number) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var repairman = this.list_record[i].wharehouse_order_repairman;
    var creator = this.list_record[i].wharehouse_order_creator;
    var id = this.list_record[i].wharehouse_order_id;
    var material_type = this.list_record[i].wharehouse_order_material_type;
    var material_id = this.list_record[i].wharehouse_order_material;
    var buy_order_id = this.list_record[i].wharehouse_order_consumed_invoice;
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6459, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].user_access_change_in_repairman == 1 || this.level == 2 || creator == this.user_id) {
              if (material_type == 1) {
                this.delete_material(id, repairman, material_id, buy_order_id);
              }
              else if (material_type == 2) {
                this.delete_service(id, repairman);
              }
              else if (material_type == 4) {
                this.delete_costs(id);
              }
            }
            else {
              var pe_message = "شما دسترسی برای حذف را ندارید";
              this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              return false;
            }
          }
        }//end if
        else {
          var pe_message = "خطا در بررسی دسترسی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************** */
  insert_material() {
    const dialogRef = this.dialog.open(WharehouseOrderRmaterialDetaileComponent, {
      width: '40rem',
      height: 'auto',
      data: { reception_id: this.reception_id, creator: this.creator, type_task: 1, code: this.code }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.push(res.result);
          this.dataSource.data = this.list_record;
          this.get_amounts();
        }
      }
    )
  }
  open_material(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderRmaterialDetaileComponent, {
      width: '40rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code, reception_id: this.reception_id, }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].tax = res.result.tax;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].tax = res.result.tax;
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].repairman_id = res.result.repairman_id;
          this.list_record[i].repairman_id = res.result.repairman_id;
          this.list_record[i].wharehouse_order_comment = res.result.wharehouse_order_comment;
          this.get_amounts();
        }
      }
    )
  }
  delete_material(id: number, repairman: number, material_id: number, buy_order_id: number) {
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 7171,
        "user_id": this.user_id,
        "reception_id": this.reception_id,
        "material_id": material_id,
        "buy_order_id": buy_order_id,
        "repairman": repairman,
        "creator": this.creator,
        'id': id,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.count_total = this.list_record.length;
            this.get_amounts();
            this.count--;
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //*********************************************************************************************** */
  insert_cost() {
    const dialogRef = this.dialog.open(WharehouseOrderCostsDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { reception_id: this.reception_id, creator: this.creator, type_task: 1 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.push(res);
          this.dataSource.data = this.list_record;
          this.get_amounts();
        }
      }
    )
  }
  open_costs(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderCostsDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, creator: this.creator, type_task: 2, reception_id: this.reception_id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].title = res.orders_costs_title;
          this.list_record[i].wharehouse_order_cost = res.wharehouse_order_cost;
          this.list_record[i].sum = res.sum;
          this.get_amounts();
        }
      }
    )
  }
  delete_costs(id: number) {
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 7160,
        "user_id": this.user_id,
        "reception_id": this.reception_id,
        "creator": this.creator,
        'id': id,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.count_total = this.list_record.length;
            this.get_amounts();
            this.count--;
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //***************************************************************************************************************** */
  insert_service() {
    const dialogRef = this.dialog.open(WharehouseOrderRserviceDetaileComponent, {
      width: '30rem',
      height: 'auto',
      data: {
        reception_id: this.reception_id,
        creator: this.creator,
        code: this.code,
        type_task: 1
      }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        console.log(res);
        if (res) {
          this.list_record.push(res.result);
          this.dataSource.data = this.list_record;
          this.get_amounts();
        }
      }
    )
  }
  open_services(i: number, id: number) {
    const dialogRef = this.dialog.open(WharehouseOrderRserviceDetaileComponent, {
      width: '30rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code, reception_id: this.reception_id, }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record[i].repairman_title = res.result.repairman_title;
          this.list_record[i].repairman_id = res.result.repairman_id;
          this.list_record[i].wharehouse_material_title = res.result.wharehouse_material_title;
          this.list_record[i].wharehouse_order_number = res.result.wharehouse_order_number;
          this.list_record[i].wharehouse_order_discount = res.result.wharehouse_order_discount;
          this.list_record[i].wharehouse_order_comment = res.result.wharehouse_order_comment;
          this.list_record[i].wharehouse_order_cost = res.result.wharehouse_order_cost;
          this.list_record[i].tax = res.result.tax;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].title = res.result.title;
          this.list_record[i].cost = res.result.cost;
          this.list_record[i].sum = res.result.sum;
          this.list_record[i].sum_without_discount = res.result.sum_without_discount;
          this.get_amounts();
        }
      }
    )
  }
  delete_service(id: number, repairman: number) {
    var confirm_delete;
    confirm_delete = window.confirm(this.serverService.message_delete_accept());
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 7156,
        "user_id": this.user_id,
        "reception_id": this.reception_id,
        "repairman": repairman,
        "creator": this.creator,
        'id': id,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_order_id == id), 1);
            this.dataSource.data = this.list_record;
            this.count_total = this.list_record.length;
            this.get_amounts();
            this.count--;
            this.message(true, this.serverService.message_delete(id, res['result'].changedRows), 1, this.serverService.message_action());
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //************************************************************************** */
  get_rstatus_title() {
    var obj = { address: 7103, id: this.id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.rstatus_title = res['result'][0].repairman_rstatus_title;
          this.status_id = res['result'][0].repairman_rstatus_id;
          this.rstatus_temp = res['result'][0].repairman_rstatus_id;
          this.shelf_temp = res['result'][0].repairman_reception_shelf;
          this.repairman = res['result'][0].repairman_reception_repairman;
          this.delivery = res['result'][0].repairman_rstatus_deliverd;
          this.repairing = res['result'][0].repairman_rstatus_repairing;
          this.customer_title = res['result'][0].user_title;
          this.customer_id = res['result'][0].user_id;
          this.customer_cellphone = res['result'][0].user_cellphone;
          this.device_status = res['result'][0].repairman_reception_device_status;
          this.device_title = res['result'][0].wharehouse_device_title;
          this.brand_title = res['result'][0].repairman_brand_title;
          this.model_title = res['result'][0].repairman_model_title;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_rstatus() {
    const dialogRef = this.dialog.open(RepairmanRstatusComponent, {
      width: '55rem',
      height: 'auto',
      data: { id: this.id, readonly: this.readonly, rstatus_id: this.status_id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.get_rstatus_title_with_id(res.rstatus);
      }
    )
  }
  get_rstatus_title_with_id(id: number) {
    var obj = { address: 7104, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.rstatus_title = res['result'][0].repairman_rstatus_title;
          this.status_id = res['result'][0].repairman_rstatus_id;
          this.repairing = res['result'][0].repairman_rstatus_repairing;
          this.delivery = res['result'][0].repairman_rstatus_deliverd;;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  save_status(type: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.status_id) {
      var message = " لطفا وضعیت پذیرش را مشخص گنید";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
    }
    this.loading = true;
    var obj = {
      address: 6104,
      id: this.id,
      device_status: this.device_status,
      status_id: this.status_id,
      creator: this.creator,
      type: type
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var confirm_sms = false;
          if ((this.rstatus_temp != res['result'][0].repairman_reception_status || type == 2) && res['result'][0].user_sms2 == 1) {
            confirm_sms = window.confirm("آیا میخواهید " + res['result'][0].user_sex_title + " " + res['result'][0].user_title + " از تغییر وضعیت مطلع نمائید؟");
          }
          res['result'][0].confirm_sms = confirm_sms;
          if (res['result'][0].repairman_rstatus_empty_shelf == 1) {
            this.empty_shelf(this.shelf_temp, 0, 'empty_reception');
          } else {
            // this.serverService.send_reception_repairman();
          }
          this.matDialogRef.close(res['result'][0]);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  empty_shelf(shelf: any, new_shelf: number, type: string) {
    var obj = {
      'address': 1811,
      'shelf': shelf,
      'type': type,
      'id': this.id,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************** */
  get_currnecy() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6115, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.currency_title = res['result'][0].site_currency_title;
          }
        }//end if 
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_repairman_title(id: string, title: string) {
    this.repairman_id = id;
    this.repairman_title = title;
  }
  //******************************************************************************************** */
  get_device_status() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6465, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_device_status = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_device_status.push(res['result'][i]);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //****************************************************************************************************************************** */
  open_serial(i: number, id: number, title: string, order_buy_id: number) {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(WharehouseOrderSaleSearialComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, title: title, order_buy_id: order_buy_id, document_type_text: 'exit' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.list_record[i].searial = res.result.searial;
      }
    )
  }
  //************************************************************************************************* */
  close() {
    this.matSnackBar.dismiss();
    this.matDialogRef.close();

  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}