<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
  {{ serverService.get_title(type_task,lang) }}
  <span class="close" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>

  <div class="video_div1" (click)="open_video()" style="float: left;" *ngIf="type_task != 3">
    <mat-icon class="video">smart_display</mat-icon>
  </div>

</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task,1)">
  <div mat-dialog-content class="matDialogContent" style="padding:0px !important;padding-bottom:1rem !important">
    <div class="row">
      <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe'>
        <div class="row" [ngStyle]="{'border-bottom' : code != 219 ? 'solid 1px #ccc' : 'none'}">

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="guilds" class='wh-label'>
              <span> اصناف </span>
              <span (click)="reset_input(258)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='guilds_title' autocomplete="off" readonly
                (click)="load_services(258)">
              <input class='wh-input' formControlName='guilds' autocomplete="off" readonly [hidden]="true">
            </label>
          </div>

          <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="sex" class='wh-label'>
              <span> عنوان </span>
              <span (click)="reset_input(10)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='sex_title' autocomplete="off" [required]="required()" readonly
                (click)="load_services(10)">
              <input class='wh-input' formControlName='sex' autocomplete="off" [required]="required()" readonly
                [hidden]="true">
            </label>
          </div>

          <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="title" class='wh-label'>
              <span>نام طرف حساب </span>
              <input class='wh-input' formControlName='title' autocomplete="off" [required]="required()">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="store" class='wh-label'>
              <span> نام شرکت / فروشگاه </span>
              <input class='wh-input' formControlName='store' autocomplete="off" [required]="required() && level == 1">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="group" class='wh-label'>
              <span> گروههای کاربری </span>
              <span (click)="reset_input(155)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='group_title' autocomplete="off" (click)="load_services(155)"
                [required]="required()" readonly>
              <input class='wh-input' formControlName='group' autocomplete="off" [required]="required()" [hidden]="true"
                readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="login_service_title" class='wh-label'>
              <span> سرویس ورود </span>
              <span (click)="reset_input(213)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='login_service_title' autocomplete="off"
                (click)="load_services(213)" [required]="required()" readonly>
              <input class='wh-input' formControlName='login_service' autocomplete="off" [required]="required()"
                [hidden]="true" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="status_id" class='wh-label'>
              <span> وضعیت </span>
              <span (click)="reset_input(26)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='status_title' autocomplete="off" (click)="load_services(26)"
                [required]="required()" readonly>
              <input class='wh-input' formControlName='status_id' autocomplete="off" [required]="required()"
                [hidden]="true" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="cellphone" class='wh-label'>
              <span> شماره همراه </span>
              <input class='wh-input' formControlName='cellphone' pattern="[+0-9]{1,}" autocomplete="off"
                [required]="required() && level == 1 " autocomplete="off">
            </label>
          </div>


          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="state" class='wh-label'>
              <span> استان </span>
              <span (click)="reset_input(233)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='state_title' autocomplete="off" (click)="load_services(233)"
                readonly>
              <input class='wh-input' formControlName='state' autocomplete="off" [hidden]="true" readonly>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="address" class='wh-label'>
              <span> آدرس </span>
              <input class='wh-input' formControlName='address' autocomplete="off" autocomplete="off">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{width50:type_task == 3}"
            *ngIf="level == 1 " [hidden]="type_task == 3">
            <label for="admin" class='wh-label'>
              <span> ورود به نرم افزار </span>
              <mat-checkbox class='wh-input' formControlName='admin' autocomplete="off" style="border:none">
              </mat-checkbox>
            </label>
          </div>
        </div><!-- row -->

        <div class="row" style="padding-top:1rem">
          <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
            <span class="header-title"> اطلاعات تکمیلی </span>
          </div>
          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{width50:type_task == 3}"
            [hidden]="level > 1">
            <label for="user" class='wh-label'>
              <span> نام کاربری </span>
              <input class='wh-input' formControlName='user' autocomplete="off" pattern="^\S+$">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{width50:type_task == 3}"
            [hidden]="type_task == 3">
            <label for="password" class='wh-label'>
              <span> رمز عبور </span>
              <input type="text" class='wh-input' formControlName='password'>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="phone" class='wh-label'>
              <span> شماره ثابت </span>
              <input class='wh-input' formControlName="phone" autocomplete="off">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="email" class='wh-label'>
              <span> پست الکترونیک </span>
              <input class='wh-input' formControlName='email' autocomplete="off">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{width50:type_task == 3}"
            *ngIf="type_task !== 3">
            <label for="sms_charge" class='wh-label'>
              <span>شارژ اولیه پیامک به ریال </span>
              <input class='wh-input' formControlName='sms_charge' autocomplete="off" currencyMask>
            </label>
          </div>


          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="expire_date" class="wh-label">
              <span>تاریخ انقضا </span>
              <div style="width:100%">
                <input class="wh-input" matInput [matDatepicker]="picker" formControlName="expire_date"
                  autocomplete="off" style="text-align:right">
                <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker datepicker1">
                </mat-datepicker-toggle>
                <mat-datepicker #picker class="datepicker1"></mat-datepicker>
              </div>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="number_access" class='wh-label'>
              <span> تعداد دسترسی مجاز </span>
              <input class='wh-input' formControlName='number_access' autocomplete="off" currencyMask>
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{width50:type_task == 3}"
            *ngIf="level == 1">
            <label for="amount" class='wh-label'>
              <span> شارژ ماهیانه </span>
              <input class='wh-input' formControlName='amount' autocomplete="off" currencyMask
                [required]="required() && level == 1">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{width50:type_task == 3}"
            *ngIf="level == 1">
            <label for="currency" class='wh-label'>
              <span> واحد پولی پرداخت </span>
              <span (click)="reset_input(156)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='currency_title' autocomplete="off" readonly
                (click)="load_services(156)" [required]="required() && level == 1">
              <input class='wh-input' formControlName='currency' autocomplete="off" readonly [hidden]="true"
                [required]="required() && level == 1">
            </label>
          </div>

          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding' [ngClass]="{width50:type_task == 3}"
            *ngIf="level == 1">
            <label for="moarf" class='wh-label'>
              <span> معرف </span>
              <span (click)="reset_input(161)">
                <mat-icon class="close" class="input_search input_search_pe">close
                </mat-icon>
              </span>
              <input class='wh-input' formControlName='moarf_title' autocomplete="off" readonly
                (click)="load_services(161)">
              <input class='wh-input' formControlName='moarf' autocomplete="off" readonly [hidden]="true">
            </label>
          </div>

          <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
            <label for="comment2" class='wh-label'>
              <span> توضیحات - فقط ادمین </span>
              <textarea class="wh-input" formControlName='comment2' style="min-height: 5rem;"> </textarea>
            </label>
          </div>

        </div>

        <div class="row" style="border-bottom:solid 1px #ccc;">
          <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
            [ngClass]="{width50:type_task == 3}">
            <label for="setting_menu" class="wh-label">
              <span> تنظیمات منو </span>
              <div style="width:100%">
                <mat-checkbox formControlName="setting_menu" autocomplete="off"></mat-checkbox>
              </div>
            </label>
          </div>


        </div><!-- row -->
      </div><!-- col-md-8 col-lg-8 col-sm-8 col-xs-12  -->
    </div><!-- row -->
    <hr>
    <!-- ************************************************************************************* -->
    <div *ngIf="level == 1">
      <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12  div_panding'
        style="text-align:right;padding-right:1rem;padding-top:1rem;padding-bottom:1rem" *ngIf="type_task != 3">
        <span class="header-title"> دسترسی ماژول ها</span>
      </div>
      <div class="row user_div1" *ngFor="let s of list_sgroup" style="padding-top:1rem">
        <div class="user_div2">--- {{ s.site_software_title }} ---</div>
        <span *ngFor="let x of list_sgroup_id[s.site_software_id]">
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-12 pe service">
            <mat-checkbox class="user-checkbox" [value]="x.site_services_id" (change)="change_select_service()"
              name="service[]" [checked]="x.checked">
            </mat-checkbox>
            {{ x.site_services_title }}
          </div>
        </span>
      </div>
    </div>
    <!--- ماژول ها -->

  </div> <!-- content -->

  <div mat-dialog-actions class="matDialogActions" style="padding-right:25px">
    <button type="submit" [disabled]="!form1.valid" class="btn_pe" *ngIf="type_task == 1 || type_task == 2">
      {{ serverService.get_event_title(type_task,lang) }}
    </button>
    <button type="submit" class="btn_pe" *ngIf="type_task == 3">
      <{{ serverService.get_event_title(type_task,lang) }} </button>
  </div><!-- row -->
</form>