<div class="my-container" [hidden]="!loading">
    <mat-progress-bar mode="indeterminate" style="position:relative !important"></mat-progress-bar>
</div>


<div mat-dialog-title class="matDialogTitle">
    دسترسی کاربران به {{ title }}
    <span>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent pe1" style="padding:0px !important" [ngClass]="{en1:lang==2}"
    style="width:100%">
    <div class="row">
        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe' [ngClass]="{en:lang==2}"
            style="font-size:0.8rem">
            <table class="table table-bordered">
                <tr *ngFor="let x of list_user_selected">
                    <td>{{ x.user_title }}</td>
                    <td style="width:1rem">
                        <span (click)="delete(x.user_id)">
                            <mat-icon class="close" style="font-size:1.2rem">close</mat-icon>
                        </span>
                    </td>
                </tr>
            </table>
        </div><!-- col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe -->
        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe' [ngClass]="{en:lang==2}"
            style="text-align:center">
            <label for="title" class='wh-label'>
                <input class='wh-input' style="width:30%" name='user' [(ngModel)]='user' (keyup.enter)="check_access(5)"
                    pattern="[{{ pattern_title_with_persian }}]{1,}" autocomplete="off">
                <br>
                <button type="search" class="btn_pe" [ngClass]="{btn_en:lang==2}" (click)="check_access(5)">
                    <span *ngIf="lang == 1">دریافت کاربر</span>
                    <span *ngIf="lang == 2">get user</span>
                </button>
            </label>
        </div><!-- col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe -->
    </div><!-- row -->


    <div class="row">
        <div class='col-md-12 col-xl-12 col-lg-12  col-sm-12 col-xs-12 pe' [ngClass]="{en:lang==2}"
            *ngFor="let x of list_user;let i=index">
            <mat-checkbox value="{{ x.user_id }}" (change)="insert(i,$event,x.user_id,x.user_userName,x.user_title)"
                [checked]="x.checked"></mat-checkbox>
            <span class="span1">{{ x.user_userName }} - {{ x.user_title }} </span>
        </div>
    </div>
</div>