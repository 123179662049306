import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InsertComponent } from '../../service/insert/insert.component';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-wharehouse-order-material-add',
  templateUrl: './wharehouse-order-material-add.component.html',
  styleUrls: ['./wharehouse-order-material-add.component.scss']
})
export class WharehouseOrderMaterialAddComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading: boolean = false;
  public pending: any = false;
  public list_group: any = [];
  public list_cate: any = [];
  public list_weight: any = [];
  public result: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public server!: string;
  public server_main!: string;
  public site!: string;
  public cate!: number;
  public weight!: number;
  public user_id!: number;
  public material_type!: number;
  public creator!: number;
  public ref!: any;
  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<WharehouseOrderMaterialAddComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    this.server = this.serverService.get_server();
    this.server_main = this.serverService.get_server_main();
    this.site = this.serverService.get_site();
    if (dialog_data) {
      this.creator = dialog_data.creator;
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form();
    this.get_group();
    this.get_weight();
  }
  create_form() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'coding': new FormControl(null),
      'cost': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'price2': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'group': new FormControl(null),
      'cate': new FormControl(null),
      'weight': new FormControl(null),
      'inventory': new FormControl(0),
    })
  }
  save(): any {
    if (!this.creator) {
      var pe_message = "نام صاحب اکانت مشخص نیست . لطفا یکبار دیگر لاگین شوید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7173,
      'title': this.form1_group.value.title,
      'coding': this.form1_group.value.coding,
      'cost': this.form1_group.value.cost,
      'price2': this.form1_group.value.price2,
      'cate': this.form1_group.value.cate,
      'weight': this.form1_group.value.weight,
      'inventory': this.form1_group.value.inventory,
      'user_id': this.user_id,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.result = res['result'][0];
            this.matDialogRef.close(this.result);
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان می بایست منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************************************************* */
  get_group(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6330, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_group = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].wharehouse_group_default == true) {
              this.form1_group.patchValue({
                'group': res['result'][i].wharehouse_group_id
              });
              this.get_cate();
            }
            this.list_group.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  add_group(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'group' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_group.unshift({ wharehouse_group_title: res.wharehouse_group_title, wharehouse_group_id: res.wharehouse_group_id })
          this.form1_group.patchValue({
            'group': res.wharehouse_group_id
          });
          this.list_cate = [];
        }
      }
    )
  }
  //******************************************************************************************************************* */
  get_cate(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6331, group: this.form1_group.value.group }).subscribe(
      (res: any) => {
        this.list_cate = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_cate.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  add_cate(): any {
    if (!this.form1_group.value.group) {
      var pe_message = "برای درج شاخه نیاز است تا دستگاه انتخاب شده باشد";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'cate', group: this.form1_group.value.group }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_cate.unshift({ wharehouse_material_cate_title: res.wharehouse_material_cate_title, wharehouse_material_cate_id: res.wharehouse_material_cate_id })
          this.form1_group.patchValue({
            'cate': res.wharehouse_material_cate_id
          })
        }
      }
    )
  }
  //******************************************************************************************************************* */
  get_weight(): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7199, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_weight = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_weight.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  add_weight(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'weight' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_weight.unshift({ wharehouse_weight_title: res.wharehouse_weight_title, wharehouse_weight_id: res.wharehouse_weight_id })
          this.form1_group.patchValue({
            'weight': res.wharehouse_weight_id
          })
        }
      }
    )
  }
  //**************************************************
  close() {
    if (this.result.length > 0) this.matDialogRef.close(this.result);
    else this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

