import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CardexDetaileComponent } from "../cardex-detaile/cardex-detaile.component";
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { InvoiceDetaileComponent } from '../../../wharehouse/wharehouse-invoice/invoice-detaile/invoice-detaile.component';
import { WharehouseExit2Component } from '../../../wharehouse-reception/wharehouse-exit2/wharehouse-exit2.component';
import { MaterialDetaileComponent } from "../../../wharehouse/wharehouse-material/material-detaile/material-detaile.component";
import { WharehouseOrderSaleSearialComponent } from '../../wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';
import { MessageService } from '../../../service/message/message.service';
import { CardexDetaile2Component } from '../cardex-detaile2/cardex-detaile2.component';


@Component({
  selector: 'app-cardex-list',
  templateUrl: './cardex-list.component.html',
  styleUrls: ['./cardex-list.component.scss']
})
export class CardexListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));;
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public lang_text: any = [];
  public user_id: number;
  public loading = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public negative: boolean = false;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  public inventory: number = 0;
  public invoice_buy: number = 0;
  public consumed_reception = 0;
  public invoice_sale = 0;
  public creator: number | undefined;
  public video: string | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public path_export: string | undefined;
  public invoice_type: number = 0;
  public sum_all: number = 0;
  public buyorderId: number = 0;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'creator', 'searial', 'date', 'material_id', 'material_title', 'group', 'cate', 'type_title', 'order_id', 'user', 'imported', 'exited', 'consumed', 'price', 'sum'];
  public material: number | undefined;
  public user: number | undefined;
  public date1: number | undefined;
  public date2: number | undefined;
  current_url: string | undefined;
  isreportPage: boolean | false;
  group_id: number | undefined;
  subgroup_id: number | undefined;
  //*************
  constructor(
    public serverService: ServerService,
    public router: Router
    , public activatedRoute: ActivatedRoute
    , public messageService: MessageService
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public dialogRef: MatDialog) {

    this.activatedRoute.url.subscribe(url => {
      if (url[1].path == "207") {
        this.isreportPage = true;
      }
    });
  }

  HideorShow() {
    if (this.router.url.endsWith("207"))

      return true;

    return false;
  }


  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.serverService.status1(2);

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    );
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.material = params['material'];
        this.buyorderId = params['buyorderId'];
        this.user = params['user'];
        this.date1 = params['date1'];
        this.date2 = params['date2'];
        this.page = 1;
        this.check_access(4, 1, 0, 1, 1);
      }
    );
    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.path_export = res['path_export'];
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_data2() {
    this.negative = true;
    this.load_data(1, 1, 1, -1);
  }

  load_data3() {
    this.negative = false;
    this.invoice_type = 0;
    this.load_data(1, 1, 1, -1);
  }

  load_data4(type: number) {
    this.invoice_type = type;
    this.load_data(1, 1, 1, -1);
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {

    console.log(page + "/" + excel + "/" + loading + "/" + changedRows + "/");

    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var obj = {
      'address': 6127
      , "page": this.page
      , "lang": this.lang
      , 'type': this.type
      , 'excel': excel
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'material': this.material
      , 'date1': this.date1
      , 'date2': this.date2
      , 'user': this.user
      , 'creator': this.creator
      , 'path_export': this.path_export
      , 'negative': this.negative
      , 'invoice_type': this.invoice_type
      , 'group_id': this.group_id
      , 'subgroup_id': this.subgroup_id
      , 'buyorderId': this.buyorderId
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.sum_all = res['sum_all'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reset({});
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  order_ref(ref: any, id: number, material: number) {
    if (ref == 1) {
      this.dialog.open(InvoiceDetaileComponent, {
        width: '65rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: 2, id: id, readonly: true, code: this.code }
      });
    }
    else if (ref == 2) {
      this.dialog.open(WharehouseExit2Component, {
        'width': '70rem',
        'height': 'auto',
        data: { 'reception_id': id, ref: 'exit', type: 2, code: this.code, creator: this.creator, readonly: true }
      });
    }
    else if (ref == 3) {
      this.dialog.open(MaterialDetaileComponent, {
        width: '45rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: 2, id: material, code: this.code, title: this.title, readonly: true }
      });
    }
  }

  open_serial(i: number, id: number, title: string, order_buy_id: number, invoice_type: string) {
    var document_type_text = 'input';
    if (invoice_type == 'sale') {
      var document_type_text = 'exit';
    }
    const dialogRef = this.dialog.open(WharehouseOrderSaleSearialComponent, {
      width: '22rem',
      height: 'auto',
      data: { id: id, title: title, document_type_text: document_type_text, order_buy_id: order_buy_id, readonly: true }
    });
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(CardexDetaileComponent, {
      width: '35rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[res.i].wharehouse_device_title = res.result.wharehouse_device_title;
            this.list_record[res.i].wharehouse_material_price = res.result.wharehouse_material_price;
            this.list_record[res.i].wharehouse_material_price2 = res.result.wharehouse_material_price2;
            this.list_record[res.i].wharehouse_material_title = res.result.wharehouse_material_title;
            this.list_record[res.i].wharehouse_material_sort = res.result.wharehouse_material_sort;
            this.list_record[res.i].wharehouse_material_cate_title = res.result.wharehouse_material_cate_title;
            this.list_record[res.i].status_title = res.result.status_title;
            this.list_record[res.i].inventory = res.result.inventory;
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }

  open_filter_group_dialog(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(CardexDetaile2Component, {
      width: '20rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[res.i].wharehouse_device_title = res.result.wharehouse_device_title;
            this.list_record[res.i].wharehouse_material_price = res.result.wharehouse_material_price;
            this.list_record[res.i].wharehouse_material_price2 = res.result.wharehouse_material_price2;
            this.list_record[res.i].wharehouse_material_title = res.result.wharehouse_material_title;
            this.list_record[res.i].wharehouse_material_sort = res.result.wharehouse_material_sort;
            this.list_record[res.i].wharehouse_material_cate_title = res.result.wharehouse_material_cate_title;
            this.list_record[res.i].status_title = res.result.status_title;
            this.list_record[res.i].inventory = res.result.inventory;
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 6040; } else { address = 6039; }
      var obj = {
        'address': address, "user_id": this.user_id
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_material_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_material_id == res['result_record'][i].wharehouse_material_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  open_report(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6125, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          this.inventory = res['result'][0].wharehouse_material_inventory;
          this.invoice_buy = res['result'][0].invoice_buy;
          this.invoice_sale = res['result'][0].invoice_sale;
          this.consumed_reception = res['result'][0].consumed_reception;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize

  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


