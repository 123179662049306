import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MaterialDetaile2Component } from "../material-detaile2/material-detaile2.component";
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MaterialDetaile3Component } from '../material-detaile3/material-detaile3.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-material-list2',
  templateUrl: './material-list2.component.html',
  styleUrls: ['./material-list2.component.scss']
})
export class MaterialList2Component implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public language: any = JSON.parse(<any>localStorage.getItem('language'));
  public lang_text: any = [];
  public user_id: number;
  public loading = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public navigate: boolean = false;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public search_all: string | undefined;
  public creator: number | undefined;
  public video: string | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public path_export: string | undefined;
  public group: number;
  public cate: number;
  public date: string | undefined;
  //******************************************************************************
  public list_record: any = [];
  public title0: string;
  public title1: string;
  public title2: string;
  public title3: string;
  public title4: string;
  public title5: string;
  public title6: string;
  public title7: string;
  public title8: string;
  public title9: string;
  public title20: string;
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'edie_date', 'wharehouse', 'creator', 'state', 'title', 'inventory', 'price', 'comment', 'id', 'operation'];
  //*************
  constructor(
    public serverService: ServerService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , private dialog: MatDialog) { }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    );

    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.show_excel = false;
        this.search_array = [];
        this.search_all = '';
        this.group = params['group'];
        this.cate = params['cate'];
        this.check_access('first', 4, 1, 0, 1, 1);
      }
    )
    //**************
    if (this.language) {
      this.lang_text[0] = this.language.new;
      this.lang_text[1] = this.language.filter;
      this.lang_text[2] = this.language.search;
      this.dir = this.language.dir;
    }
    this.get_header();
  }//end ngOnInit

  //****************************************************************************
  check_access(step: string, type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(step, type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(step: string, type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) { this.load_data(step, page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_data(step, page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5 || page == 1) { this.page = 1; }
    var obj = {
      'address': 6518, "page": this.page
      , 'excel': excel
      , user_id: this.user_id
      , search: this.search_array
      , search_all: this.search_all
      , changedRows: changedRows
      , creator: this.creator
      , path_export: this.path_export
      , group: this.group
      , cate: this.cate
      , level: this.level
      , code: this.code
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.date = res['date'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_reset({});
            if (!this.group) this.serverService.send_services_id(this.code);
            else this.serverService.send_wharehouse_group_id(this.group);
            if (this.group && !this.cate) this.serverService.send_material_group_cate(this.group);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  change_wharehouse(i: number, order_id: number, value: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6536,
      order_id: order_id,
      value: value
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.list_record[i].wharehouse_order_wharehouse = res['result'][0].wharehouse_order_wharehouse;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***************************************************************************
  open(i: number, type_task: number, material_id: number, order_id: number) {
    if (this.group || this.code == 248) {
      this.open_detaile(i, type_task, order_id);
    } else {
      this.open_detaile2(i, type_task, order_id)
    }
  }

  open_detaile2(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(MaterialDetaile3Component, {
      width: '25rem',
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task, id: id, i: i
        , search_array: this.search_array, code: this.code
        , title: this.title, creator: this.creator
        , cate: this.cate
        , group: this.group
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res)
          if (type_task == 2) {
            this.list_record[i].wharehouse_order_comment = res.result.wharehouse_order_comment;
            this.list_record[i].wharehouse_order_cost2 = res.result.wharehouse_order_cost2;
          }
          else {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access('', 4, 1, 0, excel, 1);
          }
      }
    )
  }
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(MaterialDetaile2Component, {
      width: '80rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize

  set_title(header: string, event: any) {
    var obj = {
      address: 6546,
      user_id: this.user_id,
      code: 247,
      header: header,
      title: event.target.value,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_header() {
    var obj = {
      address: 6547,
      code: 247,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.title0 = res['result'][0].site_header_title0;
          this.title1 = res['result'][0].site_header_title1;
          this.title2 = res['result'][0].site_header_title2;
          this.title3 = res['result'][0].site_header_title3;
          this.title4 = res['result'][0].site_header_title4;
          this.title5 = res['result'][0].site_header_title5;
          this.title6 = res['result'][0].site_header_title6;
          this.title7 = res['result'][0].site_header_title7;
          this.title8 = res['result'][0].site_header_title8;
          this.title9 = res['result'][0].site_header_title9;
          this.title20 = res['result'][0].site_header_title20;
        }//end if
        else {
        }
      }
    )
  }

  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


