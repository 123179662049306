<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>{{ serverService.get_title(type_task,lang) }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="customer" class='wh-label'>
                    <span> نام شرکت </span>
                    <span (click)="reset_input(161)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="customer_title" (click)="load_services(161)"
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="customer" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="amount" class='wh-label'>
                    <span> تعداد ماه </span>
                    <input type="number" class='wh-input' formControlName="number" autocomplete="off"
                        [required]="required()" (change)="get_amount()">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="status_id" class='wh-label'>
                    <span> وضعیت </span>
                    <span (click)="reset_input(236)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(236)"
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding' [hidden]="code == 230">
                <label for="issue_date" class="wh-label">
                    <span> تاریخ انقضا </span>
                    <input class="wh-input" [matDatepicker]="picker" formControlName="expire_date" autocomplete="off"
                        [required]="required()">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker datepicker1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="amount" class='wh-label'>
                    <span> مقدار پرداختی </span>
                    <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding' [hidden]="type_task != 3">
                <label for="Coupun" class='wh-label'>
                    <span> کوپن </span>
                    <span (click)="reset_input(262)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="coupon_title" (click)="load_services(262)" readonly>
                    <input class="wh-input" formControlName="coupon" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="amount" class='wh-label'>
                    <span> کد رهگیری </span>
                    <input class='wh-input' formControlName="code" autocomplete="off"
                        (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="amount" class='wh-label'>
                    <span> توضیحات </span>
                    <input class='wh-input' formControlName="comment" autocomplete="off">
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" *ngIf="type_task == 1 || type_task == 2"
            [hidden]="readonly">
            <span>ذخیره</span>
        </button>
        <button type="button" (click)="search(1)" class="btn_pe" *ngIf="type_task == 3">
            <span>فیلتر</span>
        </button>
    </div>
</form>