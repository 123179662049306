<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>کالا و خدمات</span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent" style="overflow-y: hidden;">
    <div class="row row1">
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2">
            <div class="div1">
                <span> نام طرف حساب </span>
                : {{ customer_title }}
                <span [matMenuTriggerFor]="menu">
                    <i class="fas fa-info-circle icon icon-info"></i>
                </span>
            </div>
        </div>
        <mat-menu #menu="matMenu" class="cardex-mat-menu-panel" xPosition="before">
            <table class="table table-bordered" (click)="$event.stopPropagation()">
                <tr>
                    <td class="cursor right">
                        <a href="home/financial2/202?user={{ customer_id }}" target="_blank">صورتحساب {{ customer_title
                            }}</a>
                    </td>
                </tr>
                <tr>
                    <td class="cursor right">
                        <a href="home/finance-cardex/226?user={{ customer_id }}" target="_blank">کاردکس {{
                            customer_title
                            }}</a>
                    </td>
                </tr>
            </table>
        </mat-menu>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2">
            <div class="div1">
                <span> شماره همراه </span>
                :
            </div>
            <div class="div1">
                <div *ngIf="show_cellphone == false"> {{ customer_cellphone }} </div>
                <div *ngIf="show_cellphone == true" class="cellphone">{{
                    customer_cellphone
                    | hide_cellphone }}</div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2">
            <div class="div1">
                <span> دستگاه </span>
                :
            </div>
            <div class="div1">
                <div>{{ device_title }}</div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2">
            <div class="div1">
                <span> برند </span>
                :
            </div>
            <div class="div1">
                <div> {{ brand_title }} </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2">
            <div class="div1">
                <span> مدل </span>
                :
            </div>
            <div class="div1">
                <div> {{ model_title }} </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2 fleft">
            <div class="div1">
                <span> وضعیت پذیرش </span>
                :
            </div>
            <div class="div1">
                <div> {{ rstatus_title }}
                    <span class="cursor" (click)="open_rstatus()"><i class="fas fa-external-link-alt"></i></span>
                </div>
            </div>
        </div>
    </div>

    <div style="text-align: right;width:100%">
        <button type="button" class="search_button" (click)="insert_material()" [hidden]="readonly == true">
            <span>
                <span>کالا</span>
            </span>
        </button>
        <button type="button" class="search_button" (click)="insert_service()" [hidden]="readonly == true">
            <span>خدمات</span>
        </button>
        <button type="button" class="search_button" (click)="insert_cost()" [hidden]="readonly == true">
            <span>هزینه</span>
        </button>
    </div>
    <div class="matTable content-body control-content-body"
        style='overflow-x:auto;direction:rtl;max-height:22rem;min-height:23rem'>
        <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'>
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i
                    + 1 }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef style="max-width:70px">
                    <span>نوع</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)" style="max-width:70px">
                    {{ x.wharehouse_material_type_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="material">
                <mat-header-cell *matHeaderCellDef>
                    <span>عنوان</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)">
                    <div class="right">{{ x.title }}
                        <span *ngIf="x.wharehouse_order_material_type == 1">| {{x.wharehouse_material_cate_title }}
                        </span>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                    <span>تعداد</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)" style="max-width: 110px;">
                    {{ x.wharehouse_order_number | number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="unit">
                <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                    <span>واحد</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)" style="max-width:110px">
                    <span *ngIf="x.wharehouse_weight_title">{{ x.wharehouse_weight_title }}</span>
                    <span *ngIf="!x.wharehouse_weight_title">-</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                    <span>قیمت</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 110px;" (click)="check_for_access(i)">
                    {{ x.wharehouse_order_cost | number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="discount">
                <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                    <span>تخفیف</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 110px;" (click)="check_for_access(i)">
                    <span>{{ x.wharehouse_order_discount |
                        number }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tax">
                <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                    <span>مالیات</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 110px;" (click)="check_for_access(i)">
                    <span>{{ x.tax |
                        number }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sum">
                <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                    <span>جمع</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 110px;" (click)="check_for_access(i)">
                    <span>{{ x.sum | number }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="repairman">
                <mat-header-cell *matHeaderCellDef style="max-width:150px">
                    <span>نام تعمیرکار</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width:150px">
                    <span (click)="check_for_access(i)" class="fright right">{{ x.repairman_title | substr:0:15
                        }}</span>
                    <span [matMenuTriggerFor]="menu2" (click)="get_repairman_title(x.repairman_id,x.repairman_title)"
                        class="fleft" *ngIf="x.repairman_id > 0">
                        <i class="fas fa-info-circle icon icon-info"></i>
                    </span>
                </mat-cell>
            </ng-container>
            <mat-menu #menu2="matMenu" class="cardex-mat-menu-panel" xPosition="before">
                <table class="table table-bordered" (click)="$event.stopPropagation()">
                    <tr>
                        <td class="cursor right">
                            <a href="home/cardex-personnel/255?user={{ repairman_id }}" target="_blank">کاردکس پرسنل {{
                                repairman_title
                                }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="cursor right">
                            <a href="home/financial2/202?user={{ repairman_id }}" target="_blank">صورتحساب {{
                                repairman_title
                                }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="cursor right">
                            <a href="home/finance-cardex/226?user={{ repairman_id }}" target="_blank">کاردکس {{
                                repairman_title
                                }}</a>
                        </td>
                    </tr>
                </table>
            </mat-menu>

            <ng-container matColumnDef="searial">
                <mat-header-cell *matHeaderCellDef style="max-width: 70px;">
                    <span>سریالها</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="open_serial(i,x.wharehouse_order_id,x.title,x.wharehouse_order_consumed_invoice)"
                    style="max-width: 70px;" [ngClass]="{bg2:x.searial == true}">
                    <span *ngIf="x.wharehouse_order_material_type == 1">
                        <mat-icon class="add_circle_outline">add_circle_outline</mat-icon>
                    </span>
                    <span *ngIf="x.wharehouse_order_material_type == 2 || x.wharehouse_order_material_type == 4">
                        -
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:70px' [hidden]="readonly == true">
                    <span> عملیات </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' [hidden]="readonly == true">
                    <span (click)="access_for_delete(i)"><i class="far fa-trash-alt icon"
                            style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns3" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns3;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{bg:order_id > 0 && order_id == row.wharehouse_order_id,'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>

        <div class="row">
            <div style="padding-top:1rem;text-align: right;">
                <span>جمع کل به عدد:</span>
                <span>{{ sum_all | number }}</span>
                <span> {{ currency_title }} </span>
                <span> به حروف </span>
                <span> {{ sum_all_letter }} </span>
                <span> {{ currency_title }} </span>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-2 col-xs-12"
                style="text-align: right;padding:0;padding-top:1rem">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 fright" style="padding:0px">
                    <span>جمع خدمات:</span>
                    <span style="color:blue"> {{ sum_all_services | number }} </span>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 fright" style="padding:0px">
                    <span>جمع کالا:</span>
                    <span style="color:blue">{{ sum_all_material | number }}</span>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 fright" style="padding:0px">
                    <span>جمع هزینه:</span>
                    <span style="color:blue">{{ sum_all_costs | number }}</span>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 fright" style="padding:0px">
                    <span>جمع مالیات:</span>
                    <span style="color:blue">{{ sum_tax_all | number }}</span>
                </div>

            </div>
        </div>
    </div>
    <!-- **************************************************************************************************** -->
</div>

<div mat-dialog-actions class="matDialogActions" *ngIf="readonly == false">
    <button type="button" class="btn_pe" (click)="save_status(1)">
        <span>ذخیره</span>
    </button>

    <div style="padding-right:1rem;padding-top:0.2rem">
        <mat-radio-group aria-label="Select an option" name="device_status" [(ngModel)]="device_status">
            <div class="device_status_text">
                <span>وضعیت دستگاه:</span>
            </div>
            <mat-radio-button class="status_title_items reception-status" *ngFor="let x of list_device_status"
                [value]="x.repairman_device_status_id" [checked]="x.repairman_device_status_id == device_status">
                <span class="status_span_label">{{ x.repairman_device_status_title }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>