<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr',en:lang == 2}" name="search_all"
            [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,2,0)'>
                    <span *ngIf="lang == 1">کد من</span>
                    <span *ngIf="lang == 2">My code</span>
                </button>
                <button class="search1" (click)='open_detaile(0,3,0)' [hidden]="true">
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12  hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <mat-icon style="vertical-align:middle;" class="cursor" (click)="copy()">content_copy</mat-icon>
                <input style="height:1.7rem;width:22rem;direction: ltr;" [(ngModel)]="my_moarf">

                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span *ngIf="lang == 1">ردیف</span>
                <span *ngIf="lang == 2">Row</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i + page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="moarf">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">کد معرف استفاده شده</span>
                <span *ngIf="lang == 2">Used referral code</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.user_moarf_code }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">نام و نام خانوادگی</span>
                <span *ngIf="lang == 2">Name and surname</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.user_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">تاریخ تمدید</span>
                <span *ngIf="lang == 1">Renewal date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.site_bascket_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="month">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">تعداد ماه تمدید</span>
                <span *ngIf="lang == 2">Duration of renewal months</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.site_bascket_number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">مبلغ</span>
                <span *ngIf="lang == 2">Amount</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.site_bascket_amount | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>


        <ng-container matColumnDef="profit">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">مبلغ سود شما</span>
                <span *ngIf="lang == 2">Your profit amount</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.profit | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{ profit | number }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                <span *ngIf="lang == 1">شناسه</span>
                <span *ngIf="lang == 2">ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="max-width:70px">
                {{ x.site_bascket_id }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:70px"></mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
        <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
            (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true"></mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>


<ngx-smart-modal #myModal identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
    <form [formGroup]="form1_group">
        <div class="dialog_title1">
            <span>{{ serverService.get_title(type_task,lang) }}</span>
        </div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان : </span>
                    <span *ngIf="lang == 2"> Title : </span>
                    <input class='wh-input' formControlName="title" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب : </span>
                    <span *ngIf="lang == 2"> Sort : </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت : </span>
                    <span *ngIf="lang == 2"> Status : </span>
                    <select class='wh-input' formControlName="status_id">
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>
        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'>
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="check_access(type_task,0,0,0,1)"
                    [disabled]="!form1_group.valid">
                    {{ serverService.get_event_title(type_task,lang) }}
                </button>
            </div>
        </div>
    </form>
</ngx-smart-modal>

<ngx-smart-modal #myModalSearch identifier="myModal" class="pe" [ngClass]="{en:lang==2}" (onClose)="ModalClose()">
    <form [formGroup]="form1_search_group">
        <div style='text-align:center;padding:12px !important'>{{ title }}</div>
        <div class="row">
            <div class="dialog_title1">
                <span>{{ serverService.get_title(type_task,lang) }}</span>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{en:lang==2}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت : </span>
                    <span *ngIf="lang == 2"> Status : </span>
                    <select class='wh-input' formControlName='status_id_search' pattern="[0-9]{1,}">
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>

        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12  col-md-12  col-sm-12 col-xs-12'
                style='margin-top:5px;text-align:center;font-family:irsans'>
                <button class="btn_pe" (click)="check_access(4,1,0,1,1)">
                    {{ serverService.get_event_title(type_task,lang) }}
                </button>
            </div>
        </div>
    </form>
</ngx-smart-modal>