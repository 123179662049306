<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span> فاکتور فروش </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>


<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 pe div_panding'>
                <label for="invoice_date" class="wh-label">
                    <span> تاریخ سند </span>
                    <div style="width:100%">
                        <input class="wh-input" matInput [matDatepicker]="picker" formControlName="invoice_date"
                            [required]="required()" autocomplete="off" [readonly]="readonly">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="datepicker datepicker1"
                            [hidden]="readonly == true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 pe div_panding'>
                <label for="user" class='wh-label'>
                    <span (click)="add_user()" [hidden]="readonly">
                        <mat-icon class="add-icon fright">
                            add </mat-icon>
                    </span>
                    <span> نام طرف حساب </span>
                    <span (click)="reset_input(161)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="open_user()" readonly
                        [required]="required()" [readonly]="readonly">
                    <input class="wh-input" formControlName="user" [hidden]="true" readonly [required]="required()">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 pe div_panding'>
                <label for="comment" class='wh-label'>
                    <span> توضیحات فاکتور </span>
                    <input class='wh-input' formControlName="comment" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 pe div_panding'>
                <label for="invoice_number" class='wh-label'>
                    <span> شماره فاکتور </span>
                    <input class='wh-input' formControlName="invoice_number" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 pe div_panding'>
                <label for="settlement_date" class="wh-label">
                    <span> تاریخ تسویه </span>
                    <div style="width:100%">
                        <input class="wh-input" matInput [matDatepicker]="picker2" formControlName="settlement_date"
                            autocomplete="off" [readonly]="readonly">
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="datepicker datepicker1"
                            [hidden]="readonly == true">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </div>
                </label>
            </div>

        </div>
        <!-- ********************************************************************************************************* -->
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pe right padding0">
                <button type="button" class="invoice_button2" [hidden]="readonly">
                    <span>کالا</span>
                </button>
                <button type="button" class="invoice_button2" [hidden]="readonly" (click)="insert_service()">
                    <span>خدمات</span>
                </button>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pe padding0">
                <div class="content-body1" class="content-body control-content-body">
                    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'>
                        <ng-container matColumnDef="row">
                            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                                <span>#</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i
                                + 1 }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef style="max-width:70px">
                                <span>نوع</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="max-width:70px">
                                {{ x.wharehouse_material_type_title }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="material">
                            <mat-header-cell *matHeaderCellDef style="min-width: 280px;">
                                <span>عنوان</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="min-width: 280px;">
                                <div class="right">{{ x.title }}
                                    <span *ngIf="x.wharehouse_order_material_type == 1">|
                                        {{x.wharehouse_material_cate_title }}
                                    </span>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="number">
                            <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                                <span>تعداد</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="max-width: 110px;">
                                {{ x.wharehouse_order_number | number }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="unit">
                            <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                                <span>واحد</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="max-width: 110px;">
                                <span *ngIf="x.wharehouse_weight_title">{{ x.wharehouse_weight_title }}</span>
                                <span *ngIf="!x.wharehouse_weight_title">-</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="cost">
                            <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                                <span>قیمت</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="max-width: 110px;">
                                {{ x.wharehouse_order_cost | number }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="discount">
                            <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                                <span>تخفیف</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="max-width: 110px;">
                                <span>{{ x.wharehouse_order_discount |
                                    number }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tax">
                            <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                                <span>مالیات</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="max-width: 110px;">
                                <span>{{ x.tax |
                                    number }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="sum">
                            <mat-header-cell *matHeaderCellDef style="max-width: 110px;">
                                <span>جمع</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" (click)="check_for_access(i)"
                                style="max-width: 110px;">
                                <span>{{ x.sum | number }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="repairman">
                            <mat-header-cell *matHeaderCellDef style="max-width: 150px;">
                                <span>نام فروشنده</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" style="max-width: 150px;">
                                <span (click)="check_for_access(i)" class="fright right">{{ x.repairman_title |
                                    substr:0:15
                                    }}</span>
                                <span [matMenuTriggerFor]="menu2"
                                    (click)="get_repairman_title(x.repairman_id,x.repairman_title)" class="fleft"
                                    *ngIf="x.repairman_id > 0">
                                    <i class="fas fa-info-circle icon icon-info"></i>
                                </span>
                            </mat-cell>
                        </ng-container>
                        <mat-menu #menu2="matMenu" class="cardex-mat-menu-panel" xPosition="before">
                            <table class="table table-bordered" (click)="$event.stopPropagation()">
                                <tr>
                                    <td class="cursor right">
                                        <a href="home/cardex-personnel/255?user={{ repairman_id }}"
                                            target="_blank">کاردکس پرسنل {{
                                            repairman_title
                                            }}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="cursor right">
                                        <a href="home/financial2/202?user={{ repairman_id }}" target="_blank">صورتحساب
                                            {{
                                            repairman_title
                                            }}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="cursor right">
                                        <a href="home/finance-cardex/226?user={{ repairman_id }}" target="_blank">کاردکس
                                            {{
                                            repairman_title
                                            }}</a>
                                    </td>
                                </tr>
                            </table>
                        </mat-menu>

                        <ng-container matColumnDef="searial">
                            <mat-header-cell *matHeaderCellDef style="max-width: 70px;">
                                <span>سریالها</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index"
                                (click)="open_serial(i,x.wharehouse_order_id,x.title,x.wharehouse_order_consumed_invoice)"
                                [ngClass]="{bg2:x.searial == true}" style="max-width: 70px;">
                                <span *ngIf="x.wharehouse_order_material_type == 1">
                                    <mat-icon class="add_circle_outline">add_circle_outline</mat-icon>
                                </span>
                                <span
                                    *ngIf="x.wharehouse_order_material_type == 2 || x.wharehouse_order_material_type == 4">
                                    -
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="operation">
                            <mat-header-cell *matHeaderCellDef style='max-width:70px' [hidden]="readonly == true">
                                <span> عملیات </span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'
                                [hidden]="readonly == true">
                                <span (click)="access_for_delete(i)"><i class="far fa-trash-alt icon"
                                        style='margin:0px 4px;'></i></span>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns"
                            color="primary">
                        </mat-header-row>
                        <mat-row class="mat-header-row-dialog"
                            *matRowDef="let row;columns:displayedColumns;let i = index"
                            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                            [ngClass]="{bg:order_id > 0 && order_id == row.wharehouse_order_id,'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                            class="cursor"></mat-row>
                    </mat-table>
                </div>
            </div>

            <div class="pe">
                <span> جمع کل به عدد : </span>
                <span>{{ sum_all | number }}</span>
                <span> {{ currency_title }} </span>
            </div>
        </div><!-- row -->
        <!-- ********************************************************************************************************* -->
    </div><!-- content -->
    <div mat-dialog-actions class="matDialogActions">
        <div class="row" style="width: 100%;">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="text-align: right;padding-right:0px">
                <button type="submit" (click)="save('insert_invoice')" class="btn_pe" [hidden]="readonly"
                    [disabled]="!form1_group.valid">
                    ذخیره
                </button>
                <button type="submit" (click)="save('insert_finance')" class="btn_pe"
                    *ngIf="access_insert_finance == true" [hidden]="readonly"
                    [disabled]="!form1_group.valid || (list_order.length == 0 && i == -1)">
                    <span>ذخیره و درج امور مالی</span>
                </button>
            </div>
        </div><!-- row -->
    </div>
</form>