import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-repairman-rstatus',
  templateUrl: './repairman-rstatus.component.html',
  styleUrls: ['./repairman-rstatus.component.scss']
})
export class RepairmanRstatusComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public subscription: Subscription | any;
  public list_rstatus: any = [];
  public list_rstatus2: any = [];
  public id!: number;
  public readonly!: boolean;
  public rstatus_id!: number;

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<RepairmanRstatusComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.id = dialog_data.id;
      this.rstatus_id = dialog_data.rstatus_id;
      this.readonly = dialog_data.readonly;
    }
  }
  ngOnInit(): void {
    this.get_rstatus();
  }
  get_rstatus() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 1993 }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].repairman_rstatus_show_menu == 1) {
              this.list_rstatus.push(res['result'][i]);
            } else {
              this.list_rstatus2.push(res['result'][i]);
            }
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************  */
  close() {
    this.matDialogRef.close({ rstatus: this.rstatus_id });
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy

}
