import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-renewal',
  templateUrl: './account-renewal.component.html',
  styleUrls: ['./account-renewal.component.scss']
})
export class AccountRenewalComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public refrerr: any = JSON.parse(<any>localStorage.getItem("refrerr"));
  public loading = false;
  public amount_final: number = 0;
  public user_id: number | undefined;
  public subscription: Subscription;
  public network_access: boolean = false;
  public account_month: number = 1;
  public creator: number | undefined;
  public user_amount: number = 0;
  public account_title: string | undefined;
  public currency_title: string = "تومان";
  public currency_id: number = 0;
  public price_dollar: number;
  public month_extension: number = 0;
  public expire_date: string;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , public _dialogRef: MatDialogRef<AccountRenewalComponent>
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {

    if (dialog_data) {
      this.creator = dialog_data.creator;
      //this.expire_date = dialog_data.expire_date;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.open_account();
  }

  open_account() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6812
      , user_id: this.creator
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.account_title = res['result'][0].type_account_title;
            this.user_amount = res['result'][0].user_amount;
            this.expire_date = res['result'][0].user_expire_date;
            if (res['year'] == res['result'][0].user_expire_date!.split("/")[0]) {
              this.month_extension = 12 - res['result'][0].user_expire_date!.split("/")[1];
            } else {
              this.month_extension = 12 - res['month'];
            }
            this.change_amount();
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  pay() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      'address': 6367,
      'sum': this.amount_final / 10,
      'user_id': this.user_id,
      'creator': this.creator,
      'account_month': this.account_month,
      'type': 'account'
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.pay2(res['id']);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  pay2(bascket_id: number) {
    //this.serverService.get_site(),
    var obj = {
      'site': this.serverService.get_site(),
      'sum': this.amount_final,
      'amount2': this.amount_final,
      'user_id': this.user_id,
      'bascket_id': bascket_id,
      'number': this.account_month,
    }
    this.subscription = this.serverService.post_address(this.server_main, 'pay2', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          window.location.href = res['address'];
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  change_amount() {
    this.amount_final = (this.account_month * this.user_amount) / 10;
  }

  close() {
    this._dialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}