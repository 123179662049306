<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <button class="search1" (click)='openchart()'>
                  <span>
                    نمودار
                  </span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll_bg pe1'>
        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef>
                <span>تاریخ خدمات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="number">
                {{ x.date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>
                <span>ورودی</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.type_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="reception">
            <mat-header-cell *matHeaderCellDef>
                <span>ش پذیرش/فاکتور</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.id1 }}
                <span style="float: left;" (click)="open_detaile_readonly(x.type_title2,x.id)"><i
                        class="fas fa-info-circle icon"></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="customer">
            <mat-header-cell *matHeaderCellDef style="min-width: 150px">
                <span>نام طرف حساب</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width: 150px">{{
                x.customer_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 150px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 180px">
                <span>نام تعمیرکار</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width: 180px">{{
                x.user_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 180px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="service">
            <mat-header-cell *matHeaderCellDef style="min-width:300px">
                <span>عنوان خدمات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:300px">
                <div class="right">{{ x.repairman_services_title |
                    substr:0:50 }} </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width:300px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="factor_price">
            <mat-header-cell *matHeaderCellDef>
                <span>مبلغ خدمات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="number">
                {{ x.amount | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="number">{{ sum_service | number
                }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="porsant1">
            <mat-header-cell *matHeaderCellDef>
                <span>درصد پورسانت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="number">
                {{ x.wharehouse_order_profit }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="porsant2">
            <mat-header-cell *matHeaderCellDef>
                <span>پورسانت خدمات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="number">
                {{ x.profit | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="number">{{ sum_profit | number
                }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="porsant3">
            <mat-header-cell *matHeaderCellDef>
                <span>سود ناخالص خدمات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="number">
                {{ x.profit3 | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="number">{{ sum_profit3 |
                number
                }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="discount">
            <mat-header-cell *matHeaderCellDef>
                <span>تخفیف</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="number">
                {{ x.wharehouse_order_discount | number }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef class="number">{{ discount | number }}</mat-footer-cell>
        </ng-container>


        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>
