import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';
import { InsertComponent } from '../../service/insert/insert.component';

@Component({
  selector: 'app-wharehouse-order-iservice-detaile',
  templateUrl: './wharehouse-order-iservice-detaile.component.html',
  styleUrls: ['./wharehouse-order-iservice-detaile.component.scss']
})
export class WharehouseOrderIserviceDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading: boolean = false;
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public user_id!: number;
  public id!: number;
  public type_task!: number;
  public code!: number;
  public invoice_id!: number;
  public ref!: string;
  public creator!: number;
  public level!: number;
  public token!: number;
  public trash!: number;
  public result: any;
  constructor(
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<WharehouseOrderIserviceDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form1();
    if (dialog_data) {
      this.creator = dialog_data.creator;
      this.ref = dialog_data.ref;
      this.token = dialog_data.token;
      this.trash = dialog_data.trash;
      this.type_task = dialog_data.type_task;
      this.invoice_id = dialog_data.invoice_id;
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      if (this.type_task == 1) {
        this.check_access(5);
      }
      if (this.type_task == 2) {
        this.check_access(4);
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'service_title': new FormControl(null),
      'service': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'repairman_title': new FormControl(null),
      'repairman': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
      'number': new FormControl(1, [Validators.pattern('[0-9]{1,}')]),
      'cost': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'discount': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'profit': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'tax_price': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'tax': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'tax_title': new FormControl(null),
    });
  }
  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            this.level = res['level'];
            if (type_task == 1) {
              this.insert();
            }
            if (type_task == 2) {
              this.update();
            }
            if (type_task == 4) {
              this.get_data();
            }
            if (type_task == 5) {
              this.get_default_repairman();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7189, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'service_title': res['result'][0].repairman_services_title,
            'service': res['result'][0].repairman_services_id,
            'repairman_title': res['result'][0].repairman_title,
            'repairman': res['result'][0].wharehouse_order_repairman,
            'number': res['result'][0].wharehouse_order_number,
            'comment': res['result'][0].wharehouse_order_comment,
            'cost': res['result'][0].wharehouse_order_cost,
            'discount': res['result'][0].wharehouse_order_discount,
            'profit': res['result'][0].wharehouse_order_profit,
            'tax': res['result'][0].finance_tax_id,
            'tax_title': res['result'][0].finance_tax_title,
            'tax_price': res['result'][0].wharehouse_order_tax_price,
          });
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (this.form1_group.value.discount > (this.form1_group.value.cost * this.form1_group.value.number)) {
      var message = "مقدار تخفیف نمی تواند بیشتر از مقدار قیمت خدمات باشد";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 7187,
      'token': this.token,
      'creator': this.creator,
      "user_id": this.user_id,
      'service': this.form1_group.value.service,
      'profit': this.form1_group.value.profit,
      'tax': this.form1_group.value.tax,
      'tax_price': this.form1_group.value.tax_price,
      'repairman': this.form1_group.value.repairman,
      'number': this.form1_group.value.number,
      'cost': this.form1_group.value.cost,
      'comment': this.form1_group.value.comment,
      'discount': this.form1_group.value.discount,
      'invoice_id': this.invoice_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close({ result: this.result })
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (this.form1_group.value.discount > (this.form1_group.value.cost * this.form1_group.value.number)) {
      var message = "مقدار تخفیف نمی تواند بیشتر از مقدار قیمت خدمات باشد";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 7188,
      'creator': this.creator,
      "user_id": this.user_id,
      "trash": this.trash,
      "token": this.token,
      "invoice_id": this.invoice_id,
      'service': this.form1_group.value.service,
      'profit': this.form1_group.value.profit,
      'tax': this.form1_group.value.tax,
      'tax_price': this.form1_group.value.tax_price,
      'repairman': this.form1_group.value.repairman,
      'number': this.form1_group.value.number,
      'cost': this.form1_group.value.cost,
      'comment': this.form1_group.value.comment,
      'discount': this.form1_group.value.discount,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close({ result: this.result })
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  get_profit(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 7190, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'profit': res['result'][0].user_profit_repairman
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_services(id: number) {
    var where = "";
    var code_id;
    if (id == 220) {
      code_id = 7191;
    }
    if (id == 268) {
      code_id = 7192;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, where: where, creator: this.creator, code_id: code_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 220) {
            this.form1_group.patchValue({
              'repairman': res.id,
              'repairman_title': res.title
            });
            this.get_profit(res.id);
          }
          if (id == 195) {
            this.form1_group.patchValue({
              'service': res.id,
              'service_title': res.title
            });
            this.get_service_price(res.id);
          }
          if (id == 268) {
            this.form1_group.patchValue({
              'tax': res.id,
              'tax_title': res.title
            });
            this.get_tax_amount(res.id);
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (id == 220) {
      this.form1_group.patchValue({
        'repairman': null,
        'repairman_title': null
      })
    }
    if (id == 195) {
      this.form1_group.patchValue({
        'service': null,
        'service_title': null,
      })
    }
    if (id == 268) {
      this.form1_group.patchValue({
        'tax': null,
        'tax_title': null,
        'tax_price': null,
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  add_service() {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '22rem',
      height: 'auto',
      data: { creator: this.creator, service: 'services' }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.form1_group.patchValue({
            'service': res.repairman_services_id,
            'service_title': res.repairman_services_title,
            'cost': res.repairman_services_cost,
          });
        }
      }
    )
  }
  //********************************************************* */
  get_default_repairman() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7193, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'repairman_title': res['result'][0].user_title,
              'repairman': res['result'][0].user_id,
              'profit': res['result'][0].user_profit_repairman
            })
          }
          this.get_default_tax();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_tax() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7194, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'tax_title': res['result'][0].finance_tax_title,
              'tax': res['result'][0].finance_tax_id,
              'tax_price': res['result'][0].finance_tax_percent
            })
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_tax_amount(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7195, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.form1_group.patchValue({
            'tax_price': res['result'][0].finance_tax_percent
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_service_price(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7196, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.form1_group.patchValue({
            'cost': res['result'][0].repairman_services_cost
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}