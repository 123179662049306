<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row dflex">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='open_detaile(0,1,0)' [disabled]='new_status'>
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <button class="new1" (click)='open_chart()' [disabled]='new_status'>
                    <span>نمودار</span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' class="cell1 ">{{ i + page_number_first
                }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="clear_token">
            <mat-header-cell *matHeaderCellDef style='max-width:100px'>
                <span>تغییر دادن توکن</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px;padding:0.2rem !important'>
                <span (click)='clear_token(i,x.user_id)'>
                    <mat-icon>delete_sweep</mat-icon>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="admin">
            <mat-header-cell *matHeaderCellDef>
                <span>ورود به نرم افزار</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index">
                <span (click)='check_for_access_to_software(i,x.user_admin,x.user_id)'>
                    <span *ngIf="x.user_admin == 0"><i class="far fa-star icon"></i> </span>
                    <span *ngIf="x.user_admin == 1"><i class="fas fa-star icon" style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef>
                <span>نام کاربری</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'>
                <div class="left">{{ x.user_username }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="last_login">
            <mat-header-cell *matHeaderCellDef style="max-width: 140px;min-width: 140px;"
                [hidden]="code == 161 && level != 1">
                <span>آخرین تاریخ ورود</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="max-width: 140px;min-width: 140px;"
                [ngStyle]="{background:date == x.user_last_login ? '#a7e9a7' : 'transparent' }"
                (click)='open_detaile(i,2,x.user_id)' [hidden]="code == 161 && level != 1">
                {{ x.user_last_time }} - {{ x.user_last_login }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 140px;min-width: 140px;"
                [hidden]="code == 161 && level != 1"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px;">
                <span>نام کاربر</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 250px;">
                <div class="div2 right" (click)='open_detaile(i,2,x.user_id)' style="float: right;">
                    {{ x.user_sex_title }} {{ x.user_title }}
                </div>
                <span (click)="go_to_user2(x.user_id,x.user_token_login)" class="cursor fleft">
                    <i class="fa fa-eye icon"></i>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 250px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="cellphone">
            <mat-header-cell *matHeaderCellDef>
                <span>شماره همراه</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                class="center">
                <div *ngIf="show_cellphone == true" class="cellphone">{{
                    x.user_cellPhone }}</div>
                <div *ngIf="show_cellphone == false" class="cellphone">{{
                    x.user_cellPhone | hide_cellphone
                    }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef>
                <span>گروههای کاربری</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1">{{
                x.user_groups_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <span>وضعیت</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)">
                {{ x.status_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="profit_sale">
            <mat-header-cell *matHeaderCellDef>
                <span>سود فروش</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1">{{
                x.user_profit_sale }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="profit_repairman">
            <mat-header-cell *matHeaderCellDef>
                <span>سود تعمیرات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1">{{
                x.user_profit_repairman
                }}</mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                <span>عملیات</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' class="cell1">
                <span (click)="check_access(3,0,x.user_id,0,1)" style="color:red"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
                <span class="info" [matMenuTriggerFor]="menu2" (click)="get_bg2(x.user_id)"><i
                        class="fas fa-info-circle icon"></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:70px'></mat-footer-cell>
        </ng-container>
        <mat-menu #menu2="matMenu" class="services-mat-menu-panel" xPosition="before">
            <table class="table table-bordered" (click)="$event.stopPropagation()">
                <tr>
                    <td class="right">
                        <a href="/home/financial2/202?user={{ record_id }}" target="_blank">
                            <span>صورت وضعیت</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="right">
                        <a href="/home/recieve/277?user={{ record_id }}" target="_blank">
                            <span>دریافت</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="right">
                        <a href="/home/recieve/278?user={{ record_id }}" target="_blank">
                            <span>پرداخت</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="right">
                        <a href="/home/buy/270?user={{ record_id }}" target="_blank">
                            <span>فاکتور خرید</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="right">
                        <a href="/home/sale/271?user={{ record_id }}" target="_blank">
                            <span>فاکتور فروش</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="right">
                        <a href="/home/reception/160?customer={{ record_id }}" target="_blank">
                            <span>پذیرش ها - مشتری</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="right">
                        <a href="/home/reception/160?repairman={{ record_id }}" target="_blank">
                            <span>پذیرش ها - نمایش در پنل</span>
                        </a>
                    </td>
                </tr>
            </table>
        </mat-menu>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow  || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>