// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PayPalClientId:"Ae2UADHnsLpGKEh1F5AUfu0_9h31XKjrc2UNDB0menFVTWFm_vHX7C11_8HdAK0MLIdhlxP_bGo45hVX",
  recaptcha: {
    siteKey: '6LfumsYoAAAAAG00BM0wH5PHaOdzBDTEpxBs3fEL',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
