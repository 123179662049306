<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>صندوق</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding">
                <div class="inventory">{{ cash | number }}</div>
                <div style="text-align: center;">موجودی صندوق</div>
            </div>
            <!--**********************************************************************-->
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> تاریخ از </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker3" formControlName="date3" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker3"
                            class="datepicker datepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </div>
                </label>
            </div>
            <!--**********************************************************************-->
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> تاریخ تا </span>
                    <div style="width:100%">
                        <input class="wh-input" [matDatepicker]="picker4" formControlName="date4" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="picker4"
                            class="datepicker datepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                    </div>
                </label>
            </div>
            <!--**********************************************************************-->
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="button" class="btn_pe" (click)="check_search()">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>