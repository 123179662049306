import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NewFastMaterialComponent } from '../new-fast-material/new-fast-material.component';

@Component({
  selector: 'app-search-material',
  templateUrl: './search-material.component.html',
  styleUrls: ['./search-material.component.scss']
})
export class SearchMaterialComponent implements OnInit, OnDestroy {
  public isOnline: any | undefined;
  public loading = false;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  user_id: number | undefined;
  public subscription: Subscription | any;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any = this.serverService.get_server();
  public lang: any = <any>localStorage.getItem("lang");
  list_material: any = [];
  search: string | undefined;
  page: number = 1;
  invoice_id: number;
  device: number;
  cate: number;

  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  displayedColumns = ['row', 'device', 'cate', 'material', 'inventory'];

  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , private matDialogRef: MatDialogRef<SearchMaterialComponent>
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any, private dialog: MatDialog) {
    if (dialog_data) {
      this.invoice_id = dialog_data.invoice_id;
      this.device = dialog_data.device;
      this.cate = dialog_data.cate;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_material(1);
  }

  get_material(type: number) {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      address: 1886, search: this.search, page: this.page
      , device: this.device, cate: this.cate
    }
    if (type == 1) this.loading = true;

    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_material = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_material.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_material);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  select(id: number, title: string) {
    this.matDialogRef.close({ title: title, id: id });
  }
  //************************************************************************************************  */
  add() {
    const dialogRef = this.dialog.open(NewFastMaterialComponent, {
      width: '25rem',
      height: 'auto',
      data: { device: this.device, cate: this.cate }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        res.inventory = 0;
        this.list_material.unshift(res);
        this.dataSource.data = this.list_material;
      }
    )
  }
  //************************************************************************************************  */

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
