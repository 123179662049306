//این پاپ آپ برای قسمت خروج قطعه و یا منتظر قطعه در پذیرش می باشد
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';

@Component({
  selector: 'app-exit-detaile2',
  templateUrl: './exit-detaile2.component.html',
  styleUrls: ['./exit-detaile2.component.scss']
})
export class ExitDetaile2Component implements OnInit, OnDestroy {
  public isOnline: any;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any;
  lang: any;
  type_task: number | undefined;
  form1_group: FormGroup | any;
  user_info: any;
  user_id: number | any;
  id: number | undefined;
  i: number | undefined;
  code: number | undefined;
  title: string | undefined;
  search_array: any = [];
  reception_id: number | undefined;
  group: number = 0;
  inventory: number | undefined;
  ref: string | undefined;
  material_type: number | undefined;
  order_id: number | undefined;
  temp_number: number = 0;
  creator: number | undefined;
  pending: any = false;


  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any, private dialog: MatDialog
    , private matDialogRef: MatDialogRef<ExitDetaile2Component>) {
    this.server = this.serverService.get_server();
    this.user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.ref = dialog_data.ref;
      this.pending = dialog_data.pending;//وقتی پاپ آپ از پذیرش  باز می شود این مشخص می کند که آیا منتظر قطعه هست یا خیر
      this.type_task = dialog_data.type_task;
      this.reception_id = dialog_data.reception_id;
      this.group = dialog_data.group;
      this.create_form1();
      this.id = dialog_data.id;
      this.title = dialog_data.title;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.material_type = dialog_data.material_type;
      if (this.type_task == 1) {
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          material: dialog_data.search_array.material,
          material_title: dialog_data.search_array.material_title,
          cost_center: dialog_data.search_array.cost_center,
          cost_center_title: dialog_data.search_array.cost_center_title,
          number: dialog_data.search_array.number,
          comment: dialog_data.search_array.comment,
        })
      }
    }
  }//end consructor

  ngOnInit() {
    this.server = this.serverService.get_server();
    this.lang = JSON.parse(<any>localStorage.getItem('lang'));
    this.user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'group_title': new FormControl(null),
      'group': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cate_title': new FormControl(null),
      'cate': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'material_title': new FormControl(null),
      'material': new FormControl(null),
      'repairman_title': new FormControl(null),
      'repairman': new FormControl(null),
      'cost_center_title': new FormControl(null),
      'cost_center': new FormControl(null),
      'comment': new FormControl(null),
      'number': new FormControl(null),
      'cost': new FormControl(null),
      'discount': new FormControl(null),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      if (this.ref != 'reception') this.form1_group.controls['cost_center'].setValidators([Validators.required])
    }
    if (this.group > 0 && this.type_task == 1) {
      this.get_group();
    }

  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            if (type_task == 1) { this.check_inventory(); }
            if (type_task == 2) {
              if (this.material_type == 1 && this.pending == 0) {
                this.check_inventory();
              } else {
                this.update();
              }
            }
            if (type_task == 3) { this.search(excel); }
            if (type_task == 4) {
              if (this.material_type == 1) this.get_data_material();
              else this.get_data_services();
            }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      material_title: this.form1_group.value.material_title,
      material: this.form1_group.value.material,
      cost_center_title: this.form1_group.value.cost_center_title,
      cost_center: this.form1_group.value.cost_center,
      number: this.form1_group.value.number,
      comment: this.form1_group.value.comment,
      export_excel: excel
    })
  }

  get_data_material() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1861, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'group_title': res['result'][0].wharehouse_group_title,
            'group': res['result'][0].wharehouse_group_id,
            'cate_title': res['result'][0].wharehouse_material_cate_title,
            'cate': res['result'][0].wharehouse_material_cate_id,
            'material_title': res['result'][0].wharehouse_material_title,
            'material': res['result'][0].wharehouse_order_material,
            'cost_center_title': res['result'][0].wharehouse_cost_center_title,
            'cost_center': res['result'][0].wharehouse_order_cost_center,
            'number': res['result'][0].wharehouse_order_number,
            'comment': res['result'][0].wharehouse_order_comment,
            'sort': res['result'][0].wharehouse_order_sort,
            'cost': res['result'][0].wharehouse_order_cost,
            'discount': res['result'][0].wharehouse_order_discount,
            'repairman_title': res['result'][0].repairman_title,
            'repairman': res['result'][0].wharehouse_order_repairman,
          }
          );
          this.order_id = res['result'][0].wharehouse_order_consumed_invoice;
          this.temp_number = res['result'][0].wharehouse_order_number;

          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_data_services() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1988, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'material_title': res['result'][0].repairman_services_title,
            'material': res['result'][0].wharehouse_order_material,
            'repairman_title': res['result'][0].repairman_title,
            'repairman': res['result'][0].wharehouse_order_repairman,
            'cost_center_title': res['result'][0].wharehouse_cost_center_title,
            'cost_center': res['result'][0].wharehouse_order_cost_center,
            'number': res['result'][0].wharehouse_order_number,
            'comment': res['result'][0].wharehouse_order_comment,
            'cost': res['result'][0].wharehouse_order_cost,
            'discount': res['result'][0].wharehouse_order_discount,
          }
          )
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  insert() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1857, "user_id": this.user_id
      , 'material': this.form1_group.value.material
      , 'cost_center': this.form1_group.value.cost_center
      , 'number': this.form1_group.value.number
      , 'comment': this.form1_group.value.comment
      , 'reception_id': this.reception_id
      , 'sort': this.form1_group.value.sort
      , 'ref': this.ref
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title should be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end insert

  check_inventory() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6134, material_id: this.form1_group.value.material, order_id: this.order_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (this.form1_group.value.number > this.temp_number) {
              if (Number(res['remain']) >= (Number(this.form1_group.value.number) - Number(this.temp_number))) {
                if (this.type_task == 1) {
                  this.insert();
                }
                else {
                  this.update();
                }
              }
              else {
                var pe_message = "تعدادی درخواستی بیشتر ار موجودی انبار می باشد .لطفا اصلاح نمائید.";
                var pe_action = "بستن";
                this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
              }
            } else {
              if (this.type_task == 1) {
                this.insert();
              }
              else {
                this.update();
              }
            }
          }//end res['num'] == 1
          else {
            var pe_message = "تعدادی درخواستی بیشتر ار موجودی انبار می باشد .لطفا اصلاح نمائید.";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          }
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1990, "user_id": this.user_id
      , 'material': this.form1_group.value.material
      , 'repairman': this.form1_group.value.repairman
      , 'cost_center': this.form1_group.value.cost_center
      , 'number': this.form1_group.value.number
      , 'cost': this.form1_group.value.cost
      , 'comment': this.form1_group.value.comment
      , 'sort': this.form1_group.value.sort
      , 'discount': this.form1_group.value.discount
      , 'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0], i: this.i }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
          this.serverService.send_payments(1);
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title should be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end update


  load_services(id: number) {
    if (this.material_type == 1 && (id == 223 || id == 184 || id == 195)) {
      return false;
    }
    var where = "";
    var code_id;
    if (id == 195) {
      if (this.material_type == 1) { id = 171; }
    }
    if (id == 220) {
      code_id = 6227;
    }
    if (id == 223) {
      code_id = 6346;
    }
    if (id == 184) {
      where += " and wharehouse_material_cate_group = " + this.form1_group.value.group;
    }
    if (id == 171) {
      where += " and wharehouse_material_cate = " + this.form1_group.value.cate;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, where: where, creator: this.creator, code_id: code_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 220) {
            this.form1_group.patchValue({
              'repairman': res.id,
              'repairman_title': res.title
            })
          }
          if (id == 186) {
            this.form1_group.patchValue({
              'cost_center': res.id,
              'cost_center_title': res.title
            })
          }
          if (id == 223) {
            this.form1_group.patchValue({
              'group': res.id,
              'group_title': res.title,
              'cate': null,
              'cate_title': null,
              'material': null,
              'material_title': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate': res.id,
              'cate_title': res.title,
              'material': null,
              'material_title': null
            })
          }
          if (id == 171 || id == 195) {
            this.form1_group.patchValue({
              'material': res.id,
              'material_title': res.title
            })
          }
        }
      }
    )

  }


  reset_input(id: number): any {
    if (this.material_type == 1 && (id == 223 || id == 184 || id == 195)) {
      return false;
    }
    if (id == 220) {
      this.form1_group.patchValue({
        'repairman': null,
        'repairman_title': null
      })
    }
    if (id == 186) {
      this.form1_group.patchValue({
        'cost_center': null,
        'cost_center_title': null
      })
    }
    if (id == 171 || id == 195) {
      this.form1_group.patchValue({
        'material': null,
        'material_title': null,
        'group': null,
        'group_title': null,
        'cate': null,
        'cate_title': null
      })
    }
    if (id == 223) {
      this.form1_group.patchValue({
        'group': null,
        'group_title': null,
        'cate': null,
        'cate_title': null
      })
    }
    if (id == 184) {
      this.form1_group.patchValue({
        'cate': null,
        'cate_title': null
      })
    }
  }

  get_group() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1766, group: this.group }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'group': res['result'][0].wharehouse_group_id,
              'group_title': res['result'][0].wharehouse_group_title,
            })
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  required1() {
    if ((this.type_task == 1 || this.type_task == 2) && this.ref != 'reception') {
      return true;
    }
    else {
      return false;
    }
  }

  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}