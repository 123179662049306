<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    {{ serverService.get_title(type_task,lang) }}
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent" style="padding:0px !important;padding-bottom:1rem !important">
        <div class="row">
            <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe' [ngClass]="{'width100':type_task == 3}">

                <div class="row">
                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="sex" class='wh-label'>
                            <span> عنوان </span>
                            <span (click)="reset_input(10)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='sex_title' autocomplete="off"
                                [required]="required()" readonly (click)="load_services(10)"
                                [ngClass]="{'en':dir == 'ltr'}">
                            <input class='wh-input' formControlName='sex' autocomplete="off" [required]="required()"
                                readonly [hidden]="true">
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="title" class='wh-label'>
                            <span>نام طرف حساب </span>
                            <input class='wh-input' formControlName='title' autocomplete="off" [required]="required()"
                                [ngClass]="{'en':dir == 'ltr'}">
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="group" class='wh-label'>
                            <span> گروههای کاربری </span>
                            <span (click)="reset_input(155)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='group_title' autocomplete="off"
                                (click)="load_services(155)" [required]="required()" readonly>
                            <input class='wh-input' formControlName='group' autocomplete="off" [required]="required()"
                                [hidden]="true" [ngClass]="{'en':dir == 'ltr'}" readonly>
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="status_id" class='wh-label'>
                            <span> وضعیت </span>
                            <span (click)="reset_input(26)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='status_title' autocomplete="off"
                                (click)="load_services(26)" [required]="required()" readonly
                                [ngClass]="{'en':dir == 'ltr'}">
                            <input class='wh-input' formControlName='status_id' autocomplete="off"
                                [required]="required()" [hidden]="true" readonly>
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="cellphone" class='wh-label'>
                            <span> شماره همراه </span>
                            <input class='wh-input' formControlName='cellphone' pattern="[0-9]{1,}"
                                (keypress)="get_number($event)" minlength=11 maxlength=11 autocomplete="off"
                                [required]="required() && level == 1 " autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="phone" class='wh-label'>
                            <span> شماره ثابت </span>
                            <input class='wh-input' formControlName="phone" autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="state" class='wh-label'>
                            <span> استان </span>
                            <span (click)="reset_input(233)">
                                <mat-icon class="close" class="input_search input_search_pe">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='state_title' autocomplete="off"
                                (click)="load_services(233)" readonly [ngClass]="{'en':dir == 'ltr'}">
                            <input class='wh-input' formControlName='state' autocomplete="off" [hidden]="true" readonly>
                        </label>
                    </div>

                    <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="address" class='wh-label'>
                            <span> آدرس </span>
                            <input class='wh-input' formControlName='address' autocomplete="off" autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="store" class='wh-label'>
                            <span> نام شرکت / فروشگاه </span>
                            <input class='wh-input' formControlName='store' autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="email" class='wh-label'>
                            <span> پست الکترونیک </span>
                            <input class='wh-input' formControlName='email' autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="code_meli" class='wh-label'>
                            <span> کد ملی / شناسه </span>
                            <input class='wh-input' formControlName='code_meli' autocomplete="off"
                                (keypress)="serverService.get_number($event)">
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="code_posti" class='wh-label'>
                            <span> کد پستی </span>
                            <input class='wh-input' formControlName='code_posti' autocomplete="off"
                                (keypress)="serverService.get_number($event)" length="10" minlength="10" maxlength="10"
                                [ngClass]="{'en':dir == 'ltr'}">
                        </label>
                    </div>

                    <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="economic_code" class='wh-label'>
                            <span> شماره اقتصادی </span>
                            <input class='wh-input' formControlName='economic_code' autocomplete="off"
                                (keypress)="serverService.get_number($event)" [ngClass]="{'en':dir == 'ltr'}">
                        </label>
                    </div>

                    <div class='col-md-6 col-lg-6 col-sm-6 col-xs-6 pe div_panding'
                        [ngClass]="{width50:type_task == 3}">
                        <label for="comment" class='wh-label'>
                            <span> توضیحات </span>
                            <input class="wh-input" formControlName='comment'>
                        </label>
                    </div>
                </div><!-- row -->
            </div><!-- col-md-8 col-lg-8 col-sm-8 col-xs-12  -->
        </div><!-- row -->
    </div> <!-- content -->


    <div mat-dialog-actions class="matDialogActions" style="padding-right:25px">
        <button type="submit" [disabled]="!form1.valid" class="btn_pe" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }} </button>
    </div><!-- row -->
</form>