<div class="row right-menu-row1" style="margin-top:1rem">
    <ng-container *ngIf="show_record == true">
        <div *ngIf="show_group == true">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor" *ngFor="let x of list_groups"
                (click)="go_to_group(x.user_groups_id)" [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:groups_id == x.user_groups_id}">
                <div class="title">{{ x.user_groups_title }}</div>
                <span class="count">{{ x.count }}</span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                (click)="get_moarf('my_moarf')" [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:moarf == 'my_moarf'}" *ngIf="level == 1">
                <div class="title">ست کنندگان کد معرف</div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                (click)="get_moarf('moarf_id')" [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:moarf == 'moarf_id'}" *ngIf="level == 1">
                <div class="title">استفاده کنندگان کد معرف</div>
            </div>
        </div>
        <div *ngIf="level != 1 && my_network == true">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                [ngClass]="{activeStatus:netwok == 'profit'}" (click)="get_my_netwok()">
                <span>درآمد من از کد معرف</span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                (click)="get_my_consume_in_netwok()" [ngClass]="{activeStatus:netwok == 'consume'}">
                <span> پرداخت های کیف پول </span>
            </div>
        </div>

        <div *ngIf="level == 1 && list_bascket_status.length > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor" (click)="change_bascket(0)"
                [ngClass]="{activeStatus:bascket_status_id == 0}">
                <div class="title">
                    <span>موفق</span>
                </div>
                <span class="count">{{ count_bascket_status_all }}</span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                *ngFor="let x of list_bascket_status" (click)="change_bascket(x.bascket_status_id)"
                [ngClass]="{activeStatus:bascket_status_id == x.bascket_status_id,div1_en:dir == 'ltr'}">
                <div class="title">{{ x.bascket_status_title }}</div>
                <span class="count">{{ x.count }}</span>
            </div>
        </div>
        <div *ngIf="report == 'rstatus' || report == 'repairman'">
            <ng-container>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
                    [ngClass]="{active2:report == 'rstatus'}" (click)="change_rstatus('rstatus')">
                    <span> وضعیتها </span>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
                    [ngClass]="{active2:report == 'repairman'}" (click)="change_rstatus('repairman')">
                    <span> تعمیرکاران </span>
                </div>
            </ng-container>
            <div style="padding-top: 4rem;" *ngIf="loading2 == true">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>
            <ng-container *ngIf="report == 'rstatus' && loading2 == false">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    *ngFor="let x of list_rstatus" (click)="go_to_reception_with_rstatus(x.repairman_rstatus_id)"
                    [routerLinkActive]="['activeStatus']"
                    [ngClass]="{activeStatus:rstatus_id == x.repairman_rstatus_id}">
                    <div class="title">{{ x.repairman_rstatus_title }}</div>
                    <span class="count">{{ x.count }}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="report == 'repairman'">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    *ngFor="let x of list_repairman" [routerLinkActive]="['activeStatus']"
                    [ngClass]="{activeStatus:record_id == x.user_id}"
                    (click)="go_to_reception_with_repairman(x.user_id)">
                    <div class="title">{{ x.user_title }}</div>
                    <span class="count">{{ x.count }}</span>
                </div>
            </ng-container>

        </div>
        <div *ngIf="report_users == true">
            <ng-container *ngFor="let x of list_report_users">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    [ngClass]="{activeStatus:record_id == 'all'}" (click)="get_filter_report_users('all')">
                    <div class="title">همه کاربران</div>
                    <span class="count">{{ x.count_all | number }}</span>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    [ngClass]="{activeStatus:record_id == 'active'}" (click)="get_filter_report_users('active')">
                    <div class="title">کاربران فعال</div>
                    <span class="count">{{ x.count_active | number }}</span>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    [ngClass]="{activeStatus:record_id == 'active-expired'}"
                    (click)="get_filter_report_users('active-expired')">
                    <div class="title">کاربران فعال منقضی شده</div>
                    <span class="count">{{ x.count_active_expired | number }}</span>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    [ngClass]="{activeStatus:record_id == 'free'}" (click)="get_filter_report_users('free')">
                    <div class="title">کاربران رایگان </div>
                    <span class="count">{{ x.count_free | number }}</span>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    [ngClass]="{activeStatus:record_id == 'free-expired'}"
                    (click)="get_filter_report_users('free-expired')">
                    <div class="title">کاربران رایگان منضی شده </div>
                    <span class="count">{{ x.count_free_expired | number }}</span>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="show_record == false">
        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="preview2">
                    <span>دیتایی برای نمایش وجود ندارد</span>
                    <br>
                    ...
                </div>
            </div>
        </div>
    </ng-container>
</div>












<!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
            [ngClass]="{active2:rstatus_report == true}" (click)="change_rstatus(true)" *ngIf="show_chart == true">
            گزارشات</div>
        <ng-container *ngIf="report == false">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                *ngFor="let x of list_rstatus" (click)="go_to_reception_with_repairman(x.repairman_rstatus_id)"
                [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:rstatus_id == x.repairman_rstatus_id,div1_en:dir == 'ltr',en1:dir == 'ltr'}">
                <div class="title">{{ x.repairman_rstatus_title }}</div>
                <span class="count">{{ x.count }}</span>
            </div>
        </ng-container> -->
<!-- <div (click)="change_show_chart()">.</div> -->