import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { InsertComponent } from '../../../service/insert/insert.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-material-detaile',
  templateUrl: './material-detaile.component.html',
  styleUrls: ['./material-detaile.component.scss']
})
export class MaterialDetaileComponent implements OnInit, OnDestroy {
  public server_main: string = this.serverService.get_server_main();
  public server: any = this.serverService.get_server();
  public site: any = this.serverService.get_site();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading = false;
  public loading2 = false;
  public subscription: Subscription;
  public loading_file: boolean = false;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i!: number;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public result: any | undefined;
  public temp_inventory: number = 0;
  public coding: string | undefined;
  public creator: number | undefined;
  public expire: boolean | undefined;
  public readonly: boolean = false;
  public access_service: number | undefined;
  public level: number | undefined;
  public order_id: number;
  public wharehouse: boolean;
  public token: any = new Date().getTime();
  public list_load_file: any = [];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<MaterialDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.wharehouse = dialog_data.wharehouse;
      this.readonly = dialog_data.readonly;
      this.creator = dialog_data.creator;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          group: dialog_data.search_array.group,
          group_title: dialog_data.search_array.group_title,
          cate: dialog_data.search_array.cate,
          cate_title: dialog_data.search_array.cate_title,
          title: dialog_data.search_array.title,
          price: dialog_data.search_array.price,
          price2: dialog_data.search_array.price2,
          comment: dialog_data.search_array.comment,
          coding: dialog_data.search_array.coding,
          inventory: dialog_data.search_array.inventory,
        })
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'price': new FormControl(0),
      'price2': new FormControl(0),
      'group_title': new FormControl(null),
      'group': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cate_title': new FormControl(null),
      'cate': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'inventory': new FormControl(0, [Validators.pattern('[0-9.]{1,}')]),
      'weight_title': new FormControl(null),
      'weight': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
      'coding': new FormControl(null),

    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['title'].setValidators([Validators.required])
      this.form1_group.controls['group'].setValidators([Validators.required])
      this.form1_group.controls['cate'].setValidators([Validators.required])
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.title = res['result'][0].title;
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          //this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
            this.get_weight();
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1_group.value.title,
      price: this.form1_group.value.price,
      price2: this.form1_group.value.price2,
      coding: this.form1_group.value.coding,
      group_title: this.form1_group.value.group_title,
      group: this.form1_group.value.group,
      cate_title: this.form1_group.value.cate_title,
      cate: this.form1_group.value.cate,
      status_title: this.form1_group.value.status_title,
      comment: this.form1_group.value.comment,
      inventory: this.form1_group.value.inventory,
      export_excel: excel
    })
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6041, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].wharehouse_material_title,
            'inventory': res['result'][0].wharehouse_material_inventory,
            'price': res['result'][0].wharehouse_material_price,
            'price2': res['result'][0].wharehouse_material_price2,
            'group_title': res['result'][0].wharehouse_group_title,
            'group': res['result'][0].wharehouse_group_id,
            'cate_title': res['result'][0].wharehouse_material_cate_title,
            'cate': res['result'][0].wharehouse_material_cate_id,
            'weight': res['result'][0].wharehouse_weight_id,
            'weight_title': res['result'][0].wharehouse_weight_title,
            'comment': res['result'][0].wharehouse_material_comment,
            'coding': res['result'][0].wharehouse_material_coding,
          }
          );
          this.temp_inventory = res['result'][0].wharehouse_material_inventory;
          this.order_id = res['result'][0].order_id;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6037,
      "user_id": this.user_id,
      'price': this.form1_group.value.price,
      'price2': this.form1_group.value.price2,
      'comment': this.form1_group.value.comment,
      'title': this.form1_group.value.title,
      'inventory': this.form1_group.value.inventory,
      'weight': this.form1_group.value.weight,
      'group': this.form1_group.value.group,
      'cate': this.form1_group.value.cate,
      'group2': this.form1_group.value.group2,
      'creator': this.creator,
      'coding': this.form1_group.value.coding
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.insert_order(res['result'][0].wharehouse_material_id, res['result'][0].wharehouse_material_price2);
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  insert_order(id: number, price2: number) {
    var obj = {
      address: 6131, id: id
      , user_id: this.user_id
      , creator: this.creator
      , cost2: price2
      , inventory: this.form1_group.value.inventory
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update() {
    if (this.form1_group.value.inventory < this.temp_inventory) {
      this.check_inventory();
    } else {
      this.update2();
    }
  }
  check_inventory() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6496, material: this.id, order_id: this.order_id, inventory: this.form1_group.value.inventory }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (Number(res['result'][0].consumed) > this.form1_group.value.inventory) {
            var message = "تعدادی درج شده بیشتر ار خروجی انبار می باشد .لطفا اصلاح نمائید.";
            this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
          }
          else {
            this.update2();
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update2() {
    var obj = {
      'address': 6038,
      "user_id": this.user_id,
      'title': this.form1_group.value.title,
      'price': this.form1_group.value.price,
      'price2': this.form1_group.value.price2,
      'inventory': this.form1_group.value.inventory,
      'weight': this.form1_group.value.weight,
      'group': this.form1_group.value.group,
      'cate': this.form1_group.value.cate,
      'group2': this.form1_group.value.group2,
      'comment': this.form1_group.value.comment,
      'creator': this.creator,
      'id': this.id,
      'coding': this.form1_group.value.coding

    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = { result: res['result'][0], i: this.i, token: this.token };
          this.get_inventory();
          //this.update_order();
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  update_order() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6440, material_id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.get_inventory();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_inventory() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1984, material_id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.update_remain(res['result'][0].inventory)
          } else {
            this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_remain(inventory) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6439, material_id: this.id, inventory: inventory }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(this.result);
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_weight() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6211, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            weight: res['result'][0].wharehouse_weight_id,
            weight_title: res['result'][0].wharehouse_weight_title,
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  add_group(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'group' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'group_title': res.wharehouse_group_title,
            'group': res.wharehouse_group_id
          })
        }
      }
    )
  }
  add_cate(): any {
    if (!this.form1_group.value.group) {
      var message = "برای درج زیرگروه نیاز است تا گروه انتخاب شده باشد";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'cate', group: this.form1_group.value.group }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'cate_title': res.wharehouse_material_cate_title,
            'cate': res.wharehouse_material_cate_id
          })
        }
      }
    )
  }
  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    var where = "";
    var creator = this.creator;
    var code_id;
    if (id == 1841) {
      if (!this.form1_group.value.group) {
        var message = "در ابتدا باید گروه انتخاب شود";
        this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        return false;
      }
    }
    if (id == 184) {
      code_id = 6523;
    }
    if (id == 223) {
      code_id = 6346;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: {
        id: id, where: where, creator: creator, code_id: code_id,
        obj: { group: this.form1_group.value.group, group2: this.form1_group.value.group2 }
      }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 223) {
            this.form1_group.patchValue({
              'group': res.id,
              'group_title': res.title,
              'cate': null,
              'cate_title': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate': res.id,
              'cate_title': res.title
            })
          }
          if (id == 211) {
            this.form1_group.patchValue({
              'weight': res.id,
              'weight_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    if (id == 223) {
      this.form1_group.patchValue({
        'group_title': null,
        'group': null,
        'cate': null,
        'cate_title': null
      })
    }
    if (id == 184) {
      this.form1_group.patchValue({
        'cate_title': null,
        'cate': null

      })
    }
    if (id == 211) {
      this.form1_group.patchValue({
        'weight': null,
        'weight_title': null
      })
    }
  }
  //********************************************************************************************* */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}