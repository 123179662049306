<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">لیست کالا</span>
        <span *ngIf="lang == 2">Product list</span>
    </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent matDialogContent1">
    <div [ngStyle]="{'text-align':dir == 'ltr' ? 'left' : 'right'}">
        <span class="hidden-xs">
            <button (click)="which(2)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
            <button (click)="which(3)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
        </span>
        <button type="button" (click)='get_material3()'
            [ngClass]="{button_active:type_list == 'material','full-left':dir == 'ltr'}" class="invoice_button2">
            <span *ngIf="lang == 1">لیست کالاها</span>
            <span *ngIf="lang == 2">Product List</span>
            {{ count_total_material | number}}
        </button>
        <button type="button" (click)='get_cost3()'
            [ngClass]="{button_active:type_list == 'cost','full-left':dir == 'ltr'}" class="invoice_button2"
            [hidden]="true">
            <span *ngIf="lang == 1">لیست هزینه ها</span>
            <span *ngIf="lang == 2">Expense list</span>
            {{ count_total_cost | number }}
            <span></span>
        </button>
        <button type="button" class="invoice_button2"
            [ngClass]="{button_active:type_list == 'order','full-left':dir == 'ltr'}" (click)='get_order()'>
            <span>
                <span *ngIf="lang == 1"> سبد خرید </span>
                <span *ngIf="lang == 2"> Shopping cart </span>
            </span>
            <span [ngClass]="{'en':dir == 'ltr'}">{{ count_order }}</span>
        </button>

        <button type="button" (click)='add()' class="search_button hidden-xs" *ngIf="type_list == 'material'">
            <span *ngIf="lang == 1">تعریف کالای جدید</span>
            <span *ngIf="lang == 2">Add new product</span>
        </button>
    </div>

    <div class="row" *ngIf="type_list == 'material'">
        <span>
            <select style="width:20%" (change)="get_material_with_group()" name="wharehouse_group"
                [(ngModel)]="wharehouse_group">
                <option [value]="0">انتخاب گروه</option>
                <option *ngFor="let x of list_wharehouse_group" [value]="x.wharehouse_group_id">
                    {{ x.wharehouse_group_title }}
                </option>
            </select>
            <select style="width:20%" (change)="get_material_with_cate()" name="wharehouse_cate"
                [(ngModel)]="wharehouse_cate">
                <option [value]="0">انتخاب زیرگروه</option>
                <option *ngFor="let x of list_wharehouse_cate" [value]="x.wharehouse_material_cate_id">
                    {{ x.wharehouse_material_cate_title }}
                </option>
            </select>
            <input class="search" style="width:10%" (keyup)="get_material2()" [(ngModel)]="search_code"
                autocomplete="off" placeholder="فیلتر کد کالا">
            <input class="search" style="width:50%" (keyup)="get_material2()" [(ngModel)]="search" autocomplete="off"
                placeholder="فیلتر عنوان کالا">
        </span>
        <div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}">
            <mat-table [dataSource]="dataSource" style='min-width:100%'
                class='table-scroll mat_table_fa_without_scroll pe1'
                [ngClass]="{mat_table_en_without_scroll_bg:dir=='ltr',en1:dir == 'ltr'}">

                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + page_number_first }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef style="text-align: center !important;" class="right">
                        <span *ngIf="lang == 1">کد کالا</span>
                        <span *ngIf="lang == 2">Product name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor right">
                        <span class="paddingRight" [ngClass]="{'full-left':dir == 'ltr'}">
                            {{ x.wharehouse_material_title }} | {{ x.wharehouse_group_title }} | {{
                            x.wharehouse_material_cate_title }} </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="coding">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px">
                        <span *ngIf="lang == 1">عنوان کالا</span>
                        <span *ngIf="lang == 2">Product name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="max-width:100px">
                        {{ x.wharehouse_material_coding }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="seller">
                    <mat-header-cell *matHeaderCellDef style="max-width:150px" [hidden]="document_type_text != 'exit'">
                        <span *ngIf="lang == 1">فروشنده</span>
                        <span *ngIf="lang == 2">Salesman</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor"
                        style="text-align: center;height:2rem;padding:0px !important;max-width:150px"
                        [hidden]="document_type_text != 'exit'">
                        <select class="select" id="repairman{{ x.wharehouse_order_id  }}"
                            [ngClass]="{'full-left':dir == 'ltr'}">
                            <option *ngFor="let repairman of list_repairman" [selected]="repairman.user_id == user_id"
                                [value]="repairman.user_id">{{
                                repairman.user_title }}</option>
                        </select>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="inventory">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px" [hidden]="document_type_text == 'input'">
                        <span *ngIf="lang == 1">موجودی</span>
                        <span *ngIf="lang == 2">Stock</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding" style="text-align: center;"
                        style="max-width:135px" [hidden]="document_type_text == 'input'">
                        <input class=" wh-input input1" id="inventory1{{ x.wharehouse_material_id }}" currencyMask
                            [value]="x.inventory | number" autocomplete="off" readonly>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="order">
                    <mat-header-cell *matHeaderCellDef style="max-width:120px" [hidden]='true'>
                        <span *ngIf="lang == 1">ش درخواست</span>
                        <span *ngIf="lang == 2">Request no</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding" style="max-width:120px" [hidden]='true'>
                        <input class=" wh-input input1" id="order1{{ x.wharehouse_material_id }}" autocomplete="off"
                            readonly>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="last_invoice_price">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px"
                        [hidden]="document_type_text == 'request'">
                        <span *ngIf="lang == 1">آخرین قیمت خرید</span>
                        <span *ngIf="lang == 2">Last purchase price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor" style="max-width:135px;"
                        [hidden]="document_type_text == 'request'">
                        <span (click)="show_price(i,x.wharehouse_material_id)" *ngIf="!x.price && x.price != 0">
                            <span *ngIf="lang == 1">نمایش</span>
                            <span *ngIf="lang == 2">Display</span>
                        </span>
                        <span *ngIf="x.price >= 0" (click)="hide_price(i)" [ngClass]="{'en':dir == 'ltr'}">{{ x.price |
                            number }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoice_price">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px"
                        [hidden]="document_type_text == 'request'">
                        <span *ngIf="lang == 1">قیمت خرید</span>
                        <span *ngIf="lang == 2">Purchase price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:135px;"
                        [hidden]="document_type_text == 'request'">
                        <input class=" wh-input input1" id="invoice_price1{{ x.wharehouse_material_id }}" currencyMask
                            [ngModel]="x.wharehouse_order_cost" autocomplete="off"
                            [readonly]="document_type_text != 'input'" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px">
                        <span *ngIf="lang == 1">تعداد</span>
                        <span *ngIf="lang == 2">Number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:135px;">
                        <input type="number" class=" wh-input input1" id="input1{{ x.wharehouse_material_id }}"
                            [value]="1" autocomplete="off" min="0" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef style="max-width:120px"
                        [hidden]="ref == 'order' || back == true || document_type_text == 'request' ">
                        <span *ngIf="lang == 1">تخفیف</span>
                        <span *ngIf="lang == 2">Discount</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:120px"
                        [hidden]="ref == 'order' || back == true || document_type_text == 'request'">
                        <input class="wh-input input1" id="discount1{{ x.wharehouse_material_id }}" currencyMask
                            autocomplete="off" [ngModel]="0" [disabled]="back == true" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px">
                        <span *ngIf="lang == 1">اضافه</span>
                        <span *ngIf="lang == 2">Add</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor"
                        style="max-width:80px;padding-top:3px !important">
                        <mat-icon class="add_circle_outline"
                            (click)="save(i,x.wharehouse_material_id,x.wharehouse_order_id, 1 , 1 , 0)"
                            style="vertical-align: middle;font-size: 20px;" *ngIf="ref == 'invoice' ">
                            add_circle_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns2" color="primary"></mat-header-row>
                <mat-row *matRowDef="let x;let i = index;columns:displayedColumns2"></mat-row>
            </mat-table>

        </div>
    </div>

    <div class="row" *ngIf="type_list == 'cost'">
        <input class="search" (keyup)="get_services2(2)" [(ngModel)]="search" autocomplete="off"
            placeholder="{{ serverService.get_title(3,lang) }}..." [ngClass]="{'full-left':dir == 'ltr'}">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">

            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'
                [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i+
                        page_number_first }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                        <span *ngIf="lang == 1">عنوان هزینه</span>
                        <span *ngIf="lang == 2">Cost title</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.finance_cost_type_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="group">
                    <mat-header-cell *matHeaderCellDef>
                        <span *ngIf="lang == 1">عنوان گروه هزینه</span>
                        <span *ngIf="lang == 2">Cost group title</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.finance_cost_type_group_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                        <span *ngIf="lang == 1">تعداد </span>
                        <span *ngIf="lang == 2">Number </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important" [hidden]="true">
                        <input type="number" class="wh-input input1" id="input2{{ x.finance_cost_type_id }}" [value]="1"
                            autocomplete="off" currencyMask [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cost">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px">
                        <span *ngIf="lang == 1">قیمت پایه </span>
                        <span *ngIf="lang == 2">Starting price </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important">
                        <input class="wh-input input1" id="cost2{{ x.finance_cost_type_id }}"
                            [ngModel]="x.finance_cost_type_cost" currencyMask autocomplete="off" currencyMask
                            [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="comment">
                    <mat-header-cell *matHeaderCellDef style="max-width:400px">
                        <span *ngIf="lang == 1"> توضیحات </span>
                        <span *ngIf="lang == 2"> Description </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:400px;padding:0px !important">
                        <input class="wh-input input1" id="comment{{ x.finance_cost_type_id }}" autocomplete="off"
                            style="text-align: right;" [ngClass]="{'full-left':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px">
                        <span *ngIf="lang == 1"> اضافه </span>
                        <span *ngIf="lang == 2"> Add </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor" style="text-align: center;"
                        style="max-width:80px;padding-top:3px !important">
                        <mat-icon class="add_circle_outline" (click)="add_to_invoice_cost(i,x.finance_cost_type_id)"
                            style="vertical-align: middle;font-size: 20px;">
                            add_circle_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns3" color="primary"></mat-header-row>
                <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns3;let i = index"
                    (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                    [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                    class="cursor"></mat-row>
            </mat-table>
        </div>

    </div>
    <!-- **************************************************************************************************** -->
    <div class="row" *ngIf="type_list != 'material' && type_list != 'cost'">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">
            <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll not- pe1'
                [ngStyle]="{'direction': lang == 2 ? 'ltr' : 'rtl' }">
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                        <span *ngIf="lang == 1">ردیف</span>
                        <span *ngIf="lang == 2">Row</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + 1 }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }">
                        <span *ngIf="lang == 1">نام کالا</span>
                        <span *ngIf="lang == 2">Product name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="direction: rtl;"
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.title }} | {{
                            x.wharehouse_material_cate_title | substr:0:20 }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef [hidden]="true">
                        <span *ngIf="lang == 1">مدل قطعه</span>
                        <span *ngIf="lang == 2">Component model</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '120px' : 'auto' }">
                        <span *ngIf="lang == 1">تعداد</span>
                        <span *ngIf="lang == 2">Number</span>
                        <span *ngIf="document_type_text == 'request' ">
                            <span *ngIf="lang == 1">درخواستی</span>
                            <span *ngIf="lang == 2">Requested</span>
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '120px' : 'auto' }"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_number }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                        <span *ngIf="lang == 1">واحد شمارش</span>
                        <span *ngIf="lang == 2">Counting unit</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width:100px" [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_weight_title}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="price">
                    <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">قیمت واحد</span>
                        <span *ngIf="lang == 2">Unit price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 150px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_cost | number }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">تخفیف</span>
                        <span *ngIf="lang == 2">Discount</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 150px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_discount | number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="sum">
                    <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                        [hidden]="document_type_text == 'request' ">
                        <span *ngIf="lang == 1">جمع</span>
                        <span *ngIf="lang == 2">Total</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 150px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.sum | number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="operation">
                    <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="readonly">
                        <span *ngIf="lang == 1"> عملیات </span>
                        <span *ngIf="lang == 2">Operation</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="readonly">
                        <span
                            (click)='check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)'><i
                                class="fas fa-desktop icon"></i></span>
                        <!-- x.wharehouse_order_material,x.wharehouse_order_status_add -->

                        <span
                            (click)="access_for_delete(x.wharehouse_order_id,x.wharehouse_order_material,x.wharehouse_order_trash,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            *ngIf="document_type_text != 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>

                        <span (click)="delete_request(x.wharehouse_order_id)"
                            *ngIf="document_type_text == 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>

                    </mat-cell>
                </ng-container>

                <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                </mat-header-row>
                <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                    (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                    [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                    class="cursor" [ngStyle]="{'backgroundColor':row.wharehouse_order_status_color}"></mat-row>

            </mat-table>
        </div>
        <div style="text-align: right;padding-top:1rem;" [hidden]="document_type_text == 'request' "
            [ngClass]="{'full-left':dir == 'ltr'}">
            <span *ngIf="lang == 1"> جمع کل به عدد : </span>
            <span *ngIf="lang == 2"> Sum total in numbers : </span>
            <span [ngClass]="{'en':dir == 'ltr'}">{{ sum_all | number }} </span>
            <span>{{ currency_title }}</span>
            <span *ngIf="lang == 1"> به حروف </span>
            <span *ngIf="lang == 1"> {{ sum_all_letter }} </span>
            <span *ngIf="lang == 1"> {{ currency_title }} </span>
        </div>
    </div><!-- row -->
    <!-- **************************************************************************************************** -->
</div>