<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span>گزارش پرسنل</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="material" class='wh-label'>
                    <span> نام طرف حساب </span>
                    <span (click)="reset_input(220)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="load_services(220)" readonly>
                    <input class="wh-input" formControlName="user" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="date1" class='wh-label'>
                    <span> تاریخ از </span>
                    <input class="wh-input" matInput [matDatepicker]="picker" formControlName="date1"
                        autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="picker"
                        class="datepicker datepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="date2" class='wh-label'>
                    <span> تاریخ تا </span>
                    <input class="wh-input" matInput [matDatepicker]="picker2" formControlName="date2"
                        autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="picker2"
                        class="datepicker datepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe">
            مشاهده
        </button>
    </div>
</form>