<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span *ngIf="type_task != 3">
        <span>{{ title }}</span>
    </span>
    <span *ngIf="type_task == 3">
        {{ serverService.get_title(type_task,lang) }}
    </span>

    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="group2" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه یکپارچه : </span>
                    <span *ngIf="lang == 2"> All groups : </span>
                    <span (click)="reset_input(223)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="group_title2" (click)="load_services(223)"
                        [required]="required()" readonly>
                    <input class=" wh-input" formControlName="group2" readonly [required]="required()" [hidden]="true">
                </label>
            </div>


            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="cate2" class='wh-label'>
                    <span *ngIf="lang == 1"> زیر گروه یکپارچه : </span>
                    <span *ngIf="lang == 2"> All subgroups : </span>
                    <span (click)="reset_input(184)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cate_title2" (click)="load_services(184)"
                        [required]="required()">
                    <input class="wh-input" formControlName="cate2" readonly [required]="required()" [hidden]="true">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for=" material_title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان کالا : </span>
                    <span *ngIf="lang == 2"> Product name : </span>
                    <input class="wh-input" formControlName="material_title" [required]="required()">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [hidden]="type_task == 3">
                <label for="number" class='wh-label'>
                    <span *ngIf="lang == 1"> موجودی : </span>
                    <span *ngIf="lang == 2"> Stock : </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required currencyMask>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [hidden]="type_task == 3">
                <label for="cost" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت خرید : </span>
                    <span *ngIf="lang == 2"> Purchase price : </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [hidden]="type_task == 3">
                <label for="discount" class='wh-label'>
                    <span *ngIf="lang == 1"> تخفیف : </span>
                    <span *ngIf="lang == 2"> Discount : </span>
                    <input class='wh-input' formControlName="discount" autocomplete="off" currencyMask>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [hidden]="type_task == 3">
                <label for="cost2" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت فروش : </span>
                    <span *ngIf="lang == 2"> Selling price : </span>
                    <input class="wh-input" formControlName="cost2" required currencyMask>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="type_task == 3">
                <label for="comment" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات : </span>
                    <span *ngIf="lang == 2"> Description : </span>
                    <textarea style="height:10rem" class="wh-input" formControlName="comment"></textarea>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" *ngIf="type_task == 1 || type_task == 2" class="btn_pe"
            [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
    </div>

</form>