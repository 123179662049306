<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle">
    <span>
        <span *ngIf="lang == 1">درج مدل</span>
        <span *ngIf="lang == 2">Add model</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>
<form [formGroup]="form1_group" (ngSubmit)="insert()">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row">
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="title" class='wh-label'>
                        <span *ngIf="lang == 1"> عنوان مدل </span>
                        <span *ngIf="lang == 2"> Model name </span>
                        <input class='wh-input font-en' formControlName="title" (keyup.enter)="insert()"
                            autocomplete="off" (keypress)="check_letter($event)" [ngClass]="{'en':dir == 'ltr'}"
                            required>
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <label for="comment" class='wh-label'>
                        <span *ngIf="lang == 1"> توضیحات </span>
                        <span *ngIf="lang == 2"> Description </span>
                        <textarea class='wh-input' style="height:10rem" formControlName="comment" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"></textarea>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
    </div>
</form>