import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../../service/server/server.service';


@Component({
  selector: 'app-wharehouse-order-return-buy-detaile',
  templateUrl: './wharehouse-order-return-buy-detaile.component.html',
  styleUrls: ['./wharehouse-order-return-buy-detaile.component.scss']
})
export class WharehouseOrderReturnBuyDetaileComponent implements OnInit, OnDestroy {

  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public loading = false;
  public subscription: Subscription;
  public user_id: number | any;
  public id: number | undefined;
  public code: number | undefined;
  public material: number;
  public token: number | undefined;
  public trash: number | undefined;
  public creator: number | undefined;
  public temp_number: number = 0;
  public check_order_id: number | undefined;


  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any, private dialog: MatDialog
    , private matDialogRef: MatDialogRef<WharehouseOrderReturnBuyDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.trash = dialog_data.trash;
      this.create_form1();
      this.id = dialog_data.id;
      this.token = dialog_data.token;
      this.code = dialog_data.code;
      if (this.type_task == 1) {
        this.check_access(5);
      }
      if (this.type_task == 2) {
        this.check_access(4);
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'comment': new FormControl(null),
      'cost': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'number': new FormControl(1, [Validators.pattern('[0-9]{1,}')]),
      'material_title': new FormControl(null),
      'cate_title': new FormControl(null),
      'group_title': new FormControl(null),
      'price': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['cost'].setValidators([Validators.required]);
      this.form1_group.controls['material_title'].setValidators([Validators.required]);
      this.form1_group.controls['group_title'].setValidators([Validators.required]);
      this.form1_group.controls['cate_title'].setValidators([Validators.required]);
      this.form1_group.controls['cost'].setValidators([Validators.required]);
    }
  }

  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            if (type_task == 2) {
              this.check_for_update1();
            }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6007, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'price': res['result'][0].wharehouse_material_price,
              'cost': res['result'][0].wharehouse_order_cost,
              'comment': res['result'][0].wharehouse_order_comment,
              'material_title': res['result'][0].wharehouse_material_title,
              'cate_title': res['result'][0].wharehouse_material_cate_title,
              'number': res['result'][0].wharehouse_order_number,
              'group_title': res['result'][0].wharehouse_group_title,
            });
            this.check_order_id = res['result'][0].wharehouse_order_consumed_invoice;
            this.material = res['result'][0].wharehouse_material_id;
            this.temp_number = res['result'][0].wharehouse_order_number;
          }//end if
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  check_for_update1() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6003,
      material: this.material,
      id: this.id,
      token: this.token,
      check_order_id: this.check_order_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] > 0 && (this.form1_group.value.number <= (res['remain'] + Number(this.temp_number)))
          ) {
            this.update();
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          }
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }//check_for_update1

  update(): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6495, "user_id": this.user_id
      , 'comment': this.form1_group.value.comment
      , 'number': this.form1_group.value.number
      , 'id': this.id
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
        }//end if
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end update

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

