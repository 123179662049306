<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span>خدمات</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="service" class='wh-label'>
                    <span (click)="add_service()" class="fleft">
                        <mat-icon class="add-icon fright">
                            add </mat-icon>
                    </span>
                    <span> عنوان خدمات </span>
                    <span (click)="reset_input(195)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="service_title" (click)="load_services(195)" readonly
                        required placeholder="برای مشاهده لیست خدمات کلیک کنید">
                    <input class="wh-input" formControlName="service" [hidden]="true" readonly required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="cost" class='wh-label'>
                    <span> قیمت واحد </span>
                    <input class='wh-input' formControlName="cost" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="number" class='wh-label'>
                    <span> تعداد </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required currencyMask>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="discount" class='wh-label'>
                    <span> تخفیف </span>
                    <input class='wh-input' formControlName="discount" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="repairman" class='wh-label'>
                    <span> نام تعمیرکار </span>
                    <span (click)="reset_input(220)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220)" readonly
                        required>
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="profit" class='wh-label'>
                    <span> درصد سود </span>
                    <input class='wh-input' formControlName="profit" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="tax" class='wh-label'>
                    <span (click)="reset_input(268)">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <span> انتخاب مالیات </span>
                    <input class="wh-input" formControlName="tax_title" (click)="load_services(268)" readonly>
                    <input class="wh-input" formControlName="tax" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="tax" class='wh-label'>
                    <span> درصد مالیات </span>
                    <input class="wh-input" formControlName="tax_price" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="comment" class='wh-label'>
                    <span> توضیحات </span>
                    <input class='wh-input' formControlName="comment" autocomplete="off">
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe">
            <span>ذخیره</span>
        </button>
    </div>
</form>