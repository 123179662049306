<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span> انتخاب وضعیت </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="row reception-div3" [hidden]="readonly == true">
        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pe1 div_panding'>
            <span>در قسمت پایین لطفا وضعیت پذیرش را مشخص نمائید:</span>
        </div>
        <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding div_status fright'
            *ngFor="let x of list_rstatus;let i = index" style="min-height:10rem">
            <div class="status_header">
                <span>مرحله</span>
                <span> {{ i + 1 }} </span>
            </div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="rstatus_id" name="rstatus_id"
                (ngModelChange)="close()">
                <mat-radio-button class="reception-status" [value]="x.repairman_rstatus_id"
                    [checked]="x.repairman_rstatus_id == status_id" style="width:100%">
                    <span class="status_span_label">{{x.repairman_rstatus_title }}</span>
                </mat-radio-button>
                <div *ngFor="let y of list_rstatus2" style="width:100%">
                    <mat-radio-button *ngIf="( x.repairman_rstatus_repairing == 1 && y.repairman_rstatus_repairing == 1 ) || 
                            (x.repairman_rstatus_sub_ready_delivery == 1 && y.repairman_rstatus_sub_ready_delivery == 1) || 
                            (x.repairman_rstatus_deliverd == 1 && y.repairman_rstatus_deliverd == 1)
                            " class="reception-status" [value]="y.repairman_rstatus_id" [disabled]="readonly">
                        <span>
                            <span class="status_span_label">{{y.repairman_rstatus_title }}</span>
                        </span>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <!-- **************************************************************************************************** -->
</div>