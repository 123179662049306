<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    {{ serverService.get_title(type_task,lang) }}
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pe div_panding'>
                <label for="title" class='wh-label'>
                    <span> عنوان کالا </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" [required]="required()"
                        [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 pe div_panding'>
                <label for="group" class='wh-label'>
                    <span> گروه </span>
                    <span (click)="add_group()" class="add_icon" [hidden]="type_task == 3" [hidden]="readonly == true">
                        <mat-icon class="add-icon fleft">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(223)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="group_title" (click)="load_services(223) "
                        [required]="required()" readonly [attr.disabled]="readonly">
                    <input class="wh-input" formControlName="group" [hidden]="true" [required]="required()" readonly
                        [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 pe div_panding'>
                <label for="cate" class=' wh-label'>
                    <span> زیرگروه </span>
                    <span (click)="add_cate()" class="add_icon" [hidden]="type_task == 3 || readonly == true">
                        <mat-icon class="add-icon fleft">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(184)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe">
                            close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cate_title" (click)="load_services(184) "
                        [required]="required()" readonly [attr.disabled]="readonly">
                    <input class="wh-input" formControlName="cate" [hidden]="true" [required]="required()" readonly
                        [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 pe div_panding'>
                <label for="inventory" class='wh-label'>
                    <span> موجودی اول دوره : </span>
                    <input class='wh-input' formControlName="inventory" autocomplete="off" [required]="required()"
                        [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 pe div_panding'>
                <label for="weight" class='wh-label'>
                    <span> واحد اندازه گیری </span>
                    <span (click)="reset_input(211)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="weight_title" (click)="load_services(211) " readonly
                        [attr.disabled]="readonly" [required]="required()">
                    <input class="wh-input" formControlName="weight" [hidden]="true" readonly [attr.disabled]="readonly"
                        [required]="required()">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 pe div_panding'>
                <label for="price" class='wh-label'>
                    <span> قیمت خرید اول دوره </span>
                    <input class='wh-input' formControlName="price" autocomplete="off" [required]="required()"
                        currencyMask [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 pe div_panding'>
                <label for="title" class='wh-label'>
                    <span> قیمت فروش </span>
                    <input class='wh-input' formControlName="price2" autocomplete="off" [required]="required()"
                        currencyMask [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 pe div_panding'>
                <label for="coding" class='wh-label'>
                    <span> کد کالا </span>
                    <input class='wh-input' formControlName="coding" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pe div_panding' [hidden]="type_task == 3">
                <label for="status_id" class='wh-label'>
                    <span> توضیحات </span>
                    <textarea style="height: 5rem;" class="wh-input" formControlName="comment"
                        [attr.disabled]="readonly">
                    </textarea>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions" *ngIf="readonly != true">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" *ngIf="type_task == 1 || type_task == 2">
            <span>ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" *ngIf="type_task == 3">
            <span>فیلتر</span>
        </button>
    </div>
</form>