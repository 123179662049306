import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-balance-detaile',
  templateUrl: './balance-detaile.component.html',
  styleUrls: ['./balance-detaile.component.scss']
})
export class BalanceDetaileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
