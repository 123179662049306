import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-wharehouse-select',
  templateUrl: './wharehouse-select.component.html',
  styleUrls: ['./wharehouse-select.component.scss']
})
export class WharehouseSelectComponent implements OnInit, OnDestroy {
  public isOnline: any | undefined;
  public loading = false;
  user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang = JSON.parse(<any>localStorage.getItem('lang'));
  user_id: number | undefined;
  public subscription: Subscription | any;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any = this.serverService.get_server();

  public count_total: number = 1;
  id: number | undefined;
  public no_record: string = this.serverService.no_record();
  list_record: any = [];
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  dataSource: any | undefined;
  alarm: boolean = false;
  token: number | undefined;
  title: string | undefined;
  back: number = 0;//این فیلد برای زمانی هستش که می خواهید بگوئییم نوع فاکتور برگشت هستش یا خیر
  user: number | undefined;//این برای موقعی است که می خواهیم نام ظرف حساب در فاکتور ها چه کسی هستش

  displayedColumns = ['row', 'invoice_number', 'invoice_date', 'invoice_user', 'number', 'number1', 'cost'];

  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<WharehouseSelectComponent>
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any) {

    if (dialog_data) {
      this.id = dialog_data.id;
      this.alarm = dialog_data.alarm;
      this.token = dialog_data.token;
      this.title = dialog_data.title;
      this.user = dialog_data.user;
      this.back = dialog_data.back;
    }
  }//end consructor

  ngOnInit() {
    this.get_data();
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6132, id: this.id, token: this.token, back: this.back, user: this.user }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  get_material(id) {
    this.matDialogRef.close(id);
  }


  close() {
    this.matSnackBar.dismiss();
    this.matDialogRef.close();
  }
  //**************************************************
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 5000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

