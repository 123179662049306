import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceDetaileComponent } from "../../wharehouse-invoice/invoice-detaile/invoice-detaile.component";
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { PayDetaileComponent } from '../../../finance/finance-pay/pay-detaile/pay-detaile.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-invoice-buy-list',
  templateUrl: './invoice-buy-list.component.html',
  styleUrls: ['./invoice-buy-list.component.scss']
})
export class InvoiceBuyListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_id: number;
  public loading: boolean = false;
  public show_excel: boolean = false;
  public access_insert_finance: boolean = false;
  public access_invoice_sale: boolean = false;
  public access_invoice_buy: boolean = false;
  public access_invoice_return_buy: boolean = false;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public page: number = 1;
  public excel: number = 1;
  public count_total: number = 1;
  public sum: number = 0;
  public invoice_type_id: number = 0;
  public asc_desc: string = 'asc';
  public list_record: any = [];
  public search_array: any = [];
  public selected_record: any = [];
  public subscription: Subscription | any;
  public task_type!: number;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public code!: any;
  public export_code!: string;
  public type_task!: number;
  public type!: number;
  public type_search!: number;
  public search_all!: string;
  public creator!: number;
  public video!: string;
  public access_service!: number;
  public level!: number;
  public x!: any;
  public i!: number;
  public order!: string;
  public query_user!: number;
  public wharehouse_invoice_type!: number;
  public back_i!: number;
  public expire!: boolean;
  public path_export!: string;
  public id!: number;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public title!: string;
  public title_search!: string;
  public new_invoice_id!: number;
  public select_all!: boolean;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'invoice_date', 'user', 'comment', 'price', 'invoice_number', 'settlement', 'pay', 'operation'];
  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public dialog: MatDialog) {
  }
  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.page = Number(params['page']);
        if (params['back_i']) this.back_i = params['back_i'];
        if (params['user']) this.query_user = params['user'];
      }
    );
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
        if (!this.page) this.page = 1;
        if (params['type']) this.invoice_type_id = params['type'];
        this.check_access(4, 1, 0, 1, 1);
      }
    )
    this.change_access_insert_finance();
    //**************
  }//end ngOnInit
  change_access_insert_finance() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6504, user_id: this.user_id }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.access_insert_finance = res['result'][0].access;
            this.access_invoice_sale = res['result'][0].access_invoice_sale;
            this.access_invoice_buy = res['result'][0].access_invoice_buy;
            this.access_invoice_return_buy = res['result'][0].access_invoice_return_buy;
          } else {
            this.access_insert_finance = false;
            this.access_invoice_sale = false;
            this.access_invoice_buy = false;
            this.access_invoice_return_buy = false;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.new();
            }
          }
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) {
            this.load_data(page, excel, loading, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  sort(sort: string, asc_desc: string) {
    if (asc_desc == 'asc') this.asc_desc = 'desc';
    else this.asc_desc = 'asc';
    this.order = sort + " " + this.asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  reset_sort(sort: string, asc_desc: string) {
    this.order = sort + " " + asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  load_data(page: number, excel: number, loading: number, changedRows: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (page == 1) { this.page = 1; }
    var obj = {
      'address': 6901
      , "page": this.page
      , 'type': this.type
      , 'lang': this.lang
      , 'excel': excel
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'invoice_type_id': this.invoice_type_id
      , 'creator': this.creator
      , 'path_export': this.path_export
      , 'access_invoice_sale': this.access_invoice_sale
      , 'access_invoice_buy': this.access_invoice_buy
      , 'access_invoice_return_buy': this.access_invoice_return_buy
      , 'order': this.order
      , 'query_user': this.query_user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                if (res['result'][i].wharehouse_invoice_type == 1) { res['result'][i].type = 2; }
                if (res['result'][i].wharehouse_invoice_type == 3) { res['result'][i].type = 3; }
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.sum = res['sum'];
            this.pages(0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reset({});
            this.serverService.change_window_resize(2, '', 0);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  export_excel(code: number) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  new(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6902, creator: this.creator, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) this.new_invoice_id = Number(res['result'][0].wharehouse_invoice_number) + 1;
          else this.new_invoice_id = 1;
          this.open_detaile(-1, 2, res['id'], 3);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number, trash: number) {
    var width = '75rem';
    if (type_task == 3) {
      width = '22rem';
    }
    const dialogRef = this.dialog.open(InvoiceDetaileComponent, {
      width: width,
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task, id: id, i: i, search_array: this.search_array, new_invoice_id: this.new_invoice_id
        , code: this.code, title: this.title, invoice_type_id: 1, page: this.page, trash: trash
        , creator: this.creator
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1 || i == -1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
            if (res.result.confirm_sms == true) {
              var x = res.result;
              this.check_charge_sms(x.wharehouse_invoice_id, x.user_cellphone, x.wharehouse_invoice_type_title, x.user_sex_title + ' ' + x.user_title, x.user_store, x.user_phone, x.wharehouse_invoice_token_sms, false)
            } else {
              this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
            }
            this.update_financial2(res);
            if (res.type_save == 'insert_finance' && this.access_insert_finance == true) {
              this.pay(res.result.user_id, res.result.wharehouse_invoice_amount, res.result.wharehouse_invoice_number, res.result.wharehouse_invoice_type_title);
            }
          }
          else if (type_task == 2) {
            this.list_record[i].wharehouse_invoice_token_sms = res.result.wharehouse_invoice_token_sms;
            this.list_record[i].user_sex_title = res.result.user_sex_title;
            this.list_record[i].wharehouse_invoice_amount = res.result.wharehouse_invoice_amount;
            this.list_record[i].user_cellphone = res.result.user_cellphone;
            this.list_record[i].wharehouse_invoice_type_title = res.result.wharehouse_invoice_type_title;
            this.list_record[i].wharehouse_invoice_type = res.result.wharehouse_invoice_type;
            this.list_record[i].wharehouse_invoice_title = res.result.wharehouse_invoice_title;
            this.list_record[i].wharehouse_invoice_number = res.result.wharehouse_invoice_number;
            this.list_record[i].wharehouse_invoice_comment = res.result.wharehouse_invoice_comment;
            this.list_record[i].wharehouse_invoice_date3 = res.result.wharehouse_invoice_date3;
            this.list_record[i].user_title = res.result.user_title;
            this.list_record[i].user_id = res.result.user_id;
            this.list_record[i].wharehouse_invoice_settlement_date = res.result.wharehouse_invoice_settlement_date;
            this.list_record[i].site_currency_title = res.result.site_currency_title;
            this.list_record[i].status_title = res.result.status_title;
            this.list_record[i].document_type_text = res.result.document_type_text;
            if (res.temp_user != res.user) {
              this.change_token(i, id);
            }
            this.update_financial2(res);
            if (res.type_save == 'insert_finance' && res.back == 0 && this.access_insert_finance == true) {
              this.pay(res.result.user_id, res.result.wharehouse_invoice_amount, res.result.wharehouse_invoice_number, res.result.wharehouse_invoice_type_title);
            }
          }
          else if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, excel, 1);
          }
        }
      }
    )
  }
  change_token(i: number, id: number) {
    var token = Math.floor(Math.random() * 99999999999999);
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6756, id: id, token: token }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record[i].wharehouse_invoice_token_sms = token;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 6017; } else { address = 6016; }
      var obj = {
        'address': address,
        "user_id": this.user_id,
        "creator": this.creator,
        'id': id,
        "page": this.page,
        'type': this.type,
        "search": this.search_array,
        "search_all": this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_invoice_id == id), 1);
            this.dataSource.data = this.list_record;
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.delete_financial(id);
            this.server_update_count_invoice(res['result_count'][0].count1);
            //this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  server_update_count_invoice(count: number) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6386, creator: this.creator, count: count }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************************************************************************* */
  pay(user: number, price: number, invoice_number: number, invoice_title: string): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 7018,
      user: user,
      user_id: this.user_id,
      creator: this.creator,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.new_pay(res['id'], price, invoice_number, invoice_title);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  new_pay(id: number, price: number, invoice_number: number, invoice_title: string) {
    const dialogRef = this.dialog.open(PayDetaileComponent, {
      width: '65rem',
      height: 'auto',
      data: { type_task: 2, id: id, code: this.code, document: 1, sum: price, ref: 'invoice', invoice_number: invoice_number, invoice_title: invoice_title, i: -1 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.cheque_spend(res.result.finance_financial_id);

      }
    )
  }
  cheque_spend(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6860, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.update_cheque_spend(res['result'][i].finance_financial2_cheque_spend);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_cheque_spend(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6861, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_financial2(result: any) {
    var obj = {
      address: 6920,
      id: result.result.wharehouse_invoice_id,
      amount: result.result.price,
      date3: result.result.wharehouse_invoice_date3,
      document_type: result.result.document_type,
      user_id: this.user_id,
      creator: this.creator,
      user: result.result.wharehouse_invoice_user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2(result);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_all_financial2(res: any) {
    var obj = {
      address: 6111,
      id: res.result.wharehouse_invoice_id,
      user: res.result.wharehouse_invoice_user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  delete_financial(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6107, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***************************************************************************
  check_charge_sms(id: number, cellphone: any, type_title: any, title: string, user_store: string, user_phone: string, token_sms: any, again: boolean): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (cellphone.match(/^\d{11}$/g) == null) {
      var pe_message = "فرمت شماره برای ارسال پیامک معتبر نمی باشد";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (again == true) this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6312, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].amount >= 2000) {
              if (again == false) {
                this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
              }
              this.send_sms(id, cellphone, type_title, title, user_store, user_phone, token_sms, again);
            }
            else {
              if (again == true) {
                var pe_message = "قادر به ارسال پیامک نمی باشید لطفا شارژ نمائید";
                this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              } else {
                var pe_message = "فاکتور یا موفقیت ثبت شد ولی قادر به ارسال پیامک نمی باشید لطفا شارژ نمائید.";
                this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
              }

            }
          }
        }//end if
        else if (res['status'] == 6) {
          var message = "ارسال پیامک از ساعت 21 تا ساعت 8 صبح غیر فعال می باشد";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  send_sms(id: number, cellphone: any, type_title: any, title: string, user_store: string, user_phone: string, token_sms: any, again: boolean): any {
    var token2 = this.serverService.get_my_domain() + "/recipt-printer-invoice/" + id;
    if (again == true) {
      var x = confirm(this.messageService.message_sms_accept(this.lang));
      if (x == false) {
        this.message(false, "", 1, this.messageService.close(this.lang));
        return false;
      }
      this.loading = true;
    }
    var obj = {
      address: 6514,
      cellphone: cellphone,
      type_title: "خرید",
      token2: token2,
      token_sms: token_sms,
      user_phone: user_phone,
      user_store: user_store
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (again == true) {
          var pe_message = "پیام با موفقیت ارسال شد";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
          this.log_sms(res['response'].body.entries[0].cost);
        }
        else {
          this.log_sms(res['response'].body.entries[0].cost);
        }
      })
  }//end if
  log_sms(cost: number) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6308, user_id: this.user_id, creator: this.creator, cost: cost }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_sms_charge();
        }//end if
        else {
          var pe_message = "خطا در ثبت لاگ پیامک ارسالی";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  get_menu(i: number, x: any) {
    this.x = x;
    this.i = i;
    this.wharehouse_invoice_type = x.wharehouse_invoice_type;
  }
  show_factor1() {
    window.open(this.serverService.get_my_domain() + "/invoice/" + this.x.wharehouse_invoice_id + "/" + this.x.wharehouse_invoice_token_sms, "_blank");
  }
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
