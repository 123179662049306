<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span>درج جدید
            <span *ngIf="service == 'brand' ">برند</span>
            <span *ngIf="service == 'device' ">دستگاه</span>
            <span *ngIf="service == 'cate' ">زیرگروه</span>
            <span *ngIf="service == 'group' ">گروه</span>
            <span *ngIf="service == 'cost' ">هزینه</span>
            <span *ngIf="service == 'income' ">درآمد</span>
            <span *ngIf="service == 'weight' ">واحد اندازه گیری</span>
            <span *ngIf="service == 'device_status' ">وضعیت دستگاه</span>
        </span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="save()">
    <div mat-dialog-content class="matDialogContent" style="padding:0.5rem 0rem !important">
        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe'>
                <label for="title" class='wh-label'>
                    <span> عنوان </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" required>
                </label>
            </div>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe' [hidden]="service != 'brand' ">
                <label for="title2" class='wh-label'>
                    <span> عنوان انگلیسی </span>
                    <input class='wh-input' formControlName="title2" autocomplete="off">
                </label>
            </div>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe' [hidden]="service != 'services' ">
                <label for="title2" class='wh-label'>
                    <span> قیمت پایه </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off"
                        [required]="service == 'services' " currencyMask>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe">
            <span>ذخیره</span>
        </button>
    </div>
</form>