import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-content',
  templateUrl: './user-content.component.html',
  styleUrls: ['./user-content.component.scss']
})
export class UserContentComponent implements OnInit, OnDestroy {
  //**********************************************************
  public server_main: string = this.serverService.get_server_main();
  public server: string = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public loading = false;
  public subscription: Subscription;
  public code: any;
  public id: number;
  public user_id: number;
  public form1: FormGroup;
  public type_task: number;
  public creator: number;
  public access: boolean = false;
  public level: number;
  public access_service: number;
  public title: string;
  //******************************************************************************
  constructor(
    public serverService: ServerService
    , public router: Router
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialogRef: MatDialogRef<UserContentComponent>
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
  ) {
    if (dialog_data) {
      this.id = dialog_data.id;
      this.type_task = dialog_data.type_task;
      this.code = dialog_data.code;
    }

  }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form();
    if (this.type_task == 1) {
      this.check_access(5);
    }
    if (this.type_task == 2) {
      this.check_access(4);
    }
    else if (this.type_task == 3) {
      if (this.dialog_data) this.creator = this.dialog_data.creator;
      this.form1.patchValue({

      })
    }
  }//end ngOnInit

  create_form() {
    this.form1 = new FormGroup({
      'text': new FormControl(null, [Validators.required]),
    });
  }

  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid && (type_task == 1 || type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  check_access2(type_task: any, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.loading = false;
          if (type_task == 1) { /* this.insert(); */ }
          if (type_task == 2) { this.update(); }
          if (type_task == 3) { /* this.search(); */ }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
            //this.get_status();
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6529, id: this.id }).subscribe(
      (res) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['num'] == 1) {
            this.title = res['result'][0].site_content_title;
            this.form1.patchValue({
              'text': res['result'][0].site_content_text,
            });
          }//end if num
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  search() {
    this.dialogRef.close({

    })
  }

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6530, "user_id": this.user_id, 'id': this.id
      , 'creator': this.creator
      , 'text': this.form1.value.text
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.dialogRef.close(res['result'][0]);
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  //********************************************************************************
  close() {
    this.dialogRef.close();
  }//end close
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
