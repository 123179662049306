<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        لیست کالا
    </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent" style="overflow-y: hidden;">
    <div class="row">

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 direction_rtl padding0" style="text-align: right;">
            <input class="search" (keyup)="get_material(2)" [(ngModel)]="search" autocomplete="off"
                placeholder="فیلتر عنوان کالا" style="width:20.7rem">
            <input class="search" (keyup)="get_material(2)" [(ngModel)]="search_code" autocomplete="off"
                placeholder="فیلتر کد کالا" style="width:7.8rem;">
            <select style="width:12rem;height: 1.8rem" (change)="get_material_with_group()" name="wharehouse_group"
                [(ngModel)]="wharehouse_group">
                <option [value]="0">انتخاب گروه</option>
                <option *ngFor="let x of list_wharehouse_group" [value]="x.wharehouse_group_id">
                    {{ x.wharehouse_group_title }}
                </option>
            </select>
            <select style="width:8rem;height: 1.8rem" (change)="get_material(1)" name="wharehouse_cate"
                [(ngModel)]="wharehouse_cate">
                <option [value]="0">انتخاب زیرگروه</option>
                <option *ngFor="let x of list_wharehouse_cate" [value]="x.wharehouse_material_cate_id">
                    {{ x.wharehouse_material_cate_title }}
                </option>
            </select>
        </div>

        <div class="content-body control-content-body" style="max-height: 20rem;">
            <mat-table [dataSource]="dataSource" class='table-scroll pe1'>

                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:45px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:45px">{{ i + 1 }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef style="min-width: 17.9rem;">
                        <span>عنوان کالا</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" style="min-width: 17.9rem;"
                        (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)">
                        <div class="right">{{ x.wharehouse_material_title | substr:0:30 }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                        <span>کد کالا</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)">
                        <div class="right">{{ x.wharehouse_material_coding }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="coding">
                    <mat-header-cell *matHeaderCellDef>
                        <span>کد کالا</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)">
                        {{ x.wharehouse_material_coding }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="group">
                    <mat-header-cell *matHeaderCellDef style="min-width: 12rem;">
                        <span>گروه</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)"
                        style="min-width: 12rem;">
                        <div class="right">{{ x.wharehouse_group_title | substr:0:18 }} </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cate">
                    <mat-header-cell *matHeaderCellDef>
                        <span>زیرگروه</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)">
                        <div class="right">{{ x.wharehouse_material_cate_title | substr:0:20 }} </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="inventory">
                    <mat-header-cell *matHeaderCellDef>
                        <span>موجودی</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)">
                        {{ x.inventory | number}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoice_price">
                    <mat-header-cell *matHeaderCellDef>
                        <span>قیمت خرید</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)">
                        {{ x.wharehouse_order_cost | number }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cost">
                    <mat-header-cell *matHeaderCellDef>
                        <span> قیمت فروش</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.wharehouse_order_id,x.wharehouse_material_id)">
                        {{ x.wharehouse_material_price2 | number }}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns2" color="primary"></mat-header-row>
                <mat-row *matRowDef="let x;let i = index;columns:displayedColumns2"></mat-row>
            </mat-table>

        </div>
    </div>
    <!-- **************************************************************************************************** -->
</div>