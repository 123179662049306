import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { BankAccountsComponent } from '../../bank/bank-accounts/accounts-list/bank-accounts.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-pay-cheque-detaile',
  templateUrl: './pay-cheque-detaile.component.html',
  styleUrls: ['./pay-cheque-detaile.component.scss']
})
export class PayChequeDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public loading: boolean = false;
  public status_pass: number = 0;
  public status_pass2: number = 0;
  public search_array: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public financial2_id!: number;
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public code!: number;
  public title!: string;
  public document_type!: number;
  public number_temp!: number;
  public result!: any;
  public ref!: string;
  public creator!: number;
  public token!: any;
  public expire!: boolean;
  public access_service!: number;
  public level!: number;
  public financial_id!: number;
  public record_id!: any;

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<PayChequeDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.creator = dialog_data.creator;
      this.create_form1();
      this.id = dialog_data.id;
      this.financial_id = dialog_data.financial_id;
      this.ref = dialog_data.ref;
      this.token = dialog_data.token;
      this.document_type = dialog_data.type;
      this.code = dialog_data.code;
      if (this.type_task == 1) {
        //this.check_access(5, 1);
      }
      if (this.type_task == 2 || this.type_task == 4) {
        this.check_access(4, 1);
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'number': new FormControl(null, [Validators.pattern('[0-9/-]{1,}')]),
      'title': new FormControl(null),
      'due': new FormControl(null),
      'bank_title': new FormControl(null),
      'bank': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'account_title': new FormControl(null),
      'account': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'amount': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['due'].setValidators([Validators.required])
      this.form1_group.controls['bank'].setValidators([Validators.required])
      this.form1_group.controls['account'].setValidators([Validators.required])
      this.form1_group.controls['amount'].setValidators([Validators.required])
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.title = res['result'][0].title;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          //this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) {
            this.update();
          }
          if (type_task == 3) { }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7033, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].finance_financial2_cheque_title,
            'due': res['result'][0].due_date,
            'bank_title': res['result'][0].site_bank_title,
            'bank': res['result'][0].site_bank_id,
            'account_title': res['result'][0].site_bank_accounts_number,
            'account': res['result'][0].site_bank_accounts_id,
            'number': res['result'][0].finance_financial2_cheque_number,
            'amount': res['result'][0].finance_financial2_amount,
            'status_title': res['result'][0].site_cheque_status_title,
          });
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.document_type = res['result'][0].site_cheque_type;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7031,
      "user_id": this.user_id,
      "financial_id": this.financial_id,
      'title': this.form1_group.value.title,
      'account': this.form1_group.value.account,
      'number': this.form1_group.value.number,
      'due_date': this.form1_group.value.due,
      'bank': this.form1_group.value.bank,
      'amount': this.form1_group.value.amount,
      'type': this.document_type,
      'due': this.form1_group.value.due,
      'token': this.token,
      'creator': this.creator,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شماره چک درهر حساب مي بايست منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    );
  }//end insert
  update() {
    var obj = {
      'address': 7032,
      "user_id": this.user_id,
      'title': this.form1_group.value.title,
      'account': this.form1_group.value.account,
      'number': this.form1_group.value.number,
      'due_date': this.form1_group.value.due,
      'bank': this.form1_group.value.bank,
      'amount': this.form1_group.value.amount,
      'id': this.id,
      "financial_id": this.financial_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شماره چک درهر حساب مي بايست منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  select_account(): any {
    if (!this.form1_group.value.bank) {
      var pe_message = "لطفا در ابتدا بانک خود را انتخاب نمائید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialog_data = this.dialog.open(BankAccountsComponent, {
      width: '55rem',
      height: 'auto',
      data: { ref: 'cheque', id: this.form1_group.value.bank, code: this.code }
    });
    dialog_data.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'account_title': res.number,
            'account': res.id,
            'number': null
          });
        }
      }
    )
  }
  reset_account(type: number) {
    this.form1_group.patchValue({
      'account_title': null,
      'account': null,
      'number': null
    })
  }
  load_services(id: number): any {
    var where = "";
    var code_id;
    var record_id!: number;
    if (id == 190) {
      code_id = 6794;
      var record_id = this.id;
    }
    if (id == 194) {
      if (!this.form1_group.value.account) {
        var pe_message = "لطفا در ابتدا شماره حساب را تعیین کنید";
        this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        return false;
      }
      code_id = 1950;
    }

    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, where: where, code_id: code_id, record_id: record_id, creator: this.creator, obj: { account_number: this.form1_group.value.account, lang: this.lang } }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 178) {
            this.form1_group.patchValue({
              'bank': res.id,
              'bank_title': res.title,
              'number': null,
              'account_title': null,
              'account': null,
            })
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (id == 178) {
      this.form1_group.patchValue({
        'bank_title': null,
        'bank': null,
        'number': null,
        'account_title': null,
        'account': null,
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
