<div class="div_loading">
  <div class="my-container" [hidden]="!loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div mat-dialog-title class="matDialogTitle" #myDialog   cdkDragHandle>
    منو ساز
    <span class="close" (click)="close()">
      <mat-icon>close</mat-icon>
    </span> 
  </div>

  <div mat-dialog-content class="matDialogContent" (click)="close_contextmenu()" style="max-height: 65vh;min-height: 65vh;">
    <div  [ngClass]="{content_body_en:lang==2}" (window:resize)="change_resize()">
      <div class="row" cdkDropList [cdkDropListData]="list_tab" (cdkDropListDropped)="drop_tab($event)">


        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 div2"  >
          <div class="row">
            <span class="material-icons icon-add cursor" (click)="insert_tab()" title="تب جدید">
              add_circle_outline
            </span>
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div1" *ngFor="let x of list_tab;let i = index"
            cdkDrag [cdkDragData]="x">
  
            <label class='wh-label' style="margin-bottom:0px">
              <input (contextmenu)="contextmenu_tab(i,x.site_tab_id,x.site_tab_status,$event)"  
              [value]="x.site_tab_title" class="input1 wh-input pe1" 
              (change)="update_tab(x.site_tab_id,$event)" 
              (keyup.enter)="update_tab(x.site_tab_id,$event)" 
              (click)="select_tab(i,x.site_tab_id,x.site_tab_status)" 
              [ngClass]="{active_tab:tab_id == x.site_tab_id,unpublish:x.site_tab_status == 2,en1:lang == 2}">
            </label>
          </div>
        </div>  
  

        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 div2" cdkDropList [cdkDropListData]="list_section" (cdkDropListDropped)="drop_section($event)">
          <div class="row" style="min-height: 2rem;">
            <span class="material-icons icon-add cursor" (click)="insert_section()" *ngIf="tab_id" title="دسته بندی جدید">
              add_circle_outline
            </span>
          </div>
          <div class="row" style="border:solid 1px #ccc;min-height: 20.5rem;display: block;">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div1" *ngFor="let x of list_section;let i = index" cdkDrag [cdkDragDisabled]="!tab_id">
              <label class='wh-label' style="margin-bottom:0px">
                <input (contextmenu)="contextmenu_section(i,x.site_section_id,x.site_section_title,x.site_section_status,x.site_section_tab,$event)"  [value]="x.site_section_title" class="input1 wh-input pe1" 
                (change)="update_section(x.site_section_id,$event)"
                (keyup.enter)="update_section(x.site_section_id,$event)" 
                (click)="select_section(i,x.site_section_id,x.site_section_status,x.site_section_tab)"
                [ngClass]="{active_tab:section_id == x.site_section_id,unpublish:x.site_section_status == 2,en1:lang == 2}"
                [ngStyle]="{'cursor': tab_id ? 'pointer' : 'no-drop' }">
              </label>
            </div>
          </div> 
        </div>
  
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 div2" cdkDropListGroup>

          <div class="row"   style="padding-top:0.75rem">            
            <p class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12  pe1">ماژول های در حال استفاده</p>
          </div>
          <div class="row row1" cdkDropList [cdkDropListData]="list_services_selected" (cdkDropListDropped)="drop_services_selected($event)" style="border:solid 1px #ccc">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div1" *ngFor="let x of list_services_selected;let i = index" cdkDrag  [cdkDragDisabled]="!section_id" >
                <label class='wh-label' style="margin-bottom:0px">
                  <input   (contextmenu)="contextmenu_services('selected',i,x.site_services_id,x.site_services_title,x.site_services_status,x.site_menu_section,x.site_menu_id,$event)"
                  [value]="x.site_services_title" class="input1 wh-input pe1" 
                  (change)="update_service('selected',x.site_services_id,x.site_services_status,$event)" 
                  (keyup.enter)="update_service('selected',x.site_services_id,x.site_services_status,$event)" 
                  (click)="select_services('selected',i,x.site_services_id,x.site_services_title,x.site_services_status,x.site_menu_section,x.site_menu_id)"
                  [ngClass]="{active_tab:services_id == x.site_services_id,unpublish:x.site_services_status == 2,en1:lang == 2}"
                  [ngStyle]="{'cursor': section_id ? 'pointer' : 'no-drop' }">
                </label>
              </div>
          </div> 
          
          <div class="row" style="margin-top: 1rem;">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12  p1">
              <div class="material-icons icon-add cursor" sty  (click)="insert_service()" title="تمامی سرویس ها">
                add_circle_outline
              </div>
            </div>
          </div>
          
          <div class="row row1"  cdkDropList [cdkDropListData]="list_services" (cdkDropListDropped)="drop_services($event)" style="margin-bottom:1rem">
            <div class="row" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 div1" *ngFor="let x of list_services;let i = index" cdkDrag [cdkDragDisabled]="!section_id"  
            >
              <label class='wh-label' style="margin-bottom:0px">
                <input  (contextmenu)="contextmenu_services('all',i,x.site_services_id,x.site_services_title,x.site_services_status,x.site_menu_section,x.site_menu_id,$event)" 
                [value]="x.site_services_title" class="input1 wh-input pe1" 
                (change)="update_service('all',x.site_services_id,x.site_services_status,$event)" 
                (keyup.enter)="update_service('all',x.site_services_id,x.site_services_status,$event)" 
                (click)="select_services('all',i,x.site_services_id,x.site_services_title,x.site_services_status,x.site_menu_section,x.site_menu_id)"
                [ngClass]="{active_tab:services_id == x.site_services_id,unpublish:x.site_services_status == 2,en1:lang == 2}"
                [ngStyle]="{'cursor': section_id ? 'pointer' : 'no-drop' }">
              </label>
            </div>
          </div>


        </div>
      </div>




  
      <div id="context-menu-tab" class="context-menu" >
        <div class="item cursor pe1" (click)="change_tab_publish(1,tab_id ,tab_i)" *ngIf="tab_status == 2">
          <i class="fas fa-eye"></i>
          <div class="text">انتشار</div>
        </div>
        <div class="item cursor pe1" (click)="change_tab_publish(2,tab_id ,tab_i)" *ngIf="tab_status == 1">
          <i class="fas fa-eye-slash"></i>
          <div class="text">عدم انتشار</div>
        </div>
        <hr>
        <div class="item cursor pe1" (click)="delete_tab()">
          <i class="far fa-trash-alt"></i> 
          <div class="text">حذف</div>
        </div> 
      </div>

      <div id="context-menu-section" class="context-menu" >
        <div class="item cursor pe1" (click)="change_section_publish(1,section_i)" *ngIf="section_status == 2">
          <i class="fas fa-eye"></i>
          <div class="text">انتشار</div>
        </div>
        <div class="item cursor pe1" (click)="change_section_publish(2,section_i)" *ngIf="section_status == 1">
          <i class="fas fa-eye-slash"></i>
          <div class="text">عدم انتشار</div>
        </div>
        <hr>
        <div class="item cursor pe1" (click)="delete_section()">
          <i class="far fa-trash-alt"></i> 
          <div class="text">حذف</div>
        </div>
      </div>

      <div id="context-menu-services" class="context-menu" >
        <div class="item cursor pe1" (click)="change_services_publish(1,services_i)" *ngIf="services_status == 2">
          <i class="fas fa-eye"></i>
          <div class="text">انتشار</div>
        </div>
        <div class="item cursor pe1" (click)="change_services_publish(2,services_i)" *ngIf="services_status == 1">
          <i class="fas fa-eye-slash"></i>
          <div class="text">عدم انتشار</div>
        </div>
        <div class="item cursor pe1" (click)="change_service_image()">
          <i class="fas fa-eye-slash"></i>
          <div class="text">تصویر ماژول</div>
        </div>
        <hr [hidden]="services_type == 'selected'">
        <div class="item cursor pe1" (click)="delete_services()" [hidden]="services_type == 'selected'" [hidden]="true">
          <i class="far fa-trash-alt"></i> 
          <div class="text">حذف</div>
        </div>
      </div>

    </div><!-- content body-->
  </div>

</div>
