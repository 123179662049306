<span (click)="close()" style="float:right">
  <mat-icon>close</mat-icon>
</span>

<div class="row text-center" *ngIf="loading">

  <div class="spinner-container" style="margin-top:10%">
    <mat-spinner color="accent"></mat-spinner>
    <span class="spinner-text">لطفا منتظر بمانید... </span>
  </div>

  <br />
  در حال محاسبه {{year}}
</div>
<div class="row">
  <div *ngIf="!loading">
    <div class="col-12 col-xl-6 col-lg-6" style="margin-top:2rem;">
      <highcharts-chart [Highcharts]="Highcharts1" [options]="chartOptions1"
        style="width: 100%; height: 400px; display: block;"></highcharts-chart>
    </div>
    <div class="col-12 col-xl-6 col-lg-6">
      <highcharts-chart [Highcharts]="Highcharts2" [options]="chartOptions2"
        style="width: 100%; height: 400px; display: block;"></highcharts-chart>
    </div>
  </div>
</div>
