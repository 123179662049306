<div class="div_loading">
  <div class="my-container" [hidden]="!loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<div class="row" style="display: flex !important;">
  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
    <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
      placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
  </div>
  <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
    <div class="service_pager">
      <span>
        <div class="video_div1" (click)="open_video()" *ngIf="video">
          <mat-icon class="video">smart_display</mat-icon>
        </div>
        <button class="search1" (click)='new()'>
          <span>{{ messageService.get_new(lang) }}</span>
        </button>
        <button class="search1" (click)='open_detaile(0,3,0)'>
          <span>{{ messageService.get_filter(lang) }}</span>
        </button>
        <button class="search1" (click)='openchart()'>
          <span>
            نمودار
          </span>
        </button>
        <div class="pager_pe">
          <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
              class="fas fa-fast-forward"></i></button>
          <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
          <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
          <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
              class="fas fa-fast-backward"></i></button>
        </div>
        <div class='navigation'><span> {{ page_number_first }}
          </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
            {{ count_total }} </span>
        </div>

      </span>
    </div>
  </div>
  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
    <div class="pager_checkbox">
      <div class="pager_icon_en">
        <div class="excel">
          <img src="../../../../../assets/images/excel.png" class="excel_icon cursor" (click)="load_data(1,2,1,-1)">
          <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="content-body control-content-body" (window:resize)="change_resize()">
  <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'>

    <ng-container matColumnDef="row">
      <mat-header-cell *matHeaderCellDef style='max-width:40px' (click)="reset_sort('finance_cost_id','desc')">
        <span>ردیف</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
        page_number_first }}</mat-cell>
      <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="date_document">
      <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'finance_cost_document_date ' + asc_desc }"
        (click)="sort('finance_cost_document_date',asc_desc)">
        <span>تاریخ سند</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_id)" style="direction: rtl">
        {{
        x.finance_cost_document_date }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="group">
      <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'finance_cost_type_group_title ' + asc_desc }"
        (click)="sort('finance_cost_type_group_title',asc_desc)">
        <span>گروه هزینه </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_id)">
        <div class="right">
          {{ x.finance_cost_type_group_title }}
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'finance_cost_type_title ' + asc_desc }"
        (click)="sort('finance_cost_type_title',asc_desc)">
        <span>عنوان هزینه</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_id)">
        <div class="right">
          {{x.finance_cost_type_title }}
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
      <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'finance_cost_comment ' + asc_desc }"
        (click)="sort('finance_cost_comment',asc_desc)">
        <span>توضیحات</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_id)">
        <div class="right">
          <span *ngIf="x.finance_cost_comment">
            {{x.finance_cost_comment | substr:0:25 }}
          </span>
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="price_payment">
      <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'amount ' + asc_desc }"
        (click)="sort('amount',asc_desc)">
        <span>هزینه</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_id)">
        {{ x.amount | number }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <span>
          <span *ngIf="sum >= 0">{{ sum | number }}</span>
        </span>
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'finance_cost_date ' + asc_desc }"
        (click)="sort('finance_cost_date',asc_desc)">
        <span>تاریخ ثبت</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_id)" style="direction: rtl;">
        {{
        x.finance_cost_date }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>
        <span>شناسه</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_id)" style="direction: rtl;">
        {{
        x.finance_cost_id }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="operation">
      <mat-header-cell *matHeaderCellDef style='max-width:50px'>
        <span>عملیات</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" style='max-width:50px'>
        <span (click)="check_access(3,0,x.finance_cost_id,0,1)"><i class="far fa-trash-alt icon"
            style='margin:0px 4px;'></i></span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef style='max-width:50px'></mat-footer-cell>
    </ng-container>

    <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
    </mat-header-row>
    <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
      (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
      [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
      class="cursor"></mat-row>
    <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
      *matFooterRowDef="displayedColumns;sticky : true"></mat-footer-row>
  </mat-table>

  <div class="row" class="preview1" [hidden]="count_total > 0">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div>
        <img src="../../../assets//images/preview.png">
      </div>
      <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
    </div>
  </div>
</div>