import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { UserSearch2Component } from '../../../user/user-search2/user-search2.component';
import { UserDetaile2Component } from '../../../user/user-detaile2/user-detaile2.component';
import { InsertModelComponent } from '../../insert-model/insert-model.component';
import { InsertObjectionsComponent } from '../../insert-objections/insert-objections.component';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { InsertComponent } from '../../../service/insert/insert.component';
import { RepairmanAttachDetaileComponent } from '../../repairman-attach-detaile/repairman-attach-detaile.component';
import { InsertBelongComponent } from '../../insert-belong/insert-belong.component';
import { OpenBelongComponent } from '../../open-belong/open-belong.component';
import { MessageService } from '../../../service/message/message.service';
import { Params, ActivatedRoute } from '@angular/router';
import { UserSelectRepirmanComponent } from '../../../user/user-select-repirman/user-select-repirman.component';

@Component({
  selector: 'app-reception-detaile',
  templateUrl: './reception-detaile.component.html',
  styleUrls: ['./reception-detaile.component.scss']
})
export class ReceptionDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public isOnline: any;
  public loading: boolean = false;
  public loading3: boolean = false;
  public loading4: boolean = false;
  public default_wharhouse: boolean = false;
  public show_cellphone: boolean = true;
  public today_repairman_reception_default: number = 0;
  public repairman_rstatus_repairing: number = 0;
  public token: any = new Date().getTime();
  public list_device_status: any = [];
  public search_array: any = [];
  public list_load_file: any = [];
  public count_count_list_load: any = [];
  public list_rstatus: any = [];
  public list_rstatus2: any = [];
  public list_order: any = [];
  public list_belong: any = [];
  public belong_selected: any = [];
  public list_objections: any = [];
  public objections_selected: any = [];
  public sum_all: number = 0;
  public target_user_id: number = 0;
  public sendsms: number = 0;
  public count_order: number = 0;
  public loading_file: boolean = false;
  public repairman_status_required: boolean = false;
  public readonly: boolean = false;
  public target_user_title!: string;
  public subscription: Subscription | any;
  public subscription2: Subscription | any;
  public subscription3: Subscription | any;
  public form1_group: FormGroup | any;
  public user_id!: number;
  public reception_id!: number;
  public type_task!: number;
  public id!: any;
  public i!: number;
  public code!: number;
  public title!: string;
  public rstatus_temp!: number;
  public level!: number;
  public creator!: number;
  public expire!: boolean;
  public ref!: string;
  public video!: string;
  public video2!: string;
  public rstatus!: number;
  public today!: string;
  public today_of_week!: string;
  public isclose!: number;
  public isprint!: number;
  public shelf_temp!: number;
  public default_rstatus!: number;
  public file_id!: any;
  public access_service!: number;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSourceOrder!: any;
  public displayedColumnsOrder = ['row', 'title', 'cate', 'material', 'number', 'status', 'operation'];
  public result!: any;
  public rstatus_id!: number;
  public config: any = {
    url: this.serverService.get_server() + '/upload',
    maxFilesize: 25,
    acceptedFiles: "image/*,video/*"
  }
  //******************************************************************************************************* */
  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReceptionDetaileComponent>,
    public messageService: MessageService) {
    this.subscription2 = this.serverService.get_belong().subscribe(
      (res: any) => {
        this.list_belong.unshift({ repairman_belong2_id: res.id, repairman_belong2_title: res.title })
      }
    )
    this.subscription3 = this.serverService.get_objections().subscribe(
      (res: any) => {
        this.list_objections.unshift({ repairman_objections2_id: res.id, repairman_objections2_title: res.title })
      }
    )
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.ref = dialog_data.ref;
      this.default_rstatus = dialog_data.default_rstatus;
      this.rstatus_id = dialog_data.rstatus_id;
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.readonly = dialog_data.readonly;
      this.code = 160;
      this.creator = dialog_data.creator;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.activatedRoute.queryParams.subscribe(
          (params: Params) => {
            if (params['user_reception'] != null) {
              this.target_user_id = params['user_reception'];
              this.get_user(this.target_user_id);
            }
          }
        )
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          searial: dialog_data.search_array.searial,
          reception_id: dialog_data.search_array.reception_id,
          comment: dialog_data.search_array.comment,
          comment2: dialog_data.search_array.comment2,
          customer: dialog_data.search_array.customer,
          customer_title: dialog_data.search_array.customer_title,
          device: dialog_data.search_array.device,
          device_title: dialog_data.search_array.device_title,
          type: dialog_data.search_array.type,
          type_title: dialog_data.search_array.type_title,
          brand: dialog_data.search_array.brand,
          brand_title: dialog_data.search_array.brand_title,
          model: dialog_data.search_array.model,
          model_title: dialog_data.search_array.model_title,
          status: dialog_data.search_array.status,
          repairman: dialog_data.search_array.repairman,
          repairman_title: dialog_data.search_array.repairman_title,
          creator: dialog_data.search_array.creator,
          creator_title: dialog_data.search_array.creator_title,
          shelf: dialog_data.search_array.shelf,
          shelf_title: dialog_data.search_array.shelf_title,
          approximate: dialog_data.search_array.approximate,
          estimated_time: dialog_data.search_array.estimated_time,
          password: dialog_data.search_array.password,
          pattern: dialog_data.search_array.pattern,
          urgent: dialog_data.search_array.urgent,
          date1: dialog_data.search_array.date1,
          date2: dialog_data.search_array.date2,
          device_status: dialog_data.search_array.device_status
        })
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (this.type_task != 1) this.get_device_status();
    this.get_movie();
    this.get_movie_status();
    this.load_files(1);
  }
  get_device_status() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6465, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_device_status = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_device_status.push(res['result'][i]);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_user(searched_user_id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1953, user: searched_user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {

            this.target_user_title = res['result'][0].user_title;
          }
        }//end if

      }
    )
  }
  get_movie(): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: 157 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.video = res['result'][0].video;
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  detaile_file(i: number, id: number) {
    const dialogRef = this.dialog.open(RepairmanAttachDetaileComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.list_load_file[i].reception_items_attach_title = res.title;
        }
      }
    )
  }
  onUploadSuccess(event: any) {
    var x: any = <any>document.querySelector(".dz-success .dz-image");
    x.innerHTML += " <a style='color:blue'>thanks!</a>";
    this.load_files(2)
  }
  onSending(event: any) {
    //console.log(event)
  }
  load_files(type: number) {
    if (type == 2) {
      $(document).ready(function () {
        $(".dz-complete").remove();
      });
    }
    this.loading_file = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6356, id: this.id, token: this.token, type_task: this.type_task }).subscribe(
      (res: any) => {
        this.list_load_file = [];
        for (var i = 0; i < res['num']; i++) {
          this.list_load_file.push(res['result'][i]);
        }//end for
        this.count_count_list_load = this.list_load_file.length;
        this.loading_file = false;
      }
    )
  }//end load_file
  delete_file(i: number, id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading_file = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6359, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_load_file.splice(i, 1)
          this.loading_file = false;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_movie_status(): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: 2445, id: 166 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.video2 = res['result'][0].video;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    )
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'comment': new FormControl(null),
      'comment2': new FormControl(null),
      'searial': new FormControl(null),
      'reception_date': new FormControl(new Date()),
      'reception_id': new FormControl(null),
      'customer_title': new FormControl(null),
      'customer': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'device_title': new FormControl(null),
      'device': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'type_title': new FormControl(null),
      'type': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'brand_title': new FormControl(null),
      'brand': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'model_title': new FormControl(null),
      'model': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'repairman_title': new FormControl(null),
      'repairman': new FormControl(null),
      'creator_title': new FormControl(null),
      'creator': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'shelf_title': new FormControl(null),
      'shelf': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'approximate': new FormControl(null),
      'estimated_time': new FormControl(null),
      'password': new FormControl(null),
      'pattern': new FormControl(null),
      'urgent': new FormControl(null),
      'date1': new FormControl(null),
      'date2': new FormControl(null),
      'objections': new FormControl(null),
      'belong': new FormControl(null),
      'color': new FormControl('#000000'),
      'device_status': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),

    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['customer'].setValidators([Validators.required]);
      this.form1_group.controls['device'].setValidators([Validators.required]);
      this.form1_group.controls['brand'].setValidators([Validators.required]);
      this.form1_group.controls['model'].setValidators([Validators.required]);
    }
    if (this.type_task == 2) {
      this.form1_group.controls['status'].setValidators([Validators.required]);
    }
  }
  get_title(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1065, code: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.title = res['result'][0].title;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_reception_id(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6611, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.reception_id = res['result'][0].repairman_reception_id2 + 1;
          } else {
            this.reception_id = 1;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6
      , type: 'user'
      , user_id: this.user_id
      , id: this.code
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.show_cellphone = res['show_cellphone'];
          if (this.lang == 1) this.today = res['today'];
          if (this.lang == 2) this.today = res['today2'];
          this.today_of_week = new Date().toLocaleString('fa', { weekday: 'long' });
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.expire = res['expire'];
          this.loading = false;

          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }

          if (type_task == 2) { this.update2(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
            this.get_reception_id();
            this.get_title();
            this.get_default_device();
            this.get_default_reception_type();
            this.get_default_shelf();
            this.get_default_device_status();
            this.count_device_today();
          }
          if (this.target_user_id > 0) {
            this.patch_selected_user();
          }

        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: 157 }
    })
  }
  open_video2() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: 166 }
    })
  }
  search(excel: number) {
    this.matDialogRef.close({
      reception_id: this.form1_group.value.reception_id,
      searial: this.form1_group.value.searial,
      comment: this.form1_group.value.comment,
      comment2: this.form1_group.value.comment2,
      customer_title: this.form1_group.value.customer_title,
      customer: this.form1_group.value.customer,
      device_title: this.form1_group.value.device_title,
      device: this.form1_group.value.device,
      type_title: this.form1_group.value.type_title,
      type: this.form1_group.value.type,
      brand_title: this.form1_group.value.brand_title,
      brand: this.form1_group.value.brand,
      model_title: this.form1_group.value.model_title,
      model: this.form1_group.value.model,
      status: this.form1_group.value.status,
      repairman_title: this.form1_group.value.repairman_title,
      repairman: this.form1_group.value.repairman,
      creator_title: this.form1_group.value.creator_title,
      creator: this.form1_group.value.creator,
      shelf_title: this.form1_group.value.shelf_title,
      shelf: this.form1_group.value.shelf,
      approximate: this.form1_group.value.approximate,
      estimated_time: this.form1_group.value.estimated_time,
      password: this.form1_group.value.password,
      pattern: this.form1_group.value.pattern,
      urgent: this.form1_group.value.urgent,
      date1: this.form1_group.value.date1,
      date2: this.form1_group.value.date2,
      device_status: this.form1_group.value.device_status,
      export_excel: excel
    })
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1730, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'reception_date': res['result'][0].repairman_reception_date,
              'comment': res['result'][0].repairman_reception_comment,
              'comment2': res['result'][0].repairman_reception_comment2,
              'searial': res['result'][0].repairman_reception_searial,
              'customer': res['result'][0].repairman_reception_customer,
              'customer_title': res['result'][0].user_title,
              'device': res['result'][0].repairman_model_device,
              'device_title': res['result'][0].wharehouse_device_title,
              'type': res['result'][0].reception_type_id,
              'type_title': res['result'][0].reception_type_title,
              'brand': res['result'][0].repairman_model_brand,
              'brand_title': res['result'][0].repairman_brand_title,
              'model': res['result'][0].repairman_reception_model,
              'model_title': res['result'][0].repairman_model_title,
              'status': parseInt(res['result'][0].repairman_reception_status),
              'repairman': res['result'][0].repairman_reception_repairman,
              'repairman_title': res['result'][0].repairman_title,
              'shelf': res['result'][0].repairman_reception_shelf,
              'shelf_title': res['result'][0].repairman_shelf_title,
              'approximate': res['result'][0].repairman_reception_approximate,
              'estimated_time': res['result'][0].repairman_reception_estimated_time,
              'password': res['result'][0].repairman_reception_password,
              'pattern': res['result'][0].repairman_reception_pattern,
              'urgent': res['result'][0].repairman_reception_urgent,
              'device_status': res['result'][0].repairman_device_status_id,
              'color': res['result'][0].repairman_reception_color
            });
            this.rstatus = res['result'][0].repairman_reception_status;
            this.shelf_temp = res['result'][0].repairman_reception_shelf;
          }//
          this.count_order = res['result'][0].count_order;
          if (res['result'][0].repairman_rstatus_repairing == 1 || this.count_order > 0) {
            this.repairman_status_required = true;
          }
          this.rstatus_temp = res['result'][0].repairman_reception_status;
          this.get_rstatus();
          this.get_belong_selected();
          this.get_objections_selected();
          this.check_shelf();
          this.get_repairmans(res['result'][0].repairman_reception_repairman);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_repairmans(items: string) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6814, items: items }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'repairman_title': res['result'][0].user_title
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  save(type: any) {
    if (type == 'close') {
      this.isclose = 1;
    }
    if (type == 'print') {
      this.isprint = 1;
      this.isclose = 1;
    }
    this.check_access(this.type_task, 1);
  }
  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    //******************* */
    this.belong_selected = [];
    for (var i = 0; i < this.list_belong.length; i++) {
      this.belong_selected.push(this.list_belong[i].repairman_belong2_id);
    }

    this.objections_selected = [];
    for (var i = 0; i < this.list_objections.length; i++) {
      this.objections_selected.push(this.list_objections[i].repairman_objections2_id);
    }
    //******************* */
    this.loading = true;
    var obj = {
      'address': 1726, "user_id": this.user_id
      , 'searial': this.form1_group.value.searial
      , 'reception_date': this.form1_group.value.reception_date
      , 'comment': this.form1_group.value.comment
      , 'comment2': this.form1_group.value.comment2
      , 'customer': this.form1_group.value.customer
      , 'model': this.form1_group.value.model
      , 'belong_selected': this.belong_selected
      , 'objections_selected': this.objections_selected
      , 'repairman': this.form1_group.value.repairman
      , 'shelf': this.form1_group.value.shelf
      , 'approximate': this.form1_group.value.approximate
      , 'estimated_time': this.form1_group.value.estimated_time
      , 'password': this.form1_group.value.password
      , 'pattern': this.form1_group.value.pattern
      , 'urgent': this.form1_group.value.urgent
      , 'device_status': this.form1_group.value.device_status
      , 'color': this.form1_group.value.color
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          //***************************برای این که اینجا بفهمیم پیامک برای مشتری با توجه به تیک تنظیمات و فرمت شماره ارسال خواهد شد یا نه******************* */
          if (res['result'][0].user_sms == 1) {
            if (res['result'][0].user_cellPhone == null || res['result'][0].user_cellPhone.length != 11) {
              res['result'][0].confirm_sms = false;
            } else {
              res['result'][0].confirm_sms = true;
            }
          }
          else res['result'][0].confirm_sms = false;
          //********************************************** */
          this.result = res['result'][0];
          if (this.form1_group.value.shelf > 0) {
            this.set_status_shelf_after_insert(res['result'][0].repairman_reception_shelf, res['result'][0].repairman_reception_id);
          } else {
            this.show_message_after_insert();
          }
        }//end if
        else if (res['status'] == 4) {
          var message = "شماره قفسه متاسفانه قبلا پر شده است";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  set_status_shelf_after_insert(shelf: number, reception: number) {
    var obj = {
      address: 1787,
      shelf: shelf,
      reception: reception,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.set_shelf_log_after_insert(reception);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  set_shelf_log_after_insert(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6124, id: id, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.show_message_after_insert();
        }//end if
        else {
          var message = "خطا در ثبت لاگ";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  show_message_after_insert() {
    if (this.result.confirm_sms == true) {
      this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
    }
    else {
      var message = "پذیرش با موفقیت ذخیره شد . اما پیامک برای مشتری ارسال نشد";
      var message2 = "Acceptance has been successfully saved. But the SMS was not sent to the customer";
      this.message(true, this.messageService.message(this.lang, message, message2), 1, this.messageService.close(this.lang));
    }
    this.matDialogRef.close({ result: this.result, token: this.token, isprint: this.isprint });
  }
  //*********************************************************************************************** */
  count_device_today() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6591, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.today_repairman_reception_default = res['result'][0].repairman_reception_default;
          this.repairman_rstatus_repairing = res['result'][0].repairman_rstatus_repairing;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update2(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    //******************* */
    this.belong_selected = [];
    for (var i = 0; i < this.list_belong.length; i++) {
      this.belong_selected.push(this.list_belong[i].repairman_belong2_id);
    }

    this.objections_selected = [];
    for (var i = 0; i < this.list_objections.length; i++) {
      this.objections_selected.push(this.list_objections[i].repairman_objections2_id);
    }
    //******************* */
    this.loading = true;
    var obj = {
      'address': 1727,
      "user_id": this.user_id,
      'reception_date': this.form1_group.value.reception_date,
      'searial': this.form1_group.value.searial,
      'comment': this.form1_group.value.comment,
      'comment2': this.form1_group.value.comment2,
      'customer': this.form1_group.value.customer,
      'model': this.form1_group.value.model,
      'type': this.form1_group.value.type,
      'rstatus': this.form1_group.value.status,
      'belong_selected': this.belong_selected,
      'objections_selected': this.objections_selected,
      'repairman': this.form1_group.value.repairman,
      'shelf': this.form1_group.value.shelf,
      'approximate': this.form1_group.value.approximate,
      'estimated_time': this.form1_group.value.estimated_time,
      'password': this.form1_group.value.password,
      'pattern': this.form1_group.value.pattern,
      'urgent': this.form1_group.value.urgent,
      'rstatus_temp': this.rstatus_temp,
      'device_status': this.form1_group.value.device_status,
      'color': this.form1_group.value.color,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          var confirm_sms = false;
          if (this.rstatus_temp != res['result'][0].repairman_reception_status && res['result'][0].user_sms2 == 1) {
            confirm_sms = window.confirm("آیا میخواهید " + res['result'][0].user_sex_title + " " + res['result'][0].user_title + " از تغییر وضعیت مطلع نمائید؟");
            res['result'][0].confirm_sms = confirm_sms;
          }
          this.result = res['result'][0];
          if (res['result'][0].repairman_rstatus_empty_shelf == 1) {
            res['result'][0].repairman_shelf_title = '';
            this.result = res['result'][0];
            this.empty_shelf(this.shelf_temp, 0, 'empty_reception');
          }
          else if (this.shelf_temp != res['result'][0].repairman_reception_shelf) {
            this.empty_shelf(this.shelf_temp, res['result'][0].repairman_reception_shelf, 'not_empty_reception');
          }
          else {
            this.matDialogRef.close({ result: this.result, token: this.token, isprint: this.isprint })
          }
        }//end if
        else if (res['status'] == 4) {
          var message = "شماره قفسه متاسفانه قبلا پر شده است";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  empty_shelf(shelf: any, new_shelf: number, type: string) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1811, shelf: shelf, type: type, id: this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (new_shelf == 0) {
            this.matDialogRef.close(
              { result: this.result, token: this.token, isprint: this.isprint }
            );
          } else {
            this.set_status_shelf_after_update(new_shelf, this.id);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  set_status_shelf_after_update(shelf: number, reception: number) {
    var obj = {
      address: 1787,
      shelf: shelf,
      reception: reception,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (this.result.confirm_sms == true) {
            this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
          } else {
            var message = "پذیرش با موفقیت ذخیره شد . اما پیامک برای مشتری ارسال نشد";
            var message2 = "Acceptance has been successfully saved. But the SMS was not sent to the customer";
            this.message(true, this.messageService.message(this.lang, message, message2), 1, this.messageService.close(this.lang));
          }
          if (this.form1_group.value.shelf > 0) this.set_shelf_log(reception);
          else this.matDialogRef.close({ result: this.result, token: this.token, isprint: this.isprint });
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  set_shelf_log(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6124, id: id, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ result: this.result, token: this.token, isprint: this.isprint });
        }//end if
        else {
          var message = "خطا در ثبت لاگ";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***************************************************************************** */
  load_services(id: number, type: number): any {
    if (this.readonly == true) {
      return false;
    }
    if (id == 260) {
      return false;
    }
    var code_id;
    var where = "";
    if (id == 164) {
      if (!this.form1_group.value.device) {
        var message = " برای انتخاب برند می بایست دستگاه انتخاب شده باشد.";
        this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        return false;
      }
      code_id = 6704;
    }
    if (id == 163) {
      if (!this.form1_group.value.device || !this.form1_group.value.brand) {
        var message = "برای درج جدید متعلقات ، باید نوع دستگاه و برنذ در ابتدا مشخص شود";
        this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        return false;
      } else {
        where += " and repairman_model_device = " + this.form1_group.value.device;
        where += " and repairman_model_brand = " + this.form1_group.value.brand;
      }
    }
    if (id == 220 && type == 1) {
      code_id = 6619;
    }
    if (id == 173) {
      where += " and repairman_shelf_status_empty = 1";
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: {
        id: id, where: where, brand: this.form1_group.value.brand, device: this.form1_group.value.device, creator: this.creator, code_id: code_id
        , obj: { device: this.form1_group.value.device }
      }
    });

    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (id == 154) {
            this.form1_group.patchValue({
              'device': res.id,
              'device_title': res.title,
              'brand_title': null,
              'brand': null,
              'model': null,
              'model_title': null
            })
            this.list_belong = [];
            this.list_objections = [];
          }
          if (id == 220 && type == 1) {
            this.form1_group.patchValue({
              'creator': res.id,
              'creator_title': res.title,
            })
          }
          if (id == 260) {
            this.form1_group.patchValue({
              'type': res.id,
              'type_title': res.title,
            })
          }
          if (id == 220 && type == 2) {
            this.form1_group.patchValue({
              'repairman': res.id,
              'repairman_title': res.title,
            })
          }
          if (id == 164) {
            this.form1_group.patchValue({
              'brand': res.id,
              'brand_title': res.title,
              'model': null,
              'model_title': null
            })
          }
          if (id == 163) {
            this.form1_group.patchValue({
              'model': res.id,
              'model_title': res.title
            })
          }
          if (id == 173) {
            this.form1_group.patchValue({
              'shelf': res.id,
              'shelf_title': res.title
            })
          }
        }
      }
    )
  }
  get_rstatus(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = { address: 1993, creator: this.creator, lang: this.lang }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].repairman_rstatus_show_menu == 1) {
              this.list_rstatus.push(res['result'][i]);
            } else {
              this.list_rstatus2.push(res['result'][i]);
            }
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  reset_input(id: number, type: number) {
    if (id == 154) {
      this.form1_group.patchValue({
        'device_title': null,
        'device': null,
        'brand_title': null,
        'brand': null,
        'model_title': null,
        'model': null
      })
    }
    if (id == 164) {
      this.form1_group.patchValue({
        'brand_title': null,
        'brand': null,
        'model_title': null,
        'model': null
      })
    }
    if (id == 163) {
      this.form1_group.patchValue({
        'model_title': null,
        'model': null
      })
    }
    if (id == 260) {
      this.form1_group.patchValue({
        'type': null,
        'type_title': null,
      })
    }
    if (id == 220 && type == 2) {
      this.form1_group.patchValue({
        'repairman': null,
        'repairman_title': null,
      })
    }
    if (id == 220 && type == 1) {
      this.form1_group.patchValue({
        'creator': null,
        'creator_title': null,
      })
    }
    if (id == 173) {
      this.form1_group.patchValue({
        'shelf': null,
        'shelf_title': null,
      })
    }
  }
  check_letter(event: any): any {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90) || charCode == 32 || charCode == 43) {
    } else {
      event.preventDefault();
      return false;
    }
  }
  change_letter() {
    this.form1_group.patchValue({
      'searial': this.form1_group.value.searial.toUpperCase()
    })
  }
  change_rstatus(repairman: boolean) {
    if (repairman == true || this.count_order > 0) {
      this.repairman_status_required = true;
    } else {
      this.repairman_status_required = false;
    }
  }
  add_model(): any {
    if (!this.form1_group.value.device || !this.form1_group.value.brand) {
      var message = "برای درج جدید متعلقات ، باید نوع دستگاه و برند در ابتدا مشخص شود";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(InsertModelComponent, {
      width: '20rem',
      height: 'auto',
      disableClose: true,
      data: {
        brand: this.form1_group.value.brand
        , device: this.form1_group.value.device
        , creator: this.creator
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.form1_group.patchValue({
            'model': res.model,
            'model_title': res.model_title
          })
        }
      }
    )
  }
  add_device(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '22rem',
      height: 'auto',
      data: { creator: this.creator, service: 'device' }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.form1_group.patchValue({
            'device': res.wharehouse_device_id,
            'device_title': res.wharehouse_device_title,
            'model': null,
            'model_title': null
          });
        }
      }
    )
  }
  add_brand(): any {
    if (!this.form1_group.value.device) {
      var message = " برای درج برند می بایست دستگاه انتخاب شده باشد.";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '22rem',
      height: 'auto',
      data: { creator: this.creator, service: 'brand', device: this.form1_group.value.device }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.form1_group.patchValue({
            'brand': res.repairman_brand_id,
            'brand_title': res.repairman_brand_title
          })
        }
      }
    )
  }
  //*********************************************************************************** */
  add_user() {
    var dialogRef = this.dialog.open(UserDetaile2Component, {
      width: '45rem',
      height: 'auto',
      data: { creator: this.creator, level: this.level }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        this.form1_group.patchValue({
          'customer_title': res['result'][0].user_sex_title + " " + res['result'][0].user_title,
          'customer': res['result'][0].user_id,
        });
      }
    )
  }
  open_select_select() {
    const dialog = this.dialog.open(UserSelectRepirmanComponent, {
      width: '32rem',
      height: 'auto',
      data: {
        creator: this.creator, type_task: this.type_task
        , repairman: this.form1_group.value.repairman
        , repairman_title: this.form1_group.value.repairman_title
        , show_cellphone: this.show_cellphone
      }
    });
    dialog.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.form1_group.patchValue({
            'repairman': res.items,
            'repairman_title': res.items_title,
          });
        }
      }
    )
  }
  get_default_device(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1970, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'device_title': res['result'][0].wharehouse_device_title,
              'device': res['result'][0].wharehouse_device_id,
            });
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_reception_type(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6725, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'type_title': res['result'][0].reception_type_title,
              'type': res['result'][0].reception_type_id,
            });
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_device_status() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6466 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'device_status': res['result'][0].repairman_device_status_id,
            });
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_shelf(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1791, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1)
            this.form1_group.patchValue({
              'shelf_title': res['result'][0].repairman_shelf_title,
              'shelf': res['result'][0].repairman_shelf_id,
            })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*********************************************************************************** */
  close() {
    this.matDialogRef.close();
  }
  open_user(): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(UserSearch2Component, {
      width: '40rem',
      height: 'auto',
      data: { code: this.code }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.form1_group.patchValue({
            'customer_title': res.title,
            'customer': res.id
          })
        }//end if
      }
    )
  }
  reset_customer() {
    this.form1_group.patchValue({
      customer_title: null,
      customer: null
    })
  }
  //****************************************************************************** */
  add_belong(): any {
    if (this.readonly == true) {
      return false;
    }
    if (!this.form1_group.value.device) {
      var message = "برای درج جدید متعلقات ، باید نوع دستگاه در ابتدا مشخص شود";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(InsertBelongComponent, {
      width: '20rem',
      height: 'auto',
      data: { device: this.form1_group.value.device, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.list_belong.unshift(res);
        }
      }
    )
  }
  open_belong() {
    var belong_selected: any = [];
    for (var i = 0; i < this.list_belong.length; i++) {
      belong_selected.push(this.list_belong[i].repairman_belong2_id);
    }
    this.dialog.open(OpenBelongComponent, {
      width: '35rem',
      height: 'auto',
      data: { type: 'belong', device: this.form1_group.value.device, belong_selected: belong_selected }
    })
  }
  get_belong_selected() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6448, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_belong = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_belong.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  delete_belong(id: number) {
    this.list_belong.splice(this.list_belong.findIndex((index: any) => index.repairman_belong2_id == id), 1)
  }
  //****************************************************************************** */
  add_objections(): any {
    if (this.readonly == true) {
      return false;
    }
    if (!this.form1_group.value.device) {
      var message = "برای درج جدید متعلقات ، باید نوع دستگاه در ابتدا مشخص شود";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(InsertObjectionsComponent, {
      width: '20rem',
      height: 'auto',
      data: { device: this.form1_group.value.device, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.list_objections.unshift(res);
        }
      }
    )
  }
  open_objections() {
    var objections_selected: any = [];
    for (var i = 0; i < this.list_objections.length; i++) {
      objections_selected.push(this.list_objections[i].repairman_objections2_id);
    }
    this.dialog.open(OpenBelongComponent, {
      width: '35rem',
      height: 'auto',
      data: { type: 'objections', device: this.form1_group.value.device, objections_selected: objections_selected }
    })
  }
  get_objections_selected() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6449, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_objections = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_objections.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  delete_objections(id: number) {
    this.list_objections.splice(this.list_objections.findIndex((index: any) => index.repairman_objections2_id == id), 1)
  }
  //****************************************************************************** */
  get_bg(id: number) {
    this.id = id;
  }
  check_shelf() {
    var obj = {
      address: 6565,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.empty_shelf2(res['result'][i].repairman_shelf_id)
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  empty_shelf2(id: number) {
    var obj = {
      address: 6566,
      id: id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  patch_selected_user(): any {
    this.form1_group.patchValue({
      'customer_title': this.target_user_title,
      'customer': this.target_user_id
    })
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }//end if
  }//end OnDestroy
}
