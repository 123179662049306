import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-finance-cardex-personnel-detaile',
  templateUrl: './finance-cardex-personnel-detaile.component.html',
  styleUrls: ['./finance-cardex-personnel-detaile.component.scss']
})
export class FinanceCardexPersonnelDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: any = this.serverService.get_server_main();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public loading: boolean = false;
  public type_task: number = 3;
  public code: number = 255;
  public search_array: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public user_id!: number;
  public level!: number;
  public id!: number;
  public creator!: number;
  public access_service!: number;
  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FinanceCardexPersonnelDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form1();
    if (dialog_data) {
      if (this.dialog_data) this.creator = this.dialog_data.creator;
      this.form1_group.patchValue({
        user: dialog_data.search_array.user,
        user_title: dialog_data.search_array.user_title,
        date1: dialog_data.search_array.date1,
        date2: dialog_data.search_array.date2,
      })
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'user_title': new FormControl(null),
      'user': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'date1': new FormControl(null),
      'date2': new FormControl(null),
    });
  }
  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.loading = false;
          if (type_task == 1) { }
          if (type_task == 2) { }
          if (type_task == 3) { this.search(); }
          if (type_task == 4) { }
          if (type_task == 5) {
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search() {
    this.matDialogRef.close({
      user: this.form1_group.value.user,
      user_title: this.form1_group.value.user_title,
      date1: this.form1_group.value.date1,
      date2: this.form1_group.value.date2,
    })
    var date1;
    var date2;
    if (this.form1_group.value.date1) { date1 = `${this.form1_group.value.date1.toISOString()}`; }
    if (this.form1_group.value.date2) { date2 = `${this.form1_group.value.date2.toISOString()}`; }
    var obj = {
      'user': this.form1_group.value.user,
      'date1': date1,
      'date2': date2
    };
    this.router.navigate(['/home/cardex-personnel/255'], { queryParams: obj });
    this.close();
  }
  close() {
    var obj = {
      'user': this.form1_group.value.user,
      'user_title': this.form1_group.value.user_title,
      'date1': this.form1_group.value.date1,
      'date2': this.form1_group.value.date2
    }
    this.matDialogRef.close(obj);
  }
  load_services(id: number) {
    var code_id;
    if (id == 220) {
      code_id = 6671;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator, user_id: this.user_id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 220) {
            this.form1_group.patchValue({
              'user': res.id,
              'user_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number) {
    if (id == 220) {
      this.form1_group.patchValue({
        'user_title': null,
        'user': null
      })
    }
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

