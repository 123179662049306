import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hide_cellphone'
})
export class CellphonePipe implements PipeTransform {

  transform(value: string): any {
    var x;
    if (value) {
      x = value.replace(value.substr(4, 4), '****');
    }
    else {

    }
    return x;

  }

}
